import { MapsActionTypes } from 'client/redux/actions/types'
import { IBuildingJson } from 'shared/json/IBuildingJson'
import { IMapJson } from 'shared/json/IMapJson'
import { IMapLocationContentJson } from 'shared/json/IMapLocationContentJson'

export const fetchBuildings = () => ({ type: MapsActionTypes.MAPS_BUILDINGS_FETCH_START })
export const fetchBuildingsSuccess = (buildings: IBuildingJson[]) => ({
  type: MapsActionTypes.MAPS_BUILDINGS_FETCH_SUCCESS,
  buildings
})
export const fetchBuildingsError = () => ({ type: MapsActionTypes.MAPS_BUILDINGS_FETCH_ERROR })

export interface ICreateBuildingAction {
  type: MapsActionTypes.MAPS_BUILDING_CREATE_START
  name: string
  latitude?: string | null
  longitude?: string | null
}

type ICreateBuildingArgs = {
  name: string
  latitude?: string | null
  longitude?: string | null
}
export const createBuilding = (args: ICreateBuildingArgs): ICreateBuildingAction => ({
  type: MapsActionTypes.MAPS_BUILDING_CREATE_START,
  ...args
})

export const createBuildingSuccess = (building: IBuildingJson) => ({
  type: MapsActionTypes.MAPS_BUILDING_CREATE_SUCCESS,
  building
})

export const createBuildingError = () => ({
  type: MapsActionTypes.MAPS_BUILDING_CREATE_ERROR
})

export const createBuildingComplete = () => ({
  type: MapsActionTypes.MAPS_BUILDING_CREATE_COMPLETE
})

export interface IUpdateBuilding {
  type: MapsActionTypes.MAPS_BUILDING_UPDATE_START
  building: IBuildingJson
}
export const updateBuilding = (building): IUpdateBuilding => ({
  type: MapsActionTypes.MAPS_BUILDING_UPDATE_START,
  building
})

export const updateBuildingSuccess = (building: IBuildingJson) => ({
  type: MapsActionTypes.MAPS_BUILDING_UPDATE_SUCCESS,
  building
})

export const updateBuildingError = () => ({ type: MapsActionTypes.MAPS_BUILDING_UPDATE_ERROR })

export interface IDeleteBuildingAction {
  type: MapsActionTypes.MAPS_BUILDING_DELETE_START
  id: number
  isSelected: boolean
}

export const deleteBuilding = (id: number, isSelected: boolean): IDeleteBuildingAction => ({
  type: MapsActionTypes.MAPS_BUILDING_DELETE_START,
  id,
  isSelected
})

export const deleteBuildingSuccess = (id: number) => ({
  type: MapsActionTypes.MAPS_BUILDING_DELETE_SUCCESS,
  id
})

export const deleteBuildingError = () => ({
  type: MapsActionTypes.MAPS_BUILDING_DELETE_ERROR
})

export const fetchExterior = () => ({
  type: MapsActionTypes.MAPS_EXTERIOR_FETCH_START
})
export const fetchExteriorSuccess = (exterior: IMapJson) => ({
  type: MapsActionTypes.MAPS_EXTERIOR_FETCH_SUCCESS,
  exterior
})

export const fetchExteriorError = () => ({
  type: MapsActionTypes.MAPS_EXTERIOR_FETCH_ERROR
})

export interface IUpdateExteriorMap {
  type: MapsActionTypes.MAPS_EXTERIOR_UPDATE_START
  image?: File
  isGoogleMap?: boolean
  cameraCenterLatitude?: number
  cameraCenterLongitude?: number
  cameraHeading?: number
  cameraZoom?: number
}
export const updateExteriorMap = ({
  image,
  isGoogleMap,
  cameraCenterLatitude,
  cameraCenterLongitude,
  cameraHeading,
  cameraZoom
}: Omit<IUpdateExteriorMap, 'type'>): IUpdateExteriorMap => ({
  type: MapsActionTypes.MAPS_EXTERIOR_UPDATE_START,
  image,
  isGoogleMap,
  cameraCenterLatitude,
  cameraCenterLongitude,
  cameraHeading,
  cameraZoom
})

interface IUpdateExteriorMapSuccess {
  type: MapsActionTypes.MAPS_EXTERIOR_UPDATE_SUCCESS
  exterior: IMapJson
}
export const updateExteriorMapSuccess = (exterior: IMapJson): IUpdateExteriorMapSuccess => ({
  type: MapsActionTypes.MAPS_EXTERIOR_UPDATE_SUCCESS,
  exterior
})

export const updateExteriorMapError = (error) => ({
  type: MapsActionTypes.MAPS_EXTERIOR_UPDATE_ERROR,
  error
})

export interface IDeleteExteriorMap {
  type: MapsActionTypes.MAPS_EXTERIOR_DELETE_START
  isExteriorMapSelected?: boolean
}
export const deleteExteriorMap = (isExteriorMapSelected?: boolean): IDeleteExteriorMap => ({
  type: MapsActionTypes.MAPS_EXTERIOR_DELETE_START,
  isExteriorMapSelected
})

export const deleteExteriorMapSuccess = () => ({
  type: MapsActionTypes.MAPS_EXTERIOR_DELETE_SUCCESS,
  exterior: {}
})

export const deleteExteriorMapError = (error) => ({
  type: MapsActionTypes.MAPS_EXTERIOR_DELETE_ERROR,
  error
})

type IContentPayload =
  | {
      itemId: number
      featured: boolean | undefined
      updateAllMapLocations: boolean | undefined
    }
  | {
      exhibitId: number | undefined
      featured: boolean | undefined
      updateAllMapLocations: boolean | undefined
    }

export interface IPinDataPayload {
  x: number
  y: number
  radius: number
  name: string
  content: IContentPayload[]
  latitude?: number
  longitude?: number
}

export interface IFetchPinContentAction {
  floorId: number
  locationId: number
  type: MapsActionTypes.FETCH_PIN_CONTENT_START
}

export const fetchPinContent = (floorId: number, locationId: number): IFetchPinContentAction => ({
  floorId,
  locationId,
  type: MapsActionTypes.FETCH_PIN_CONTENT_START
})

interface IFetchPinContentSuccessAction {
  mapLocationContent: IMapLocationContentJson[]
  locationId: number
  type: MapsActionTypes.FETCH_PIN_CONTENT_SUCCESS
}

export const fetchPinContentSuccess = (
  mapLocationContent: IMapLocationContentJson[],
  locationId: number
): IFetchPinContentSuccessAction => ({
  type: MapsActionTypes.FETCH_PIN_CONTENT_SUCCESS,
  locationId,
  mapLocationContent
})

export interface IExteriorPinContentCreateAction {
  type: MapsActionTypes.MAPS_EXTERIOR_PIN_CREATE_START
  mapId: number
  locationData: IPinDataPayload
}

export const createExteriorMapPin = (
  mapId: number,
  locationData: IPinDataPayload
): IExteriorPinContentCreateAction => ({
  type: MapsActionTypes.MAPS_EXTERIOR_PIN_CREATE_START,
  mapId,
  locationData
})

export const createExteriorMapPinSuccess = ({ location }) => ({
  type: MapsActionTypes.MAPS_EXTERIOR_PIN_CREATE_SUCCESS,
  location
})

export const createExteriorMapPinError = () => ({
  type: MapsActionTypes.MAPS_EXTERIOR_PIN_CREATE_ERROR
})

export interface IPinDataContentUpdate {
  id: number
  name: string
  content: IContentPayload[]
}

export interface IExteriorPinContentUpdateAction {
  type: MapsActionTypes.MAPS_EXTERIOR_PIN_LINKED_CONTENT_UPDATE_START
  mapId: number
  locationData: IPinDataContentUpdate
}

export const updateExteriorMapPinContent = (
  mapId: number,
  locationData: IPinDataContentUpdate
): IExteriorPinContentUpdateAction => ({
  type: MapsActionTypes.MAPS_EXTERIOR_PIN_LINKED_CONTENT_UPDATE_START,
  mapId,
  locationData
})

export const updateExteriorMapPinContentSuccess = ({ location }) => ({
  type: MapsActionTypes.MAPS_EXTERIOR_PIN_LINKED_CONTENT_UPDATE_SUCCESS,
  location
})

export const updateExteriorMapPinContentError = () => ({
  type: MapsActionTypes.MAPS_EXTERIOR_PIN_LINKED_CONTENT_UPDATE_ERROR
})

export interface IExteriorPinDeleteAction {
  type: MapsActionTypes.MAPS_EXTERIOR_PIN_DELETE_START
  mapId: number
  pinId: Number
}

export const deleteExteriorMapPin = ({ mapId, pinId }): IExteriorPinDeleteAction => ({
  type: MapsActionTypes.MAPS_EXTERIOR_PIN_DELETE_START,
  mapId,
  pinId
})

export const deleteExteriorMapPinSuccess = ({ mapId, pinId }) => ({
  type: MapsActionTypes.MAPS_EXTERIOR_PIN_DELETE_SUCCESS,
  mapId,
  pinId
})

export const deleteExteriorMapPinError = () => ({
  type: MapsActionTypes.MAPS_EXTERIOR_PIN_DELETE_ERROR
})

export interface ICreateBuildingFloor {
  type: MapsActionTypes.MAPS_BUILDING_FLOOR_CREATE_START
  buildingId: number
  image: File
}
export const createBuildingFloor = (buildingId: number, image: File): ICreateBuildingFloor => ({
  type: MapsActionTypes.MAPS_BUILDING_FLOOR_CREATE_START,
  buildingId,
  image
})

export const createBuildingFloorSuccess = (floor: IMapJson) => ({
  type: MapsActionTypes.MAPS_BUILDING_FLOOR_CREATE_SUCCESS,
  floor
})

export const createBuildingFloorError = () => ({
  type: MapsActionTypes.MAPS_BUILDING_FLOOR_CREATE_ERROR
})

export interface IUpdateBuildingFloorOrder {
  type: MapsActionTypes.MAPS_BUILDING_FLOORS_ORDER_UPDATE_START
  buildingId: number
  orderedFloorIds: string[]
}
export const updateBuildingFloorOrder = (
  buildingId: number,
  orderedFloorIds: string[]
): IUpdateBuildingFloorOrder => ({
  type: MapsActionTypes.MAPS_BUILDING_FLOORS_ORDER_UPDATE_START,
  buildingId,
  orderedFloorIds
})
export const updateBuildingFloorOrderSuccess = ({ buildingId, floors }) => ({
  type: MapsActionTypes.MAPS_BUILDING_FLOORS_ORDER_UPDATE_SUCCESS,
  buildingId,
  floors
})
export const updateBuildingFloorOrderError = () => ({
  type: MapsActionTypes.MAPS_BUILDING_FLOORS_ORDER_UPDATE_ERROR
})

export interface IUpdateBuildingFloor {
  type: MapsActionTypes.MAPS_BUILDING_FLOOR_UPDATE_START
  floor: IMapJson
  image?: File
}
export const updateBuildingFloor = (floor: IMapJson, image?: File): IUpdateBuildingFloor => ({
  type: MapsActionTypes.MAPS_BUILDING_FLOOR_UPDATE_START,
  floor,
  image
})

export const updateBuildingFloorSuccess = ({ buildingId, floors }) => ({
  type: MapsActionTypes.MAPS_BUILDING_FLOOR_UPDATE_SUCCESS,
  buildingId,
  floors
})

export const updateBuildingFloorError = () => ({
  type: MapsActionTypes.MAPS_BUILDING_FLOOR_UPDATE_ERROR
})

export interface IDeleteBuildingFloor {
  type: MapsActionTypes.MAPS_BUILDING_FLOOR_DELETE_START
  buildingId: number
  floorId: number
  isFloorSelected?: boolean
}

export const deleteBuildingFloor = (
  buildingId: number,
  floorId: number,
  isFloorSelected?: boolean
): IDeleteBuildingFloor => ({
  type: MapsActionTypes.MAPS_BUILDING_FLOOR_DELETE_START,
  buildingId,
  floorId,
  isFloorSelected
})

interface IDeleteBuildingFloorSuccess {
  buildingId: number
  floors: IMapJson[]
}
export const deleteBuildingFloorSuccess = ({
  buildingId,
  floors
}: IDeleteBuildingFloorSuccess) => ({
  type: MapsActionTypes.MAPS_BUILDING_FLOOR_DELETE_SUCCESS,
  buildingId,
  floors
})

export const deleteBuildingFloorError = () => ({
  type: MapsActionTypes.MAPS_BUILDING_FLOOR_DELETE_ERROR
})

export interface ICreateBuildingFloorPin {
  type: MapsActionTypes.MAPS_BUILDING_FLOOR_PIN_CREATE_START
  buildingId: number
  floorId: number
  locationData: IPinDataPayload
}
export const createBuildingFloorPin = (
  buildingId: number,
  floorId: number,
  locationData: IPinDataPayload
): ICreateBuildingFloorPin => ({
  type: MapsActionTypes.MAPS_BUILDING_FLOOR_PIN_CREATE_START,
  buildingId,
  floorId,
  locationData
})

export const createBuildingFloorPinSuccess = ({ buildingId, floorId, location }) => ({
  type: MapsActionTypes.MAPS_BUILDING_FLOOR_PIN_CREATE_SUCCESS,
  buildingId,
  floorId,
  location
})

export const createBuildingFloorPinError = () => ({
  type: MapsActionTypes.MAPS_BUILDING_FLOOR_PIN_CREATE_ERROR
})

export interface IUpdateFloorPinData {
  id: number
  coordinates?: {
    x: number
    y: number
  }
  radius?: number
}
export interface IUpdateFloorPin {
  buildingId?: number
  floorId: number
  pinData: IUpdateFloorPinData
}

export interface IUpdateBuildingFloorPin extends IUpdateFloorPin {
  type: MapsActionTypes.MAPS_BUILDING_FLOOR_PIN_UPDATE_START
}

export const updateBuildingFloorPin = ({
  buildingId,
  floorId,
  pinData
}: {
  buildingId?: number
  floorId: number
  pinData: IUpdateFloorPinData
}): IUpdateBuildingFloorPin => ({
  type: MapsActionTypes.MAPS_BUILDING_FLOOR_PIN_UPDATE_START,
  buildingId,
  floorId,
  pinData
})
export const updateBuildingFloorPinSuccess = ({ buildingId, floorId, location }) => ({
  type: MapsActionTypes.MAPS_BUILDING_FLOOR_PIN_UPDATE_SUCCESS,
  buildingId,
  floorId,
  location
})

export const updateBuildingFloorPinError = () => ({
  type: MapsActionTypes.MAPS_BUILDING_FLOOR_PIN_UPDATE_ERROR
})

export interface IUpdateExteriorFloorPin extends IUpdateFloorPin {
  type: MapsActionTypes.MAPS_EXTERIOR_PIN_UPDATE_START
}
export const updateExteriorPin = ({ floorId, pinData }: IUpdateFloorPin) => ({
  type: MapsActionTypes.MAPS_EXTERIOR_PIN_UPDATE_START,
  floorId,
  pinData
})
export const updateExteriorPinSuccess = ({ floorId, location }) => ({
  type: MapsActionTypes.MAPS_EXTERIOR_PIN_UPDATE_SUCCESS,
  floorId,
  location
})

export const updateExteriorPinError = () => ({
  type: MapsActionTypes.MAPS_EXTERIOR_PIN_UPDATE_ERROR
})

export interface IUpdateBuildingFloorPinContent {
  type: MapsActionTypes.MAPS_BUILDING_FLOOR_PIN_LINKED_CONTENT_UPDATE_START
  buildingId: number
  floorId: number
  locationData: IPinDataContentUpdate
}

export const updateBuildingFloorPinContent = (
  buildingId: number,
  floorId: number,
  locationData: IPinDataContentUpdate
): IUpdateBuildingFloorPinContent => ({
  type: MapsActionTypes.MAPS_BUILDING_FLOOR_PIN_LINKED_CONTENT_UPDATE_START,
  buildingId,
  floorId,
  locationData
})

export const updateBuildingFloorPinContentSuccess = ({ buildingId, floorId, location }) => ({
  type: MapsActionTypes.MAPS_BUILDING_FLOOR_PIN_LINKED_CONTENT_UPDATE_SUCCESS,
  buildingId,
  floorId,
  location
})

export const updateBuildingFloorPinContentError = () => ({
  type: MapsActionTypes.MAPS_BUILDING_FLOOR_PIN_LINKED_CONTENT_UPDATE_ERROR
})

export interface IDeleteBuildingFloorPin {
  type: MapsActionTypes.MAPS_BUILDING_FLOOR_PIN_DELETE_START
  buildingId: number
  floorId: number
  pinId: Number
}
export const deleteBuildingFloorPin = ({
  buildingId,
  floorId,
  pinId
}): IDeleteBuildingFloorPin => ({
  type: MapsActionTypes.MAPS_BUILDING_FLOOR_PIN_DELETE_START,
  buildingId,
  floorId,
  pinId
})

export const deleteBuildingFloorPinSuccess = ({ buildingId, floorId, pinId }) => ({
  type: MapsActionTypes.MAPS_BUILDING_FLOOR_PIN_DELETE_SUCCESS,
  buildingId,
  floorId,
  pinId
})

export const deleteBuildingFloorPinError = () => ({
  type: MapsActionTypes.MAPS_BUILDING_FLOOR_PIN_DELETE_ERROR
})

export const updateSelectedFloorPinId = (selectedId: number) => ({
  type: MapsActionTypes.MAPS_SELECTED_FLOOR_PIN_UPDATE,
  selectedId
})

export const updateSelectedBuildingId = (selectedId: number) => ({
  type: MapsActionTypes.MAPS_SELECTED_BUILDING_UPDATE,
  selectedId
})
