import _ from 'lodash'
import { useEffect, useState } from 'react'
import { EditorProvider, useCurrentEditor } from '@tiptap/react'
import Document from '@tiptap/extension-document'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import Bold from '@tiptap/extension-bold'
import Italic from '@tiptap/extension-italic'
import Link from '@tiptap/extension-link'
import HardBreak from '@tiptap/extension-hard-break'
import History from '@tiptap/extension-history'
import styled from 'styled-components'
import sanitizeHtml from 'sanitize-html'
import RichTextEditorToolbar from './RichTextEditorToolbar'

const EMPTY_VALUE = '<p></p>'

type EditorMode = 'single' | 'multi'

interface IContainerProps {
  mode: EditorMode
}

const Container = styled.div<IContainerProps>`
  display: flex;
  flex-direction: column;
  position: relative;
  font-size: 14px;
  background-color: var(--color-white);
  margin-top: 8px;

  .tiptap {
    height: ${({ mode }) => (mode === 'single' ? 40 : 150)}px;
    outline: 0;
    padding: ${({ mode }) => (mode === 'single' ? '8px 12px 4px 12px' : '10px')};
    overflow: auto;
    border-top: 1px solid var(--color-grey-02);

    > p:last-child {
      margin-bottom: 0px;
    }
  }
`

interface RTEChangeEvent {
  target: {
    name: string
    value: string | null
  }
}

const RTEModeConfigs = {
  multi: {
    extensions: [
      Document,
      Text,
      Paragraph,
      Bold,
      Italic,
      Link.extend({
        inclusive: false,
        addAttributes() {
          return {
            // just allow href and target attributes
            ..._.pick(this.parent?.(), ['href', 'target'])
          }
        }
      }).configure({
        openOnClick: false,
        HTMLAttributes: { rel: null }
      }),
      HardBreak,
      History
    ]
  },
  single: {
    extensions: [Document.extend({ content: 'block' }), Text, Paragraph, Italic, History]
  }
}
export interface IRichTextEditorProps {
  name: string
  value: string
  onChange: (event: RTEChangeEvent) => void
  onBlur?: () => void
  mode?: EditorMode
  className?: string
}

// TipTap doesn't re-initialize itself when content changes from the prop.
// This plugin re-initializes the editor when value changes from nil to non nil for the first time or if name changes
const EditorInitializer = ({
  name,
  value
}: {
  name: string
  value: IRichTextEditorProps['value']
}) => {
  const [isInitializedOnceOnValueChanged, setIsInitializedOnceOnValueChanged] = useState(
    !_.isNil(value)
  )
  const { editor } = useCurrentEditor()

  // Re-initialize for the first time if value is not nil
  useEffect(() => {
    if (!isInitializedOnceOnValueChanged && value) {
      editor?.commands.setContent(value)
      setIsInitializedOnceOnValueChanged(true)
    }
  }, [value, editor, isInitializedOnceOnValueChanged])

  // Re-initialize if the name changes.
  // We can use "key={name}" on the <EditorProvider />, but this is far more efficient and removes any flickering.
  useEffect(() => {
    editor?.commands.setContent(value)
    // We only want to update if "name" changes. Waiting on `useEffectEvent` to be released by react.
    // Until then, we ignore "value" deps as we don't want to update every time that changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor, name])

  return null
}

const RichTextEditor = (props: IRichTextEditorProps) => {
  const { name, value, mode = 'multi', className = '', onChange, onBlur = _.noop } = props
  const { extensions } = RTEModeConfigs[mode]

  const isLikeMSWordComment = (frame: sanitizeHtml.IFrame) => {
    const { attribs } = frame
    // From the different MSWord docs, these were the attribute properties I could find
    // in common to identify comments tags in the docs
    const msoCommentKeyTriggers = ['msocommentshow', 'msocommenthide']
    const attribsStr = JSON.stringify(attribs).toLowerCase()
    return _.some(msoCommentKeyTriggers, (keyword) => attribsStr.includes(keyword))
  }

  return (
    <Container mode={mode} className={className}>
      <EditorProvider
        editorProps={{
          transformPastedHTML: (html) => {
            // TipTap also sanitizes.
            // We're ONLY stripping off the microsoft word comments tags and
            // allowing everything else for TipTap to process
            const sanitized = sanitizeHtml(html, {
              allowedTags: false,
              allowedAttributes: false,
              allowVulnerableTags: true,
              exclusiveFilter: isLikeMSWordComment
            })
            return sanitized
          }
        }}
        extensions={extensions}
        content={value}
        slotBefore={<RichTextEditorToolbar mode={mode} />}
        onUpdate={({ editor }) => {
          const content = editor.getHTML()
          const parsed = content === EMPTY_VALUE ? null : content
          onChange({ target: { name, value: parsed } })
        }}
        onBlur={onBlur}
      >
        <EditorInitializer name={name} value={value} />
      </EditorProvider>
    </Container>
  )
}

export default RichTextEditor
