import Button from 'client/components/Button/Button'
import MenuPopout, { IMenuOption } from 'client/components/MenuPopout/MenuPopout'
import { Dialog, DialogActions, DialogBody, DialogHeader } from 'client/dsm/Dialog/Dialog'
import { usePost } from 'client/hooks/api'
import { flash } from 'client/redux/actions/flash'
import _ from 'lodash'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { GQLUser } from 'shared/graphql/types/graphql'
import { t } from 'client/i18n'
import UserMFAType from 'shared/UserMFAType'

interface IConfirmationDialog {
  onConfirm: () => void
  onCancel: () => void
}

const ResendEmailInviteConfirmation = (props: IConfirmationDialog) => {
  const { onCancel, onConfirm } = props
  return (
    <Dialog>
      <DialogHeader>{t('Resend Email Invite?')}</DialogHeader>
      <DialogBody>
        <p>
          {t(
            'This will send a new email and cause the previously sent email invite to no longer work.'
          )}
        </p>
        <p>{t('The new email invite will expire after 14 days.')}</p>
      </DialogBody>
      <DialogActions>
        <Button onClick={onCancel} label={t('Cancel')} />
        <Button type="primary" onClick={onConfirm} label={t('Resend Invite')} />
      </DialogActions>
    </Dialog>
  )
}

const ResetTwoFactorAuthenticationConfirmation = (props: IConfirmationDialog) => {
  const { onCancel, onConfirm } = props
  return (
    <Dialog>
      <DialogHeader>{t('Reset Two-Factor Authentication?')}</DialogHeader>
      <DialogBody>
        <p>{t('This user will need to re-enroll in Google Authenticator to log into the CMS.')}</p>
      </DialogBody>
      <DialogActions>
        <Button onClick={onCancel} label={t('Cancel')} />
        <Button type="primary" onClick={onConfirm} label={t('Reset')} />
      </DialogActions>
    </Dialog>
  )
}

interface IOverflowMenuProps {
  user: GQLUser
}
const OverflowMenu = (props: IOverflowMenuProps) => {
  const { user } = props

  const [showResendEmailInviteDialog, setShowResendEmailInviteDialog] = useState(false)
  const [showResetTwoFactorDialog, setShowResetTwoFactorDialog] = useState(false)

  const dispatch = useDispatch()

  const [resendInvite] = usePost(`/api/users/${user.id}/resend-invite`, {
    // TODO: Check for flash calls
    onSuccess: () => dispatch(flash(t('Invitation sent')))
  })
  const [mfaReset] = usePost(`/api/users/${user.id}/mfa-reset`, {
    onSuccess: () => dispatch(flash(t('Two-factor authentication reset')))
  })

  const menuOptions: IMenuOption[] = _.compact([
    user.mfaEnabled &&
      user.mfaType === UserMFAType.TOTP && {
        label: t('Reset Two-Factor Authentication'),
        onClick: () => setShowResetTwoFactorDialog(true)
      },
    user.canResendPlatformInvite && {
      label: t('Resend Email Invite'),
      onClick: () => setShowResendEmailInviteDialog(true)
    }
  ])

  return (
    <>
      <MenuPopout options={menuOptions} appendTo={document.body} placement="bottom-end" />
      {showResendEmailInviteDialog && (
        <ResendEmailInviteConfirmation
          onConfirm={() => {
            resendInvite()
            setShowResendEmailInviteDialog(false)
          }}
          onCancel={() => setShowResendEmailInviteDialog(false)}
        />
      )}
      {showResetTwoFactorDialog && (
        <ResetTwoFactorAuthenticationConfirmation
          onConfirm={() => {
            mfaReset()
            setShowResetTwoFactorDialog(false)
          }}
          onCancel={() => setShowResetTwoFactorDialog(false)}
        />
      )}
    </>
  )
}

export default OverflowMenu
