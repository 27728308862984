import styled from 'styled-components'
import { t } from 'client/i18n'
import { Header2CSS } from 'client/components/TextStyles'

const TipsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-height: 100%;
  padding: 20px;
  background-color: var(--color-black);
  box-shadow: var(--elevation-01);
  border-radius: 2px;
`

const Title = styled.div`
  ${Header2CSS};
  color: var(--color-white);
`

const TipsList = styled.ul`
  margin: 0;
  padding: 0;
  padding-left: 16px;
`

const Tip = styled.li`
  font-size: 12px;
  color: var(--color-white);
`

interface IComponentProps {
  tips: string[]
}

const Tips = (props: IComponentProps) => {
  const { tips = [] } = props

  const renderTips = () => tips.map((tip) => <Tip key={tip}>{tip}</Tip>)
  return (
    <TipsContainer>
      <Title>{t('Tips')}</Title>
      <div>
        <TipsList>{renderTips()}</TipsList>
      </div>
    </TipsContainer>
  )
}

export default Tips
