import { IFlashState } from 'client/types'
import createReducer from './createReducer'
import * as types from '../actions/types'

const initialState: IFlashState = {
  message: null
}
export const flash = createReducer(initialState, {
  [types.FLASH_MESSAGE](state, action) {
    return {
      ...state,
      message: { text: action.text, error: action.error }
    }
  }
})
