import { FunctionComponent, SVGProps, forwardRef, Ref } from 'react'
import styled from 'styled-components'
import _ from 'lodash'

interface IButtonBaseProps {
  hasSVG: boolean
  selected: boolean
}
const ButtonBase = styled.button<IButtonBaseProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none !important;
  padding: ${({ hasSVG }) => {
    // A button can have
    // - an SVG component AND text e.g., '+ Add New Section' OR
    // - only text e.g., 'Reorder Sections'
    return hasSVG ? '10px 20px' : '10px 16px'
  }};

  border: none;
  border-radius: 4px;
  color: var(--color-white);
  cursor: pointer;

  &:disabled {
    cursor: default;
    pointer-events: none;
  }

  // This div wraps the SVG component so height and width are respected and the SVG won't shrink in size when the user shrinks their window
  div {
    margin-right: 4px;
    margin-left: -2px;
  }

  span {
    height: 19px;
  }
`

const DangerButton = styled(ButtonBase)`
  background-color: var(--color-red-07);

  svg {
    color: var(--color-white);
  }

  &:hover:enabled,
  &:active:hover {
    background-color: var(--color-red-08);
  }

  &:disabled {
    background-color: var(--color-grey-04);
  }
`

const PrimaryButton = styled(ButtonBase)`
  background-color: var(--color-brand);

  svg {
    color: var(--color-white);
  }

  &:hover:enabled,
  &:active:hover {
    background-color: var(--color-blue-07);
  }

  &:disabled {
    background-color: var(--color-grey-04);
  }
`

const SecondaryButton = styled(ButtonBase)`
  color: var(--color-black);
  height: 40px;
  border: 1px solid var(--color-black);
  background-color: transparent;

  svg {
    color: var(--color-black);
  }

  &:hover:enabled,
  &:active:hover {
    color: var(--color-white);
    background-color: var(--color-grey-07);
    border-color: var(--color-grey-07);

    svg {
      color: var(--color-white);
    }
  }

  &:disabled {
    color: var(--color-grey-04);
    border-color: var(--color-grey-04);

    svg {
      color: var(--color-grey-04);
    }
  }
`

const TertiaryButton = styled(ButtonBase)`
  padding: 0px;
  color: var(--color-blue-07);
  background-color: transparent;

  svg {
    color: var(--color-blue-07);
  }

  ${({ selected }) => {
    if (selected) {
      return `
      color: var(--color-blue-08);

      svg {
        color: var(--color-blue-08);
      }
      `
    }
  }}

  &:hover:enabled,
  &:active:hover {
    color: var(--color-blue-08);

    svg {
      color: var(--color-blue-08);
    }
  }

  &:disabled {
    color: var(--color-grey-04);

    svg {
      color: var(--color-grey-04);
    }
  }
`

export interface IButtonProps {
  className?: string
  type?: 'danger' | 'primary' | 'secondary' | 'tertiary'
  disabled?: boolean
  selected?: boolean
  onClick?: () => void
  label: string
  svgComponent?: FunctionComponent<SVGProps<SVGSVGElement>>
}

const ButtonComponent = (props: IButtonProps, ref: Ref<HTMLButtonElement>) => {
  const {
    className = undefined,
    type = 'secondary',
    disabled = false,
    selected = false,
    onClick = _.noop,
    label,
    svgComponent: SVGComponent = null
  } = props

  let StyledButton = SecondaryButton
  if (type === 'primary') {
    StyledButton = PrimaryButton
  } else if (type === 'tertiary') {
    StyledButton = TertiaryButton
  } else if (type === 'danger') {
    StyledButton = DangerButton
  }

  return (
    <StyledButton
      type="button"
      className={className}
      onClick={onClick}
      disabled={disabled}
      selected={selected}
      ref={ref}
      hasSVG={!_.isNil(SVGComponent)}
    >
      {SVGComponent && (
        <div>
          <SVGComponent />
        </div>
      )}
      <span>{label}</span>
    </StyledButton>
  )
}

const Button = forwardRef(ButtonComponent)

export default Button
