import styled from 'styled-components'

export const SectionDivider = styled.div`
  margin: var(--spacing-large) 0px;
  border-top: var(--border-light);
`

export const LookupSectionContent = styled.div`
  display: flex;
  justify-content: space-between;
  & header {
    margin: 0;
  }
  margin-right: 20px;
`

export const SelectContainer = styled.div`
  width: 80px;
  margin-left: 8px;
`
