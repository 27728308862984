import * as React from 'react'
import { Dialog as DSMDialog } from 'client/dsm/Dialog/Dialog'
import styled from 'styled-components'

const BuildingFactoryDialog = styled(DSMDialog)`
  width: 390px;
`

const MapDialog = (props: { children: React.ReactNode }) => (
  <BuildingFactoryDialog>{props.children}</BuildingFactoryDialog>
)

export default MapDialog
