import { useDispatch, useSelector } from 'react-redux'
import BulkUploadData, { IReduxState } from 'client/types'
import { fetchBulkUpload } from 'client/redux/actions/bulkUpload'
import _ from 'lodash'

type LatestBulkUploadReturn = {
  data: BulkUploadData | null
  isProcessing?: BulkUploadData['isProcessing']
  refresh: () => any
}

function useLatestBulkUpload(): LatestBulkUploadReturn {
  const dispatch = useDispatch()
  const data = useSelector((state: IReduxState) => state.bulkUpload.data)
  const isProcessing = data?.isProcessing

  return {
    data,
    isProcessing,
    refresh: () => dispatch(fetchBulkUpload())
  }
}

export function useLatestBulkUploadStatus() {
  const data = useSelector((state: IReduxState) => state.bulkUpload.data)
  const isProcessing = data?.isProcessing
  const hasError = !_.isEmpty(data?.processingError)
  const contentHasMediaErrors = data?.contentHasMediaErrors

  return {
    isProcessing,
    hasError,
    contentHasMediaErrors
  }
}

export default useLatestBulkUpload
