import { IAuthError } from 'client/types'
import UserMFAType from 'shared/UserMFAType'
import * as types from './types'

export function login(email: string, password: string) {
  return { type: types.LOGIN_START, email, password }
}

interface ILoginSuccessPayload {
  data: any
  name: string
  firstName: string
  lastName: string
  permissionType: string
  mfaEnabled: boolean
  mfaEnrolled: boolean
  mfaType: UserMFAType
  isPasswordExpired: boolean
  privacyPolicyAcceptedAt: Date
  isUserLoggingInForFirstTime?: boolean
}
export function loginSuccess(payload: ILoginSuccessPayload) {
  const { data, ...restPayload } = payload
  return {
    type: types.LOGIN_SUCCESS,
    ...data,
    ...restPayload
  }
}

export function loginError(error: IAuthError) {
  return { type: types.LOGIN_ERROR, error }
}

export function clearAuthError() {
  return { type: types.CLEAR_AUTH_ERROR }
}

export function fetchToken() {
  return { type: types.FETCH_TOKEN }
}

interface IFetchTokenSuccessPayload {
  mfaPassed: boolean
  mfaEnrolled: boolean
  mfaEnabled: boolean
  name?: string
  permissionType?: string
  isPasswordExpired: boolean
}

export function fetchTokenSuccess(payload: IFetchTokenSuccessPayload) {
  return { type: types.FETCH_TOKEN_SUCCESS, ...payload }
}

export function logout() {
  return { type: types.LOGOUT_START }
}

export function logoutSuccess() {
  return { type: types.LOGOUT_SUCCESS }
}

export function authFailed(error: IAuthError) {
  return { type: types.AUTH_FAILED, error }
}

export function mfaFetchQRUrl() {
  return { type: types.MFA_FETCH_QR_URL }
}

interface IMFAFetchQRUrlSuccessPayload {
  qrDataURL: string
}
export function mfaFetchQRUrlSuccess(payload: IMFAFetchQRUrlSuccessPayload) {
  return { type: types.MFA_FETCH_QR_URL_SUCCESS, ...payload }
}

export function mfaVerificationStart(code: string) {
  return { type: types.MFA_VERIFICATION_START, code }
}

export function mfaVerificationSuccess(token: string) {
  return { type: types.MFA_VERIFICATION_SUCCESS, token }
}

export function mfaVerificationFailure(error?: IAuthError) {
  return { type: types.MFA_VERIFICATION_FAILURE, error }
}

export function resetApp() {
  return { type: types.RESET_APP }
}

export interface IPasswordResetValues {
  password: string | null
  passwordConfirmation: string | null
}
export const resetPassword = (values: IPasswordResetValues) => ({
  type: types.USER_RESET_PASSWORD,
  values
})

export const resetPasswordError = (error: IAuthError) => ({
  type: types.USER_RESET_PASSWORD_ERROR,
  error
})

export const authAccessTimeRestricted = (error?: IAuthError) => ({
  type: types.AUTH_ACCESS_TIME_RESTRICTED,
  error
})
