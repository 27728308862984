import { MuseumImageType } from 'shared/constants/images'
import PageContent from 'client/components/PageContent/PageContent'
import { StyledFormSection } from 'client/components/Form/FormField/FormField'
import Splash from 'client/screens/AppEditor/BrandAssets/Splash'
import { t } from 'client/i18n'
import styled from 'styled-components'
import ImageFormField from 'client/screens/AppEditor/BrandAssets/ImageFormField'

const Panel = styled.article`
  padding: var(--spacing-xlarge);
  display: flex;
  height: 100%;
`

const LeftSection = styled.section`
  width: 50%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding-right: var(--spacing-xlarge);

  > ${StyledFormSection} + ${StyledFormSection} {
    padding-top: var(--spacing-large);
    border-top: var(--border-light);
  }
`

const RightSection = styled.section`
  width: 50%;
  padding-left: var(--spacing-xlarge);
  border-left: 1px solid var(--color-grey-02);
`

function BrandAssets() {
  return (
    <PageContent title={t('Brand Assets')}>
      <Panel>
        <LeftSection>
          <ImageFormField type={MuseumImageType.EXPLORE_HERO} />
          <ImageFormField type={MuseumImageType.EXPLORE_ICON} />
          <ImageFormField type={MuseumImageType.MUSEUM_LOGO} />
        </LeftSection>

        <RightSection>
          <Splash />
        </RightSection>
      </Panel>
    </PageContent>
  )
}

export default BrandAssets
