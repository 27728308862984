enum AuthenticationErrorType {
  UNRECOGNIZED_LOGIN_CREDENTIALS = 'UNRECOGNIZED_LOGIN_CREDENTIALS',
  EXPIRED_PLATFORM_INVITE_LINK = 'EXPIRED_PLATFORM_INVITE_LINK',
  EXPIRED_PASSWORD_RESET_LINK = 'EXPIRED_PASSWORD_RESET_LINK',
  CANNOT_MATCH_CURRENT_PASSWORD = 'CANNOT_MATCH_CURRENT_PASSWORD',
  PASSWORDS_DO_NOT_MATCH = 'PASSWORDS_DO_NOT_MATCH',
  PASSWORD_DOES_NOT_MEET_REQUIREMENTS = 'PASSWORD_DOES_NOT_MEET_REQUIREMENTS'
}

export default AuthenticationErrorType
