import * as React from 'react'
import styled from 'styled-components'
import InlineEditField from 'client/screens/AppEditor/MapEditor/FloorEditor/InlineEditField'

const Container = styled.div({
  display: 'flex',
  height: 30
})

const InlineInput = styled(InlineEditField)`
  text-align: center;
  padding: 0px;
  width: 30px;
`

interface IProps {
  title?: string
  editing?: boolean
  onBeginEditing?: () => void
  onEndEditing?: () => void
  onChange?: (value: string) => void
}

const FloorTitleInput: React.FC<IProps> = (props: IProps) => (
  <Container>
    <InlineInput
      value={props.title}
      maxWidth={40}
      maxLength={2}
      minLength={1}
      editing={props.editing}
      onBeginEditing={props.onBeginEditing}
      onEndEditing={props.onEndEditing}
      onSave={props.onChange}
    />
  </Container>
)

export default FloorTitleInput
