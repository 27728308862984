import styled, { css } from 'styled-components'

export const Header1CSS = css`
  font-size: 24px;
  line-height: 32px;
  font-weight: var(--font-weight-bold);
`

export const Header2CSS = css`
  font-size: 18px;
  line-height: 26px;
  font-weight: var(--font-weight-bold);
`

export const Header3CSS = css`
  font-size: 16px;
  line-height: 24px;
  font-weight: var(--font-weight-bold);
`

export const Header3 = styled.h3`
  ${Header3CSS}
`

export const Label1CSS = css`
  font-size: 14px;
  line-height: 20px;
  font-weight: var(--font-weight-bold);
`

export const Label2CSS = css`
  font-size: 12px;
  line-height: 16px;
  font-weight: var(--font-weight-bold);
`

export const Body1CSS = css`
  font-size: 14px;
  line-height: 20px;
  font-weight: var(--font-weight-regular);
`

export const Body2CSS = css`
  font-size: 12px;
  line-height: 16px;
  font-weight: var(--font-weight-regular);
`
export const Body2 = styled.div`
  ${Body2CSS}
`
