import { Body1CSS } from 'client/components/TextStyles'
import styled from 'styled-components'

export const SplashFormContainer = styled.div`
  display: flex;
  height: 100%;
`

export const LeftFormInput = styled.div`
  padding: var(--spacing-medium) var(--spacing-large);
  width: 372px;
  flex-shrink: 0;
`

export const RightFormPreview = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--color-white);
  border-left: 1px solid var(--color-grey-02);
  padding-top: 60px;
  width: 100%;
`

export const PreviewTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
`

export const Divider = styled.div`
  height: 1px;
  margin-bottom: 24px;
  margin-top: 24px;
  background-color: var(--color-grey-02);
`

export const PreviewText = styled.div<{ bold?: boolean }>`
  ${Body1CSS};
  margin-bottom: 0;
  color: var(--color-grey-05);
  white-space: nowrap;
`

export const PreviewContainer = styled.div`
  width: 312px;
  padding-bottom: 52px;
`
