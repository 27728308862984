import { IColumnProps, ITableCellProps } from 'client/dsm/Table/Table'
import { ComponentType } from 'react'
import styled from 'styled-components'
import { TableCellRenderer } from 'react-virtualized'

const Container = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`

const IconContainer = styled.div`
  position: absolute;
  left: 0;
`

const GutterComponentContainer = styled.div`
  position: absolute;
  left: -24px;
  top: 50%;
  margin-top: -8px;
`

export function LeftGutterRendererWrapper(
  renderer: TableCellRenderer,
  GutterComponent: ComponentType<ITableCellProps<any>>
) {
  return (props: ITableCellProps<any>) => {
    const rendered = renderer(props)
    return (
      <Container>
        {rendered}
        <IconContainer>
          <GutterComponentContainer>
            <GutterComponent {...props} />
          </GutterComponentContainer>
        </IconContainer>
      </Container>
    )
  }
}

export function LeftGutterColumnPropsWrapper(
  columnProps: IColumnProps,
  gutterComponent: ComponentType<ITableCellProps<any>>
) {
  const { cellRenderer, ...rest } = columnProps
  return {
    ...rest,
    cellRenderer: LeftGutterRendererWrapper(cellRenderer!, gutterComponent)
  }
}
