import LabelTooltip, { ILabelTooltipProps } from 'client/components/Languages/LabelTooltip'
import _ from 'lodash'
import { t } from 'client/i18n'

type IClosedCaptionLabelTooltipProps = Omit<ILabelTooltipProps, 'children' | 'tooltipHeader'>

function ClosedCaptionLabelTooltip(props: IClosedCaptionLabelTooltipProps) {
  const { translations } = props
  const translationsWithSubtitles = _.filter(translations, 'videoSubtitles')
  return (
    <LabelTooltip
      {...props}
      tooltipHeader={t('Subtitles/Captions')}
      translations={translationsWithSubtitles}
    >
      {t('CC')}
    </LabelTooltip>
  )
}

export default ClosedCaptionLabelTooltip
