import { useMemo, useState } from 'react'
import styled from 'styled-components'
import Section from 'client/components/Form/Section/Section'
import DateRangeInput from 'client/components/DateRangeInput/DateRangeInput'
import { DateRange } from 'react-day-picker'
import Button from 'client/components/Button/Button'
import _ from 'lodash'
import { t } from 'client/i18n'
import { Trans } from 'react-i18next'
import { startOfYesterday } from 'date-fns'

const ContainerContent = styled.div<{ disabled?: boolean }>`
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  color: ${({ disabled = false }) => (disabled ? 'var(--color-grey-04)' : 'inherit')};
  position: relative;
  height: 100%;
`

const DateControlsContainer = styled.div`
  display: flex;
  align-items: flex-end;
  column-gap: 20px;
`

interface IGenerateReportView {
  disabled: boolean
  publicDate: Date | undefined
  isGeneratingReport: boolean
  onGenerateReport: (startDate: Date, endDate: Date) => void
}

const GenerateReportView = ({
  disabled,
  publicDate,
  isGeneratingReport,
  onGenerateReport
}: IGenerateReportView) => {
  const [selectedDateRange, setSelectedDateRange] = useState<DateRange>()
  const { from: startDate, to: endDate } = selectedDateRange || {}

  const yesterday = useMemo(startOfYesterday, [])

  return (
    <ContainerContent disabled={disabled}>
      <Section
        disabled={disabled}
        title={t('Generate Report')}
        note={
          <span>
            <Trans i18nKey="generateReportNote" />
          </span>
        }
      />
      <DateControlsContainer>
        <DateRangeInput
          value={selectedDateRange}
          onChange={setSelectedDateRange}
          disabled={disabled}
          minimumDate={publicDate}
          maximumDate={yesterday}
        />
        <Button
          type="primary"
          onClick={() => onGenerateReport(startDate!, endDate!)}
          disabled={disabled || _.isNil(startDate) || _.isNil(endDate)}
          label={isGeneratingReport ? t('Generating Report...') : t('Generate Report')}
        />
      </DateControlsContainer>
    </ContainerContent>
  )
}

export default GenerateReportView
