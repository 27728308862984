import { useState } from 'react'
import styled from 'styled-components'
import { GQLLocale } from 'shared/graphql/types/graphql'
import Button from 'client/components/Button/Button'
import TranslationFormDialog from 'client/components/TranslationForm/TranslationFormDialog'
import { LanguageInput } from 'client/components/Languages/LanguageInput'
import { t } from 'client/i18n'

// Note: ILocaleJSON (and others) will eventually change to reflect the proper GQL type -- not the REST type
interface IAddTranslationDialogProps {
  availableLocales?: GQLLocale[]
  excludedLocales: GQLLocale[]
  onAddLocale: (locale: GQLLocale) => void
  onCancel: () => void
}

const StyledLanguageInput = styled(LanguageInput)`
  margin-top: var(--spacing-medium);
`

const AddTranslationDialog = (props: IAddTranslationDialogProps) => {
  const { availableLocales, excludedLocales, onAddLocale, onCancel } = props

  const [selectedLocale, setSelectedLocale] = useState<GQLLocale>()

  return (
    <TranslationFormDialog
      title={t('Add Translation')}
      body={
        <StyledLanguageInput
          selectedLocale={selectedLocale}
          setSelectedLocale={setSelectedLocale}
          availableLocales={availableLocales}
          excludedLocales={excludedLocales}
        />
      }
      actions={
        <>
          <Button onClick={onCancel} label={t('Cancel')} />
          <Button
            type="primary"
            disabled={selectedLocale === undefined}
            onClick={() => onAddLocale(selectedLocale!)}
            label={t('Add')}
          />
        </>
      }
    />
  )
}

export default AddTranslationDialog
