// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".p8JWD6iBBwar4_GuKolR {\n  position: relative;\n  display: flex;\n  width: 100%;\n  margin-top: var(--spacing-xsmall);\n}\n\n.hyzgYhhyRRAWzBLS_xzh {\n  width: 100%;\n  padding: 0 10px;\n}\n\ninput[type='number']::-webkit-inner-spin-button,\ninput[type='number']::-webkit-outer-spin-button {\n  -webkit-appearance: none;\n  margin: 0;\n}\n\ninput[type='number'] {\n  appearance: textfield;\n}\n\n.Wk1GDQtreM4l4ieRGbie {\n  position: absolute;\n  align-self: center;\n  left: -28px;\n}\n\n.ABEG9j7GcmLFe4cO84CZ {\n  margin: 4px 0 0;\n  color: var(--color-status-error);\n  font-size: 12px;\n  line-height: 16px;\n}\n", "",{"version":3,"sources":["webpack://./components/Form/StringInput/StringInput.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,WAAW;EACX,iCAAiC;AACnC;;AAEA;EACE,WAAW;EACX,eAAe;AACjB;;AAEA;;EAEE,wBAAwB;EACxB,SAAS;AACX;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,eAAe;EACf,gCAAgC;EAChC,eAAe;EACf,iBAAiB;AACnB","sourcesContent":[".container {\n  position: relative;\n  display: flex;\n  width: 100%;\n  margin-top: var(--spacing-xsmall);\n}\n\n.input {\n  width: 100%;\n  padding: 0 10px;\n}\n\ninput[type='number']::-webkit-inner-spin-button,\ninput[type='number']::-webkit-outer-spin-button {\n  -webkit-appearance: none;\n  margin: 0;\n}\n\ninput[type='number'] {\n  appearance: textfield;\n}\n\n.errorExclaim {\n  position: absolute;\n  align-self: center;\n  left: -28px;\n}\n\n.errorWarning {\n  margin: 4px 0 0;\n  color: var(--color-status-error);\n  font-size: 12px;\n  line-height: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
export var container = "p8JWD6iBBwar4_GuKolR";
export var input = "hyzgYhhyRRAWzBLS_xzh";
export var errorExclaim = "Wk1GDQtreM4l4ieRGbie";
export var errorWarning = "ABEG9j7GcmLFe4cO84CZ";
export default ___CSS_LOADER_EXPORT___;
