import _ from 'lodash'
import FormField from 'client/components/TranslationForm/TranslatableFormField'
import useField from 'client/hooks/useField'
import ImagesList, { MAX_IMAGES } from 'client/components/PickerLists/ImagesList'
import { t } from 'client/i18n'

interface IImagesFieldProps {
  name: string
}

const ImagesListField = (props: IImagesFieldProps) => {
  const { name } = props
  const { value: images = [] } = useField(name)

  return (
    <FormField
      label={t('imagesListFieldLabel', { current: _.size(images), max: MAX_IMAGES })}
      translatable={false}
    >
      <ImagesList name={name} />
    </FormField>
  )
}

export default ImagesListField
