// With the exception of the styles in the export statement, this entire file was taken from
// https://bbgithub.dev.bloomberg.com/bbnpm/bb-ui-framework/blob/v1/src/components/VirtualTable/BaseRowRenderer/BaseRowRenderer.tsx
import { ReactNode, CSSProperties } from 'react'
import styled from 'styled-components'

interface BaseRowRendererProps {
  // react-virtualized row
  children: ReactNode

  // styled-component classes
  className?: string

  // react-virtualized styles
  style?: CSSProperties

  // Unique key
  key: string
}

const BaseRowRenderer = ({ className, style, key, children }: BaseRowRendererProps) => (
  <div className={className} style={style} key={key}>
    {children}
  </div>
)

export default styled(BaseRowRenderer)`
  color: var(--color-black);
  border-top: none !important;
  border-bottom: var(--border-light);
`
