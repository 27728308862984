import { useMemo } from 'react'
import styled from 'styled-components'
import { t } from 'client/i18n'
import { DateRange } from 'react-day-picker'
import _ from 'lodash'
import SingleDateTextInputField, { ISingleDateTextInputProps } from './SingleDateTextInputField'

const DateFieldsContainer = styled.div`
  display: flex;
  column-gap: var(--spacing-small);
`

const DateTimeContainer = styled.div`
  display: flex;
  column-gap: var(--spacing-xsmall);
`

const EnDashContainer = styled.div`
  padding-top: 37px;
`

export interface IDateRangeInputProps {
  value: DateRange | undefined
  onChange: (dateRange: DateRange | undefined) => void
  hasError?: boolean
  disabled?: boolean
  minimumDate?: Date
  maximumDate?: Date
}

// TODO: it may be easier if this thing just deals with midnight, then the exhibit form has a wrapper that deals with BOD/EOD
export default function DateRangeInput(props: IDateRangeInputProps) {
  const { value, onChange, hasError, disabled, minimumDate, maximumDate } = props

  const { to, from } = value || {}

  const startDateDisabledDates = useMemo(() => {
    const before = minimumDate
    const after = to ?? maximumDate
    return _.pickBy({ before, after }) as ISingleDateTextInputProps['disabledDays']
  }, [to, minimumDate, maximumDate])

  const endDateDisabledDates = useMemo(() => {
    const after = maximumDate
    const before = from ?? minimumDate
    return _.pickBy({ before, after }) as ISingleDateTextInputProps['disabledDays']
  }, [from, minimumDate, maximumDate])

  return (
    <DateFieldsContainer>
      <DateTimeContainer>
        <SingleDateTextInputField
          label={t('* Start Date')}
          value={from}
          selectedRange={{ from, to }}
          hasError={hasError}
          disabled={disabled}
          disabledDays={startDateDisabledDates}
          onChange={(date) => {
            onChange({
              from: date,
              to
            })
          }}
        />
      </DateTimeContainer>
      <EnDashContainer>–</EnDashContainer>
      <DateTimeContainer>
        <SingleDateTextInputField
          label={t('* End Date')}
          value={to}
          selectedRange={{ from, to }}
          hasError={hasError}
          disabled={disabled}
          disabledDays={endDateDisabledDates}
          onChange={(date) =>
            onChange({
              from,
              to: date
            })
          }
        />
      </DateTimeContainer>
    </DateFieldsContainer>
  )
}
