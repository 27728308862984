import EnhancedStandardForm from 'client/components/StandardForm/EnhancedStandardForm'
import { t } from 'client/i18n'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { getExterior } from 'client/redux/selectors/maps'
import { createBuilding, updateBuilding } from 'client/redux/actions/maps'
import { IBuildingJson } from 'shared/json/IBuildingJson'
import { useGet } from 'client/hooks/api'
import _ from 'lodash'
import useCoercedParam from 'client/hooks/useCoercedParam'
import { BuildingFormSchemaClient, BuildingSchemaCore } from 'client/validation/Map'
import TextInput from 'client/components/Formik/TextInput/TextInput'
import { useCallback } from 'react'
import { getCreateSuccessMessage } from 'client/screens/AppEditor/MapEditor/DialogMessages/buildings'
import { confirm } from 'client/redux/actions/confirmation'
import FormFieldSection from 'client/components/Form/FormField/FormFieldSection'
import { LatitudeContextualHelp, LongitudeContextualHelp } from '../GoogleMapContextualHelp'

const BuildingForm = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const buildingId = useCoercedParam<number>('editBuildingId')
  const isGoogleMap = useSelector(getExterior)?.isGoogleMap ?? false
  const exteriorMap = useSelector(getExterior)
  const onSuccess = useCallback(() => {
    const { message, title } = getCreateSuccessMessage(!_.isEmpty(exteriorMap))
    dispatch(
      confirm({
        title,
        message,
        isAlert: true
      })
    )
  }, [dispatch, exteriorMap])

  const [fetchedBuildings, isFetchedBuildingsLoading] = useGet<IBuildingJson[]>('/maps/building')
  const building = _.find(fetchedBuildings, { id: buildingId })

  const onSubmit = (values) => {
    if (buildingId) {
      dispatch(
        updateBuilding({
          id: buildingId,
          name: values.name,
          mapLocation: {
            latitude: values.latitude,
            longitude: values.longitude
          }
        })
      )
    } else {
      dispatch(
        createBuilding({
          name: values.name,
          latitude: values.latitude,
          longitude: values.longitude
        })
      )
      onSuccess()
    }

    navigate('..')
  }

  const getBuildingDescriptionText = () => {
    return isGoogleMap
      ? t('You can set the building’s location using latitude and longitude coordinates.')
      : t('You can set the building’s location by dragging the pin on the map.')
  }

  const initialValues = {
    name: building?.name,
    latitude: building?.mapLocation?.latitude,
    longitude: building?.mapLocation?.longitude
  }

  return (
    <EnhancedStandardForm
      contentName="Building"
      contentId={buildingId}
      validationSchema={isGoogleMap ? BuildingFormSchemaClient : BuildingSchemaCore}
      initialValues={initialValues}
      onSubmit={onSubmit}
      isLoading={isFetchedBuildingsLoading}
    >
      <FormFieldSection
        label={t('Building')}
        description={getBuildingDescriptionText()}
        hideDivider={true}
      />
      <TextInput name="name" label={t('* Name')} />
      {isGoogleMap && (
        <>
          <TextInput
            name="latitude"
            label={t('* Latitude')}
            additionalLabelNode={LatitudeContextualHelp}
          />
          <TextInput
            name="longitude"
            label={t('* Longitude')}
            additionalLabelNode={LongitudeContextualHelp}
          />
        </>
      )}
    </EnhancedStandardForm>
  )
}

export default BuildingForm
