import _ from 'lodash'
import { parse } from 'node-html-parser'

function getTextContent(html: string) {
  return parse(html).textContent
}

export function getPlainTextForHtml(value: undefined | null): null
export function getPlainTextForHtml(value: string): string
export function getPlainTextForHtml(value: string | undefined | null): string | null
export function getPlainTextForHtml(value: string | undefined | null) {
  return _.isNil(value) ? null : getTextContent(value)
}

export const getCharacterCountForHtml = (value: string): number =>
  value ? getPlainTextForHtml(value).length : 0
