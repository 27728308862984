import { useRef } from 'react'
import * as React from 'react'
import _ from 'lodash'
import { confirm } from 'client/redux/actions/confirmation'
import { useDispatch } from 'react-redux'
import { GQLMap } from 'shared/graphql/types/graphql'
import Pill from 'client/screens/AppEditor/MapEditor/FloorEditor/Pill'
import MenuPopout, { IMenuOption } from 'client/components/MenuPopout/MenuPopout'
import { PermanentActionMessage } from 'client/dsm/Dialog/Dialog'
import { t } from 'client/i18n'
import FloorTitleInput from './FloorTitleInput'
import {
  FloorRowContainer,
  FloorRowLeftContent,
  FloorRowRightContent,
  FloorRowGrabber,
  FloorRowFloorLabel,
  FloorRowUpdateInput
} from './styledComponents'

interface IProps {
  floor: GQLMap
  isDefault?: boolean
  dragging: boolean
  selected: boolean
  editing?: boolean
  onBeginEditing?: () => void
  onEndEditing?: () => void
  onChangeTitle?: (value: string) => void
  onDelete?: () => void
  onReplace?: (file: File) => void
  onSetDefault: () => void
  onClick?: () => void
  showGrabber: boolean
}

const FloorRow: React.FC<IProps> = (props: IProps) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const dispatch = useDispatch()
  const { dragging, selected, isDefault, floor, onClick, showGrabber } = props

  const handleReplaceFloorImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { onReplace } = props
    const file = event.target.files![0]
    if (onReplace) {
      onReplace(file)
    }

    /**
     * Resetting target value to empty string allows the same file to be uploaded multiple times.
     * It's an edge case, but can happen.
     */
    // eslint-disable-next-line
    event.target.value = ''
  }

  const handleReplaceOption = () => {
    const replaceAction = () => {
      if (inputRef && inputRef.current) {
        inputRef.current.click()
      }
    }

    if (!_.isEmpty(floor.mapLocations)) {
      const confirmPayload = {
        title: t('Replace Floor?'),
        message: (
          <>
            <p>
              {t(
                'This floor is associated with content locations. Replacing this floor plan will not affect content locations.'
              )}
            </p>
            <p>{t('Are you sure you want to replace this floor?')}</p>
          </>
        ),
        confirmYes: { action: replaceAction, label: t('Replace Floor') }
      }
      dispatch(confirm(confirmPayload))
    } else {
      replaceAction()
    }
  }

  const handleDeleteFloorOption = () => {
    const deleteAction = () => {
      if (props.onDelete) {
        props.onDelete()
      }
    }

    dispatch(
      confirm({
        title: t('Delete Floor?'),
        message: (
          <>
            <p>{t('Are you sure you want to delete this floor?')}</p>
            <PermanentActionMessage />
          </>
        ),
        confirmYes: { action: deleteAction, label: t('Delete Floor'), isDestructive: true }
      })
    )
  }

  const handleSetDefault = () => {
    props.onSetDefault()
  }

  const options: IMenuOption[] = [
    {
      label: t('Replace Floor Map'),
      onClick: handleReplaceOption
    },
    { label: t('Delete Floor'), onClick: handleDeleteFloorOption }
  ]

  if (!isDefault) {
    options.push({
      label: t('Set Default Floor'),
      onClick: handleSetDefault
    })
  }

  return (
    <FloorRowContainer dragging={dragging} selected={selected}>
      <FloorRowUpdateInput
        ref={inputRef}
        id={`${props.floor.id}update-floor-image`}
        type="file"
        accept=".jpg,.jpeg"
        onChange={handleReplaceFloorImage}
      />
      <FloorRowLeftContent onClick={onClick}>
        {showGrabber && <FloorRowGrabber />}
        <FloorRowFloorLabel>{t('Floor')}</FloorRowFloorLabel>
        <FloorTitleInput
          title={floor.title ?? undefined}
          onChange={props.onChangeTitle}
          editing={props.editing}
          onBeginEditing={props.onBeginEditing}
          onEndEditing={props.onEndEditing}
        />
      </FloorRowLeftContent>
      <FloorRowRightContent>
        {isDefault && <Pill label={t('Default Floor')} />}
        <MenuPopout options={options} />
      </FloorRowRightContent>
    </FloorRowContainer>
  )
}

export default FloorRow
