import { Routes, Route, Navigate } from 'react-router-dom'
import Items from './grids/Items'
import BotanicalItems from './grids/BotanicalItems'
import Exhibits from './grids/Exhibits'
import Tours from './grids/Tours'
import Images from './grids/Images'
import Audios from './grids/Audios'
import Videos from './grids/Videos'
import Creators from './grids/Creators'
import Events from './grids/Events'

const Catalog = () => {
  return (
    <Routes>
      <Route path="exhibits/*" element={<Exhibits />} />
      <Route path="tours/*" element={<Tours />} />
      <Route path="events/*" element={<Events />} />
      <Route path="items/*" element={<Items />} />
      <Route path="botanicalItems/*" element={<BotanicalItems />} />
      <Route path="images/*" element={<Images />} />
      <Route path="audios/*" element={<Audios />} />
      <Route path="videos/*" element={<Videos />} />
      <Route path="creators/*" element={<Creators />} />
      <Route path="/*" element={<Navigate replace={true} to="exhibits" />} />
    </Routes>
  )
}

export default Catalog
