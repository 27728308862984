import { useEffect, useState } from 'react'
import _ from 'lodash'
import { useFormikContext } from 'formik'
import { IErrorMap } from 'client/types'

export default (): IErrorMap => {
  const [capturedErrors, setCapturedErrors] = useState({})
  const { errors, isSubmitting } = useFormikContext()
  useEffect(() => {
    if (isSubmitting && !_.isEmpty(errors)) {
      setCapturedErrors(errors)
    }
  }, [errors, isSubmitting])

  return capturedErrors
}
