import { IBaseContentRow } from 'client/components/ContentRow/types'
import _ from 'lodash'
import {
  ContentStateCaption,
  Header,
  HeaderContainer,
  HTMLText,
  Subheader,
  SubHeaderContainer
} from 'client/components/ContentRow/styledComponents'

interface IHeadersProps extends IBaseContentRow {
  isPickerRow?: boolean
}

export const HeaderSubheaderContainer = ({
  header,
  subheaders = [],
  isError,
  isPickerRow = false,
  contentStateCaption
}: IHeadersProps) => {
  const filteredSubheaders = _.compact(subheaders)
  return (
    <>
      <HeaderContainer>
        {header && (
          <Header isPickerRow={isPickerRow}>
            <HTMLText html={header} />
          </Header>
        )}
      </HeaderContainer>
      <SubHeaderContainer>
        {filteredSubheaders.map((subheader) => (
          <Subheader key={subheader} isPickerRow={isPickerRow}>
            <HTMLText html={subheader} />
          </Subheader>
        ))}
      </SubHeaderContainer>
      {contentStateCaption && (
        <ContentStateCaption isError={isError} key={contentStateCaption}>
          <HTMLText html={contentStateCaption} />
        </ContentStateCaption>
      )}
    </>
  )
}
