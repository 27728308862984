import { ToggleInputField } from 'client/components/Form/ToggleInput/ToggleInput'
import FieldLabel from 'client/components/Form/Label/Label'
import styled from 'styled-components'

const SectionContent = styled.div`
  display: flex;
`

const Description = styled.span`
  margin-top: 8px;
  padding-right: 16px;
`

interface IToggleFieldProps {
  name: string
  label: string
  description?: string
  className?: string
}

export default function ToggleField({ name, label, description, className }: IToggleFieldProps) {
  return (
    <SectionContent className={className}>
      <FieldLabel>
        {label}
        {description && <Description>{description}</Description>}
      </FieldLabel>
      <ToggleInputField name={name} />
    </SectionContent>
  )
}
