import ConnectsLogo from 'client/assets/svg/logo_digitalExBy_BP.svg'
import {
  BackgroundAndLogoContainer,
  BackgroundImage,
  ConnectsLogoContainer,
  LogoContainer,
  LogoImage,
  LogoTitle,
  OuterContainer
} from './styledComponents'

interface ISplashPreviewProps {
  logoUrl?: string | null
  backgroundImageUrl?: string | null
  displayName: string
}
export default function SplashPreview({
  backgroundImageUrl,
  logoUrl,
  displayName
}: ISplashPreviewProps) {
  const shouldShowDisplayName = !logoUrl && !backgroundImageUrl

  return (
    <OuterContainer shouldShowDefault={!backgroundImageUrl}>
      <BackgroundAndLogoContainer>
        {backgroundImageUrl && <BackgroundImage src={backgroundImageUrl} />}
        <LogoContainer>
          {logoUrl && <LogoImage src={logoUrl} />}
          {shouldShowDisplayName && <LogoTitle>{displayName}</LogoTitle>}
        </LogoContainer>
      </BackgroundAndLogoContainer>
      <ConnectsLogoContainer>
        <ConnectsLogo />
      </ConnectsLogoContainer>
    </OuterContainer>
  )
}
