import AccentTableSVG from 'client/assets/svg/accentIcons/accent_table.svg'
import ChevronDownIconSVG from 'client/assets/svg/icon/chevron_20_down.svg'
import ChevronUpIconSVG from 'client/assets/svg/icon/chevron_20_up.svg'
import { Body1CSS } from 'client/components/TextStyles'
import EmptyState from 'client/dsm/EmptyState/EmptyState'
import Tooltip from 'client/dsm/Tooltip/Tooltip'
import useHover from 'client/hooks/useHover'
import { t } from 'client/i18n'
import _ from 'lodash'
import { PropsWithChildren, ReactNode, useEffect, useMemo, useRef, useState } from 'react'
import {
  Column,
  ColumnProps,
  SortDirection,
  SortDirectionType,
  TableCellDataGetterParams,
  TableCellProps,
  TableHeaderProps,
  TableProps,
  defaultTableCellRenderer
} from 'react-virtualized'
import styled from 'styled-components'
import VirtualTable from './VirtualTable'

export interface ITableCellProps<T> extends TableCellProps {
  rowData: T
}

export interface IColumnProps extends Omit<ColumnProps, 'width'> {
  // Not requiring width per column item. Our defaults handles not needing this
  width?: number
  sortable?: boolean
  sortBy?: (row: any) => any
}

// Note explicitly only exposing necessary props to reduce clutter in api and reduce possible confusion
interface ITable extends Pick<TableProps, 'onRowClick' | 'onHeaderClick'> {
  data?: object[]
  columns: IColumnProps[]
  placeholder?: ReactNode
  placeholderIcon?: ReactNode
  headerHeight?: number
  rowHeight?: number
  fixedHeight?: boolean
  defaultSortColumnDataKey?: string
}

const HeaderCellContainer = styled.div<{ shouldHighlight: boolean }>`
  cursor: ${({ shouldHighlight }) => (shouldHighlight ? 'pointer' : 'auto')};
  color: ${({ shouldHighlight }) =>
    shouldHighlight ? 'var(--color-black)' : 'var(--color-grey-07)'};
  display: flex;
  align-items: center;
  user-select: none; // prevent text selection when double-clicking a header to sort desc
`

const CellContainer = styled.div`
  ${Body1CSS};
  align-items: center;
  max-height: 100%;
  // Styles to allow multi-line ellipses on chromium
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  word-break: break-word;

  p {
    margin: 0;
  }
`

const ChevronUpIcon = styled(ChevronUpIconSVG)`
  color: inherit;
  pointer-events: none;
`

const ChevronDownIcon = styled(ChevronDownIconSVG)`
  color: inherit;
  pointer-events: none;
`

interface IHeaderCell {
  isSortable?: boolean
  isSorted?: boolean
  sortDirection?: SortDirectionType
}

const HeaderCell = (props: PropsWithChildren<IHeaderCell>) => {
  const { isSortable, isSorted, sortDirection } = props

  const hoverRef = useRef<HTMLDivElement>(null)
  const isHovered = useHover(hoverRef)

  const SortIcon = sortDirection === SortDirection.DESC ? ChevronDownIcon : ChevronUpIcon
  const shouldHighlight = !!(isSorted || (isSortable && isHovered))

  return isSortable ? (
    <Tooltip content={t('Click to sort')}>
      <HeaderCellContainer ref={hoverRef} shouldHighlight={shouldHighlight}>
        {props.children}
        {isSorted && <SortIcon />}
      </HeaderCellContainer>
    </Tooltip>
  ) : (
    <HeaderCellContainer ref={hoverRef} shouldHighlight={shouldHighlight}>
      {props.children}
      {isSorted && <SortIcon />}
    </HeaderCellContainer>
  )
}

const Table = (props: ITable) => {
  const {
    data,
    columns: columnsConfig,
    placeholder = '',
    placeholderIcon = <AccentTableSVG />,
    headerHeight = 40,
    rowHeight = 96,
    fixedHeight = false,
    defaultSortColumnDataKey,
    ...rest
  } = props

  const [sortedColumnDataKey, setSortedColumnDataKey] = useState(defaultSortColumnDataKey)
  const [sortedDirection, setSortedDirection] = useState<SortDirectionType>(SortDirection.DESC)

  const normalizedColumnConfig = useMemo(
    () =>
      _.map(columnsConfig, (config) => ({
        ..._.omit(config, 'label'),
        flexGrow: config.flexGrow ?? 1,
        flexShrink: config.flexShrink ?? 1,
        headerRenderer: _.isString(config.label) ? _.constant(config.label) : config.headerRenderer,
        // Note: sets flex-basis to width. Setting to 0 means all available space will be distributed per flexGrow factor per column.
        width: config.width ?? 0,
        sortable: config.sortable ?? true
      })),
    [columnsConfig]
  )

  const filteredData = useMemo(() => {
    if (!sortedColumnDataKey) {
      return data
    }
    const defaultSortBy = (row) => row[sortedColumnDataKey]
    const columnEntry = _.find(columnsConfig, { dataKey: sortedColumnDataKey })
    const sortBy = columnEntry?.sortBy || defaultSortBy
    const cleanupValueForSorting = (row) => {
      const result = sortBy(row)
      return _.isString(result) ? result.toLowerCase().replace(/^the\s/i, '') : result
    }
    const direction = sortedDirection === SortDirection.DESC ? 'desc' : 'asc'
    return _.orderBy(data, cleanupValueForSorting, direction)
  }, [columnsConfig, data, sortedColumnDataKey, sortedDirection])

  // using _.get to accommodate nested data keys (ex item.model.name)
  const cellDataGetter = ({ rowData, dataKey }: TableCellDataGetterParams) =>
    _.get(rowData, dataKey)

  const columns = useMemo(
    () =>
      _.map(normalizedColumnConfig, (column) => (
        <Column
          {...column}
          key={column.dataKey}
          cellDataGetter={cellDataGetter}
          // Wrapping in custom div for all in order to embed test ids
          headerRenderer={(headerProps: TableHeaderProps) => (
            <HeaderCell
              isSortable={column.sortable}
              isSorted={column.dataKey === sortedColumnDataKey}
              sortDirection={column.dataKey === sortedColumnDataKey ? sortedDirection : undefined}
            >
              {column.headerRenderer?.(headerProps)}
            </HeaderCell>
          )}
          // Wrapping in custom div for all in order to embed test ids
          cellRenderer={(cellProps: TableCellProps) => {
            const cellRenderer = _.isFunction(column.cellRenderer)
              ? column.cellRenderer
              : defaultTableCellRenderer
            return <CellContainer>{cellRenderer(cellProps)}</CellContainer>
          }}
        />
      )),
    [normalizedColumnConfig, sortedColumnDataKey, sortedDirection]
  )

  useEffect(() => {
    if (!data?.length) {
      return
    }
    const dataKeysWithArrayValuesNoSortBy = _(normalizedColumnConfig)
      .map(({ dataKey, sortBy, sortable }) => {
        const firstRow = data[0]
        return _.isArray(firstRow?.[dataKey]) && sortable && !_.isFunction(sortBy) ? dataKey : null
      })
      .compact()
      .value()

    if (!_.isEmpty(dataKeysWithArrayValuesNoSortBy)) {
      throw new Error(
        `The following column's cell data is of type Array but no 'sortBy' column property has been provided: [${dataKeysWithArrayValuesNoSortBy.join(
          ', '
        )}]`
      )
    }
  }, [data, normalizedColumnConfig])

  const handleSortColumn = ({ sortBy }: { sortBy: string }) => {
    // Skip non sortable columns
    if (_.find(normalizedColumnConfig, { dataKey: sortBy })?.sortable !== true) {
      return
    }
    setSortedColumnDataKey((oldKey) => {
      const direction =
        sortedDirection === SortDirection.DESC ? SortDirection.ASC : SortDirection.DESC
      setSortedDirection(sortBy === oldKey ? direction : SortDirection.ASC)
      return sortBy
    })
  }

  return (
    <VirtualTable
      items={filteredData}
      headerHeight={headerHeight}
      rowHeight={rowHeight}
      fixedHeight={fixedHeight}
      isRowClickable={_.isFunction(rest.onRowClick)}
      noRowsRenderer={() => <EmptyState icon={placeholderIcon}>{placeholder}</EmptyState>}
      disableHeader={_.isEmpty(data)}
      sort={handleSortColumn}
      {...rest}
    >
      {columns}
    </VirtualTable>
  )
}

export default Table
