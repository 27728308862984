import { GQLItem } from 'shared/graphql/types/graphql'
import { useDispatch } from 'react-redux'
import { gql, useMutation, useQuery } from '@apollo/client'
import createMutationQueries from 'shared/util/gqlMutation'
import { flash } from 'client/redux/actions/flash'
import { useErrorDialog } from 'client/components/ErrorDialog'
import { Dialog, DialogActions, DialogBody, DialogHeader } from 'client/dsm/Dialog/Dialog'
import Button from 'client/components/Button/Button'
import LoadingOverlay from 'client/components/LoadingOverlay/LoadingOverlay'
import { ReactNode, useMemo } from 'react'
import GQLErrorRenderer from 'client/components/GQLErrorRenderer'
import { t } from 'client/i18n'
import { UpdateItemOrExhibitVisibilityDialogBodyContent } from './UpdateItemOrExhibitVisibilityDialogContent'

const ITEM_QUERY = gql`
  query getItems($id: Int!) {
    item(id: $id) {
      id
      isFeatured
      published
      title
      commonName
      scientificName
    }
  }
`

interface IDialogContentProps {
  item: GQLItem
  onClose: () => void
}

function DialogContent({ item, onClose }: IDialogContentProps) {
  const dispatch = useDispatch()

  const { published, isFeatured, title, commonName, scientificName } = item

  const labels = useMemo(() => {
    const buttonLabel = published ? t('Hide Item') : t('Make Item Visible')
    const header = published ? t('Hide Item?') : t('Make Item Visible?')
    const successFlashMessage = published ? t('Content hidden') : t('Content made visible')
    const errorTitle = published ? t('Unable to Hide Item') : t('Unable to Make Item Visible')
    const name = (title || commonName || scientificName)! // TODO add a db constraint for this?
    return {
      buttonLabel,
      header,
      successFlashMessage,
      errorTitle,
      name
    }
  }, [commonName, published, scientificName, title])

  const [errorDialog, setError] = useErrorDialog()

  const [updateItemVisibility] = useMutation(createMutationQueries('item').update, {
    onCompleted: () => {
      onClose()
      dispatch(flash(labels.successFlashMessage))
    },
    onError: (error) =>
      setError({
        error,
        title: labels.errorTitle
      })
  })

  return (
    <>
      <DialogHeader>{labels.header}</DialogHeader>
      <DialogBody>
        <UpdateItemOrExhibitVisibilityDialogBodyContent
          contentName={labels.name}
          contentType="item"
          published={published}
          isFeatured={isFeatured}
        />
      </DialogBody>
      <DialogActions>
        <Button onClick={onClose} label={t('Cancel')} />
        <Button
          type="primary"
          onClick={() => {
            updateItemVisibility({
              variables: { id: item.id, input: { published: !published } }
            })
          }}
          label={labels.buttonLabel}
        />
      </DialogActions>
      {errorDialog}
    </>
  )
}

interface IUpdateItemVisibilityDialogProps {
  id: number
  onClose: () => void
}

export default function UpdateItemVisibilityDialog({
  id,
  onClose
}: IUpdateItemVisibilityDialogProps) {
  const { data, loading, error } = useQuery<{ item: GQLItem }>(ITEM_QUERY, {
    variables: { id }
  })

  let contents: ReactNode

  if (loading) {
    return <LoadingOverlay fullScreen={true} />
  }

  if (error) {
    contents = (
      <>
        <GQLErrorRenderer error={error} />
        <DialogActions>
          <Button onClick={onClose} label={t('Cancel')} />
        </DialogActions>
      </>
    )
  } else {
    contents = <DialogContent item={data!.item} onClose={onClose} />
  }

  return <Dialog>{contents}</Dialog>
}
