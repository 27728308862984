import { useState } from 'react'
import styled from 'styled-components'
import { useFilePicker } from 'use-file-picker'

import Button from 'client/components/Button/Button'
import PageContent from 'client/components/PageContent/PageContent'
import UploadSVG from 'client/assets/svg/icon/upload_20.svg'
import DownloadSVG from 'client/assets/svg/icon/download_20.svg'
import { usePost } from 'client/hooks/api'
import LoadingOverlay from 'client/components/LoadingOverlay/LoadingOverlay'
import { BULK_UPLOAD_ACCEPTED_FILE_TYPES } from 'shared/constants/bulkUpload'
import Spinner from 'client/dsm/Spinner/Spinner'
import { EmptyView } from 'client/components/PageContent/EmptyView'
import { CompletedView } from 'client/screens/Tools/BulkUpload/CompletedView'
import useLatestBulkUpload from 'client/hooks/useLatestBulkUpload'
import ConfirmBulkUploadDialog from 'client/screens/Tools/BulkUpload/BulkUploadConfirmDialog'
import { getFullUrl } from 'client/util/api'
import { t } from 'client/i18n'
import { Trans } from 'react-i18next'
import { Label1CSS } from 'client/components/TextStyles'
import BulkUploadErrorView from './BulkUploadErrorView'

const ProcessingContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const ProcessingText = styled.div`
  ${Label1CSS};
  margin-top: 16px;
  color: var(--color-black);
`

const EmptyMessageContainer = styled.div`
  width: 260px;
`

interface PageControlsProps {
  uploadDisabled?: boolean
  onUpload: () => void
}
const PageControls = (props: PageControlsProps) => {
  const { onUpload, uploadDisabled } = props
  return (
    <>
      <Button
        onClick={() => window.open(getFullUrl('bulk-upload/template'), '_self')}
        svgComponent={DownloadSVG}
        label={t('Download Starter Templates')}
      />
      <Button
        type="primary"
        onClick={onUpload}
        disabled={uploadDisabled}
        svgComponent={UploadSVG}
        label={t('Upload')}
      />
    </>
  )
}

export default () => {
  const [showConfirmDialog, setShowConfirmDialog] = useState(false)
  const { openFilePicker, plainFiles: selectedFiles } = useFilePicker({
    accept: BULK_UPLOAD_ACCEPTED_FILE_TYPES,
    onFilesSuccessfullySelected: () => setShowConfirmDialog(true)
  })

  const {
    data: latestBulkUpload,
    isProcessing,
    refresh: forceLatestBulkUploadRefresh
  } = useLatestBulkUpload()

  const [postBulkUpload, isUploading] = usePost('/bulk-upload')

  const handleBulkUploadConfirm = async () => {
    setShowConfirmDialog(false)

    const formData = new FormData()
    formData.append('file', selectedFiles[0])
    await postBulkUpload(formData)
    await forceLatestBulkUploadRefresh()
  }

  const renderPageContent = () => {
    if (!latestBulkUpload) {
      return (
        <EmptyView
          message={
            <EmptyMessageContainer>
              <Trans i18nKey="bulkUploadEmptyMessage" />
            </EmptyMessageContainer>
          }
        />
      )
    }

    if (isProcessing) {
      return (
        <ProcessingContainer>
          <Spinner />
          <ProcessingText>
            {t('Processing __processingPercentageText__', {
              processingPercentageText: latestBulkUpload.percentageComplete
            })}
          </ProcessingText>
        </ProcessingContainer>
      )
    }

    if (latestBulkUpload.processingError) {
      return <BulkUploadErrorView error={latestBulkUpload.processingError} />
    }

    return <CompletedView name={latestBulkUpload.fileName} createdAt={latestBulkUpload.createdAt} />
  }

  return (
    <PageContent
      title={t('Bulk Upload')}
      controls={<PageControls onUpload={openFilePicker} uploadDisabled={isProcessing} />}
    >
      {isUploading && <LoadingOverlay loadingText={t('Uploading...')} />}
      {showConfirmDialog && (
        <ConfirmBulkUploadDialog
          onConfirmed={handleBulkUploadConfirm}
          onCancel={() => setShowConfirmDialog(false)}
        />
      )}
      {renderPageContent()}
    </PageContent>
  )
}
