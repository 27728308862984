/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react'
import { useContext, useEffect } from 'react'
import styled from 'styled-components'
import { TranslationFormContext } from 'client/components/TranslationForm/TranslationFormContext'
import TranslationFormDialog from 'client/components/TranslationForm/TranslationFormDialog'
import Button from 'client/components/Button/Button'
import { useDialog } from 'client/components/TranslationForm/util'
import useField from 'client/hooks/useField'
import FileUploader, { FileUploadChangeEvent } from 'client/components/FileUploader/FileUploader'
import { SUPPORTED_AUDIO_MIME_TYPES } from 'shared/constants/audio'
import Spinner, { SpinnerSizeType } from 'client/dsm/Spinner/Spinner'
import { t } from 'client/i18n'
import { Label2CSS } from 'client/components/TextStyles'
import AudioFileMenuPopout from 'client/screens/Catalog/forms/AudioForm/AudioFileMenuPopout'
import ProcessingErrorMessage from '../shared/ProcessingErrorMessage'
import useAudioFileUploader from './useAudioFileUploader'

enum DialogType {
  ERROR = 'DialogType.Error'
}

const AudioRowWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: var(--spacing-xsmall);
`

const PlayerWrapper = styled.div<{ hasError: boolean }>`
  display: flex;
  margin-right: var(--spacing-xsmall);
  border: 1px solid var(--color-grey-03);
  border-radius: 27px;
  border-color: ${({ hasError }) =>
    hasError ? 'var(--color-status-error)' : 'var(--color-grey-03)'};
`

const StyledAudio = styled.audio`
  width: 300px;
`

const ProgressSpinnerContainer = styled.div`
  ${Label2CSS};
  color: var(--color-grey-07);
  margin-top: var(--spacing-xsmall);
`

const ProgressSpinner = styled(Spinner)`
  display: inline-block;
  vertical-align: bottom;
  margin-right: var(--spacing-xsmall);
`

interface IAudioFieldProps {
  onChange: (file: File) => void
}

const AudioField = ({ onChange }: IAudioFieldProps) => {
  const hiddenAudioInputRef = React.useRef<HTMLInputElement>(null)
  const { getFieldName, getFieldValue, contentId, selectedLocale } =
    useContext(TranslationFormContext)
  const url = getFieldValue('url')
  const localFileUrl = getFieldValue('localFileUrl')
  const isProcessing = getFieldValue('isProcessing')
  const processingError = getFieldValue('processingError')
  const { error, touched } = useField(getFieldName('processingError'))

  const hasActiveProcessingError = !!(error && touched)

  const resolvedUrl = localFileUrl || url
  // urlFieldValue contains the url of the audio
  // It might not always be populated until the audio is done processing successfully
  const hasUploadedAudio = resolvedUrl || isProcessing || hasActiveProcessingError

  if (!hasUploadedAudio) {
    return (
      <FileUploader
        name="audioFileInput"
        buttonLabel={t('Add Audio File')}
        fileFormats={SUPPORTED_AUDIO_MIME_TYPES}
        onChange={(event: FileUploadChangeEvent) => onChange(event.target.files![0])}
      />
    )
  }

  const onAudioReplace = (event) => onChange(event.target.files[0])

  return (
    <>
      <PlayerWrapper hasError={!resolvedUrl && hasActiveProcessingError}>
        <StyledAudio controls={true} controlsList="nodownload noplaybackrate" src={resolvedUrl} />
      </PlayerWrapper>
      <AudioFileMenuPopout
        id={contentId}
        selectedLocaleCode={selectedLocale?.code!}
        onReplace={() => hiddenAudioInputRef.current!.click()}
        processingError={processingError}
        isProcessing={isProcessing}
        localFile={localFileUrl}
      />
      <input
        ref={hiddenAudioInputRef}
        name="audioFileInputReplace"
        type="file"
        accept={SUPPORTED_AUDIO_MIME_TYPES.join(',')}
        onChange={onAudioReplace}
        hidden={true}
      />
    </>
  )
}

const AudioFileField = () => {
  const { isDialog, setCurrentDialog, closeDialog } = useDialog<DialogType>()
  const { setFieldValue, getFieldName, getFieldValue } = useContext(TranslationFormContext)

  const isProcessing = getFieldValue('isProcessing')

  const { upload, data, error, reset } = useAudioFileUploader()

  const revokeLocalFileUrl = () => URL.revokeObjectURL(getFieldValue('localFileUrl'))

  useEffect(() => {
    // reset the hook information when the field name changes
    reset()
  }, [getFieldName('url')])

  useEffect(() => {
    let cleanup
    if (data) {
      setFieldValue('url', data.file?.url)
      setFieldValue('localFileUrl', data.localFileUrl)
      setFieldValue('file', data.file)
      // Note, we're clearing out the error state in our validation
      // but also clearing out the property in the values as it's no longer valid
      // to have it.
      setFieldValue('processingError', undefined)
      // Tell browser it can release the file
      cleanup = () => revokeLocalFileUrl
    }

    return cleanup
  }, [data])

  useEffect(() => {
    if (error) {
      setCurrentDialog(DialogType.ERROR)
    }
  }, [error])

  return (
    <>
      {isDialog(DialogType.ERROR) && (
        <TranslationFormDialog
          title={error?.title!}
          body={<p>{error?.body}</p>}
          actions={<Button type="primary" onClick={closeDialog} label={t('OK')} />}
        />
      )}
      <AudioRowWrapper>
        <AudioField onChange={upload} />
      </AudioRowWrapper>
      {isProcessing && !data?.localFileUrl && (
        <ProgressSpinnerContainer>
          <ProgressSpinner size={SpinnerSizeType.SMALL} />
          <span>{t('Processing...')}</span>
        </ProgressSpinnerContainer>
      )}
      <ProcessingErrorMessage name="url" contentName="audio" />
    </>
  )
}

export default AudioFileField
