import { memo, useCallback } from 'react'
import { GQLItem } from 'shared/graphql/types/graphql'
import LookupNumberSelect from 'client/components/LookupNumberSelect/LookupNumberSelect'
import {
  ReorderableItemRow,
  IReorderableRowProps
} from 'client/components/ContentRow/ReorderableRow'
import styled from 'styled-components'
import { SelectContainer } from 'client/screens/Catalog/forms/shared/styledComponents'
import { t } from 'client/i18n'
import { ExhibitionType } from 'shared/constants/exhibits'
import ContentMapLocationSelect from 'client/screens/Catalog/forms/shared/MapLocations/ContentMapLocationSelect'
import useAvailableShowOnMapOptions from 'client/screens/Catalog/forms/shared/MapLocations/useAvailableShowOnMapOptions'
import _ from 'lodash'
import { Body2CSS } from 'client/components/TextStyles'

const TextContainer = styled.span`
  white-space: nowrap;
`

interface IExhibitItemProps extends IReorderableRowProps<GQLItem> {
  lookupNumbers: number[]
  type: ExhibitionType
}

type IStyledReorderableItemRowProps = IReorderableRowProps<GQLItem> & {
  isWarning: boolean
}
const StyledReorderableItemRow = styled((props: IStyledReorderableItemRowProps) => {
  const { isWarning, ...rest } = props
  return <ReorderableItemRow {...rest} />
})`
  ${(props) => props.isWarning && 'border: 1px solid var(--color-status-warning);'}
`

const TourWarningWrapper = styled.div`
  margin-bottom: var(--spacing-medium);
`

const TourWarningText = styled.div`
  ${Body2CSS};
  color: var(--color-status-warning-text);
`

function ExhibitItemRow(props: IExhibitItemProps) {
  const { isDragging, value, onEdit, onRemove, lookupNumbers, showGrabber, type } = props
  const { lookupNumber } = value
  const mapLocationOptions = useAvailableShowOnMapOptions()
  const handleLookupChange = (selected) => {
    if (selected) {
      onEdit!({ ...value, lookupNumber: selected.value })
    }
  }

  const actualLookupNumber = lookupNumber === -1 ? null : lookupNumber
  const lookupComponent = (
    <LookupNumberSelect
      name="lookupNumber"
      onChange={handleLookupChange}
      value={actualLookupNumber}
      lookupNumbers={lookupNumbers}
    />
  )

  const handleLocationChanged = useCallback(
    (e) => {
      onEdit!({ ...value, itemMapLocation: { mapLocation: { id: e.target.value } } })
    },
    [onEdit, value]
  )

  const itemMapLocationComponent = (
    <ContentMapLocationSelect
      onChange={handleLocationChanged}
      selectedMapLocationId={value?.itemMapLocation?.mapLocation?.id}
      placeholder={t('None')}
      buildings={mapLocationOptions}
    />
  )

  const isTour = type === ExhibitionType.TOUR

  const flexComponent = () => (
    <>
      <TextContainer>{isTour ? t('Location:') : t('Lookup Number:')}</TextContainer>
      <SelectContainer>{isTour ? itemMapLocationComponent : lookupComponent}</SelectContainer>
    </>
  )

  const isWarning = isTour && _.isNil(value.itemMapLocation?.mapLocation)
  return (
    <TourWarningWrapper>
      <StyledReorderableItemRow
        value={value}
        flexComponent={flexComponent}
        onRemove={onRemove}
        isDragging={isDragging}
        showGrabber={showGrabber}
        isWarning={isWarning}
      />
      {isWarning && <TourWarningText>{t('This item needs a map location')}</TourWarningText>}
    </TourWarningWrapper>
  )
}

export default memo(ExhibitItemRow)
