import { GuideExportRedirectRoute } from 'client/screens/App/GuideExportRedirectRoute'
import { Routes, Route } from 'react-router-dom'

const Deeplink = () => (
  <Routes>
    <Route
      path="guide/:guideId/version/:versionType/:contentType/:contentTypeId"
      element={<GuideExportRedirectRoute />}
    />
  </Routes>
)

export default Deeplink
