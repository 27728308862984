import { useCallback, useState } from 'react'
import { GQLBuilding, GQLMapLocationContent, GQLMapLocation } from 'shared/graphql/types/graphql'
import _ from 'lodash'
import { MapLocationRenderer } from 'client/screens/Catalog/forms/shared/MapLocations/MapLocationRenderer'
import useAvailableShowOnMapOptions from 'client/screens/Catalog/forms/shared/MapLocations/useAvailableShowOnMapOptions'
import PlusIconAddButton from 'client/components/Button/PlusIconAddButton'
import useField from 'client/hooks/useField'
import styled from 'styled-components'
import { t } from 'client/i18n'

const AddLocationButton = styled(PlusIconAddButton)`
  margin-top: var(--spacing-small);
`
function filterBuildings(
  buildings: GQLBuilding[],
  selectedMapLocations: GQLMapLocation[],
  currentMapLocation: GQLMapLocation
): GQLBuilding[] {
  if (_.isEmpty(selectedMapLocations)) {
    return buildings
  }

  const selectedMapLocationIds = _(selectedMapLocations).map('id').compact().value()

  const mapLocationIdsToRemove = _.without(selectedMapLocationIds, currentMapLocation?.id)

  return _.map(buildings, (building) => ({
    ...building,
    mapLocations: _.reject(building.mapLocations, (mapLocation) =>
      _.includes(mapLocationIdsToRemove, mapLocation.id)
    )
  }))
}

interface IMapLocationsProps {
  name: string
}

export default function MapLocations(props: IMapLocationsProps) {
  const { name } = props
  const { value: values = [], setValue: setValues } = useField(name)
  const [emptyMapLocation, setEmptyMapLocation] = useState<null | { featured: true }>(null)

  const mapLocationOptions = useAvailableShowOnMapOptions()

  const handleAddLocation = useCallback(() => {
    // done to avoid adding invalid map location to formik values
    setEmptyMapLocation({ featured: true })
  }, [setEmptyMapLocation])

  const resetEmptyLocation = useCallback(
    (index: number) => {
      // change occurred on the empty location; clear out the state
      if (index > values.length - 1) {
        setEmptyMapLocation(null)
      }
    },
    [values.length]
  )

  const handleChange = useCallback(
    (index: number, mapLocation: Partial<GQLMapLocationContent>) => {
      const newValues = [...values]
      newValues[index] = mapLocation

      resetEmptyLocation(index)
      setValues(newValues)
    },
    [values, setValues, resetEmptyLocation]
  )

  const handleRemove = useCallback(
    (index: number) => {
      resetEmptyLocation(index)
      setValues(_.without(values, values[index]))
    },
    [values, setValues, resetEmptyLocation]
  )

  const selectedMapLocations = _.map(values, 'mapLocation')
  return (
    <>
      {_.map(
        _.compact([...values, emptyMapLocation]),
        (mapLocationContent: GQLMapLocationContent, index: number) => (
          <MapLocationRenderer
            key={mapLocationContent.mapLocation?.id || 'new'}
            buildings={filterBuildings(
              mapLocationOptions,
              selectedMapLocations,
              mapLocationContent.mapLocation
            )}
            onChange={(mapLocation) => handleChange(index, mapLocation)}
            onRemove={() => handleRemove(index)}
            value={mapLocationContent}
          />
        )
      )}

      {_.isNil(emptyMapLocation) && (
        <AddLocationButton label={t('Add Location')} onClick={handleAddLocation} />
      )}
    </>
  )
}
