import { t } from 'client/i18n'
import ContextualHelp from 'client/components/ContextualHelp/ContextualHelp'

export const LatitudeContextualHelp = () => (
  <ContextualHelp
    helpSize="large"
    header={t('Latitude')}
    helpContent={
      <>
        <p>{t('Latitude values range from -90 to 90.')} </p>
        <p>
          {t(
            'Latitude and longitude coordinates are expressed in decimal degrees format (e.g., 40.68941207542726, -74.04445748733185).'
          )}
        </p>
      </>
    }
    tooltipContent={t('More on latitude values')}
  />
)

export const LongitudeContextualHelp = () => (
  <ContextualHelp
    helpSize="large"
    header={t('Longitude')}
    helpContent={
      <>
        <p>{t('Longitude values range from -180 to 180.')} </p>
        <p>
          {t(
            'Latitude and longitude coordinates are expressed in decimal degrees format (e.g., 40.68941207542726, -74.04445748733185).'
          )}
        </p>
      </>
    }
    tooltipContent={t('More on longitude values')}
  />
)

export const MapOrientationContextualHelp = () => (
  <ContextualHelp
    helpSize="large"
    header={t('Map Orientation')}
    helpContent={
      <>
        <p>{t('Set the orientation of the map using degrees (0-360) from true north.')}</p>
        <p>
          {t(
            'The default orientation is set to 0, which means that the top of the map points true north.'
          )}
        </p>
        <p>
          {t(
            'Negative values rotate the map clockwise; positive values rotate the map counterclockwise. For example, enter 90 to make the top of the map point east. 180 and -180 both mean that the top of the map points south.'
          )}
        </p>
      </>
    }
    tooltipContent={t('More on changing the map orientation')}
  />
)
