import { GQLItem } from 'shared/graphql/types/graphql'
import { ItemType } from 'shared/constants/item'
import BaseGridView from 'client/components/BaseGridView/BaseGridView'
import { ITableCellProps } from 'client/dsm/Table/Table'
import gql from 'graphql-tag'
import _ from 'lodash'
import {
  ITEM_OVERFLOW_MENU_COLUMN,
  ITEM_EXHIBIT_IMAGE_COLUMN,
  LANGUAGES_COLUMN,
  LOOKUP_NUMBER_COLUMN,
  UPDATED_AT_COLUMN,
  VISIBILITY_COLUMN,
  ITEM_INCLUDED_IN_COLUMN
} from 'client/screens/Catalog/grids/shared/columns'
import { t } from 'client/i18n'
import { Header3 } from 'client/components/TextStyles'
import ItemForm from '../forms/ItemForm'
import GridPlusIconAddButton from './shared/GridPlusIconAddButton'
import { EmptyStatePlaceholderDescription } from './shared/styledComponents'
import MultilineTooltip from './shared/MultilineTooltip'

const getCreatorNames = (rowData: GQLItem) =>
  _.map(rowData.creators, ({ prefix, name }) => _.join([prefix, name], ' ').trim())

const getCreatorDisplayName = (rowData: GQLItem) => getCreatorNames(rowData).join(', ')

const creatorsRenderer = ({ rowData }: ITableCellProps<GQLItem>) => {
  const creators = getCreatorDisplayName(rowData)
  return <MultilineTooltip lines={getCreatorNames(rowData)}>{creators}</MultilineTooltip>
}

const ITEMS_GRAPHQL_QUERY = gql`
  query itemsForMuseum($museumId: Int!) {
    museum(id: $museumId) {
      id
      items {
        id
        uuid
        type
        published
        lookupNumber
        title
        information
        exhibits {
          id
          title
        }
        externalId
        images {
          id
          hero
        }
        creators {
          id
          prefix
          name
          lifedates
        }
        locales {
          code
          englishName
        }
        updatedAt
      }
    }
  }
`
const addItemButton = <GridPlusIconAddButton label={t('Add Item')} to="/catalog/items/new" />

const ItemsEmptyPlaceholder = () => (
  <>
    <Header3>{t('Add your first item')}</Header3>
    <EmptyStatePlaceholderDescription>
      <p>{t('An item represents a single object or idea, like:')}</p>
      <ul>
        <li>{t('A work of art')}</li>
        <li>{t('An architectural feature of your space')}</li>
        <li>{t("A director's welcome or other informational content")}</li>
      </ul>
      {addItemButton}
    </EmptyStatePlaceholderDescription>
  </>
)

export default function Items() {
  return (
    <BaseGridView
      contentName="Item"
      gqlQuery={ITEMS_GRAPHQL_QUERY}
      // Probably want to do this on the server in the future
      gqlFilterFunction={(item: GQLItem) => item.type === ItemType.ITEM}
      columns={_.compact([
        ITEM_EXHIBIT_IMAGE_COLUMN,
        { dataKey: 'title', label: t('Title') },
        {
          dataKey: 'creators',
          label: t('Creator'),
          cellRenderer: creatorsRenderer,
          sortBy: getCreatorDisplayName
        },
        LOOKUP_NUMBER_COLUMN,
        ITEM_INCLUDED_IN_COLUMN,
        VISIBILITY_COLUMN,
        LANGUAGES_COLUMN,
        UPDATED_AT_COLUMN,
        ITEM_OVERFLOW_MENU_COLUMN
      ])}
      filterCriteria={[
        'lookupNumber',
        'title',
        'creators.name',
        'creators.prefix',
        'exhibits.title'
      ]}
      buttons={addItemButton}
      formComponent={() => <ItemForm type={ItemType.ITEM} />}
      placeholder={<ItemsEmptyPlaceholder />}
    />
  )
}
