import IVersionInfo from 'shared/VersionInfo'
import { NAVIGATE_WITH_REFRESH, NAVIGATE_WITH_VERSION_INFO } from './types'

export function navigateWithRefresh(url: string) {
  return { type: NAVIGATE_WITH_REFRESH, url }
}

export function navigateWithVersionInfo(selectedVersionInfo: Partial<IVersionInfo>, url: string) {
  return { type: NAVIGATE_WITH_VERSION_INFO, selectedVersionInfo, url }
}
