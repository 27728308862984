import styled from 'styled-components'
import { useLocation } from 'react-router-dom'
import Spinner, { SpinnerColorType, SpinnerSizeType } from 'client/dsm/Spinner/Spinner'
import ErrorSVG from 'client/assets/svg/icon/status_error_20.svg'
import { useDispatch } from 'react-redux'
import useInterval from 'client/hooks/useInterval'
import { fetchBulkUpload } from 'client/redux/actions/bulkUpload'
import { useLatestBulkUploadStatus } from 'client/hooks/useLatestBulkUpload'
import { t } from 'client/i18n'
import { StyledNavLink } from './styledComponents'

const StyledBulkUploadNavLink = styled(StyledNavLink)`
  display: flex;
  justify-content: space-between;
`

/**
 * Responsible for rendering the following
 *  - Bulk Upload Nav Link
 *  - Inline Loading Indicator: When bulk upload is processing
 *  - Inline Error Indicator: When a processed bulk upload completed with errors
 */
const BulkUploadNavLink = () => {
  const location = useLocation()
  const dispatch = useDispatch()

  // NOTE: would be be smarter about this and stop fetching once done
  // Then have bulk upload view restart interval on next upload
  useInterval(() => {
    dispatch(fetchBulkUpload())
  }, 5000)

  const { hasError, isProcessing } = useLatestBulkUploadStatus()

  const isCurrentRouteBulkUpload = location.pathname.indexOf('bulk-upload') !== -1
  const isProcessedWithErrors = !isProcessing && hasError

  return (
    <StyledBulkUploadNavLink to="/tools/bulk-upload">
      {t('Bulk Upload')}
      {!isCurrentRouteBulkUpload && (
        <>
          {isProcessing && <Spinner size={SpinnerSizeType.SMALL} color={SpinnerColorType.DARK} />}
          {isProcessedWithErrors && <ErrorSVG />}
        </>
      )}
    </StyledBulkUploadNavLink>
  )
}

export default BulkUploadNavLink
