import styled from 'styled-components'
import Spinner from 'client/dsm/Spinner/Spinner'
import PlusIconAddButton from 'client/components/Button/PlusIconAddButton'
import { Body1CSS, Body2CSS, Label2CSS } from 'client/components/TextStyles'

export const StyledVideoContainer = styled.div`
  margin: var(--spacing-small) 0 var(--spacing-xsmall);
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  align-items: center;
`

export const StyledVideoPlayer = styled.video<{ error: boolean }>`
  height: ${({ error }) => 180 - (error ? 4 : 0)}px;
  width: ${({ error }) => 320 - (error ? 4 : 0)}px;
  margin-right: var(--spacing-xsmall);
  ${({ error }) =>
    error
      ? `
  border: 2px solid var(--color-red-06);
  padding: 2px;
  `
      : ''}
`

export const ExplanatoryText = styled.div`
  ${Body1CSS};
  margin-top: 4px;
`

export const TranscodingContainer = styled.div`
  ${Label2CSS};
  color: var(--color-grey-07);
`

export const TranscodingProgressSpinner = styled(Spinner)`
  display: inline-block;
  vertical-align: bottom;
  margin-right: 8px;
`
export const AddVideoFileButton = styled(PlusIconAddButton)`
  margin-top: var(--spacing-xsmall);
`

export const ADBannerMessage = styled.div`
  ${Body2CSS};
  svg {
    color: var(--color-green-07);
    margin-right: 4px;
  }

  display: flex;
  justify-content: start;
  align-items: center;
  color: var(--color-green-07);
  margin-top: var(--spacing-small);
  padding-bottom: var(--spacing-medium);
  border-bottom: 1px solid var(--color-grey-02);
`

export const TrackInfoNote = styled.div`
  ${Body1CSS}
`
