import { BulkUploadSheetName } from 'shared/bulkUpload/types'

export enum BulkUploadErrorType {
  PARSE_ERROR = 'PARSE_ERROR',
  FILE_STRUCTURE_ERROR = 'FILE_STRUCTURE_ERROR',
  FILE_DATA_ERROR = 'FILE_DATA_ERROR',
  MEDIA_PROCESSING_ERROR = 'MEDIA_PROCESSING_ERROR'
}

export type BulkUploadFileStructureValidationInfo = Partial<{
  missing: string[]
  unrecognized: string[]
  duplicate: string[]
}>

export type BulkUploadFileStructureErrorDetails = {
  sheets: BulkUploadFileStructureValidationInfo
  columns: Partial<Record<BulkUploadSheetName, BulkUploadFileStructureValidationInfo>>
}

export type BulkUploadErrorDetails = Array<{
  sheetName: string
  errors: Array<{
    row?: number
    messages: string[]
  }>
}>

export interface IBulkUploadError {
  type?: BulkUploadErrorType
  details?: BulkUploadErrorDetails | BulkUploadFileStructureErrorDetails
}
