import * as React from 'react'
import _ from 'lodash'
import { ThumbnailContainer, ThumbnailImage } from 'client/components/ContentRow/styledComponents'

interface IImageProps {
  image?: string | React.FunctionComponent
  isError?: boolean
  isPickerRow?: boolean
  isGridRow?: boolean
}
export const ImageContainer = ({
  image: Image,
  isError = false,
  isPickerRow = false,
  isGridRow = false
}: IImageProps) => {
  const isSVG = _.isFunction(Image)

  return (
    <ThumbnailContainer
      isError={isError}
      isSVG={isSVG}
      isPickerRow={isPickerRow}
      isGridRow={isGridRow}
    >
      {isSVG ? (
        <Image />
      ) : (
        <ThumbnailImage
          isError={isError}
          src={Image}
          isPickerRow={isPickerRow}
          isGridRow={isGridRow}
        />
      )}
    </ThumbnailContainer>
  )
}
