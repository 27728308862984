import { ReactNode, useRef } from 'react'
import styled from 'styled-components'
import ErrorSVG from 'client/assets/svg/icon/status_error_20.svg'
import WarningSVG from 'client/assets/svg/icon/status_warning_20.svg'
import _ from 'lodash'
import { Body1CSS, Header3CSS } from 'client/components/TextStyles'

interface IBannerProps {
  type: 'error' | 'warning'
  headingText: string
  description?: string
  children?: ReactNode
  className?: string
}

const BANNER_TYPE_STYLES = {
  error: {
    backgroundColor: 'var(--color-status-error-background)',
    color: 'var(--color-status-error)'
  },
  warning: {
    backgroundColor: 'var(--color-status-warning-background)',
    color: 'var(--color-status-warning-text)'
  }
}

const StyledBanner = styled.div<{ type: IBannerProps['type'] }>`
  display: flex;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  padding: 20px 24px;
  background-color: ${({ type }) => BANNER_TYPE_STYLES[type].backgroundColor};
`
const BannerIcon = styled.i`
  display: block;
  margin-top: 2px;
  margin-right: 8px;
  height: 20px;
  width: 20px;
  & svg {
    display: block;
  }
`

const BannerHeading = styled.div<{ type: IBannerProps['type'] }>`
  ${Header3CSS};
  margin: 0;
  color: ${({ type }) => BANNER_TYPE_STYLES[type].color};
`

const BannerDescription = styled.div`
  ${Body1CSS};
  margin-top: var(--spacing-xsmall);
  color: var(--color-black);
`

const BannerMessage = styled.div`
  margin: 0;
  /* Set spacing between the header and the first content if exists */
  ${BannerHeading} + * {
    margin-top: 8px;
  }
`

const BannerItem = styled.div`
  ${Body1CSS};
  display: block;
  margin: 4px 0;
  color: var(--color-black);
`

export default (props: IBannerProps) => {
  const { type, headingText, children, className, description, ...rest } = props
  const { current: headingId } = useRef(`banner-label-${_.uniqueId()}`)
  return (
    <StyledBanner type={type} className={className} aria-labelledby={headingId} {...rest}>
      <BannerIcon>
        {type === 'error' && <ErrorSVG />}
        {type === 'warning' && <WarningSVG />}
      </BannerIcon>
      <BannerMessage>
        <BannerHeading id={headingId} type={type}>
          {headingText}
          {description && <BannerDescription>{description}</BannerDescription>}
        </BannerHeading>
        {children}
      </BannerMessage>
    </StyledBanner>
  )
}

export { BannerItem }
