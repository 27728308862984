import { HeroImage } from 'client/screens/Catalog/grids/shared/styledComponents'
import { ITableCellProps } from 'client/dsm/Table/Table'
import { GQLImage } from 'shared/graphql/types/graphql'
import { LeftGutterRendererWrapper } from 'client/screens/Catalog/grids/shared/LeftGutterWrapper'
import { MediaStatusIcon } from 'client/screens/Catalog/grids/shared/MediaStatusIcon'

function InnerImagePreview(props: ITableCellProps<GQLImage>) {
  const { cellData } = props
  return <HeroImage src={cellData} />
}

const ImagePreview = LeftGutterRendererWrapper(InnerImagePreview, MediaStatusIcon)

export default ImagePreview
