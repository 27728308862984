import { ReorderableAudioRow } from 'client/components/ContentRow/ReorderableRow'
import { PickerAudioRow } from 'client/components/ContentRow/PickerRow'
import gql from 'graphql-tag'
import DataAwareReorderableList from 'client/components/Formik/ReorderableList/DataAwareReorderableList'
import { SUPPORTED_AUDIO_MIME_TYPES } from 'shared/constants/audio'
import { UploadMediaType } from 'client/hooks/useMediaUpload'

const AUDIOS_MINIPICKER_QUERY = gql`
  query audiosForMinipicker($museumId: Int!) {
    museum(id: $museumId) {
      id
      audios {
        id
        url
        sourceUrl
        title
        duration
        transcript
        locales {
          code
          englishName
        }
        isProcessing
        isChildrenProcessingError
        isMarketingUseAllowed
      }
    }
  }
`

interface IAudiosListProps {
  name: string
}

export default function AudiosList({ name }: IAudiosListProps) {
  return (
    <DataAwareReorderableList
      name={name}
      rowComponent={ReorderableAudioRow}
      pickerRowComponent={PickerAudioRow}
      filterCriteria={['title', 'fileName']}
      contentName="Audio"
      gqlQuery={AUDIOS_MINIPICKER_QUERY}
      uploadMedia={{
        uploadFileFormats: SUPPORTED_AUDIO_MIME_TYPES,
        type: UploadMediaType.AUDIO
      }}
    />
  )
}
