import { useRef } from 'react'
import styled from 'styled-components'
import PlusIconAddButton from 'client/components/Button/PlusIconAddButton'
import { t } from 'client/i18n'
import MapImageInput from '../MapImageInput'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledPlusIconAddButton = styled(PlusIconAddButton)`
  border: 1px dotted var(--color-grey-02);
  border-radius: 2px;
  width: 100%;
  height: 60px;
`

interface IAddFloorImageButtonProps {
  id: string
  onUpload: (file: File) => void
}

const AddFloorImageButton = (props: IAddFloorImageButtonProps) => {
  const { id } = props

  const inputRef = useRef<HTMLInputElement>(null)
  const handleImageChange = (file: File) => {
    if (props.onUpload) {
      props.onUpload(file)
    }
  }

  return (
    <Container>
      <MapImageInput id={id} onFileChange={handleImageChange} ref={inputRef} />
      <StyledPlusIconAddButton
        label={t('Add Floor Map')}
        onClick={() => inputRef.current?.click()}
      />
    </Container>
  )
}

export default AddFloorImageButton
