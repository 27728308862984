import _ from 'lodash'
import util from 'util'
import { charsets, PasswordPolicy } from 'password-sheriff'

const passwordPolicy = new PasswordPolicy({
  length: {
    minLength: 8,
    maxLength: 48
  },
  containsAtLeast: {
    atLeast: 3,
    expressions: [
      charsets.upperCase,
      charsets.lowerCase,
      charsets.numbers,
      charsets.specialCharacters
    ]
  }
})

const formatPasswordSheriffErrors = (rules) =>
  _.map(rules, (rule) => {
    const errorMessage = util.format(rule.message, ...rule.format)

    if (rule.code === 'containsAtLeast') {
      return `${errorMessage} ${_.map(rule.items, 'message').join(', ')}`
    }

    return errorMessage
  })

const validatePassword = (password) => {
  const passwordValid = passwordPolicy.check(password)
  if (!passwordValid) {
    return {
      error: 'Password does not meet the required password policy.',
      messages: formatPasswordSheriffErrors(passwordPolicy.explain(password))
    }
  }
}

export default validatePassword
