import { useMemo } from 'react'
import _ from 'lodash'
import { ExhibitItemsHeading, ExhibitItemsHeadingLabel } from 'shared/constants/exhibits'
import SelectBox from 'client/components/Form/SelectBox/SelectBox'
import useField from 'client/hooks/useField'
import { t } from 'client/i18n'
import { useFormikContext } from 'formik'

interface IItemsHeadingSelectField {
  name: string
}

const ITEMS_HEADING_OPTIONS = _.map(ExhibitItemsHeadingLabel, (label, value) => ({
  value,
  // TODO: discuss with app team about sending the enum value to the app, rather than translated
  // If we did this, there would be no need for human readable values on the server, and this could all be handled client-side
  label: t(`exhibitItemsHeading.${label}`)
}))

const ItemsHeadingSelectField = (props: IItemsHeadingSelectField) => {
  const { name } = props
  const { value: itemsHeading, setValue } = useField(name)
  const { initialValues } = useFormikContext<{ itemsHeading: ExhibitItemsHeading }>()

  const showStopsOption = useMemo(
    () => initialValues.itemsHeading === ExhibitItemsHeadingLabel.STOPS,
    [initialValues]
  )

  const options = useMemo(
    () =>
      showStopsOption
        ? ITEMS_HEADING_OPTIONS
        : _.reject(ITEMS_HEADING_OPTIONS, { value: ExhibitItemsHeadingLabel.STOPS }),
    [showStopsOption]
  )

  const currentItemsHeading = useMemo(
    () =>
      itemsHeading
        ? _.find(ITEMS_HEADING_OPTIONS, { value: itemsHeading })
        : ITEMS_HEADING_OPTIONS[0],
    [itemsHeading]
  )

  return (
    <SelectBox
      name={name}
      options={options}
      onChange={({ target }) => setValue(target.value)}
      value={currentItemsHeading}
    />
  )
}

export default ItemsHeadingSelectField
