import { useGet } from 'client/hooks/api'
import PageContent from 'client/components/PageContent/PageContent'
import styled from 'styled-components'
import useGuideVisibility from 'client/screens/Reports/useGuideVisibility'
import Spinner from 'client/dsm/Spinner/Spinner'
import { t } from 'client/i18n'
import GQLErrorRenderer from 'client/components/GQLErrorRenderer'
import Banner, { BannerItem } from 'client/dsm/Banner/Banner'

const NoPaddingPageContent = styled(PageContent)`
  max-width: none;
`

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`

const StyledIFrame = styled.iframe`
  width: 100%;
  height: 100%;
  border: 0;
`

function PrivateGuideWarningBanner() {
  return (
    <Banner type="warning" headingText={t('Private Guide')}>
      <BannerItem>
        {t(
          'This guide is currently set to Private. Data Dashboard is only available for Public guides.'
        )}
      </BannerItem>
    </Banner>
  )
}

export default function DataDashboard() {
  const [urlResponse, isUrlLoading] = useGet<any>('/data-dashboard/url')

  const { loading: isPublicGuideLoading, error: graphqlError, isPrivate } = useGuideVisibility()

  const pageTitle = t('Data Dashboard')

  if (isUrlLoading || isPublicGuideLoading) {
    return (
      <PageContent title={pageTitle}>
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      </PageContent>
    )
  }

  if (graphqlError) {
    return (
      <PageContent title={pageTitle}>
        <GQLErrorRenderer error={graphqlError} />
      </PageContent>
    )
  }

  if (isPrivate) {
    return (
      <PageContent title={pageTitle}>
        <PrivateGuideWarningBanner />
      </PageContent>
    )
  }

  return (
    <NoPaddingPageContent title={pageTitle}>
      <StyledIFrame src={urlResponse.url} />
    </NoPaddingPageContent>
  )
}
