import { changeLanguage, enabledLanguageCodeToLabel, t } from 'client/i18n'
import ArrowDownSVG from 'client/assets/svg/icon/chevron_20_down.svg'
import MenuPopout from 'client/components/MenuPopout/MenuPopout'
import _ from 'lodash'

const languageSelectorOptions = _.map(enabledLanguageCodeToLabel, (label, code) => ({
  label,
  onClick: () => changeLanguage(code)
}))

export default function LanguageSelector() {
  return (
    <MenuPopout
      options={[
        ...languageSelectorOptions,

        {
          label: '♥️',
          onClick: () => {
            changeLanguage('hearts')
          }
        },
        {
          label: 'Reset to default',
          onClick: () => {
            changeLanguage(null)
          }
        }
      ]}
      placement="bottom-end"
    >
      {t('Language')}
      <ArrowDownSVG />
    </MenuPopout>
  )
}
