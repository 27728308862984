import GuideVisibilityType from 'shared/GuideVisibilityType'
import FormField from 'client/components/Form/FormField/FormField'
import styled from 'styled-components'
import Tooltip from 'client/dsm/Tooltip/Tooltip'
import ToggleInput from 'client/components/Form/ToggleInput/ToggleInput'
import { useFormikContext, FormikContextType } from 'formik'
import _ from 'lodash'
import { t } from 'client/i18n'

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

interface IGetTooltipContentParams {
  guideVisibilityType: GuideVisibilityType
  guideId: number
  isInternalTestingGuide: boolean
}
const getTooltipContent = ({
  guideVisibilityType,
  guideId,
  isInternalTestingGuide
}: IGetTooltipContentParams) => {
  if (!guideId) {
    return 'Guides cannot be made public when adding'
  }

  if (guideVisibilityType === GuideVisibilityType.SECRET) {
    return (
      <>
        <p>{t('This guide is secret and cannot be changed.')}</p>
        <p>{t('Contact CMS Engineering with questions.')}</p>
      </>
    )
  }
  if (guideVisibilityType === GuideVisibilityType.PUBLIC) {
    return (
      <>
        <p>
          {t(
            'This guide is available to the public in the Bloomberg Connects app and cannot be made private.'
          )}
        </p>
        <p>{t('Contact CMS Engineering with questions.')}</p>
      </>
    )
  }
  if (isInternalTestingGuide) {
    return (
      <p>
        {t(
          'This is an internal testing guide and cannot be exposed to the public. Contact CMS Engineering with questions.'
        )}
      </p>
    )
  }

  return null
}

interface IPublicVisibilityFieldProps {
  originalGuideVisibilityType: GuideVisibilityType
  guideId: number
}
const PublicVisibilityField = ({
  originalGuideVisibilityType,
  guideId
}: IPublicVisibilityFieldProps) => {
  const { values, setFieldValue } = useFormikContext() as FormikContextType<{
    visibilityType: GuideVisibilityType
    isInternal: boolean
  }>

  const disabledTooltipContent = getTooltipContent({
    guideVisibilityType: originalGuideVisibilityType,
    guideId,
    isInternalTestingGuide: values.isInternal
  })

  const disabled = !_.isNil(disabledTooltipContent)

  const value = values.visibilityType === GuideVisibilityType.PUBLIC

  const onChange = (e) => {
    if (e.target.value) {
      setFieldValue('visibilityType', GuideVisibilityType.PUBLIC)
    } else {
      setFieldValue('visibilityType', GuideVisibilityType.PRIVATE)
    }
  }

  const toggle = <ToggleInput value={value} onChange={onChange} disabled={disabled} />

  return (
    <FlexContainer>
      <FormField
        label={t('Public Guide')}
        description={t(
          'Switch this setting on and then save the form to make this guide available to the public in the Bloomberg Connects app.'
        )}
        disabled={disabled}
      />
      {disabledTooltipContent ? (
        <Tooltip content={disabledTooltipContent}>
          <div>{toggle}</div>
        </Tooltip>
      ) : (
        toggle
      )}
    </FlexContainer>
  )
}

export default PublicVisibilityField
