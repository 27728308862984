import { Field, FieldProps } from 'formik'
import LookupNumberSelect, {
  LookupNumber
} from 'client/components/LookupNumberSelect/LookupNumberSelect'
import ErrorMessage from 'client/components/Formik/ErrorMessage/ErrorMessage'
import { useCallback } from 'react'

interface ILookupNumberSelect extends FieldProps {
  source: number[]
}

const FormikLookupNumberSelect = ({ field, ...customProps }: ILookupNumberSelect) => {
  const { name, value, onChange } = field
  const { source } = customProps

  const handleChange = useCallback(
    (entry: LookupNumber) => onChange({ target: { name, value: entry.value } }),
    [name, onChange]
  )

  return (
    <>
      <LookupNumberSelect
        name={name}
        value={value}
        onChange={handleChange}
        lookupNumbers={source}
      />
      <ErrorMessage name={name} />
    </>
  )
}

const FormikLookupNumberSelectField = (props) => (
  <Field {...props} component={FormikLookupNumberSelect} />
)

export default FormikLookupNumberSelectField
