import ContextualHelp, {
  ContextualHelpTextSection
} from 'client/components/ContextualHelp/ContextualHelp'
import { t } from 'client/i18n'
import { Trans } from 'react-i18next'

const CaptionContextualHelp = () => (
  <ContextualHelp
    helpSize="large"
    header={t('Image Caption')}
    helpContent={
      <>
        <ContextualHelpTextSection subheader={t('Purpose')}>
          {t(
            'At a minimum, identify the content of the image and meet your organization’s legal requirements.'
          )}
        </ContextualHelpTextSection>
        <ContextualHelpTextSection subheader={t('Primary Audience')}>
          {t('Everyone.')}
        </ContextualHelpTextSection>
        <ContextualHelpTextSection subheader={t('Experience in the Guide')}>
          {t(
            'When you tap an image to make it full-screen, the caption appears below the image. The caption appears everywhere the image is used.'
          )}
        </ContextualHelpTextSection>
        <ContextualHelpTextSection subheader={t('Examples')}>
          <ul>
            <li>
              <Trans i18nKey="Johannes Vermeer, Girl with a Pearl Earring, c. 1665" />
            </li>
            <li>
              <Trans i18nKey="Andy Warhol, Self-Portrait, 1986. © 2021 The Andy Warhol Foundation for the Visual Arts, Inc. / Licensed by Artists Rights Society (ARS), New York" />
            </li>
            <li>
              {t('Louis and Lucille Armstrong pose in their living room, 1970. Photo: Yuzo Sato')}
            </li>
            <li>{t('Family day at Storm King, May 2017. Photo: Tormenta Rey')}</li>
          </ul>
        </ContextualHelpTextSection>
      </>
    }
    tooltipContent={t('More on how to use image captions')}
  />
)

export default CaptionContextualHelp
