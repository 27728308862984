import * as yup from 'yup'
import { ITEM_TITLE_MAX_LENGTH } from 'shared/validation/constants'
import _ from 'lodash'
import { getPlainTextForHtml } from 'shared/util/html'
import { ValidationError } from 'yup'
import { TFunction } from 'i18next'
import { validHtml, validLookupNumber } from './util'

export const ItemSchemaCore = (t: TFunction) =>
  yup.object().shape({
    title: yup.string().nullable().required().max(ITEM_TITLE_MAX_LENGTH),
    date: yup.string().nullable(),
    description: yup.string().nullable().test(validHtml(t)),
    information: yup.string().nullable().test(validHtml(t)),
    medium: yup.string().nullable(),
    dimensions: yup.string().nullable(),
    credit: yup.string().nullable(),
    accessionNumber: yup.string().nullable(),
    lookupNumber: validLookupNumber(t),
    published: yup.boolean()
  })

export const BotanicalItemSchemaCore = (t: TFunction) =>
  yup
    .object()
    .shape({
      commonName: yup.string().label('Common Name').nullable().max(180),
      scientificName: yup
        .string()
        .label('Scientific Name')
        .nullable()
        // get plain text from HTML to properly calculate length
        .transform((value) => getPlainTextForHtml(value))
        .max(180),
      bloomSeason: yup.string().nullable(),
      family: yup.string().nullable(),
      origin: yup.string().nullable(),
      range: yup.string().nullable(),
      flowers: yup.string().nullable(),
      leaves: yup.string().nullable(),
      attributes: yup.string().nullable(),
      credit: yup.string().nullable(),
      accessionNumber: yup.string().nullable()
    })
    .test({
      name: 'common-or-scientific-name-required',
      test: (value, context) => {
        const { commonName } = value
        const { scientificName } = context.parent
        const { defaultLocaleCode } = context.options.context!
        const isDefaultLanguage = _.startsWith(context.path, defaultLocaleCode)

        if (scientificName || commonName) {
          return true
        }

        const message = t('Common OR Scientific Name is required for saving')

        const errors = _.compact([
          !commonName &&
            context.createError({
              message: `${t('Common Name')}: ${message}`,
              path: `${context.path}.commonName`
            }),
          !scientificName &&
            isDefaultLanguage &&
            context.createError({
              message: `${t('Scientific Name')}: ${message}`,
              path: 'scientificName'
            })
        ])

        return new ValidationError(errors)
      }
    })
