import * as yup from 'yup'
import { TFunction } from 'i18next'
import { validHtml } from './util'

// Note that client submits `fileId`, not URL
// This needs to get cleaned up at some point
export const AudioSchemaCore = (t: TFunction) =>
  yup.object().shape({
    title: yup.string().nullable().required(),
    description: yup.string().nullable(),
    transcript: yup.string().nullable().test(validHtml(t))
  })
