import * as React from 'react'
import styled from 'styled-components'
import AccentSearchSVG from 'client/assets/svg/accentIcons/accent_search.svg'

interface IProps {
  className?: string
  icon?: React.ReactNode // defaults to EmptyStateSVG
  showIcon?: boolean // defaults to true
  children?: React.ReactNode
}

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: var(--spacing-xlarge);
  color: var(--color-grey-07);
  text-align: center;
`

const IconContainer = styled.i`
  display: block;
  margin: 0 auto var(--spacing-medium);
  svg {
    height: 48px;
    width: 48px;
  }
`

const Text = styled.div`
  &,
  p {
    margin: 0;
    font-size: 14px;
    white-space: pre-line;
  }
`

const EmptyState = (props: IProps) => {
  const { className, showIcon = true, icon = <AccentSearchSVG />, children } = props
  return (
    <Container className={className}>
      {showIcon && <IconContainer>{icon}</IconContainer>}
      <Text>{children}</Text>
    </Container>
  )
}

export default EmptyState
