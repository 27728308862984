import {
  GraphQLInputObjectType,
  GraphQLString,
  GraphQLList,
  GraphQLNonNull,
  GraphQLBoolean
} from 'graphql'
import { FileInput, IdInput } from './types'

const VideoTranslationInput = new GraphQLInputObjectType({
  name: 'VideoTranslationInput',
  fields: {
    localeCode: {
      type: GraphQLString
    },
    title: {
      type: GraphQLString
    },
    credits: {
      type: GraphQLString
    },
    transcript: {
      type: GraphQLString
    },
    subtitlesFile: {
      type: FileInput
    },
    videoSubtitles: {
      type: IdInput
    }
  }
})

export const VideoInput = new GraphQLInputObjectType({
  name: 'VideoInput',
  fields: {
    translations: {
      type: new GraphQLList(new GraphQLNonNull(VideoTranslationInput))
    },
    file: {
      type: FileInput
    },
    posterImageFile: {
      type: FileInput
    },
    posterUrlWithoutFallback: {
      type: GraphQLString
    },
    isMarketingUseAllowed: {
      type: GraphQLBoolean
    }
  }
})
