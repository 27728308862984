import {
  IBaseContentRowWithTags,
  IReorderableRowWrapperProps
} from 'client/components/ContentRow/types'
import * as React from 'react'
import { ImageContainer } from 'client/components/ContentRow/ImageContainer'
import {
  HeaderSubheaderWrapper,
  LowerContent,
  TextContainer,
  UpperContent
} from 'client/components/ContentRow/styledComponents'
import { HeaderSubheaderContainer } from 'client/components/ContentRow/HeaderSubheaderContainer'
import { IconGroupContainer } from 'client/components/ContentRow/IconGroupContainer'
import { ReorderableRowWrapper } from 'client/components/ContentRow/ReorderableRowWrapper'

export interface IFullContentRowProps<T>
  extends IReorderableRowWrapperProps<T>,
    IBaseContentRowWithTags {
  flexComponent?: (value) => React.ReactNode
  image?: string | React.FunctionComponent
  onRemove?: () => void
  note?: React.ReactNode
  isSelected?: boolean
  value: T
  iconNodes?: React.ReactNode
  showRemoveIcon?: boolean
  isCompact?: boolean
}

export const FullContentRow = <T extends Object>(props: IFullContentRowProps<T>) => {
  const {
    header,
    subheaders = [],
    image,
    flexComponent,
    value,
    onRemove,
    tags,
    isError,
    contentStateCaption,
    note,
    isSelected,
    iconNodes,
    showRemoveIcon = true
  } = props
  return (
    <ReorderableRowWrapper {...props} flexComponent={flexComponent}>
      <ImageContainer image={image} isError={isError} />
      <TextContainer>
        <UpperContent>
          <HeaderSubheaderWrapper>
            <HeaderSubheaderContainer
              header={header}
              subheaders={subheaders}
              isError={isError}
              contentStateCaption={contentStateCaption}
            />
          </HeaderSubheaderWrapper>
          <IconGroupContainer
            onRemove={onRemove}
            showRemoveIcon={showRemoveIcon}
            tags={tags}
            note={note}
            iconNodes={iconNodes}
            isSelected={isSelected}
          />
        </UpperContent>
        {flexComponent && <LowerContent>{flexComponent(value)}</LowerContent>}
      </TextContainer>
    </ReorderableRowWrapper>
  )
}
