import styled from 'styled-components'
import Banner, { BannerItem } from 'client/dsm/Banner/Banner'

export const AuthWorkflowHeader = styled.div`
  font-weight: var(--font-weight-bold);

  span {
    font-size: 12px;
    color: var(--color-grey-07);
  }

  h2 {
    font-size: 16px;
    font-weight: var(--font-weight-bold);
  }

  :nth-child(2) {
    margin-top: var(--spacing-xsmall);
  }
`

export const StyledBannerItem = styled(BannerItem)`
  max-width: 496px;
`

export const StyledBanner = styled(Banner)`
  margin-left: -48px;
  margin-right: -48px;
  margin-bottom: var(--spacing-large);
`
