import { useEffect, useState } from 'react'
import { t } from 'client/i18n'
import styled, { keyframes } from 'styled-components'

const TIMEOUT_SECONDS = 2
const messageFadeIn = keyframes`
  from {
    opacity: 0
  }

  to {
    opacity: 1
  }
`

const messageFadeOut = keyframes`
  from {
    opacity: 1
  }

  to {
    opacity: 0
  }
`

interface IErrorBorderContainer {
  active: boolean
}

const ErrorBorderContainer = styled.div<IErrorBorderContainer>`
  position: absolute;
  top: 50%;
  left: 30%;
  animation: ${({ active }) => (active ? messageFadeIn : messageFadeOut)} 0.5s;
  opacity: ${({ active }) => (active ? 1 : 0)};
`

const ErrorText = styled.span`
  color: var(--color-white);
  text-align: center;
`

interface IErrorMessageProps {
  filledIn: boolean
}

const ErrorMessage = styled.div<IErrorMessageProps>`
  animation: ${({ filledIn }) => (filledIn ? messageFadeIn : messageFadeOut)} 0.5s;
  opacity: ${({ filledIn }) => (filledIn ? 1 : 0)};
  opcacity: 1;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: var(--color-black);
  padding: 10px;
  box-shadow: var(--elevation-01);
  border-radius: 3px;
`

interface IProps {
  active: boolean
}

const ErrorOverlay = (props: IProps) => {
  const { active } = props
  const [filledIn, setFilledIn] = useState(true)

  useEffect(() => {
    setFilledIn(active)
    if (active) {
      const timeout = setTimeout(() => setFilledIn(false), TIMEOUT_SECONDS * 1000)
      return () => clearTimeout(timeout)
    }
  }, [active])

  return (
    <ErrorBorderContainer active={active}>
      <ErrorMessage filledIn={filledIn}>
        <ErrorText>{t('The image must cover the entire background')}</ErrorText>
      </ErrorMessage>
    </ErrorBorderContainer>
  )
}

export default ErrorOverlay
