import { Route, Routes, Navigate } from 'react-router-dom'
import Guides from './Guides/Guides'
import Users from './Users/Users'

export default function Admin() {
  return (
    <Routes>
      <Route path="users/*" element={<Users />} />
      <Route path="guides/*" element={<Guides />} />
      <Route path="/*" element={<Navigate replace={true} to="users" />} />
    </Routes>
  )
}
