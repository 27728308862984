import styled from 'styled-components'
import ButtonComponent from 'client/components/Button/Button'
import { useDispatch, useSelector } from 'react-redux'
import { getAuthData } from 'client/redux/selectors/auth'
import { navigateWithRefresh } from 'client/redux/actions/navigation'
import { usePost } from 'client/hooks/api'
import { parseTokenToObject } from 'client/util/auth'
import { fetchTokenSuccess } from 'client/redux/actions/auth'
import PrivacyNoticeContent from 'client/components/PrivacyNoticeContent/PrivacyNoticeContent'
import DividerComponent from 'client/components/Divider/Divider'
import ContainerWithConnectsLogo from 'client/components/ContainerWithConnectsLogo'
import { AuthWorkflowHeader } from 'client/screens/styledComponents'
import { t } from 'client/i18n'

const Container = styled.div`
  padding: 40px;
`

const StyledContainerWithConnectsLogo = styled(ContainerWithConnectsLogo)`
  font-size: 16px;
  width: 600px;
  max-width: 800px;
`

const StyledHeader = styled(AuthWorkflowHeader)`
  margin-bottom: var(--spacing-small);
`

const AcknowledgeText = styled.div`
  margin-top: 24px;
  padding: 24px 0px;
  color: var(--color-black);
`

const Button = styled(ButtonComponent)`
  width: 100%;
`

const PrivacyNoticeContentContainer = styled(PrivacyNoticeContent)`
  height: 500px;
  padding-right: 24px;
  margin-right: -48px;
  :before {
    content: ' ';
    white-space: pre;
  }
`

const Divider = styled(DividerComponent)`
  margin: 0px -48px;
`

const useAcceptPrivacyNoticePost = () => {
  const dispatch = useDispatch()
  return usePost('/auth/accept-privacy-notice', {
    onSuccess: ({ token, privacyPolicyAcceptedAt }) => {
      dispatch(
        fetchTokenSuccess({
          ...parseTokenToObject(token),
          privacyPolicyAcceptedAt
        })
      )
      dispatch(navigateWithRefresh('/catalog/exhibits'))
    }
  })
}

export default () => {
  const [acceptPrivacyNoticePost] = useAcceptPrivacyNoticePost()
  const { isUserLoggingInForFirstTime } = useSelector(getAuthData)

  return (
    <Container>
      <StyledContainerWithConnectsLogo>
        <StyledHeader>
          {isUserLoggingInForFirstTime && <span>{t('Step 3 of 3')}</span>}
          <h2>{t('Acknowledge the privacy notice')}</h2>
        </StyledHeader>
        <Divider />
        <PrivacyNoticeContentContainer />
        <Divider />
        <AcknowledgeText>
          {t(
            'By clicking "Complete Login", you acknowledge and agree that you have read the above Privacy Notice.'
          )}
        </AcknowledgeText>
        <Button
          type="primary"
          onClick={() => acceptPrivacyNoticePost()}
          label={t('Complete Login')}
        />
      </StyledContainerWithConnectsLogo>
    </Container>
  )
}
