import { useCallback } from 'react'
import AsyncSelect from 'react-select/async'
import _ from 'lodash'
import { components, MenuListProps, OptionsOrGroups } from 'react-select'
import useField from 'client/hooks/useField'
import api from 'client/util/api'
import { LightThemeStyles } from 'client/components/Form/SelectBox/themes'
import styled from 'styled-components'
import FormField from 'client/components/Form/FormField/FormField'
import { PlaceAutocompleteResult } from '@googlemaps/google-maps-services-js'
import ErrorMessage from 'client/components/Formik/ErrorMessage/ErrorMessage'
import { t } from 'client/i18n'

const googleLogo = require('client/assets/images/google_on_white.png')

type OptionsType = {
  label: string
  value: string
}

type CBType = (options: OptionsOrGroups<OptionsType, any>) => void

async function fetchSuggestions(input: string) {
  const { data } = await api.post('/locations/place-suggestions', {
    input
  })

  const mappedSuggestions = _.map(data as PlaceAutocompleteResult[], (suggestion) => ({
    label: suggestion.description,
    value: suggestion
  }))

  return mappedSuggestions
}

const LogoContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  img {
    padding: var(--spacing-xsmall);
  }
`

const MenuList = <T,>(props: MenuListProps<T, false>) => {
  return (
    <components.MenuList {...props}>
      {props.children}
      <LogoContainer>
        <img src={googleLogo} />
      </LogoContainer>
    </components.MenuList>
  )
}

const StyledAsyncSelect: typeof AsyncSelect = styled(AsyncSelect)`
  margin-top: var(--spacing-xsmall);
`

export default function GooglePlacesAutoComplete() {
  const { value: fullAddress, setValue: setFullAddress } = useField('fullAddress')
  const { setValue: setPlaceId, error, touched } = useField('placeId')

  const fetchSuggestionsCallback = useCallback((input: string, callback: CBType) => {
    fetchSuggestions(input).then(callback)
  }, [])

  const value = {
    value: fullAddress,
    label: fullAddress
  }

  return (
    <FormField label={t('* Address')}>
      <StyledAsyncSelect
        unstyled={true}
        styles={LightThemeStyles}
        components={{ DropdownIndicator: null, MenuList }}
        loadOptions={fetchSuggestionsCallback}
        value={value}
        noOptionsMessage={() => null}
        onChange={(newValue) => {
          const placeAutoCompleteResult = newValue?.value
          setPlaceId(placeAutoCompleteResult.place_id)
          // Don't revalidate when setting this, doing so will cause the Error Banner will flash for a moment when saving a new Location
          // I think this is due to internal Formik timing issues when setting multiple values
          setFullAddress(placeAutoCompleteResult.description, false)
        }}
        hasError={!!(error && touched)}
      />
      <ErrorMessage name="placeId" />
    </FormField>
  )
}
