import { ReactNode } from 'react'
import styled from 'styled-components'

const Container = styled.div<{ transparentBackgroundContainer: boolean; zIndex: number }>`
  position: fixed;
  overflow: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  padding: 8px;
  background-color: ${({ transparentBackgroundContainer }) =>
    transparentBackgroundContainer ? 'transparent' : 'var(--overlay-dark-03)'};
  z-index: ${({ zIndex }) => zIndex};
`

const FormContainer = styled.div<{ isLarge: boolean }>`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 80px);
  min-height: 600px;
  width: 100%;
  min-width: 700px;
  max-width: ${({ isLarge }) => (isLarge ? '1000' : '750')}px;
  margin: auto;
  border-radius: 8px;
  background-color: var(--color-white);
  box-shadow: var(--elevation-03);
`

interface IProps {
  zIndex: number
  isLarge?: boolean
  transparentBackgroundContainer?: boolean
  children?: ReactNode
}

export default function PopupWidget(props: IProps) {
  const { zIndex, isLarge = false, transparentBackgroundContainer = false, children } = props
  return (
    <Container zIndex={zIndex} transparentBackgroundContainer={transparentBackgroundContainer}>
      <FormContainer isLarge={isLarge}>{children}</FormContainer>
    </Container>
  )
}
