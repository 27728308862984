import _ from 'lodash'

// Both min and max are inclusive
const MIN_LOOKUP_NUMBER = 1
const MAX_LOOKUP_NUMBER = 9999

export const getAvailableLookupNumbers = (unavailable: number[] = []): number[] => {
  const allValues = _.range(MIN_LOOKUP_NUMBER, MAX_LOOKUP_NUMBER + 1)
  return _.without(allValues, ...unavailable)
}
