import _ from 'lodash'

export const HELP_CENTER_URL = 'https://support.bloombergconnects.org'

export const SupportedImageTypes = {
  JPEG: ['image/jpeg'],
  PNG: ['image/png']
}
export const DefaultImageFormats = _(SupportedImageTypes).values().flatten().value()

export const SupportedAudioTypes = {
  MP3: ['audio/mp3', 'audio/mpeg'],
  WAV: ['audio/wav', 'audio/vnd.wave']
}

export const SupportedVideoTypes = {
  MP4: ['video/mp4']
}

export const SupportedLogoFormats = ['image/svg+xml', 'image/jpeg', 'image/png']
