import _ from 'lodash'
import gql from 'graphql-tag'

const createMutationQueries = (typeName: string) => {
  const lowerTypeName = _.lowerFirst(typeName)
  const upperTypeName = _.upperFirst(typeName)

  const createMutation = `
    mutation create${upperTypeName}Mutation($museumId: Int!, $input: ${upperTypeName}Input!) {
        create${upperTypeName}(museumId: $museumId, ${lowerTypeName}: $input) {
            id
            __typename
        }
    }`

  const updateMutation = `
    mutation update${upperTypeName}Mutation($id: Int!, $input: ${upperTypeName}Input!) {
      update${upperTypeName}(id: $id, ${lowerTypeName}: $input) {
        id
        __typename
      }
    }
  `

  const deleteMutation = `
    mutation delete${upperTypeName}Mutation($id: Int!) {
      delete${upperTypeName}(id: $id)
    }
  `

  return {
    create: gql(createMutation),
    update: gql(updateMutation),
    delete: gql(deleteMutation)
  }
}

export default createMutationQueries
