export enum UsageReportServiceJobStatus {
  PROCESSING = 'processing',
  COMPLETE = 'complete',
  ERROR = 'error'
}

export interface UsageReportServiceJobType {
  startDate: Date
  endDate: Date
  status: UsageReportServiceJobStatus
}
