import { enabledLanguageCodeToLabel } from 'client/i18n'
import _ from 'lodash'

export type Option = {
  label: string
  value: string
}

export const languageOptions = _.map(enabledLanguageCodeToLabel, (label, code) => ({
  value: code,
  label
}))
