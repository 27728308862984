import { Formik } from 'formik'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import { GQLFeature } from 'shared/graphql/types/graphql'
import { confirm, IConfirmArgs } from 'client/redux/actions/confirmation'
import { useNavigate } from 'react-router-dom'
import createMutationQueries from 'shared/util/gqlMutation'
import { useMutation } from '@apollo/client'
import { usePost } from 'client/hooks/api'
import { refetchActiveQueries } from 'client/apollo'
import { PermanentActionMessage } from 'client/dsm/Dialog/Dialog'
import { useErrorDialog } from 'client/components/ErrorDialog'
import { t } from 'client/i18n'
import { showChangesSavedToast } from 'client/redux/actions/toast'
import FormView, { IFeaturedItem } from './FormView'

const DELETE_MUTATION = createMutationQueries('feature').delete

interface IFeatureSectionProps {
  feature: GQLFeature
}

const FeatureSection = ({ feature }: IFeatureSectionProps) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [errorDialog, setError] = useErrorDialog()
  const [updateFeature] = usePost(`/features/${feature.id}`, {
    onSuccess: () => {
      refetchActiveQueries()
      dispatch(showChangesSavedToast())
    },
    onError: (error) =>
      setError({
        error,
        title: t('Unable to Edit Feature')
      })
  })
  const [deleteMutation] = useMutation(DELETE_MUTATION, {
    onError: (error) =>
      setError({
        error,
        title: t('Unable to Delete Feature')
      })
  })

  const showConfirm = (arg: IConfirmArgs) => dispatch(confirm(arg))

  const handleEdit = () => navigate(`features/${feature.id}/edit`)

  const handleSubmit = async (values: GQLFeature) => {
    const { title, featuredItems } = values

    const minimalFeaturedItems = _.map(featuredItems, (featuredItem) =>
      _.pick(featuredItem, ['item.id', 'exhibit.id'])
    )

    await updateFeature({ title, featuredItems: minimalFeaturedItems })
  }

  const handleEditFeaturedItems = async (featuredItems: IFeaturedItem[]) => {
    const { title } = feature
    const params = {
      title,
      featuredItems
    }

    await updateFeature(params)
  }

  const handleConfirmDelete = () => {
    showConfirm({
      title: t('Delete Section?'),
      message: (
        <>
          <p>{t('Are you sure you want to delete this Section?')}</p>
          <PermanentActionMessage />
        </>
      ),
      confirmYes: {
        action: async () => {
          await deleteMutation({ variables: { id: feature.id } })
          dispatch(showChangesSavedToast())
        },
        label: t('Delete Section'),
        isDestructive: true
      }
    })
  }

  return (
    <Formik initialValues={feature} enableReinitialize={true} onSubmit={handleSubmit}>
      {(formikProps) => {
        const { values, handleChange, handleSubmit: formikHandleSubmit, resetForm } = formikProps
        return (
          <>
            {errorDialog}
            <FormView
              {...formikProps}
              values={values}
              handleChange={handleChange}
              handleSubmit={formikHandleSubmit}
              resetForm={resetForm}
              handleDelete={handleConfirmDelete}
              handleEdit={handleEdit}
              handleEditFeaturedItems={handleEditFeaturedItems}
            />
          </>
        )
      }}
    </Formik>
  )
}

export default FeatureSection
