import { useRef, useState } from 'react'
import styled from 'styled-components'
import Tooltip from 'client/dsm/Tooltip/Tooltip'
import XSVG from 'client/assets/svg/icon/x_20.svg'
import CheckmarkSVG from 'client/assets/svg/icon/check_20.svg'
import IconOnlyButton from 'client/components/IconButton/IconOnlyButton'
import { t } from 'client/i18n'

const LinkInputContainer = styled.div`
  display: flex;
  column-gap: var(--spacing-xsmall);
`

const LinkInputButtonControls = styled.div`
  display: flex;
  flex: 1;
  column-gap: var(--spacing-small);
  button {
    :disabled {
      opacity: 0.7;
      svg {
        color: var(--color-grey-04);
      }
    }
  }
`

const Input = styled.input`
  height: auto;
  border: none;
  border: 1px solid var(--input-border-placeholder);
  :focus {
    outline: 0;
  }
`
const Flex = styled.div`
  display: flex;
`

interface ILinkInputControlsProps {
  value: string
  onConfirm: (url: string) => void
  onCancel: () => void
  onBlur: () => void
}

const LinkInputControls = (props: ILinkInputControlsProps) => {
  const { value, onConfirm, onCancel, onBlur } = props

  const [url, setUrl] = useState(value)
  const containerRef = useRef<HTMLDivElement>(null)

  return (
    <LinkInputContainer
      ref={containerRef}
      onBlur={(e) => {
        const clickedOnChild = containerRef.current?.contains(e.relatedTarget)
        // If clicked outside blur it, else let the children handle the event.
        // We need this because blur is fired before the children click event.
        // If we trigger blur, the child gets destroyed before its onClick handler completes.
        if (!clickedOnChild) {
          onBlur()
        }
      }}
    >
      <Input
        placeholder={t('Type or paste link')}
        value={url || ''}
        onChange={(e) => setUrl(e.target.value)}
        autoFocus={true}
        onKeyUp={(e) => {
          if (e.key === 'Enter') {
            onConfirm(url)
          }
        }}
      />
      <LinkInputButtonControls>
        <Tooltip content={t('Save link')} disabled={!url}>
          <Flex>
            <IconOnlyButton
              svgComponent={CheckmarkSVG}
              onClick={() => onConfirm(url)}
              disabled={!url}
            />
          </Flex>
        </Tooltip>
        <Tooltip content={t('Remove link')}>
          <Flex>
            <IconOnlyButton svgComponent={XSVG} onClick={onCancel} />
          </Flex>
        </Tooltip>
      </LinkInputButtonControls>
    </LinkInputContainer>
  )
}

export default LinkInputControls
