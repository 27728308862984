import _ from 'lodash'
import BaseGridView from 'client/components/BaseGridView/BaseGridView'
import gql from 'graphql-tag'
import GridPlusIconAddButton from 'client/screens/Catalog/grids/shared/GridPlusIconAddButton'
import {
  ITEM_EXHIBIT_IMAGE_COLUMN,
  LANGUAGES_COLUMN,
  LOOKUP_NUMBER_COLUMN,
  UPDATED_AT_COLUMN,
  VISIBILITY_COLUMN,
  EXHIBIT_OVERFLOW_MENU_COLUMN
} from 'client/screens/Catalog/grids/shared/columns'
import { GQLExhibit } from 'shared/graphql/types/graphql'
import { ExhibitionType } from 'shared/constants/exhibits'
import { t } from 'client/i18n'
import { Header3 } from 'client/components/TextStyles'
import ExhibitForm from '../forms/ExhibitForm'
import { EmptyStatePlaceholderDescription } from './shared/styledComponents'

const EXHIBITS_GRAPHQL_QUERY = gql`
  query exhibitsForMuseum($museumId: Int!) {
    museum(id: $museumId) {
      id
      exhibits {
        id
        type
        uuid
        published
        lookupNumber
        title
        externalId
        images {
          hero
        }
        locales {
          code
          englishName
        }
        updatedAt
      }
    }
  }
`

const addExhibitionButton = (
  <GridPlusIconAddButton label={t('Add Exhibition')} to="/catalog/exhibits/new" />
)

const ExhibitsEmptyPlaceholder = () => (
  <>
    <Header3>{t('Add your first exhibition')}</Header3>
    <EmptyStatePlaceholderDescription>
      <p>{t('An exhibition is a group of items that can be experienced in any order, like:')}</p>
      <ul>
        <li>{t('Artworks that share a creator or theme')}</li>
        <li>{t('Artifacts shown together in a historical house')}</li>
        <li>{t('A cluster of plants in a botanic garden')}</li>
        <li>{t('A set of instructional items about your guide')}</li>
      </ul>
      {addExhibitionButton}
    </EmptyStatePlaceholderDescription>
  </>
)

export default function Exhibits() {
  return (
    <BaseGridView
      contentName="Exhibition"
      gqlQuery={EXHIBITS_GRAPHQL_QUERY}
      // Probably want to do this on the server in the future
      gqlFilterFunction={(exhibit: GQLExhibit) => exhibit.type === ExhibitionType.EXHIBITION}
      columns={_.compact([
        ITEM_EXHIBIT_IMAGE_COLUMN,
        { dataKey: 'title', label: t('Title') },
        LOOKUP_NUMBER_COLUMN,
        VISIBILITY_COLUMN,
        LANGUAGES_COLUMN,
        UPDATED_AT_COLUMN,
        EXHIBIT_OVERFLOW_MENU_COLUMN
      ])}
      filterCriteria={['lookupNumber', 'title', 'information']}
      buttons={addExhibitionButton}
      formComponent={ExhibitForm}
      placeholder={<ExhibitsEmptyPlaceholder />}
    />
  )
}
