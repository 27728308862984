import { t } from 'client/i18n'
import styled from 'styled-components'
import Pill from '../AppEditor/MapEditor/FloorEditor/Pill'

const NewTagContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  margin: 4px;
`

const NewLinkTag = () => (
  <NewTagContainer>
    <Pill label={t('New')} />
  </NewTagContainer>
)

export default NewLinkTag
