import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogHeader,
  PermanentActionMessage
} from 'client/dsm/Dialog/Dialog'
import Button from 'client/components/Button/Button'
import { GQLExhibit } from 'shared/graphql/types/graphql'
import { useQuery, useMutation } from '@apollo/client'
import { useDispatch } from 'react-redux'
import { flash } from 'client/redux/actions/flash'
import gql from 'graphql-tag'
import LoadingOverlay from 'client/components/LoadingOverlay/LoadingOverlay'
import _ from 'lodash'
import { useErrorDialog } from 'client/components/ErrorDialog'
import styled from 'styled-components'
import { t } from 'client/i18n'
import { Trans } from 'react-i18next'

const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin: var(--spacing-small) 0;
  background-color: var(--color-grey-02);
`

const EXHIBIT_QUERY = gql`
  query getExhibitItems($id: Int!) {
    exhibit(id: $id) {
      id
      title
      items {
        id
        exhibits {
          id
        }
      }
    }
  }
`

const CLOSE_EXHIBIT_MUTATION = gql`
  mutation closeExhibit($id: Int!) {
    closeExhibit(id: $id) {
      id
    }
  }
`
interface ICloseExhibitionProps {
  id?: number
  onClose: () => void
}
function CloseExhibitionDialog({ id, onClose }: ICloseExhibitionProps) {
  const { data, loading } = useQuery<{ exhibit: GQLExhibit }>(EXHIBIT_QUERY, {
    variables: { id }
  })
  const [errorDialog, setError] = useErrorDialog()
  const dispatch = useDispatch()

  const exhibit = data?.exhibit

  const numItemsBelongingToMultipleExhibits = _.filter(
    exhibit?.items,
    (item) => item.exhibits.length > 1
  ).length

  const [closeExhibitMutation] = useMutation(CLOSE_EXHIBIT_MUTATION, {
    onCompleted: () => {
      onClose()
      dispatch(flash(t('Exhibition closed')))
    },
    onError: (error) =>
      setError({
        error,
        title: t('Unable to Close Exhibit')
      })
  })

  if (loading) {
    return <LoadingOverlay fullScreen={true} />
  }

  // TODO: deal with this
  return (
    <>
      <Dialog>
        <DialogHeader>{t('Close Exhibition?')}</DialogHeader>
        <DialogBody>
          <p>
            <Trans
              i18nKey="You are about to close __exhibitTitle__."
              values={{ exhibitTitle: exhibit!.title }}
            />
          </p>
          <p>
            {t(
              'Closing an exhibition removes the map location and lookup number from the exhibition and all included items.'
            )}
          </p>
          {numItemsBelongingToMultipleExhibits > 0 && (
            <>
              <Divider />
              <p>
                <Trans i18nKey="closeExhibitItemsWarning" />
              </p>
            </>
          )}
          <PermanentActionMessage />
        </DialogBody>
        <DialogActions>
          <Button onClick={onClose} label={t('Cancel')} />
          <Button
            type="danger"
            onClick={() =>
              closeExhibitMutation({
                variables: { id: exhibit!.id }
              })
            }
            label={t('Close Exhibition')}
          />
        </DialogActions>
      </Dialog>
      {errorDialog}
    </>
  )
}

export default CloseExhibitionDialog
