import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import StandardForm from 'client/components/StandardForm/StandardForm'
import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import FormField from 'client/components/Form/FormField/FormField'
import Checkbox, { CheckboxGroup } from 'client/components/Checkbox/Checkbox'
import _ from 'lodash'
import styled from 'styled-components'
import { usePost } from 'client/hooks/api'
import { GQLLocale } from 'shared/graphql/types/graphql'
import { showChangesSavedToast } from 'client/redux/actions/toast'
import { useDispatch } from 'react-redux'
import { refetchActiveQueries } from 'client/apollo'
import SaveDialog from 'client/screens/AppEditor/About/SaveDialog'
import DiscardChangesDialog from 'client/components/DiscardChangesDialog'
import DefaultLanguageContextualHelp from 'client/screens/AppEditor/About/DefaultLanguageContextualHelp'
import { useErrorDialog } from 'client/components/ErrorDialog'
import { t } from 'client/i18n'
import { TKey } from 'shared/i18n/types/translationResources'

const Label = styled.div`
  display: flex;

  button {
    margin-left: 4px;
  }
`

function getDescription(isGoogleTranslateEnabled: boolean) {
  if (!isGoogleTranslateEnabled) {
    return t(
      'Indicate which languages are supported by your guide. Supported languages should correspond to visible translations in the CMS, and will be available to users of your guide. Content not in supported languages will be hidden from users of your guide.'
    )
  }
  return t(
    'Your guide is automatically available in 30+ languages via Google Translate. Select a language to replace the automated translations with your human translations. All text content without a human translation is translated by Google Translate.'
  )
}

const calculateAddedRemoved = (selected: GQLLocale[], persisted: GQLLocale[]) => ({
  added: _.differenceBy(selected, persisted, 'code'),
  removed: _.differenceBy(persisted, selected, 'code')
})

interface LocaleInformationQuery {
  museum: {
    defaultLocale: GQLLocale
    languages: GQLLocale[]
    guide: {
      isGoogleTranslateEnabled: boolean
    }
  }

  locales: GQLLocale[]
}

const LOCALE_INFORMATION = gql`
  query getMuseumLocales($museumId: Int!) {
    museum(id: $museumId) {
      id
      guide {
        isGoogleTranslateEnabled
      }
      defaultLocale {
        id
        code
        englishName
      }
      languages {
        id
        code
        englishName
      }
    }
    locales {
      id
      code
      englishName
    }
  }
`

interface LanguageLabelProps {
  label: string
  isDefault: boolean
}
const LanguageLabel = ({ label, isDefault }: LanguageLabelProps) => (
  <Label>
    {label}
    {isDefault && ` - ${t('Default')}`}
    {isDefault && <DefaultLanguageContextualHelp />}
  </Label>
)

const EditSupportedLanguagesForm = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [errorDialog, setError] = useErrorDialog()

  const [submitLanguages, isSubmitting] = usePost('/languages', {
    onSuccess: () => {
      navigate('..')
      dispatch(showChangesSavedToast())
      refetchActiveQueries()
    },
    onError: (error) => setError({ error, title: t('Unable to Edit Supported Languages') })
  })

  const { data, loading } = useQuery<LocaleInformationQuery>(LOCALE_INFORMATION, {
    fetchPolicy: 'network-only'
  })

  const allLocales = data?.locales
  const languages = data?.museum?.languages
  const defaultLanguage = data?.museum?.defaultLocale
  const isGoogleTranslateEnabled = data?.museum?.guide?.isGoogleTranslateEnabled

  const availableLanguage = useMemo(
    () =>
      _.compact([
        defaultLanguage,
        ..._(allLocales)
          .reject({ code: defaultLanguage?.code })
          .compact()
          .sortBy('englishName')
          .value()
      ]),
    [allLocales, defaultLanguage]
  )

  const [selectedLanguages, setSelectedLanguages] = useState(languages)
  const [showSaveDialog, setShowSaveDialog] = useState(false)
  const [showDiscardChangesDialog, setShowDiscardChangesDialog] = useState(false)

  const handleLanguageSelected = ({ target }) => {
    const { name: code, checked } = target
    const language = _.find(availableLanguage, { code })!

    if (checked) {
      setSelectedLanguages([...selectedLanguages!, language])
    } else {
      setSelectedLanguages(_.reject(selectedLanguages, { code: language.code }))
    }
  }

  const { added, removed } = useMemo(
    () => calculateAddedRemoved(selectedLanguages!, languages!),
    [selectedLanguages, languages]
  )

  const handleSave = () => {
    // no changes made
    if (_.isEmpty(added) && _.isEmpty(removed)) {
      navigate('..')
    } else {
      setShowSaveDialog(true)
    }
  }

  const handleCancel = () => {
    // no changes made
    if (_.isEmpty(added) && _.isEmpty(removed)) {
      navigate('..')
    } else {
      setShowDiscardChangesDialog(true)
    }
  }

  useEffect(() => {
    setSelectedLanguages(languages)
  }, [languages])

  const description = getDescription(isGoogleTranslateEnabled!)

  return (
    <StandardForm
      title={t('Edit Languages')}
      onCancel={handleCancel}
      onSave={handleSave}
      isLoading={loading || isSubmitting}
    >
      <FormField label="" description={description}>
        <CheckboxGroup>
          {_.map(availableLanguage, ({ code, englishName }) => (
            <Checkbox
              key={code}
              name={code}
              checked={!!_.find(selectedLanguages, { code })}
              onChange={handleLanguageSelected}
              label={
                <LanguageLabel
                  label={t(`language.${code}` as TKey)}
                  isDefault={code === defaultLanguage?.code}
                />
              }
              disabled={code === defaultLanguage?.code}
              data-testid={englishName}
            />
          ))}
        </CheckboxGroup>
      </FormField>
      {errorDialog}
      {showSaveDialog && (
        <SaveDialog
          added={added}
          removed={removed}
          onCancel={() => setShowSaveDialog(false)}
          onSave={() => {
            setShowSaveDialog(false)
            submitLanguages({ languages: _.map(selectedLanguages, 'id') })
          }}
        />
      )}
      {showDiscardChangesDialog && (
        <DiscardChangesDialog
          onCancel={() => setShowDiscardChangesDialog(false)}
          onSave={() => navigate('..')}
        />
      )}
    </StandardForm>
  )
}

export default EditSupportedLanguagesForm
