import { Field, FieldProps } from 'formik'
import styled from 'styled-components'
import _ from 'lodash'
import FormField, { IFormFieldProps } from 'client/components/Form/FormField/FormField'
import FieldContainer from 'client/components/Form/FieldContainer'
import CharLimitLabel from 'client/components/Form/CharLimitLabel/CharLimitLabel'
import { KeyboardEventHandler } from 'react'
import ErrorMessage from '../ErrorMessage/ErrorMessage'

const StyledInput = styled.input<{ isError: boolean }>`
  margin-top: var(--spacing-xsmall);
  width: 100%;
  /* Need to double class it to override the global input style */
  && {
    border-color: ${({ isError }) => (isError ? 'var(--color-status-error)' : '')};
  }
  &:hover {
    border-color: ${({ isError }) => (isError ? 'var(--color-status-error)' : '')};
  }
  &:focus {
    border-color: ${({ isError }) =>
      isError ? 'var(--color-status-error)' : 'var(--color-accent-01)'};
  }
`

// Temporarily done for transitioning. We don't normally  want to override styles on standard components.
// Some container expects this file's default component to have the styling of <FieldContainer>
// This adjustment is added to keep the expectation aligned visually until the consumers of this
// component moves away.
const StyledFormField = styled(FormField)`
  padding-bottom: 0px;
`

export interface IProps
  extends FieldProps,
    Pick<IFormFieldProps, 'disabledTooltip' | 'additionalLabelNode'> {
  name: string
  label?: string
  placeholder?: string
  description?: string
  maxLength?: number
  inline?: boolean
  className?: string
  enableSpellCheck?: boolean
  type?: string
  autoComplete?: string
  disabled?: boolean
  min?: string | number
  max?: string | number
  step?: string | number
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>
}

export const FormikInputBase = (props: IProps) => {
  const {
    field,
    form,
    placeholder,
    enableSpellCheck,
    type,
    autoComplete,
    disabled,
    min,
    max,
    step,
    onKeyDown
  } = props
  const { name, value, onChange } = field
  const { errors, touched } = form

  const showError = !_.isEmpty(_.get(errors, name)) && _.get(touched, name) === true

  return (
    <StyledInput
      {...field}
      min={min}
      max={max}
      step={step}
      dir="auto"
      data-testid={name}
      placeholder={placeholder}
      value={value || ''}
      onChange={(event) => onChange({ target: { name, value: event.target.value || null } })}
      isError={showError}
      spellCheck={enableSpellCheck}
      type={type}
      autoComplete={autoComplete}
      disabled={disabled}
      onKeyDown={onKeyDown}
    />
  )
}

const FormikTextInput = (props: IProps) => {
  const {
    field,
    label,
    maxLength,
    description,
    inline,
    className,
    disabledTooltip,
    additionalLabelNode
  } = props
  const { name, value } = field
  const hintNode = maxLength && (
    <CharLimitLabel maxLength={maxLength} currentLength={_.size(value)} />
  )

  return (
    <FieldContainer className={className}>
      <StyledFormField
        label={label}
        description={description}
        hint={hintNode}
        inline={inline}
        disabled={props.disabled}
        disabledTooltip={disabledTooltip}
        additionalLabelNode={additionalLabelNode}
      >
        <FormikInputBase {...props} />
        <ErrorMessage name={name} />
      </StyledFormField>
    </FieldContainer>
  )
}

// Can be removed by using useField() hook directly in FormikRichTextEditor when formik is upgraded without issues
const TextInputField = (props) => <Field {...props} component={FormikTextInput} />

export default TextInputField
