export const PreviewDimensions = { width: 375, height: 667 }
// TODO: Find a way to delete the need for this ratio
export const PreviewImageAspectRatio = 0.6605
export const SplashScreenPreviewFooter = { height: 100 }
// TODO: Create a footer component that can silo this value instead
export const SplashPreviewConnectsLogoWidth = '45%'
export const DefaultBackgroundColor = {
  HEX: '#F9F9F9',
  R: 249,
  G: 249,
  B: 249
}
