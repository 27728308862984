import styled from 'styled-components'
import { RadioButton } from 'client/components/RadioButton/RadioButton'
import useField from 'client/hooks/useField'
import { t } from 'client/i18n'
import { ExhibitionType } from 'shared/constants/exhibits'
import TourExpirationContextualHelp from 'client/screens/Catalog/forms/TourForm/TourExpirationContextualHelp'

const ExhibitPeriod = styled.div`
  display: flex;
  margin-top: var(--spacing-small);
`

const RadioButtonWrapper = styled.div`
  display: flex;
  button {
    margin-left: var(--spacing-xxsmall);
  }
`

const RadioButtonGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xsmall);
`

export default function Expiration() {
  const { value: hideOnExpiration, setValue: setHideOnExpiration } = useField('hideOnExpiration')
  const { value: type } = useField('type')
  const isTour = type === ExhibitionType.TOUR

  const getHideLabelText = () => {
    switch (type) {
      case ExhibitionType.TOUR:
        return t('Hide this tour in the guide')
      case ExhibitionType.EVENT:
        return t('Hide this event in the guide')
      default:
        return t('Hide this exhibition in the guide')
    }
  }

  const getShowLabelText = () => {
    switch (type) {
      case ExhibitionType.TOUR:
        return t('Convert this tour to an exhibition to keep it visible in the guide')
      case ExhibitionType.EVENT:
        return t('Keep this event visible in the guide')
      default:
        return t('Keep this exhibition visible in the guide')
    }
  }

  return (
    <ExhibitPeriod>
      <RadioButtonGroupContainer>
        <RadioButton
          label={getHideLabelText()}
          checked={hideOnExpiration === true}
          value={true}
          onChange={() => {
            setHideOnExpiration(true)
          }}
        />
        <RadioButtonWrapper>
          <RadioButton
            label={getShowLabelText()}
            value={false}
            checked={hideOnExpiration === false}
            onChange={() => {
              setHideOnExpiration(false)
            }}
          />
          {isTour && <TourExpirationContextualHelp />}
        </RadioButtonWrapper>
      </RadioButtonGroupContainer>
    </ExhibitPeriod>
  )
}
