import { useContext } from 'react'
import * as yup from 'yup'
import gql from 'graphql-tag'
import TranslationForm from 'client/components/TranslationForm/TranslationForm'
import { TranslationFormContext } from 'client/components/TranslationForm/TranslationFormContext'
import TextInputFormField from 'client/components/TranslationForm/TranslatableTextInputField'
import { createApiConfig } from 'client/components/TranslationForm/util'
import { getSelectedVersionInfo } from 'client/redux/selectors/version'
import { useSelector } from 'react-redux'
import MuseumInput from 'shared/graphql/mutations/MuseumInput'
import ContextualHelp, {
  ContextualHelpTextSection
} from 'client/components/ContextualHelp/ContextualHelp'
import { ContextualHelpImage } from 'client/components/ContextualHelp/styledComponents'
import { t } from 'client/i18n'
import { Trans } from 'react-i18next'

const friendlyNameImage = require('client/assets/images/contextualHelp/friendly_name_image.png')

const apiConfig = createApiConfig(
  'museum',
  gql`
    query getSingleMuseum($id: Int!) {
      museum(id: $id) {
        id
        title
        friendlyName
      }
    }
  `
)

const validationSchema = yup.object().shape({
  title: yup.string().label('Formal Name').nullable().required(),
  friendlyName: yup.string().label('Friendly Name').nullable()
})

const NamesFormView = () => {
  const { getFieldName } = useContext(TranslationFormContext)
  return (
    <>
      <TextInputFormField
        translatable={false}
        name={getFieldName('title')}
        label={t('* Formal Name')}
      />

      <TextInputFormField
        translatable={false}
        name={getFieldName('friendlyName')}
        label={t('Friendly Name')}
        description={t('If applicable, add a common nickname for your organization.')}
        additionalLabelNode={() => (
          <ContextualHelp
            helpSize="large"
            header={t('Friendly Name')}
            helpContent={
              <ContextualHelpTextSection>
                <Trans i18nKey="Your friendly name appears for visitors browsing the Explore screen. If left blank, your formal name appears everywhere." />
                <ContextualHelpImage src={friendlyNameImage} />
              </ContextualHelpTextSection>
            }
            tooltipContent={t('More on where your friendly name appears')}
            tooltipPlacement="right"
          />
        )}
      />
    </>
  )
}

const FormalFriendlyNamesForm = () => {
  const contentId = useSelector(getSelectedVersionInfo).id

  return (
    <TranslationForm
      contentName="Names"
      contentId={contentId}
      apiConfig={apiConfig}
      inputType={MuseumInput}
      allowDelete={false}
      validationSchema={validationSchema}
      translationsEnabled={false}
      submitValuesParser={(values) => {
        return {
          ...values,
          friendlyName: values.friendlyName ? values.friendlyName : null
        }
      }}
    >
      <NamesFormView />
    </TranslationForm>
  )
}

export default FormalFriendlyNamesForm
