import { useState, useMemo } from 'react'
import _ from 'lodash'
import { DragDropContext, Draggable, DropResult, Droppable } from '@hello-pangea/dnd'
import { t } from 'client/i18n'
import {
  EditorListContainer,
  EditorHeaderFooter,
  EditorBullet,
  EditorFloorContainer,
  IEditorHeaderFooter
} from './styledComponents'
import FloorRow from './FloorRow'
import AddFloorImageButton from './AddFloorImageButton'

interface IFloorLevelLabel extends IEditorHeaderFooter {
  label: string
}

const FloorLevelLabel = (props: IFloorLevelLabel) => (
  <EditorHeaderFooter isHighestLevel={props.isHighestLevel} isLowestLevel={props.isLowestLevel}>
    <EditorBullet />
    {props.label}
  </EditorHeaderFooter>
)

interface IProps {
  id: number | string
  floors: any
  selectedFloorId: number | string
  onFloorSelected?: (id: number) => void
  onFloorAdd?: (file: File) => void
  onFloorTitleChanged?: (id: number, title: string) => void
  onFloorDelete?: (id: number) => void
  onFloorReplaceImage?: (id: number, file: File) => void
  onFloorSetDefault?: (id: number) => void
  onFloorsRepositioned?: (floorIds: any) => void
}

export default (props: IProps) => {
  const {
    id,
    floors: propFloors,
    selectedFloorId,
    onFloorSelected = _.noop,
    onFloorAdd = _.noop,
    onFloorTitleChanged = _.noop,
    onFloorDelete = _.noop,
    onFloorReplaceImage = _.noop,
    onFloorSetDefault = _.noop,
    onFloorsRepositioned = _.noop
  } = props

  const floors = useMemo(() => _.sortBy(propFloors, 'position').reverse(), [propFloors])

  const [editFieldId, setEditFieldId] = useState<number | null>(null)

  const handleDragEnd = (result: DropResult) => {
    const { destination, source, draggableId } = result
    if (destination && destination.index !== source.index) {
      const newId = _.toNumber(draggableId)
      const sortedIds = _.map(floors, 'id')
      const newFloorIds = _.without(sortedIds, newId)
      newFloorIds.splice(destination.index, 0, newId)
      onFloorsRepositioned(newFloorIds.reverse())
    }
  }

  const handleBeginEditing = (fieldId: number) => {
    setEditFieldId(fieldId)
  }

  const handleEndEditing = (fieldId: number) => {
    if (editFieldId === fieldId) {
      setEditFieldId(null)
    }
  }

  return (
    <>
      {floors.length > 1 && <FloorLevelLabel label={t('Highest Floor')} isHighestLevel={true} />}
      <EditorListContainer count={floors.length}>
        <AddFloorImageButton onUpload={onFloorAdd} id={`add-map-image-button-${id}`} />
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="floor-dropper">
            {(providerOuter) => (
              <EditorFloorContainer ref={providerOuter.innerRef} count={floors.length}>
                {floors.map((floor, index) => (
                  <Draggable
                    key={`draggable-floor-${floor.id}`}
                    draggableId={floor.id.toString()}
                    index={index}
                    isDragDisabled={floors.length === 1}
                  >
                    {(provided, snapshot) => (
                      <div
                        key={`draggable-div${floor.id}`}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <FloorRow
                          onClick={() => onFloorSelected(floor.id)}
                          floor={floor}
                          editing={editFieldId === floor.id}
                          isDefault={floor.isDefault}
                          selected={floor.id === selectedFloorId}
                          dragging={snapshot.isDragging}
                          onBeginEditing={() => handleBeginEditing(floor.id)}
                          onEndEditing={() => handleEndEditing(floor.id)}
                          onChangeTitle={(newValue) => onFloorTitleChanged(floor.id, newValue)}
                          onDelete={() => onFloorDelete(floor.id)}
                          onSetDefault={() => onFloorSetDefault(floor.id)}
                          onReplace={(file: File) => onFloorReplaceImage(floor.id, file)}
                          showGrabber={floors.length > 1}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
              </EditorFloorContainer>
            )}
          </Droppable>
        </DragDropContext>
      </EditorListContainer>
      {floors.length > 1 && <FloorLevelLabel label={t('Lowest Floor')} isLowestLevel={true} />}
    </>
  )
}
