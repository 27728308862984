import _ from 'lodash'
import { IMapsState } from 'client/types'
import { MapsActionTypes } from 'client/redux/actions/types'
import { AnyAction } from 'redux'
import { IMapJson } from 'shared/json/IMapJson'

const initialState: IMapsState = {
  exterior: {} as IMapJson,
  buildings: {},
  mapLocationContent: {},
  dialog: {
    buildingId: null,
    showEditBuildingTitle: false
  },
  selected: {
    pinId: null
  },
  httpStatus: {
    type: null,
    loading: false
  }
}

// Helper to set api fetch state
const isAPIAction = (type) => /MAPS.+(START|SUCCESS|ERROR)$/.test(type)

const apiReducer = (state: IMapsState, action: AnyAction = { type: null }) =>
  isAPIAction(action.type)
    ? {
        ...state,
        httpStatus: {
          type: action.type,
          loading: _.endsWith(action.type, 'START')
        }
      }
    : state

export const maps = (baseState: IMapsState = initialState, action: AnyAction = { type: null }) => {
  const state = apiReducer(baseState, action)
  switch (action.type) {
    case MapsActionTypes.FETCH_PIN_CONTENT_SUCCESS: {
      return {
        ...state,
        mapLocationContent: {
          ...state.mapLocationContent,
          [action.locationId]: action.mapLocationContent
        }
      }
    }

    case MapsActionTypes.MAPS_BUILDINGS_FETCH_SUCCESS: {
      return {
        ...state,
        buildings: {
          ...state.buildings,
          ..._.keyBy(action.buildings, 'id')
        }
      }
    }

    case MapsActionTypes.MAPS_BUILDING_CREATE_SUCCESS: {
      return {
        ...state,
        buildings: {
          ...state.buildings,
          [action.building.id]: {
            ...action.building,
            floors: []
          }
        }
      }
    }

    case MapsActionTypes.MAPS_BUILDING_UPDATE_SUCCESS: {
      return {
        ...state,
        buildings: {
          ...state.buildings,
          [action.building.id]: {
            ...state.buildings[action.building.id],
            ...action.building
          }
        }
      }
    }

    case MapsActionTypes.MAPS_BUILDING_DELETE_SUCCESS: {
      const buildings = _.omit(state.buildings, action.id)
      return {
        ...state,
        buildings
      }
    }

    case MapsActionTypes.MAPS_EXTERIOR_FETCH_SUCCESS:
    case MapsActionTypes.MAPS_EXTERIOR_CREATE_SUCCESS:
    case MapsActionTypes.MAPS_EXTERIOR_UPDATE_SUCCESS:
    case MapsActionTypes.MAPS_EXTERIOR_DELETE_SUCCESS: {
      return {
        ...state,
        exterior: action.exterior
      }
    }

    case MapsActionTypes.MAPS_BUILDING_FLOOR_CREATE_SUCCESS: {
      return {
        ...state,
        buildings: {
          ...state.buildings,
          [action.floor.buildingId]: {
            ...state.buildings[action.floor.buildingId],
            floors: [...state.buildings[action.floor.buildingId].floors, action.floor]
          }
        }
      }
    }

    case MapsActionTypes.MAPS_BUILDING_FLOOR_UPDATE_SUCCESS: {
      const { buildingId, floors } = action
      return {
        ...state,
        buildings: {
          ...state.buildings,
          [buildingId]: {
            ...state.buildings[buildingId],
            floors
          }
        }
      }
    }

    case MapsActionTypes.MAPS_BUILDING_FLOORS_ORDER_UPDATE_SUCCESS:
    case MapsActionTypes.MAPS_BUILDING_FLOOR_DELETE_SUCCESS: {
      return {
        ...state,
        buildings: {
          ...state.buildings,
          [action.buildingId]: {
            ...state.buildings[action.buildingId],
            floors: action.floors
          }
        }
      }
    }

    case MapsActionTypes.MAPS_BUILDING_FLOOR_PIN_CREATE_SUCCESS: {
      const building = state.buildings[action.buildingId]
      const floorIndex = _.findIndex(building.floors, { id: action.floorId })
      const floor = {
        ...building.floors[floorIndex],
        mapLocations: [...(building.floors[floorIndex].mapLocations || []), action.location]
      }
      const floors = [...building.floors]
      floors[floorIndex] = floor

      return {
        ...state,
        buildings: {
          ...state.buildings,
          [action.buildingId]: {
            ...state.buildings[action.buildingId],
            floors
          }
        }
      }
    }

    case MapsActionTypes.MAPS_EXTERIOR_PIN_CREATE_SUCCESS: {
      const { location } = action
      return {
        ...state,
        exterior: {
          ...state.exterior,
          mapLocations: [..._.toArray(state.exterior?.mapLocations), location]
        }
      }
    }

    case MapsActionTypes.MAPS_EXTERIOR_PIN_DELETE_SUCCESS: {
      const { pinId } = action
      return {
        ...state,
        exterior: {
          ...state.exterior,
          mapLocations: _.reject(state.exterior.mapLocations, { id: pinId })
        }
      }
    }

    case MapsActionTypes.MAPS_EXTERIOR_PIN_LINKED_CONTENT_UPDATE_SUCCESS:
    case MapsActionTypes.MAPS_EXTERIOR_PIN_UPDATE_SUCCESS: {
      const { location } = action
      return {
        ...state,
        exterior: {
          ...state.exterior,
          mapLocations: [..._.reject(state.exterior?.mapLocations, { id: location.id }), location]
        }
      }
    }

    case MapsActionTypes.MAPS_BUILDING_FLOOR_PIN_LINKED_CONTENT_UPDATE_SUCCESS:
    case MapsActionTypes.MAPS_BUILDING_FLOOR_PIN_UPDATE_SUCCESS: {
      const { buildingId, floorId, location } = action

      const building = state.buildings[buildingId]
      const floorIndex = _.findIndex(building.floors, { id: floorId })
      const mapLocationIndex = _.findIndex(building.floors[floorIndex].mapLocations, {
        id: location.id
      })
      const mapLocations = [...building.floors[floorIndex].mapLocations!]
      mapLocations[mapLocationIndex] = location

      const floors = [...state.buildings[buildingId].floors]
      floors[floorIndex] = { ...floors[floorIndex], mapLocations }

      return {
        ...state,
        ...state,
        buildings: {
          ...state.buildings,
          [buildingId]: {
            ...state.buildings[action.buildingId],
            floors
          }
        }
      }
    }

    case MapsActionTypes.MAPS_BUILDING_FLOOR_PIN_DELETE_SUCCESS: {
      const { buildingId, floorId, pinId } = action
      const building = state.buildings[buildingId]
      const floorIndex = _.findIndex(building.floors, { id: floorId })
      const mapLocations = _.reject(
        building.floors[floorIndex].mapLocations,
        ({ id }) => id === pinId
      )
      const floors = [...state.buildings[buildingId].floors]
      floors[floorIndex] = { ...floors[floorIndex], mapLocations }

      return {
        ...state,
        buildings: {
          ...state.buildings,
          [buildingId]: {
            ...state.buildings[action.buildingId],
            floors
          }
        }
      }
    }

    case MapsActionTypes.MAPS_SELECTED_FLOOR_PIN_UPDATE: {
      return {
        ...state,
        selected: {
          ...state.selected,
          pinId: action.selectedId
        }
      }
    }

    case MapsActionTypes.MAPS_SELECTED_BUILDING_UPDATE: {
      return {
        ...state,
        selected: {
          ...state.selected,
          buildingId: action.selectedId
        }
      }
    }

    default:
      return state
  }
}
