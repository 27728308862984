import SelectBox from 'client/components/Form/SelectBox/SelectBox'
import _ from 'lodash'
import { getSelectedVersionInfo } from 'client/redux/selectors/version'
import { useSelector } from 'react-redux'
import useField from 'client/hooks/useField'
import { useGet } from 'client/hooks/api'
import { t } from 'client/i18n'
import LoadingOverlay from 'client/components/LoadingOverlay/LoadingOverlay'
import FormField from 'client/components/TranslationForm/TranslatableFormField'
import FormFieldSection from 'client/components/TranslationForm/TranslatableFormFieldSection'
import { useFeatureFlags } from 'client/hooks/useFeatureFlags'

const PST_ART_GUIDE_CODE = 'pstArt'
const NONE_VALUE = { label: t('None'), value: 'None' }

const GuideSelectBoxField = () => {
  const name = 'linkedGuideId'
  const { value, setValue } = useField(name)
  const { guideId } = useSelector(getSelectedVersionInfo)
  const [linkableGuides, isLoading] = useGet<{ id: number; code: string; displayName: string }[]>(
    '/api/available-guides-for-linking'
  )
  if (isLoading) {
    return <LoadingOverlay />
  }

  const linkableGuidesKeyedById = _.keyBy(linkableGuides, 'id')

  // User's guide has to be a participating guide in order to see the dropdown to link a guide
  if (!linkableGuidesKeyedById[guideId]) {
    return null
  }

  const isPstArtGuide = linkableGuidesKeyedById[guideId].code === PST_ART_GUIDE_CODE

  //   { label: 'None', value: 'None' },
  //   { label: 'Academy Museum', value: guideId },
  //   ...
  // ]
  const options = [
    NONE_VALUE,
    ..._(linkableGuides)
      // If user's guide is PST ART, will see other guides in the dropdown; if user's guide is not PST ART, will only see PST ART in the dropdown
      .filter((guide) =>
        isPstArtGuide ? guide.code !== PST_ART_GUIDE_CODE : guide.code === PST_ART_GUIDE_CODE
      )
      .map((guide) => ({ label: guide.displayName, value: guide.id }))
      .value()
  ]

  // In order for the None option to work properly in the SelectBox, the SelectBox value for the 'None' option can't be null, but the Formik value is null.
  const onChange = ({ target }) => {
    setValue(target.value === 'None' ? null : target.value)
  }
  const selectBoxValue = _.find(options, { value: value || 'None' })

  return (
    <FormFieldSection label={t('Guide Link')} translatable={false}>
      <FormField translatable={false}>
        <SelectBox name={name} options={options} onChange={onChange} value={selectBoxValue} />
      </FormField>
    </FormFieldSection>
  )
}

const LinkedGuideSelectBoxField = () => {
  const { PST_ART_GUIDE_LINKING } = useFeatureFlags()
  if (!PST_ART_GUIDE_LINKING) {
    return null
  }

  return <GuideSelectBoxField />
}

export default LinkedGuideSelectBoxField
