// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TPwwSNSGEGnLNw__Flkd {\n  display: flex;\n  flex-direction: column;\n  z-index: 50;\n  bottom: 0;\n  height: 100vh;\n  max-height: 520px;\n  min-height: 160px;\n  width: 400px;\n  color: var(--color-grey-03);\n  box-shadow: var(--elevation-01);\n  border-radius: 4px;\n}\n\n.r9IcsjzJESts0B0jACOi {\n  flex: 1;\n  overflow: auto;\n  margin-top: 16px;\n  box-shadow: 0 -1px 0 0 var(--color-grey-08);\n  border-bottom-left-radius: 4px;\n  border-bottom-right-radius: 4px;\n}\n", "",{"version":3,"sources":["webpack://./components/MiniPicker/MiniPicker/MiniPicker.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;EACX,SAAS;EACT,aAAa;EACb,iBAAiB;EACjB,iBAAiB;EACjB,YAAY;EACZ,2BAA2B;EAC3B,+BAA+B;EAC/B,kBAAkB;AACpB;;AAEA;EACE,OAAO;EACP,cAAc;EACd,gBAAgB;EAChB,2CAA2C;EAC3C,8BAA8B;EAC9B,+BAA+B;AACjC","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  z-index: 50;\n  bottom: 0;\n  height: 100vh;\n  max-height: 520px;\n  min-height: 160px;\n  width: 400px;\n  color: var(--color-grey-03);\n  box-shadow: var(--elevation-01);\n  border-radius: 4px;\n}\n\n.contentContainer {\n  flex: 1;\n  overflow: auto;\n  margin-top: 16px;\n  box-shadow: 0 -1px 0 0 var(--color-grey-08);\n  border-bottom-left-radius: 4px;\n  border-bottom-right-radius: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
export var container = "TPwwSNSGEGnLNw__Flkd";
export var contentContainer = "r9IcsjzJESts0B0jACOi";
export default ___CSS_LOADER_EXPORT___;
