import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogHeader,
  PermanentActionMessage
} from 'client/dsm/Dialog/Dialog'
import Button from 'client/components/Button/Button'
import { t } from 'client/i18n'

interface IDeleteLocationConfirmationDialogProps {
  onCancel: () => void
  onSave: () => void
}
function DeleteLocationConfirmationDialog({
  onCancel,
  onSave
}: IDeleteLocationConfirmationDialogProps) {
  return (
    <Dialog>
      <DialogHeader>{t('Delete Location?')}</DialogHeader>
      <DialogBody>
        <p>{t('Are you sure you want to delete this location?')}</p>
        <PermanentActionMessage />
      </DialogBody>
      <DialogActions>
        <Button onClick={onCancel} label={t('Cancel')} />
        <Button
          onClick={() => {
            onSave()
            onCancel()
          }}
          type="danger"
          label={t('Delete Location')}
        />
      </DialogActions>
    </Dialog>
  )
}

export default DeleteLocationConfirmationDialog
