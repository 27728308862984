export const BULK_UPLOAD_ACCEPTED_XLSX_FILE_TYPES = [
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel'
]

export const BULK_UPLOAD_ACCEPTED_ZIP_FILE_TYPES = [
  'application/zip',
  'application/x-zip-compressed',
  'multipart/x-zip'
]

export const BULK_UPLOAD_ACCEPTED_FILE_TYPES = [
  ...BULK_UPLOAD_ACCEPTED_XLSX_FILE_TYPES,
  ...BULK_UPLOAD_ACCEPTED_ZIP_FILE_TYPES
]

// Includes the header size
export const ROW_DATA_START_INDEX = 2
