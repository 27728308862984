export const injectAuthHeader = (axiosOptions, token) => ({
  ...axiosOptions,
  headers: {
    ...axiosOptions.headers,
    Authorization: `Bearer ${token}`
  }
})

export const parseTokenToObject = (token) => {
  if (token) {
    const buffer = Buffer.from(token.split('.')[1], 'base64')
    return JSON.parse(buffer.toString('utf8'))
  }
  return {
    email: null,
    name: null,
    firstName: null,
    lastName: null,
    qrDataURL: null,
    mfaPassed: false,
    mfaEnrolled: false,
    permissionType: null,
    isPasswordExpired: false,
    privacyPolicyAcceptedAt: null
  }
}
