import { memo, useState } from 'react'
import styled from 'styled-components'
import PlusIconAddButton from 'client/components/Button/PlusIconAddButton'
import { t } from 'client/i18n'
import {
  ExteriorMapSuccess,
  ExteriorMapUpload
} from 'client/screens/AppEditor/MapEditor/ExteriorMapUpload'
import { usePost } from 'client/hooks/api'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { fetchBuildings, fetchExterior } from 'client/redux/actions/maps'
import { showChangesSavedToast } from 'client/redux/actions/toast'
import { confirm } from 'client/redux/actions/confirmation'
import LoadingOverlay from 'client/components/LoadingOverlay/LoadingOverlay'
import { getExteriorMapCreateConfirmationMessage } from 'client/screens/AppEditor/MapEditor/DialogMessages/exteriorMap'
import useBuildingList from 'client/screens/AppEditor/MapEditor/useBuildingList'
import _ from 'lodash'
import { GQLMap } from 'shared/graphql/types/graphql'

const Container = styled.div`
  border-bottom: var(--border-light);
  display: flex;
  justify-content: center;
  align-items: center;
`

const AddExteriorMapButton = styled(PlusIconAddButton)`
  border: 1px dotted var(--color-grey-02);
  border-radius: 2px;
  width: 100%;
  height: 60px;
  margin: var(--spacing-small);
`

interface IAddExteriorMapProps {
  isHidden?: boolean
}

const AddExteriorMap = memo((props: IAddExteriorMapProps) => {
  const [showUploadExteriorDialog, setShowUploadExteriorDialog] = useState(false)
  const [showSuccessUploadExteriorDialog, setShowSuccessUploadExteriorDialog] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { isHidden = false } = props
  const buildings = useBuildingList()
  const hasBuildings = !_.isEmpty(buildings)

  const [createExterior, isUploadingExteriorMap] = usePost<GQLMap>('/maps/exterior', {
    onSuccess: (data) => {
      dispatch(fetchExterior())
      dispatch(fetchBuildings())
      navigate(`/app-editor/maps/exterior/${data.id}`)
      if (hasBuildings) {
        setShowSuccessUploadExteriorDialog(true)
      }
      dispatch(showChangesSavedToast())
    },
    onError: () => {
      dispatch(
        confirm({
          title: t('Unable to Save Changes'),
          message: t("We weren't able to upload the exterior map. Please try again later."),
          isAlert: true
        })
      )
    }
  })

  const { message, title } = getExteriorMapCreateConfirmationMessage()

  const handleImageChange = async (file: File) => {
    setShowUploadExteriorDialog(false)
    const formData = new FormData()
    formData.append('image', file)
    await createExterior(formData)
  }

  return (
    <Container>
      {!isHidden && (
        <AddExteriorMapButton
          label={t('Add Exterior Map')}
          onClick={() => setShowUploadExteriorDialog(true)}
        />
      )}
      {showUploadExteriorDialog && (
        <ExteriorMapUpload
          onFileChange={handleImageChange}
          onCancel={() => setShowUploadExteriorDialog(false)}
          message={message}
          title={title}
        />
      )}
      {showSuccessUploadExteriorDialog && (
        <ExteriorMapSuccess
          onContinue={() => {
            setShowSuccessUploadExteriorDialog(false)
          }}
        />
      )}
      {isUploadingExteriorMap && <LoadingOverlay />}
    </Container>
  )
})

export default AddExteriorMap
