import { ChangeEventHandler, useEffect, useMemo, useState } from 'react'
import { format, isValid, parse } from 'date-fns'
import { DateRange, DayClickEventHandler, DayPicker, DayPickerBase } from 'react-day-picker'
import DocentTippy from 'client/dsm/Tooltip/DocentTippy'
import styled from 'styled-components'
import FormField from 'client/components/Form/FormField/FormField'
import ChevronLeftIconSVG from 'client/assets/svg/icon/chevron_20_left.svg'
import ChevronRightIconSVG from 'client/assets/svg/icon/chevron_20_right.svg'
import { getDateFnsLocale, t } from 'client/i18n'

import 'react-day-picker/dist/style.css'
import { UNIVERSAL_DATE_FORMAT } from 'shared/constants/dates'

const parseDateString = (dateString: string) => {
  const newDate = parse(dateString, UNIVERSAL_DATE_FORMAT, new Date())
  return isValid(newDate) ? newDate : undefined
}

const formatDate = (date: Date | undefined) => (date ? format(date, UNIVERSAL_DATE_FORMAT) : '')

const StyledDayPicker = styled(DayPicker)`
  --color-icon: var(--color-white);
  --rdp-background-color: var(--color-accent-01);
  --rdp-accent-color: var(--color-brand);
  --rdp-background-color: var(--color-blue-07);
  --rdp-cell-size: 30px;
  --rdp-caption-font-size: 14px;

  font-size: 14px;
  color: var(--color-white);
  margin: 0px;

  .rdp-nav_button:hover {
    border-radius: 0px;
  }

  .rdp-day_today {
    font-weight: normal;
    border: 1px solid var(--color-blue-06);
    border-radius: 0;
  }

  .rdp-day {
    :not(.rdp-day_disabled) {
      border-radius: 0px;

      :hover {
        background-color: var(--color-blue-07);
      }
    }

    &.rdp-day_disabled {
      color: var(--color-grey-06);
      cursor: default;
      opacity: 1;
      &.rdp-day_selected {
        background-color: transparent;
      }
    }
  }
`

const DateTextInput = styled.input.attrs((props) => ({
  ...props,
  type: 'text'
}))<{ hasError?: boolean }>`
  && {
    border-color: ${({ hasError }) => (hasError ? 'var(--color-status-error)' : '')};
  }

  margin-top: var(--spacing-xsmall);
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><path fill="%23242424" fill-rule="evenodd" d="M5,15 L15,15 L15,10 L5,10 L5,15 Z M5,8 L15,8 L15,6 L5,6 L5,8 Z M14,4 L14,3 C14,2.447 13.553,2 13,2 C12.447,2 12,2.447 12,3 L12,4 L8,4 L8,3 C8,2.447 7.553,2 7,2 C6.447,2 6,2.447 6,3 L6,4 L4,4 C3.447,4 3,4.447 3,5 L3,16 C3,16.553 3.447,17 4,17 L16,17 C16.553,17 17,16.553 17,16 L17,5 C17,4.447 16.553,4 16,4 L14,4 Z"/></svg>');
  background-repeat: no-repeat;
  background-position: right 10px top 50%;
  width: 150px;

  :disabled {
    color: var(--color-grey-04);
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><path fill="%23ADADAD" fill-rule="evenodd" d="M5,15 L15,15 L15,10 L5,10 L5,15 Z M5,8 L15,8 L15,6 L5,6 L5,8 Z M14,4 L14,3 C14,2.447 13.553,2 13,2 C12.447,2 12,2.447 12,3 L12,4 L8,4 L8,3 C8,2.447 7.553,2 7,2 C6.447,2 6,2.447 6,3 L6,4 L4,4 C3.447,4 3,4.447 3,5 L3,16 C3,16.553 3.447,17 4,17 L16,17 C16.553,17 17,16.553 17,16 L17,5 C17,4.447 16.553,4 16,4 L14,4 Z"/></svg>');
  }
`

export interface ISingleDateTextInputProps {
  value: Date | undefined
  selectedRange: DateRange
  label: string
  onChange: (newDate: Date | undefined) => void
  hasError?: boolean
  disabled?: boolean
  disabledDays?: DayPickerBase['disabled']
}

const SingleDateTextInputField = (props: ISingleDateTextInputProps) => {
  const { value, selectedRange, onChange, label, hasError, disabled, disabledDays } = props
  const [isTippyVisible, setIsTippyVisible] = useState(false)
  const [dateInputValue, setDateInputValue] = useState<string>('')
  const [currentMonth, setCurrentMonth] = useState<Date>(new Date())
  const locale = useMemo(getDateFnsLocale, [])

  // The reason we need useEffect to setDateInputValue is because the changed value mignt not be ready when we call formatDate(value),
  // thus giving us an empty string of the initial state instead. This is caused by a timing issue of React. As a sollution, use
  // useEffect here to force the state change once we have the value ready.
  useEffect(() => {
    setDateInputValue(formatDate(value))
    setCurrentMonth(value || new Date())
  }, [value])

  const hideTooltip = () => {
    setIsTippyVisible(false)
  }

  const handleDayClick: DayClickEventHandler = (day) => {
    setIsTippyVisible(false)
    onChange(day)
  }

  const handleDateTextInputChanged: ChangeEventHandler<HTMLInputElement> = (event) => {
    const changedDate = parseDateString(event.currentTarget.value)
    onChange(changedDate)
  }

  return (
    <FormField label={label} inline={true} disabled={disabled}>
      <DocentTippy
        interactive={true}
        placement="bottom-start"
        visible={isTippyVisible}
        onClickOutside={hideTooltip}
        appendTo={document.body}
        content={
          <StyledDayPicker
            mode="single"
            // This is intentional casting, 'single' mode
            // types does not support showing range.
            // But it still works as expected.
            // Added to keep in parity with old widget for now.
            selected={selectedRange as any}
            onDayClick={handleDayClick}
            disabled={disabledDays}
            month={currentMonth}
            onMonthChange={(date) => setCurrentMonth(date)}
            components={{
              IconLeft: ChevronLeftIconSVG,
              IconRight: ChevronRightIconSVG
            }}
            locale={locale} // If undefined, DayPicker uses en-US by default
          />
        }
      >
        <DateTextInput
          value={dateInputValue}
          onChange={handleDateTextInputChanged}
          hasError={hasError}
          disabled={disabled}
          placeholder={t('YYYY-MM-DD')}
          onFocus={() => setIsTippyVisible(true)}
          onKeyDown={(e) => {
            // A blur event occurs when user interacts with the day picker widget.
            // Using blur here to dismiss tippy conflicts with the day picker handlers.
            // As a result, we're explicitly looking for a tab press-down in order to only dismiss only for that press.
            // Note: 'Tab' value is guaranteed - https://www.w3.org/TR/uievents-key/#named-key-attribute-values
            if (e.key === 'Tab') {
              hideTooltip()
            }
          }}
        />
      </DocentTippy>
    </FormField>
  )
}

export default SingleDateTextInputField
