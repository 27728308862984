import ReorderableList from 'client/components/ReorderableList/ReorderableList'
import * as React from 'react'
import _ from 'lodash'
import FilterableMiniPicker from 'client/components/MiniPicker/FilterableMiniPicker/FilterableMiniPicker'
import FieldContainer from 'client/components/Form/FieldContainer'

interface IPickerItem {
  [key: string]: any
  id?: number
}

interface IProps {
  listRowComponent: any
  pickerRowComponent: any
  pickerFields: string[]
  values: any[]
  pickerValues: IPickerItem[]
  idFieldName?: string
  onUpdate: (values: any[]) => void
  createItem?: (value) => any
  fieldContainer: React.ElementType
}

export default function ListWithPicker(props: IProps) {
  const {
    pickerValues,
    values,
    listRowComponent,
    idFieldName = 'id',
    pickerRowComponent,
    pickerFields,
    onUpdate,
    createItem = _.identity,
    fieldContainer: FieldContainerComponent = FieldContainer
  } = props

  // TODO[DOCNT-3880] Move this to FilterableMiniPicker
  const handleAdd = (ids: string[]) => {
    // TODO[DOCNT-3880] FilterableMiniPicker should not be dealing with ids, preferably
    const items = _.map(ids, (id) => _.find(pickerValues, { id }))

    const newItems = _.map(items, (item) => createItem(item))
    const listWithNewItems = _.concat(values || [], newItems)

    onUpdate(listWithNewItems)
  }

  const selectedIds = _.map(values, idFieldName)
  const filteredPickerValues = _.reject(pickerValues, (value: IPickerItem) =>
    _.includes(selectedIds, value.id)
  )

  return (
    <>
      <FieldContainerComponent>
        <ReorderableList
          name="Content-list"
          values={values}
          rowComponent={listRowComponent}
          onUpdate={onUpdate}
          idFieldName={idFieldName}
        />
      </FieldContainerComponent>
      <FieldContainerComponent>
        <FilterableMiniPicker
          items={filteredPickerValues}
          contentName="Content"
          rowComponent={pickerRowComponent}
          filterCriteria={pickerFields}
          onSubmit={handleAdd}
        />
      </FieldContainerComponent>
    </>
  )
}
