import { memo, useCallback, useMemo } from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import ReorderableList, {
  IReorderableList
} from 'client/components/ReorderableList/ReorderableList'
import FilterableMiniPicker, {
  IFilterableMiniPickerProps
} from 'client/components/MiniPicker/FilterableMiniPicker/FilterableMiniPicker'
import ErrorMessage from 'client/components/Formik/ErrorMessage/ErrorMessage'
import useField from 'client/hooks/useField'

export interface IReorderableListWithPickerProps<T = number> {
  name: string
  className?: string
  rowComponent: IReorderableList['rowComponent']
  isDragDisabled?: boolean
  pickerProps: Omit<IFilterableMiniPickerProps<T>, 'items' | 'onSubmit' | 'maxSelectable'>
  // Source is the available set of things that can be picked
  source: any[]
  // Values is the current set of things that are chosen
  values: any[]
  maxValues?: number
  onChange: (value: any[]) => void
  valueFieldsWithHigherPriority?: string[]
}

interface IStyledFilterableMiniPickerProps {
  isListEmpty: boolean
}
const StyledFilterableMiniPicker = styled(FilterableMiniPicker)<IStyledFilterableMiniPickerProps>`
  margin-top: ${({ isListEmpty }) => (isListEmpty ? 0 : 'var(--spacing-medium)')};
`

export function BaseReorderableListWithPicker(props: IReorderableListWithPickerProps) {
  const {
    values,
    onChange,
    className,
    rowComponent: RowComponent,
    source,
    pickerProps,
    isDragDisabled = false,
    name,
    maxValues = Number.MAX_SAFE_INTEGER, // defaults to ~= "no limit"
    valueFieldsWithHigherPriority
  } = props

  const handlePickerSelectChange = useCallback(
    (ids) => {
      const addedModels = ids.map((id) => _.find(source, { id }))
      const newValue = _.concat(values || [], addedModels)
      onChange(newValue)
    },
    [source, values, onChange]
  )

  const valueIds = useMemo(() => _.map(values, 'id'), [values])
  const pickerSource = useMemo(
    () => _.reject(source, (value) => _.includes(valueIds, value.id)),
    [source, valueIds]
  )

  const actualValues = useMemo(
    () =>
      _.map(values, (value) => ({
        // The reason we spread value before source is because we need to override the value on
        // non-editable fields when we switch to another language in the translation form.
        ...value,
        ..._.find(source, { id: value.id }),
        // Certain cases, user might want to override the source with any changed fields in value.
        // For example, if user changes lookupNumber or itemMapLocation property in value locally,
        // we want that to overlay on top of the server data which has the old lookup number or map location respectively.
        ...(valueFieldsWithHigherPriority && _.pick(value, valueFieldsWithHigherPriority))
      })),
    [values, source, valueFieldsWithHigherPriority]
  )

  const maxSelectable = maxValues - _.size(values)

  return (
    <div className={className}>
      <ReorderableList
        name={name}
        values={actualValues}
        rowComponent={RowComponent}
        onUpdate={onChange}
        isDragDisabled={isDragDisabled}
      />
      <StyledFilterableMiniPicker
        {...pickerProps}
        items={pickerSource}
        onSubmit={handlePickerSelectChange}
        isListEmpty={_.isEmpty(values)}
        showAddButton={maxSelectable > 0}
        maxSelectable={maxSelectable}
      />
      <ErrorMessage name={name} />
    </div>
  )
}

type IFormikReorderableListWithPickerProps = Omit<
  IReorderableListWithPickerProps,
  'onChange' | 'values'
>

function FormikReorderableListWithPicker(props: IFormikReorderableListWithPickerProps) {
  const { name } = props
  const { value, setValue } = useField(name)

  return <BaseReorderableListWithPicker {...props} values={value} onChange={setValue} />
}

export default memo(FormikReorderableListWithPicker)
