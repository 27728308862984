import _ from 'lodash'
import MuseumLocationRow from 'client/screens/AppEditor/About/MuseumLocationRow'
import PlusIconAddButton from 'client/components/Button/PlusIconAddButton'
import styled from 'styled-components'
import ReorderableList from 'client/components/ReorderableList/ReorderableList'
import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import LoadingOverlay from 'client/components/LoadingOverlay/LoadingOverlay'
import { useNavigate } from 'react-router-dom'
import { usePost } from 'client/hooks/api'
import { refetchActiveQueries } from 'client/apollo'
import { useErrorDialog } from 'client/components/ErrorDialog'
import { t } from 'client/i18n'
import { useDispatch } from 'react-redux'
import { showChangesSavedToast } from 'client/redux/actions/toast'

const StyledReorderableList = styled(ReorderableList)`
  margin-top: var(--spacing-small);
  margin-bottom: ${({ values }) => (_.isEmpty(values) ? null : 'var(--spacing-small)')};
`

const LOCATIONS_QUERY = gql`
  query getLocations($museumId: Int!) {
    museum(id: $museumId) {
      id
      locations {
        id
        name
        fullAddress
        shortAddress
        isNavigable
      }
    }
  }
`

export default function Locations() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { data, loading } = useQuery(LOCATIONS_QUERY)
  const [errorDialog, setError] = useErrorDialog()

  const [updateLocations] = usePost('/locations/reorder', {
    onSuccess: () => {
      refetchActiveQueries()
      dispatch(showChangesSavedToast())
    },
    onError: (error) => setError({ title: t('Unable to Reorder Locations'), error })
  })

  if (loading) {
    return <LoadingOverlay />
  }

  const { locations } = data.museum

  const onAddLocation = () => navigate('location')

  const onReorderLocations = async (reorderedList) => {
    const reorderedLocations = _.map(reorderedList, 'id')
    await updateLocations(reorderedLocations)
  }

  return (
    <>
      {errorDialog}
      <StyledReorderableList
        name="museum-location-reorderable-list"
        values={locations}
        isDragDisabled={_.size(locations) <= 1}
        rowComponent={({ value, index }) => (
          <MuseumLocationRow
            location={value}
            isDefaultAndOneOfSeveralLocations={index === 0 && _.size(locations) > 1}
            showGrabber={_.size(locations) > 1}
            showName={_.size(locations) > 1}
          />
        )}
        onUpdate={onReorderLocations}
      />
      <PlusIconAddButton label={t('Add Location')} onClick={onAddLocation} />
    </>
  )
}
