import { ToastContainer, toast, CloseButtonProps, Slide } from 'react-toastify'
import styled from 'styled-components'
import CloseIcon from 'client/assets/svg/icon/x_20.svg'
import IconOnlyButton from 'client/components/IconButton/IconOnlyButton'
import { ThemeType } from 'client/types'
import 'react-toastify/dist/ReactToastify.css'

const Toast = styled(ToastContainer).attrs((props) => ({
  ...props,
  autoClose: 5000,
  transition: Slide,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  position: toast.POSITION.BOTTOM_CENTER,
  closeButton: ({ closeToast }: CloseButtonProps) => (
    <IconOnlyButton theme={ThemeType.LIGHT} svgComponent={CloseIcon} onClick={closeToast} />
  )
}))`
  --toastify-toast-min-height: 40px;
  --toastify-font-family: var(--font-family);
  --toastify-z-index: 9999999;
  --toastify-toast-width: 300px;
  --toastify-toast-min-height: 48px;

  font-size: 14px;

  .Toastify__toast {
    background-color: var(--color-black);
    display: flex;
    align-items: center;
    padding: 0px 16px;
  }
  .Toastify__toast-body {
    padding: 0px;
    margin: 0px;
  }
`

export default Toast
