import { ContentType } from 'client/types'
import { t } from 'i18next'
import { useMemo } from 'react'
import { Trans } from 'react-i18next'

type ContentTypeUnion = `${ContentType}`
type UpdateItemOrExhibitVisibilityDialogBodyContentProps = {
  contentName: string
  contentType: ContentTypeUnion
  published: boolean
  isFeatured: boolean
}

export function UpdateItemOrExhibitVisibilityDialogBodyContent({
  contentName,
  contentType,
  published,
  isFeatured
}: UpdateItemOrExhibitVisibilityDialogBodyContentProps) {
  const singularContentType = useMemo(() => {
    switch (contentType) {
      case ContentType.TOUR:
        return 'a tour' as const
      case ContentType.EXHIBITION:
        return 'an exhibition' as const
      case ContentType.EVENT:
        return 'an event' as const
      case ContentType.ITEM:
        return 'an item' as const
      default:
        throw new Error('This should be unreachable')
    }
  }, [contentType])

  // TS typing is a bit involved with how it deals with interpolating the same union type multiple
  // times. This is a workaround to get it to only handle the case where both interpolations have
  // the same single string, rather than all permutations
  // inspired by https://stackoverflow.com/questions/74737478/how-do-i-write-a-typescript-template-literal-using-a-union-twice-without-invalid
  type HidingIsFeaturedText<T extends ContentTypeUnion = ContentTypeUnion> = T extends T
    ? `In the CMS, this ${T} will be removed from the Home page but the ${T} details will not be changed.`
    : never

  /* eslint-disable docent/require-translation-keys-to-be-literals */
  return published ? (
    <>
      <p>
        <Trans i18nKey="You are about to hide __contentName__." values={{ contentName }} />
      </p>
      <p>
        {t(`Hiding ${singularContentType} prevents visitors from finding it in your guide.`)}{' '}
        {isFeatured
          ? t(
              `In the CMS, this ${contentType} will be removed from the Home page but the ${contentType} details will not be changed.` as HidingIsFeaturedText
            )
          : t(`In the CMS, ${contentType} details will not be changed.`)}
      </p>
    </>
  ) : (
    <>
      <p>
        <Trans i18nKey="You are about to make __contentName__ visible." values={{ contentName }} />
      </p>
      {singularContentType === 'an event' ? (
        <p>
          {t(
            'Making an event visible lets visitors find it in your guide via the map, home screen, and/or related content.'
          )}
        </p>
      ) : (
        <p>
          {t(
            `Making ${singularContentType} visible lets visitors find it in your guide via the lookup tab, map, home screen, and/or related content.`
          )}
        </p>
      )}
    </>
  )
  /* eslint-enable docent/require-translation-keys-to-be-literals */
}
