import _ from 'lodash'
import CharLimitLabel from 'client/components/Form/CharLimitLabel/CharLimitLabel'
import useField from 'client/hooks/useField'

interface ICharacterLimitProps {
  name: string
  maxLength?: number
}

const CharacterLimitField = ({ name, maxLength = 180 }: ICharacterLimitProps) => {
  const { value } = useField(name)
  return <CharLimitLabel maxLength={maxLength} currentLength={_.size(value)} />
}

export default CharacterLimitField
