import { memo, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'
import { updateExteriorPin } from 'client/redux/actions/maps'
import { getExterior, getBuildings } from 'client/redux/selectors/maps'
import FloorMapLocation from 'client/screens/AppEditor/MapEditor/MapLocation/FloorMapLocation'
import { t } from 'client/i18n'
import MapPreview from './MapPreview'
import BuildingMapLocation from './MapLocation/BuildingMapLocation'

interface IProps {
  selectedPinId?: number | null
  onSelectedPinChanged?: (id: number) => void
}

const ExteriorMapLocation = memo((props: any) => {
  const { id, ...rest } = props
  const buildings = useSelector(getBuildings)
  const buildingsByMapLocationId = useMemo(() => {
    return _.keyBy(buildings, 'mapLocationId')
  }, [buildings])
  return !buildingsByMapLocationId[id] ? (
    <FloorMapLocation id={id} {...rest} />
  ) : (
    <BuildingMapLocation id={id} {...rest} />
  )
})

export default (props: IProps) => {
  const { selectedPinId, onSelectedPinChanged = _.noop } = props
  const exteriorMap = useSelector(getExterior)!
  const dispatch = useDispatch()

  const map = useMemo(
    () => ({
      ...exteriorMap,
      title: t('Exterior Map')
    }),
    [exteriorMap]
  )

  const handleDragEndLocation = ({ id, coordinates }) =>
    dispatch(
      updateExteriorPin({
        floorId: exteriorMap.id,
        pinData: { id, coordinates }
      })
    )

  const handleMapLocationResized = (id, radius, coordinates) => {
    dispatch(
      updateExteriorPin({
        floorId: exteriorMap.id,
        pinData: { id, radius, coordinates }
      })
    )
  }

  return (
    <MapPreview
      map={map}
      selectedPinId={selectedPinId}
      onSelectedPinChanged={onSelectedPinChanged}
      onDragEndLocation={handleDragEndLocation}
      onMapLocationResized={handleMapLocationResized}
      mapLocationElement={ExteriorMapLocation}
    />
  )
}
