import GuideVisibilityType from 'shared/GuideVisibilityType'
import FormField from 'client/components/Form/FormField/FormField'
import styled from 'styled-components'
import Tooltip from 'client/dsm/Tooltip/Tooltip'
import ToggleInput from 'client/components/Form/ToggleInput/ToggleInput'
import { useFormikContext, FormikContextType } from 'formik'
import { t } from 'client/i18n'

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const InternalTestingGuideField = () => {
  const { values, setFieldValue } = useFormikContext() as FormikContextType<{
    isInternal: boolean
    visibilityType: GuideVisibilityType
  }>

  const value = values.isInternal
  const disabled = values.visibilityType === GuideVisibilityType.PUBLIC
  const disabledTooltipContent = (
    <p>
      {t(
        'This guide is available to the public in the Bloomberg Connects app and cannot be made an internal testing guide.'
      )}
    </p>
  )

  const onChange = (e) => {
    setFieldValue('isInternal', e.target.value)
  }

  const toggle = <ToggleInput value={value} onChange={onChange} disabled={disabled} />

  return (
    <FlexContainer>
      <FormField
        label={t('Internal Testing Guide')}
        description={t('Internal testing guides are never exposed to CPs or the public.')}
        disabled={disabled}
      />
      {disabled ? (
        <Tooltip content={disabledTooltipContent}>
          <div>{toggle}</div>
        </Tooltip>
      ) : (
        toggle
      )}
    </FlexContainer>
  )
}

export default InternalTestingGuideField
