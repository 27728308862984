import ContextualHelp, {
  ContextualHelpTextSection
} from 'client/components/ContextualHelp/ContextualHelp'
import { t } from 'client/i18n'
import { Trans } from 'react-i18next'

const VideoContextualHelp = () => (
  <ContextualHelp
    helpSize="large"
    header={t('Video with Audio Description Track')}
    helpContent={
      <>
        <ContextualHelpTextSection>
          {t('Video files can include a secondary audio track for audio descriptions.')}
        </ContextualHelpTextSection>
        <ContextualHelpTextSection subheader={t('Purpose')}>
          {t('Supplement the main audio file with a narration of key visual information.')}
        </ContextualHelpTextSection>
        <ContextualHelpTextSection subheader={t('Primary Audience')}>
          {t('People who are blind or have low vision.')}
        </ContextualHelpTextSection>
        <ContextualHelpTextSection subheader={t('Experience in the Guide')}>
          <Trans i18nKey="videoADExperienceInTheGuide" />
        </ContextualHelpTextSection>
        <ContextualHelpTextSection subheader={t('Tips')}>
          <ul>
            <li>{t('Embed the audio description track in your video file before upload.')}</li>
            <li>
              {t(
                'Consider adding a video with audio description when key information is not conveyed through the original video’s main audio track.'
              )}
            </li>
          </ul>
        </ContextualHelpTextSection>
      </>
    }
    tooltipContent={t('More on how to use audio descriptions in your video file')}
  />
)

export default VideoContextualHelp
