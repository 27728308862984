import { PropsWithChildren, useCallback, useRef, useState } from 'react'
import _ from 'lodash'
import { Dialog, DialogActions, DialogBody, DialogHeader } from 'client/dsm/Dialog/Dialog'
import Button from 'client/components/Button/Button'
import useMediaUpload, { UploadMediaType } from 'client/hooks/useMediaUpload'
import GenericSVG from 'client/assets/svg/emptyStates/droppable_grid_view.svg'
import { t } from 'client/i18n'
import { Trans } from 'react-i18next'
import { Header3CSS } from 'client/components/TextStyles'
import styled from 'styled-components'
import LoadingOverlayWithDialog from 'client/components/LoadingOverlay/LoadingOverlayWithDialog'
import { Thumbnail, SubText, BackdropView, TableWrapper } from './styledComponents'

const Text = styled.div`
  ${Header3CSS};
  margin-top: 16px;
  color: var(--color-grey-07);
`

interface IDroppableBackgroundProps {
  uploadMediaType: UploadMediaType
  formatDisplayNames: string
}

const DroppableBackground = ({
  uploadMediaType,
  formatDisplayNames
}: IDroppableBackgroundProps) => {
  return (
    <BackdropView>
      <Thumbnail>
        <GenericSVG />
      </Thumbnail>
      <Text>{t('Drop __uploadMediaType__ Files to Upload', { uploadMediaType })}</Text>
      <SubText>{t('Supports: __formatDisplayNames__', { formatDisplayNames })}</SubText>
    </BackdropView>
  )
}

interface IDroppableMediaFileViewProps {
  isEmpty: boolean
  uploadMediaType: UploadMediaType
  droppableFileFormats: { [key: string]: string[] }
  onDragEnter: () => void
  onDragLeave: () => void
}

export const DroppableMediaFileView = (props: PropsWithChildren<IDroppableMediaFileViewProps>) => {
  const { isEmpty, uploadMediaType, droppableFileFormats, onDragEnter, onDragLeave } = props
  const [isDragAreaActive, setIsDragAreaActive] = useState(false)
  const [showFormatErrorDialog, setShowFormatErrorDialog] = useState(false)
  const { uploadMedia, isUploading, loadingText, errorDialog } = useMediaUpload(uploadMediaType)
  const tableWrapperRef = useRef<HTMLDivElement>(null)
  const supportedFileFormats = _(droppableFileFormats).values().flatten().value()
  const formatDisplayNames = _.keys(droppableFileFormats).join(' and ')

  const handleDrop = useCallback(
    (e) => {
      e.preventDefault()
      setIsDragAreaActive(false)
      onDragLeave()
      const { files } = e.dataTransfer
      const invalidFiles = _.reject(files, ({ type }) => _.includes(supportedFileFormats, type))
      // If there's at least one file doesn't have a supported format, pause the upload process.
      if (!_.isEmpty(invalidFiles)) {
        setShowFormatErrorDialog(true)
        return
      }

      // If the file format is right, start uploading it.
      uploadMedia(files)
    },
    [onDragLeave, supportedFileFormats, uploadMedia]
  )

  const handleDragEnter = useCallback(
    (e) => {
      e.preventDefault()
      setIsDragAreaActive(true)
      onDragEnter()
    },
    [onDragEnter]
  )

  const handleDragLeave = useCallback(
    (e) => {
      e.preventDefault()
      // The logic here is to ignore the event fired by children(rows).
      // We only want this event to execute when users leave the parent drag area.
      const { relatedTarget } = e
      if (relatedTarget && tableWrapperRef.current?.contains(relatedTarget)) {
        return
      }
      setIsDragAreaActive(false)
      onDragLeave()
    },
    [onDragLeave]
  )

  const handleDragOver = useCallback((e) => e.preventDefault(), [])
  const handleClick = useCallback(() => setShowFormatErrorDialog(false), [])

  return (
    <>
      <TableWrapper
        isEmpty={isEmpty}
        isDragAreaActive={isDragAreaActive}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        ref={tableWrapperRef}
      >
        {props.children}
      </TableWrapper>
      {isDragAreaActive && (
        <DroppableBackground
          uploadMediaType={uploadMediaType}
          formatDisplayNames={formatDisplayNames}
        />
      )}
      {isUploading && <LoadingOverlayWithDialog loadingText={loadingText} />}
      {showFormatErrorDialog && (
        <Dialog>
          <DialogHeader>{t('Unable to Upload Files')}</DialogHeader>
          <DialogBody>
            <>
              <p>
                {t(
                  'The files you dragged onto the page were not uploaded because at least one file in your selection was an unsupported file type.'
                )}
              </p>
              <p>
                <Trans
                  i18nKey="uploadMediaOnlySupportsFormats"
                  values={{ uploadMediaType, formatDisplayNames }}
                />
              </p>
              <p>
                {t(
                  'Make sure your selection only includes __formatDisplayNames__ files, then try dragging the files into the page again.',
                  { formatDisplayNames }
                )}
              </p>
            </>
          </DialogBody>
          <DialogActions>
            <Button type="primary" onClick={handleClick} label={t('OK')} />
          </DialogActions>
        </Dialog>
      )}
      {errorDialog}
    </>
  )
}
