import ContextualHelp, {
  ContextualHelpTextSection
} from 'client/components/ContextualHelp/ContextualHelp'
import { t } from 'client/i18n'

const AltTextContextualHelp = () => (
  <ContextualHelp
    helpSize="large"
    header={t('Image Alt Text')}
    helpContent={
      <>
        <ContextualHelpTextSection subheader={t('Purpose')}>
          {t("Translate an image's purpose, context, and key visual information into words.")}
        </ContextualHelpTextSection>
        <ContextualHelpTextSection subheader={t('Primary Audience')}>
          {t('People who are blind or have low vision.')}
        </ContextualHelpTextSection>
        <ContextualHelpTextSection subheader={t('Experience in the Guide')}>
          {t(
            'Alt text does not appear as text in your guide, but is read out loud by a screen reader to improve or replace the experience of seeing the image in context.'
          )}
        </ContextualHelpTextSection>
        <ContextualHelpTextSection subheader={t('Tips')}>
          <ul>
            <li>{t('Be concise.')}</li>
            <li>{t('Don’t repeat the image caption.')}</li>
            <li>{t('Lead with the essential information.')}</li>
            <li>
              {t(
                'Avoid using images that include text. If an image does need to include text, all text should be included in the alt text.'
              )}
            </li>
            <li>
              {t(
                'Avoid words like “image” and “picture.” Screen readers announce the object as an image.'
              )}
            </li>
          </ul>
        </ContextualHelpTextSection>
      </>
    }
    tooltipContent={t('More on how to use image alt text')}
  />
)

export default AltTextContextualHelp
