import styled from 'styled-components'

const AssetContainer = styled.div<{ width: number; height: number }>`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  display: flex;
  margin-right: var(--spacing-xsmall);
`

const AssetPreviewContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  margin: 0;
  background-color: rgba(0, 0, 0, 0.1);
`

const Asset = styled.img<{ imageIsDisabled: boolean }>`
  width: 100%;
  object-fit: contain;
  opacity: ${({ imageIsDisabled }) => (imageIsDisabled ? 0.5 : 1)};
`

interface IImageAssetProps {
  src: string
  width?: number
  height?: number
  imageIsDisabled: boolean
}

const ImageAsset = ({
  src,
  width = 222,
  height = 100,
  imageIsDisabled = false
}: IImageAssetProps) => {
  return (
    <AssetContainer width={width} height={height}>
      <AssetPreviewContainer>
        <Asset src={src} imageIsDisabled={imageIsDisabled} />
      </AssetPreviewContainer>
    </AssetContainer>
  )
}

export default ImageAsset
