import { useCallback, useContext, useState } from 'react'
import tusdUpload from 'client/util/tusdUpload'
import { GQLFileInput } from 'shared/graphql/types/graphql'
import { t } from 'client/i18n'
import { TranslationFormContext } from 'client/components/TranslationForm/TranslationFormContext'

const GENERIC_UPLOAD_ERROR = {
  title: t('Unable to Add Audio File'),
  body: t('We were unable to add the audio file. Please check asset guidelines and try again.')
}

interface IFileUploadData {
  localFileUrl: string
  file?: GQLFileInput
}

const useAudioFileUploader = () => {
  const { setLoadingStatus } = useContext(TranslationFormContext)

  const [data, setData] = useState<IFileUploadData | null>()
  const [error, setError] = useState<typeof GENERIC_UPLOAD_ERROR | null>()

  const reset = useCallback(() => {
    setData(null)
    setError(null)
    setLoadingStatus(false)
  }, [setLoadingStatus])

  const upload = useCallback(
    async (file: File) => {
      setLoadingStatus(true)
      try {
        const url = await tusdUpload(file, (bytesUploaded, bytesTotal) => {
          const percentage = Math.round((bytesUploaded / bytesTotal) * 100)
          setLoadingStatus(percentage, t('Uploading Audio (__percentage__%)', { percentage }))
        })
        setData({
          localFileUrl: URL.createObjectURL(file),
          file: { name: file.name, url }
        })
      } catch {
        setError(GENERIC_UPLOAD_ERROR)
      } finally {
        setLoadingStatus(false)
      }
    },
    [setLoadingStatus]
  )

  return { upload, reset, data, error }
}

export default useAudioFileUploader
