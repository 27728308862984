import * as React from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import Tooltip from 'client/dsm/Tooltip/Tooltip'
import { Body1CSS } from 'client/components/TextStyles'

export interface IFormFieldProps {
  label?: React.ReactNode
  description?: React.ReactNode
  hint?: React.ReactNode
  htmlFor?: string
  inline?: boolean
  children?: React.ReactNode
  className?: string
  disabled?: boolean
  headerContainer?: React.ElementType
  additionalLabelNode?: React.ElementType
  disabledTooltip?: React.ReactNode
}

export const StyledFormSection = styled.div<{ inline?: boolean }>`
  margin-bottom: ${({ inline }) => (inline ? '0px' : 'var(--spacing-large)')};
`

const StyledLabelContainer = styled.label<{ disabled?: boolean }>`
  display: flex;
  justify-content: space-between;
  margin: 0px;
  color: ${({ disabled = false }) => (disabled ? 'var(--color-grey-04)' : 'inherit')};
`

const Label = styled.div`
  display: flex;

  > :last-child {
    margin-right: 0;
  }

  button {
    margin-left: 4px;
  }
`

const StyledDescriptionContainer = styled.div<{ hasLabelNode: boolean; disabled?: boolean }>`
  ${Body1CSS};
  display: block;
  padding-top: ${({ hasLabelNode }) => (hasLabelNode ? 4 : 0)}px;
  white-space: pre-line;
  color: ${({ disabled = false }) => (disabled ? 'var(--color-grey-04)' : 'inherit')};
`

const FormField = (props: IFormFieldProps) => {
  const {
    label: labelNode,
    additionalLabelNode: AdditionalLabelNode,
    description: descriptionNode,
    hint: hintNode,
    htmlFor = '',
    inline,
    children,
    disabled,
    headerContainer: HeaderContainer = React.Fragment,
    disabledTooltip,
    ...restProps
  } = props

  return (
    <StyledFormSection {...restProps} inline={inline}>
      <HeaderContainer>
        {(labelNode || hintNode) && (
          <StyledLabelContainer {...(!_.isNil(htmlFor) && { htmlFor })} disabled={disabled}>
            <Label>
              {hintNode ? labelNode || <label /> : labelNode}
              {!_.isNil(AdditionalLabelNode) && <AdditionalLabelNode />}
            </Label>
            {hintNode && <span>{hintNode}</span>}
          </StyledLabelContainer>
        )}
        {descriptionNode && (
          <StyledDescriptionContainer hasLabelNode={!!(labelNode || hintNode)} disabled={disabled}>
            {descriptionNode}
          </StyledDescriptionContainer>
        )}
      </HeaderContainer>
      {disabled && disabledTooltip ? (
        <Tooltip content={disabledTooltip}>
          <div>{children}</div>
        </Tooltip>
      ) : (
        children
      )}
    </StyledFormSection>
  )
}

export default FormField
