import { useContext } from 'react'
import gql from 'graphql-tag'
import TranslationForm from 'client/components/TranslationForm/TranslationForm'
import { TranslationFormContext } from 'client/components/TranslationForm/TranslationFormContext'
import TextInputFormField from 'client/components/TranslationForm/TranslatableTextInputField'
import { createApiConfig } from 'client/components/TranslationForm/util'
import { getSelectedVersionInfo } from 'client/redux/selectors/version'
import { useSelector } from 'react-redux'
import MuseumInput from 'shared/graphql/mutations/MuseumInput'
import * as yup from 'yup'
import { t } from 'client/i18n'

const apiConfig = createApiConfig(
  'museum',
  gql`
    query getSingleMuseum($id: Int!) {
      museum(id: $id) {
        id
        marketingUrl
      }
    }
  `
)

const validationSchema = yup.object().shape({
  marketingUrl: yup.string().nullable().url().label('Marketing Web Page Link')
})

const MarketingWebPageFormView = () => {
  const { getFieldName } = useContext(TranslationFormContext)
  return (
    <TextInputFormField
      translatable={false}
      name={getFieldName('marketingUrl')}
      label={t('Marketing Web Page Link')}
      description={t(
        "If your organization's website has a page that highlights your digital guide, add the link to that page."
      )}
    />
  )
}

const MarketingWebPageForm = () => {
  const contentId = useSelector(getSelectedVersionInfo).id

  return (
    <TranslationForm
      contentName="Link"
      contentId={contentId}
      apiConfig={apiConfig}
      inputType={MuseumInput}
      validationSchema={validationSchema}
      allowDelete={false}
      translationsEnabled={false}
    >
      <MarketingWebPageFormView />
    </TranslationForm>
  )
}

export default MarketingWebPageForm
