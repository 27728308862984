import _ from 'lodash'
import { useSelector } from 'react-redux'
import { useMemo } from 'react'
import { getBuildings } from 'client/redux/selectors/maps'

const useBuildingList = () => {
  const buildings = useSelector(getBuildings)
  return useMemo(() => _(buildings).values().sortBy('position').value(), [buildings])
}

export default useBuildingList
