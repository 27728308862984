import Button from 'client/components/Button/Button'
import PencilSVG from 'client/assets/svg/icon/edit_20_filled.svg'
import styled from 'styled-components'
import { t } from 'client/i18n'

const StyledButton = styled(Button)`
  margin-top: var(--spacing-xsmall);
`

interface IPencilIconEditButtonProps {
  onClick: () => void
  className?: string
}
const PencilIconEditButton = ({ onClick, className }: IPencilIconEditButtonProps) => {
  return (
    <StyledButton
      type="tertiary"
      onClick={onClick}
      svgComponent={PencilSVG}
      label={t('Edit')}
      className={className}
    />
  )
}

export default PencilIconEditButton
