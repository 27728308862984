import * as yup from 'yup'
import { ImageSchemaCore } from 'shared/validation/Image'
import { t } from 'client/i18n'
import { createRequiredIfNoProcessingErrorTest, createHasMediaProcessingErrorTest } from './util'

export const ImageSchemaClient = ImageSchemaCore(t).shape({
  file: yup
    .object()
    .nullable()
    .test(
      createRequiredIfNoProcessingErrorTest(
        'image',
        `${t('Image')}: ${t('An image file is required.')}`
      )
    ),
  processingError: yup
    .string()
    .nullable()
    .test(
      createHasMediaProcessingErrorTest('image', `${t('Image')}: ${t('Unable to process image.')}`)
    )
})
