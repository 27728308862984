import Divider from 'client/components/Divider/Divider'
import SupportLink from 'client/components/SupportLink'
import styled from 'styled-components'
import { HELP_CENTER_URL } from 'client/constants'
import { t } from 'client/i18n'
import { Trans } from 'react-i18next'

const StyledDivider = styled(Divider)`
  margin: var(--spacing-large) 0;
`
const Footer = styled.footer`
  line-height: 20px;
  margin-bottom: -16px;
`

const SupportFooter = () => {
  return (
    <Footer>
      <StyledDivider />
      <strong>{t('Questions?')}</strong>
      <div>
        <Trans
          i18nKey="supportFooter"
          components={{
            HelpCenterLink: (
              // eslint-disable-next-line jsx-a11y/control-has-associated-label, jsx-a11y/anchor-has-content
              <a
                href={`${HELP_CENTER_URL}/category/238-login-details`}
                target="_blank"
                rel="noreferrer"
              />
            ),
            SupportLink: <SupportLink />
          }}
        />
      </div>
    </Footer>
  )
}

export default SupportFooter
