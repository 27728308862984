import _ from 'lodash'

export const COMMON_CONTENT_FILTER_CRITERIA = [
  'title',
  'creators.name',
  'date',
  'medium',
  'commonName',
  'scientificName',
  'family',
  'origin',
  'range'
]

// Allows grabbing field value for nested array/object mix
/** 
 Example
  data: [
    { 
      uuid: 1,
      items: [ 
        title: 'item 1 title',
        meta: { _private: "XYZ" }
        { creators: [
          { name: 'Foo' }, 
          { name: 'Bar' }
        ]}
    ]},
    // { ... }
  ]
  getFieldValueHelper(data, ['items.creators.name]) => ['Foo', 'Bar]
  getFieldValueHelper(data, ['items.title']) => ['item 1 title']
  getFieldValueHelper(data, ['items.meta._private']) => ['XYZ']
 */
const getFieldValueHelper = (data: any, paths: string[]) => {
  let value = data
  paths.forEach((nextField) => {
    // handle the case where we're trying to get a field of values from an array (ex items.title)
    value = _.isArray(value) ? _(value).map(nextField).flatten().value() : value?.[nextField]
  })
  return value
}

function getFieldValue(obj: any, path: string): string {
  if (_.includes(path, '.')) {
    const pathTokens = path.split('.')
    const primitiveValuePath = pathTokens.pop()
    const fieldValue = getFieldValueHelper(obj, pathTokens)
    // Note, spreading to be an array to keep handling between object and array the same
    return fieldValue && _.map([...fieldValue], primitiveValuePath).join(' ')
  }
  return _.get(obj, path)
}

export function getFilteredData(data: any[], filterCriteria: string[], filterText: string) {
  if (_.isEmpty(filterCriteria) || !filterText) {
    return data
  }

  const filterTextLowercase = filterText.trim().toLowerCase()

  return _.filter(data, (thing) => {
    const searchableValues = filterCriteria.map((criteria) =>
      _.toLower(getFieldValue(thing, criteria))
    )
    return _.some(searchableValues, (value) => _.includes(value, filterTextLowercase))
  })
}
