import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import _ from 'lodash'
import { t } from 'client/i18n'

const BUILDINGS_WITH_EXTERIOR_MAP_GRAPHQL_QUERY = gql`
  query relatedItems($museumId: Int!) {
    museum(id: $museumId) {
      id
      exteriorMap {
        id
        mapLocations {
          id
          rawName
          building {
            id
          }
        }
      }
      buildings {
        id
        name
        mapLocations {
          id
          rawName
        }
      }
    }
  }
`

export default function useAvailableShowOnMapOptions() {
  const { data } = useQuery(BUILDINGS_WITH_EXTERIOR_MAP_GRAPHQL_QUERY)
  const { exteriorMap = {}, buildings = [] } = data?.museum || {}

  if (_.isEmpty(exteriorMap)) {
    return buildings
  }

  const exterior = {
    ...exteriorMap,
    mapLocations: _.reject(exteriorMap.mapLocations, 'building'),
    name: t('Exterior Map')
  }

  return [exterior, ...buildings]
}
