import { useCallback, useState } from 'react'
import { Dialog, DialogHeader, DialogBody, DialogActions } from 'client/dsm/Dialog/Dialog'
import Button from 'client/components/Button/Button'
import styled from 'styled-components'
import SupportLink from 'client/components/SupportLink'
import extractDisplayErrorMessage, {
  IExtractDisplayErrorMessageProps
} from 'client/util/extractDisplayErrorMessage'
import { t } from 'client/i18n'
import { Trans } from 'react-i18next'

const WrappingText = styled.p`
  white-space: pre-wrap;
  && {
    margin-bottom: 16px;
  }
`

const ErrorDetailsTextArea = styled.textarea`
  width: 100%;
  height: 240px;
  resize: none;
  padding: 16px;
  color: var(--color-black);
  border: none;
  margin-top: var(--spacing-medium);
`

interface IErrorBodyProps {
  error: IExtractDisplayErrorMessageProps
}
// This is only exported so it can be used with TranslationForm,
// which has a specific way of handling dialogs that means it can't use <ErrorDialog/> directly
export const ErrorBody = ({ error }: IErrorBodyProps) => {
  const errorMessage = extractDisplayErrorMessage(error)

  return (
    <>
      <WrappingText>{t('Sorry, something went wrong. Try your request again.')}</WrappingText>
      <WrappingText data-testid="2nd-line-of-error-body">
        <Trans i18nKey="emailSupportWithIssues" components={{ SupportLink: <SupportLink /> }} />
      </WrappingText>
      <ErrorDetailsTextArea disabled={true} value={errorMessage} />
    </>
  )
}

interface IErrorDialogProps extends IErrorBodyProps {
  title: string
  onClose?: () => void
}
const ErrorDialog = ({ title, error, onClose }: IErrorDialogProps) => {
  return (
    <Dialog>
      <DialogHeader>{title}</DialogHeader>
      <DialogBody>
        <ErrorBody error={error} />
      </DialogBody>
      <DialogActions>
        <Button onClick={onClose} type="primary" label={t('Close')} />
      </DialogActions>
    </Dialog>
  )
}

type IErrorPayload = Pick<IErrorDialogProps, 'error' | 'title'>

export const useErrorDialog = () => {
  const [errorPayload, setErrorPayload] = useState<IErrorPayload | null>(null)
  const clearError = useCallback(() => setErrorPayload(null), [])
  const setError = useCallback(
    ({ error, title }: IErrorPayload) => setErrorPayload({ error, title }),
    []
  )

  const errorDialog = errorPayload ? <ErrorDialog {...errorPayload} onClose={clearError} /> : null
  return [errorDialog, setError] as [React.ReactNode, (errorPayload: IErrorPayload) => void]
}

export default ErrorDialog
