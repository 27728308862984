import { Header1CSS } from 'client/components/TextStyles'
import * as React from 'react'
import styled from 'styled-components'

export const MAX_PAGE_CONTENT_WIDTH = 1140

const PageContent = styled.div`
  // 64px is nav height header. Copying existing approach for now.
  height: calc(100vh - 64px);
  width: 100%;
  max-width: ${MAX_PAGE_CONTENT_WIDTH}px;
  padding: 24px 32px;
  display: flex;
  flex-direction: column;
`

const PageContentHeader = styled.div`
  padding-bottom: 24px;
  display: flex;
  justify-content: space-between;
`

const PageContentTitle = styled.div`
  ${Header1CSS};
  margin: 0;
`

const PageContentControls = styled.div`
  display: flex;
  flex: auto;
  justify-content: end;
  column-gap: 16px;
`

// TODO: We use `position: relative` here to establish a "containing block" for our LoadingOverlay on
// non-Grid pages. Is this best way?
export const PageContentView = styled.div`
  background-color: var(--color-white);
  border: var(--border-light);
  border-radius: 8px;
  height: 100%;
  overflow: auto;
  position: relative;
`

interface PageContentProps {
  title?: React.ReactNode
  controls?: React.ReactNode
  children?: React.ReactNode
  className?: string
  contextualHelp?: React.ReactNode
}

export default (props: PageContentProps) => {
  const { title, controls, children, className, contextualHelp } = props

  return (
    <PageContent className={className}>
      <PageContentHeader>
        <PageContentTitle>{title}</PageContentTitle>
        {contextualHelp}
        {controls && <PageContentControls>{controls}</PageContentControls>}
      </PageContentHeader>
      <PageContentView>{children}</PageContentView>
    </PageContent>
  )
}
