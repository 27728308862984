import { useContext } from 'react'
import gql from 'graphql-tag'
import TranslationForm from 'client/components/TranslationForm/TranslationForm'
import { TranslationFormContext } from 'client/components/TranslationForm/TranslationFormContext'
import RichTextEditorField from 'client/components/Formik/RichTextEditor/RichTextEditor'
import FormField from 'client/components/TranslationForm/TranslatableFormField'
import TextInputFormField from 'client/components/TranslationForm/TranslatableTextInputField'
import useCoercedParam from 'client/hooks/useCoercedParam'
import { createApiConfig } from 'client/components/TranslationForm/util'
import { AudioInput } from 'shared/graphql/mutations/AudioInput'
import { AudioSchemaClient } from 'client/validation/Audio'
import ContextualHelp, {
  ContextualHelpTextSection
} from 'client/components/ContextualHelp/ContextualHelp'
import { t } from 'client/i18n'
import { Trans } from 'react-i18next'
import ToggleField from 'client/components/Form/ToggleField/ToggleField'
import AudioFileField from './AudioFileField'

const TranscriptContextualHelp = () => (
  <ContextualHelp
    helpSize="large"
    header={t('Audio Transcript')}
    helpContent={
      <>
        <ContextualHelpTextSection subheader={t('Purpose')}>
          {t('Provide a text version of the content in an audio file.')}
        </ContextualHelpTextSection>
        <ContextualHelpTextSection subheader={t('Primary Audience')}>
          {t(
            'People who are deaf, are hard of hearing, or prefer to read a written transcript instead of listening to the audio.'
          )}
        </ContextualHelpTextSection>
        <ContextualHelpTextSection subheader={t('Experience in the Guide')}>
          <Trans i18nKey="audioTranscriptExperienceInTheGuide" />
        </ContextualHelpTextSection>
        <ContextualHelpTextSection subheader={t('Tips')}>
          <ul>
            <li>{t('Break long chunks of text into short paragraphs of 2-3 sentences.')}</li>
            <li>
              {t(
                'Transcribe all speech verbatim; don’t paraphrase, add extra detail, or introduce abbreviations. You can cut excessive interjections (like “um”) if they’re distracting.'
              )}
            </li>
            <li>
              {t(
                'If the audio does not contain speech, provide a concise description of the experience.'
              )}
            </li>
            <li>
              {t('Use rich text formatting, like bold or italics, to indicate different speakers.')}
            </li>
          </ul>
        </ContextualHelpTextSection>
      </>
    }
    tooltipContent={t('More on how to use audio transcripts')}
  />
)

const DescriptionContextualHelp = () => (
  <ContextualHelp
    helpSize="large"
    header={t('Audio Description')}
    helpContent={
      <>
        <ContextualHelpTextSection subheader={t('Purpose')}>
          {t(
            'Encourage visitors to play the audio clip by providing a concise teaser or introduction.'
          )}
        </ContextualHelpTextSection>
        <ContextualHelpTextSection subheader={t('Primary Audience')}>
          {t('Everyone.')}
        </ContextualHelpTextSection>
        <ContextualHelpTextSection subheader={t('Experience in the Guide')}>
          {t(
            'Appears next to the audio play button, under the title, towards the top of a content screen.'
          )}
        </ContextualHelpTextSection>
        <ContextualHelpTextSection subheader={t('Examples')}>
          <ul>
            <li>
              {t(
                'Lead archivist Sonia describes a surprising detail uncovered by a recent restoration.'
              )}
            </li>
            <li>{t('Learn about fruit tree grafting with garden intern Sam.')}</li>
            <li>{t('Immerse yourself in a soundtrack selected by the artist.')}</li>
            <li>{t('Listen to the sounds of our blacksmith at work.')}</li>
          </ul>
        </ContextualHelpTextSection>
      </>
    }
    tooltipContent={t('More on how to use audio descriptions')}
  />
)

const apiConfig = createApiConfig(
  'audio',
  gql`
    query getSingleAudio($id: Int!) {
      audio(id: $id) {
        id
        externalId
        isMarketingUseAllowed
        translations {
          title
          sourceUrl
          url
          description
          transcript
          isProcessing
          processingError
          locale {
            englishName
            code
          }
        }
      }
    }
  `
)

const AudioFormView = () => {
  const { getFieldName } = useContext(TranslationFormContext)

  return (
    <>
      <FormField label={t('* Audio')}>
        <AudioFileField />
      </FormField>
      <TextInputFormField name={getFieldName('title')} label={t('* Title')} />
      <TextInputFormField
        name={getFieldName('description')}
        label={t('Description')}
        additionalLabelNode={DescriptionContextualHelp}
      />
      <RichTextEditorField
        additionalLabelNode={TranscriptContextualHelp}
        name={getFieldName('transcript')}
        label={t('Transcript')}
        fieldContainer={FormField}
      />
      <FormField translatable={false}>
        <ToggleField
          name={getFieldName('isMarketingUseAllowed')}
          label={t('Allow Use in Bloomberg Connects Social Media')}
          description={t(
            'Bloomberg Connects can use this audio on Bloomberg Connects social media channels. Posts will be shared with you for awareness before going live and will be credited, where possible, based on credit information provided in the CMS.'
          )}
        />
      </FormField>
    </>
  )
}

const AudioForm = () => {
  const contentId = useCoercedParam<number>('id')

  return (
    <TranslationForm
      contentName="Audio"
      contentId={contentId}
      apiConfig={apiConfig}
      validationSchema={AudioSchemaClient}
      inputType={AudioInput}
      initialValidationFields={contentId ? ['processingError'] : undefined}
      additionalFields={{
        // Each translation can have its own audio
        // localeFileUrl contains the in-browser url of the currently uploaded audio
        // file is populated properties from tusd uploads
        translatable: ['localFileUrl', 'file']
      }}
    >
      <AudioFormView />
    </TranslationForm>
  )
}
export default AudioForm
