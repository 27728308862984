import { useMemo } from 'react'
import _ from 'lodash'
import { IMapJson } from 'shared/json/IMapJson'
import { useDispatch } from 'react-redux'
import { updateBuildingFloorPin } from 'client/redux/actions/maps'
import { t } from 'client/i18n'
import MapPreview from './MapPreview'
import FloorMapLocation from './MapLocation/FloorMapLocation'

interface IProps {
  floor: IMapJson
  selectedPinId?: number | null
  onSelectedPinChanged?: (id: number) => void
}

export default (props: IProps) => {
  const { floor: floorRaw, selectedPinId, onSelectedPinChanged } = props
  const floor = useMemo(
    () => ({
      ...floorRaw,
      title: t('Floor __floorTitle__', { floorTitle: floorRaw.title })
    }),
    [floorRaw]
  )

  const dispatch = useDispatch()

  const handleDragEndLocation = ({ id, coordinates }) =>
    dispatch(
      updateBuildingFloorPin({
        buildingId: floor.buildingId,
        floorId: floor.id,
        pinData: { id, coordinates }
      })
    )

  const handleMapLocationResized = (id, radius, coordinates) =>
    dispatch(
      updateBuildingFloorPin({
        buildingId: floor.buildingId,
        floorId: floor.id,
        pinData: { id, radius, coordinates }
      })
    )

  return !_.isEmpty(floor) ? (
    <MapPreview
      map={floor}
      selectedPinId={selectedPinId}
      onSelectedPinChanged={onSelectedPinChanged}
      onDragEndLocation={handleDragEndLocation}
      onMapLocationResized={handleMapLocationResized}
      mapLocationElement={FloorMapLocation}
    />
  ) : null
}
