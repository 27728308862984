import { useEffect, useState } from 'react'
import * as React from 'react'
import styled from 'styled-components'
import _ from 'lodash'

const ScrollableElement = styled.div<{ percentageScrolled: number }>`
  overflow: auto;
  mask-image: ${({ percentageScrolled }) =>
    percentageScrolled < 0.99 ? 'linear-gradient(to bottom, white 70%, transparent 100%)' : ''};
`

export interface IScrollable {
  className?: string
  children?: React.ReactNode
  maskBottom?: boolean
}

// Component to allow scrolling with managed scroll detection and mask effects.
// For now, only scrolled-to-bottom state is exposed
const Scrollable = (props: IScrollable) => {
  const { className, maskBottom = false, ...rest } = props
  const [percentageScrolled, setPercentageScrolled] = useState(maskBottom ? 0 : 1)

  const handleScroll = ({ target }) => {
    const { scrollTop, clientHeight, scrollHeight } = target
    const hasHeight = _.isFinite(scrollHeight) && scrollHeight
    const percentage = hasHeight ? (scrollTop + clientHeight) / scrollHeight : 1
    setPercentageScrolled(maskBottom ? percentage : 1)
  }

  useEffect(() => (!maskBottom ? setPercentageScrolled(1) : undefined), [maskBottom])

  return (
    <ScrollableElement
      className={className}
      onScroll={handleScroll}
      percentageScrolled={percentageScrolled}
      {...rest}
    />
  )
}

export default Scrollable
