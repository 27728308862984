import { ICoordinates } from 'shared/util/maps'

interface IDimensions {
  width: number
  height: number
}

interface IRatio {
  min: number
  max: number
}

interface IPosition {
  topLeft: ICoordinates
  topRight: ICoordinates
  bottomLeft: ICoordinates
  bottomRight: ICoordinates
}

interface IRectangle extends IDimensions {
  top: number
  left: number
}

export const getCanvasToCropboxRatio = (canvas: IDimensions, cropbox: IDimensions): IRatio => {
  const widthRatio = canvas.width / cropbox.width
  const heightRatio = canvas.height / cropbox.height
  const maxRatio = Math.max(widthRatio, heightRatio)
  const minRatio = Math.min(widthRatio, heightRatio)
  return { min: minRatio, max: maxRatio }
}

export const getCenter = (info: IDimensions): ICoordinates => {
  const { width, height } = info
  return { x: width / 2, y: height / 2 }
}

export const get2DCoordinates = (figureIn2D: IRectangle): IPosition => {
  // topLeft
  const x1 = figureIn2D.left
  const y1 = figureIn2D.top

  // bottomLeft
  const x2 = x1
  const y2 = y1 + figureIn2D.height

  // bottomRight
  const x3 = x2 + figureIn2D.width
  const y3 = y2

  // topRight
  const x4 = x3
  const y4 = y1

  return {
    topLeft: { x: x1, y: y1 },
    bottomLeft: { x: x2, y: y2 },
    bottomRight: { x: x3, y: y3 },
    topRight: { x: x4, y: y4 }
  }
}
