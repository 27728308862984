import { Body1CSS } from 'client/components/TextStyles'
import styled from 'styled-components'

export const ContentDetails = styled.div`
  ${Body1CSS};
  min-width: 0;
  flex: 1;
  margin-right: 36px;
`

export const Detail = styled.span`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-shrink: 1;
`
