import { useEffect, useRef } from 'react'

function useInterval(callback: () => void, delay: number) {
  const savedCallback = useRef(callback)

  // Remember the latest callback if it changes.
  // We use ref to avoid issues with closure capture in our setInterval
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    // Kick off a call immediately.
    // Normal JS interval behavior is to wait first, but we use this hook to fetch data so want to
    // do a first fetch ASAP.
    // We could make this configurable in the future if needed.
    savedCallback.current()
    const id = setInterval(() => savedCallback.current(), delay)
    return () => clearInterval(id)
  }, [delay])
}

export default useInterval
