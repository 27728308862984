import { memo } from 'react'
import _ from 'lodash'
import BuildingIconSVG from 'client/assets/svg/icon/ic_map_building.svg'
import BuildingIconActiveSVG from 'client/assets/svg/icon/ic_map_building_active.svg'
import useBuildingList from 'client/screens/AppEditor/MapEditor/useBuildingList'
import MapLocation, { IMapLocation } from './MapLocation'
import MapTooltip, { IMapTooltip } from './MapLocationTooltip/MapLocationTooltip'
import BuildingTooltipMenu from './MapLocationTooltip/BuildingTooltipMenu'

const HALF_PIN_HEIGHT = 20
const pinOffsetCoordinates = { x: 0, y: HALF_PIN_HEIGHT }

const ExteriorMapLocationTooltip = memo((props: IMapTooltip) => {
  const { locationId: mapLocationId } = props
  const buildings = useBuildingList()
  const building = _.find(buildings, { mapLocationId })!

  // Capping this to 1. The MapTooltip prop totalContent was intended to indicate
  // the number of contents linked to a location. This does not correlate with total floors in a building.
  // When linkedContentCount > 1, the tooltip description is expected to render additional text which is not needed for buildings
  const totalContent = building.floors.length > 1 ? 1 : 0

  return (
    <MapTooltip
      {...props}
      menuPopout={BuildingTooltipMenu}
      pinOffset={pinOffsetCoordinates}
      body={{ header: building.name, totalContent }}
    />
  )
})

export default (props: IMapLocation) => (
  <MapLocation
    {...props}
    tooltipElement={ExteriorMapLocationTooltip}
    pinIcon={BuildingIconSVG}
    pinIconActive={BuildingIconActiveSVG}
    pinPositionOffset={pinOffsetCoordinates}
  />
)
