import ContextualHelp, {
  ContextualHelpTextSection
} from 'client/components/ContextualHelp/ContextualHelp'
import { t } from 'client/i18n'
import { Trans } from 'react-i18next'

const TranscriptContextualHelp = () => (
  <ContextualHelp
    helpSize="large"
    header={t('Video Transcript')}
    helpContent={
      <>
        <ContextualHelpTextSection subheader={t('Purpose')}>
          {t('Provide a text version of the audio content in a video.')}
        </ContextualHelpTextSection>
        <ContextualHelpTextSection subheader={t('Primary Audience')}>
          {t(
            'People who are deaf, are hard of hearing, or prefer to read a written transcript instead of listening to the video. People who are both deaf and blind may read the transcript via a braille device to experience the video content.'
          )}
        </ContextualHelpTextSection>
        <ContextualHelpTextSection subheader={t('Experience in the Guide')}>
          <Trans i18nKey="videoTranscriptExperienceInTheGuide" />
        </ContextualHelpTextSection>
        <ContextualHelpTextSection subheader={t('Tips')}>
          <ul>
            <li>{t('Break long chunks of text into short paragraphs of 2-3 sentences.')}</li>
            <li>
              {t(
                'Transcribe all speech verbatim; don’t paraphrase, add extra detail, or introduce abbreviations or shorthand. You can cut excessive interjections (like “um”) if they’re distracting.'
              )}
            </li>
            <li>
              {t(
                'If the audio does not contain speech, provide a concise description of the experience.'
              )}
            </li>
            <li>
              {t('Use rich text formatting, like bold or italics, to indicate different speakers.')}
            </li>
            <li>
              {t(
                'Consider also including essential visual information to make the video content accessible to people who are both deaf and blind and may be reading the transcript via a braille device.'
              )}
            </li>
          </ul>
        </ContextualHelpTextSection>
      </>
    }
    tooltipContent={t('More on how to use video transcripts')}
  />
)

export default TranscriptContextualHelp
