import { Label1CSS } from 'client/components/TextStyles'
import styled from 'styled-components'

const Container = styled.div`
  ${Label1CSS};
  margin-right: var(--spacing-xsmall);

  span {
    display: block;
    font-weight: var(--font-weight-regular);
  }
  margin-bottom: 0;
`
interface IProps {
  children: React.ReactNode
  className?: string
}

export default function Label({ children, className }: IProps) {
  return <Container className={className}>{children}</Container>
}
