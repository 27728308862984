import _ from 'lodash'
import { RadioButton, RadioButtonGroup } from 'client/components/RadioButton/RadioButton'
import ExhibitDisplayType from 'shared/ExhibitDisplayType'
import styled from 'styled-components'
import Label from 'client/components/Form/Label/Label'
import useField from 'client/hooks/useField'
import { t } from 'client/i18n'
import { Trans } from 'react-i18next'

const StyledLabel = styled(Label)`
  span {
    margin-top: 8px;
  }
`
const LayoutSectionContent = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: var(--spacing-large);
`

const LayoutRadioContainer = styled.div`
  display: flex;
  margin-top: 16px;
`

interface IDisplayTypeProps {
  name: string
}
export default function DisplayType({ name }: IDisplayTypeProps) {
  const { value: items } = useField('items')
  const { value: displayType, setValue: setDisplayType } = useField(name)

  if (_.isEmpty(items)) {
    return null
  }

  return (
    <>
      <LayoutSectionContent>
        <StyledLabel>
          {/* TODO: fix this forced line break */}
          <Trans i18nKey="exhibitFormLayoutLabel" />
        </StyledLabel>
      </LayoutSectionContent>
      <LayoutRadioContainer>
        <RadioButtonGroup
          name="displayType"
          value={displayType}
          onChange={(value) => setDisplayType(value)}
        >
          <RadioButton
            label={t('List (images and text; best for fewer than 12 items)')}
            value={ExhibitDisplayType.LIST}
          />
          <RadioButton
            label={t('Grid (images only; best for 12 or more items)')}
            value={ExhibitDisplayType.GRID}
          />
        </RadioButtonGroup>
      </LayoutRadioContainer>
    </>
  )
}
