import { ITrackAndLanguageInfo } from 'shared/constants/video'
import styled from 'styled-components'
import _ from 'lodash'
import { Label2CSS, Body2CSS } from 'client/components/TextStyles'

const WarningSection = styled.div<{ marginTop: number; paddingBottom: number }>`
  margin-top: ${({ marginTop }) => marginTop}px;
  padding-bottom: ${({ paddingBottom }) => paddingBottom}px;
  border-bottom: 1px solid var(--color-grey-02);
`

const WarningWrapper = styled.div`
  ${Body2CSS};
  color: var(--color-grey-07);

  &:not(:last-of-type) {
    padding-bottom: 24px;
  }
`

const WarningHeader = styled.div`
  ${Label2CSS};
`

const WarningBody = styled.div`
  font-weight: var(--font-weight-regular);
  margin-top: 5px;
`

interface IUnsupportedOrUnrecognizedWarningProps {
  languages: ITrackAndLanguageInfo[]
}

const UnsupportedWarning = ({ languages }: IUnsupportedOrUnrecognizedWarningProps) => {
  const numLanguages = languages.length
  const names = _(languages).map('name').join(', ')
  const headerText = `Language not supported (${numLanguages}): ${names}`
  const bodyText =
    'This video file contains one or more tracks with languages which are not supported. Unsupported languages will not be available to users of your guide.'

  return (
    <WarningWrapper role="note">
      <WarningHeader>{headerText}</WarningHeader>
      <WarningBody>{bodyText}</WarningBody>
    </WarningWrapper>
  )
}

const UnrecognizedWarning = ({ languages }: IUnsupportedOrUnrecognizedWarningProps) => {
  const numLanguages = languages.length
  const names = _(languages).map('name').join(', ')
  const headerText = `Language not recognized (${numLanguages}): ${names}`

  const bodyText =
    'This video file contains one or more tracks with languages which are not recognized. Languages must be in ISO format in order to be recognized. Unrecognized languages will not be available to users of your guide.'

  return (
    <WarningWrapper role="note">
      <WarningHeader>{headerText}</WarningHeader>
      <WarningBody>{bodyText}</WarningBody>
    </WarningWrapper>
  )
}

interface IMissingWarningProps {
  numUntaggedTracks: number
}
const MissingWarning = ({ numUntaggedTracks }: IMissingWarningProps) => {
  const headerText = `Language missing (${numUntaggedTracks}):`
  const bodyText =
    'This video file contains one or more tracks which are missing language information. Tracks must be labeled with languages in ISO format. Unlabeled tracks will not be available to users of your guide.'

  return (
    <WarningWrapper role="note">
      <WarningHeader>{headerText}</WarningHeader>
      <WarningBody>{bodyText}</WarningBody>
    </WarningWrapper>
  )
}

interface IWarningMessagesProps {
  marginTop: number
  paddingBottom: number
  unsupportedLanguages: ITrackAndLanguageInfo[]
  unrecognizedLanguages: ITrackAndLanguageInfo[]
  numUntaggedTracks: number
}
const WarningMessages = ({
  marginTop,
  paddingBottom,
  unsupportedLanguages,
  unrecognizedLanguages,
  numUntaggedTracks
}: IWarningMessagesProps) => {
  const showWarningMessages =
    !_.isEmpty(unsupportedLanguages) || !_.isEmpty(unrecognizedLanguages) || numUntaggedTracks > 0

  if (!showWarningMessages) {
    return null
  }

  return (
    <WarningSection marginTop={marginTop} paddingBottom={paddingBottom}>
      {!_.isEmpty(unsupportedLanguages) && <UnsupportedWarning languages={unsupportedLanguages} />}
      {!_.isEmpty(unrecognizedLanguages) && (
        <UnrecognizedWarning languages={unrecognizedLanguages} />
      )}
      {numUntaggedTracks > 0 && <MissingWarning numUntaggedTracks={numUntaggedTracks} />}
    </WarningSection>
  )
}

export default WarningMessages
