import * as React from 'react'
import { IBaseContentRowWithTags } from 'client/components/ContentRow/types'
import styled from 'styled-components'
import { Note } from 'client/components/ContentRow/Note'
import { HeaderSubheaderContainer } from 'client/components/ContentRow/HeaderSubheaderContainer'
import { ImageContainer } from 'client/components/ContentRow/ImageContainer'
import { FullContentRow, IFullContentRowProps } from 'client/components/ContentRow/FullContentRow'
import { DescriptionContainer, Label, IconsContainer } from './styledComponents'

const RowWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
`

interface ICompactContentRowProps extends IBaseContentRowWithTags {
  image?: string | React.FunctionComponent
  note?: React.ReactNode
  isSelected?: boolean
}

// CompactContentRow is used by the minipicker and the "New and Featured" page.
// We may want to rethink/simplify this CompactContentRow component in the future from being overloaded/trying to do too much.
const CompactContentRow = ({
  image: Image,
  header,
  subheaders = [],
  note,
  isError,
  contentStateCaption,
  tags,
  isSelected
}: ICompactContentRowProps) => {
  return (
    <RowWrapper>
      <ImageContainer isError={isError} image={Image} isPickerRow={true} />
      <DescriptionContainer>
        <HeaderSubheaderContainer
          header={header}
          subheaders={subheaders}
          isPickerRow={true}
          isError={isError}
          contentStateCaption={contentStateCaption}
        />
        <IconsContainer>
          <div>
            {tags?.map((tag) => (
              <Label key={tag} isSelected={isSelected}>
                {tag}
              </Label>
            ))}
          </div>
          <Note>{note}</Note>
        </IconsContainer>
      </DescriptionContainer>
    </RowWrapper>
  )
}

interface IContentRowProps<T> extends IFullContentRowProps<T> {
  isCompact?: boolean
  isSelected?: boolean
}
export const ContentRow = <T extends Object>(props: IContentRowProps<T>) => {
  return props.isCompact ? <CompactContentRow {...props} /> : <FullContentRow {...props} />
}
