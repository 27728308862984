import isValidVTTFile from 'shared/util/isValidVTTFile'
import _ from 'lodash'
import * as yup from 'yup'
import { VideoSchemaCore } from 'shared/validation/Video'
import { t } from 'client/i18n'
import { createHasMediaProcessingErrorTest, createRequiredIfNoProcessingErrorTest } from './util'

export const TITLE_CHAR_LIMIT = 100

export const VideoSchemaClient = VideoSchemaCore(t).shape({
  subtitlesFile: yup
    .mixed()
    .test(
      'subtitlesFile',
      `${t('Subtitles')}: ${t(
        'Incorrect file format. Please make sure subtitles are in .vtt format.'
      )}`,
      (subtitlesFile) => _.isNil(subtitlesFile) || isValidVTTFile(subtitlesFile.file)
    ),
  file: yup
    .object()
    .nullable()
    .test(
      createRequiredIfNoProcessingErrorTest(
        'video',
        `${t('Video')}: ${t('A video file is required.')}`
      )
    ),
  processingError: yup
    .string()
    .nullable()
    .test(
      createHasMediaProcessingErrorTest('video', `${t('Video')}: ${t('Unable to process video.')}`)
    )
})
