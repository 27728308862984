import LabelTooltip, { ILabelTooltipProps } from 'client/components/Languages/LabelTooltip'
import _ from 'lodash'
import { t } from 'client/i18n'

type IAudioDescriptionLabelTooltipProps = Omit<ILabelTooltipProps, 'children' | 'tooltipHeader'>

function AudioDescriptionLabelTooltip(props: IAudioDescriptionLabelTooltipProps) {
  const { translations } = props
  const translationsWithAudioDescriptions = _.filter(
    translations,
    (translation) => !_.isNil(translation.audioDescriptionTrackIndex)
  )
  return (
    <LabelTooltip
      {...props}
      tooltipHeader={t('Audio Descriptions')}
      translations={translationsWithAudioDescriptions}
    >
      {t('AD')}
    </LabelTooltip>
  )
}

export default AudioDescriptionLabelTooltip
