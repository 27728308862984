import PageContent from 'client/components/PageContent/PageContent'
import Button from 'client/components/Button/Button'
import styled from 'styled-components'
import { t } from 'client/i18n'

const ContainerContent = styled.div`
  padding: 32px;
`

export default function AssetCounts() {
  const handleClick = () => {
    window.open('/api/marketing-reports/asset-counts')
  }

  return (
    <PageContent title={t('Asset Counts')}>
      <ContainerContent>
        <Button type="primary" onClick={handleClick} label={t('Generate Report')} />
      </ContainerContent>
    </PageContent>
  )
}
