import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import { GQLGuide, GQLLocale } from 'shared/graphql/types/graphql'
import _ from 'lodash'

// Fetch default locale for guide and all available locales in system
const getLocaleInformation = gql`
  query getDefaultLocale($guideId: Int!) {
    guide(id: $guideId) {
      id
      defaultLocale {
        code
        englishName
      }
    }

    locales {
      id
      code
      englishName
    }
  }
`

export default function useGuideLocalesQuery() {
  const queryResult = useQuery<{ guide: GQLGuide; locales: GQLLocale[] }>(getLocaleInformation)
  return {
    ...queryResult,
    defaultLocale: _.get(queryResult.data, 'guide.defaultLocale'),
    availableLocales: _.get(queryResult.data, 'locales')
  }
}
