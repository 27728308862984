import { useCallback, useMemo, useState } from 'react'
import * as React from 'react'
import { Formik, FormikErrors, FormikProps, FormikValues } from 'formik'
import _ from 'lodash'
import StandardForm from 'client/components/StandardForm/StandardForm'
import useCoercedParam from 'client/hooks/useCoercedParam'
import TextInputField from 'client/components/Formik/TextInput/TextInput'
import styled from 'styled-components'
import FormErrorBanner from 'client/dsm/Banner/FormErrorBanner'
import useFormikErrors from 'client/hooks/useFormikErrors'
import { GQLUser } from 'shared/graphql/types/graphql'
import FormField from 'client/components/Form/FormField/FormField'
import UserPermissionType from 'shared/UserPermissionType'
import validatePassword from 'shared/util/validatePassword'
import SelectBox from 'client/components/Form/SelectBox/SelectBox'
import gql from 'graphql-tag'
import LoadingOverlay from 'client/components/LoadingOverlay/LoadingOverlay'
import useAdminApi from 'client/screens/Admin/useAdminApi'
import { AxiosError } from 'axios'
import { useNavigate } from 'react-router-dom'
import Button from 'client/components/Button/Button'
import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogHeader,
  PermanentActionMessage
} from 'client/dsm/Dialog/Dialog'
import { useFeatureFlags } from 'client/hooks/useFeatureFlags'
import GuideListWithPicker from 'client/components/GuideListWithPicker'
import { ToggleInputField } from 'client/components/Form/ToggleInput/ToggleInput'
import AuthenticationErrorType from 'shared/AuthenticationErrorType'
import { t } from 'client/i18n'
import UserMFAType from 'shared/UserMFAType'
import { languageOptions, Option } from 'client/screens/shared'
import MFATypeDisplayName from '../MFATypeDisplayName'

const Container = styled.div`
  padding: var(--container-padding);
  overflow: auto;
  height: 100%;
`

const ErrorList = styled.ul`
  padding-left: 15px;
  margin: 0px;
`

interface IFormikFieldErrors extends FormikErrors<Omit<GQLUser, 'password' | 'guide'>> {
  password?: React.ReactNode
}

interface IUserFormProps extends FormikProps<GQLUser> {
  permissionOptions: Option[]
  serverError: AxiosError | null
  mfaOptions: Option[]
}

const UserForm = (props: IUserFormProps) => {
  const { values, handleChange, permissionOptions, serverError, mfaOptions } = props
  const serverErrorMessage = serverError ? (serverError.response!.data as any).message : null
  const isCannotMatchCurrentPasswordError =
    serverErrorMessage === AuthenticationErrorType.CANNOT_MATCH_CURRENT_PASSWORD

  const { CHANGE_USER_PERMISSION_TYPE, CHANGE_USER_MFA_TYPE, EMAIL_MFA } = useFeatureFlags()

  const formErrors = useFormikErrors()

  /*
    If there are form errors, no request to the server will be made. We don't want to display a stale server error.
    To give an example, an admin attempts to change a user's password but unwittingly types the user's current password and saves the form.
    The server returns a `CANNOT_MATCH_CURRENT_PASSWORD` error. The admin sees the `CANNOT_MATCH_CURRENT_PASSWORD` error and chooses another password.
    However, the new password is too short. In this case, the `CANNOT_MATCH_CURRENT_PASSWORD` server error no longer applies and the
    'Password does not meet the required password policy.' form error should be displayed.
  */
  const allErrors =
    _.isEmpty(formErrors) && serverError
      ? {
          server: isCannotMatchCurrentPasswordError
            ? 'Password: New password cannot match current password.'
            : serverErrorMessage
        }
      : formErrors

  const selectedPermissionValue = useMemo(
    () => _.find(permissionOptions, { value: values.permissionType }),
    [values.permissionType, permissionOptions]
  ) as Option

  const selectedMFAValue = useMemo(
    () => _.find(mfaOptions, { value: values.mfaType }),
    [mfaOptions, values.mfaType]
  ) as Option

  return (
    <>
      <FormErrorBanner errorMap={allErrors} />

      <Container>
        <FormField label={t('* Guides')}>
          <GuideListWithPicker name="assignedGuides" showOnlyPublicGuides={false} />
        </FormField>
        <TextInputField name="email" label={t('* Email')} autoComplete="off" />
        <TextInputField name="firstName" label={t('* First Name')} autoComplete="off" />
        <TextInputField name="lastName" label={t('* Last Name')} autoComplete="off" />
        {!_.isNil(values.id) && (
          <TextInputField
            name="password"
            label={t('* Password')}
            type="password"
            autoComplete="new-password"
          />
        )}
        <FormField label={t('Language')}>
          <SelectBox
            name="language"
            options={languageOptions}
            onChange={handleChange}
            value={_.find(languageOptions, { value: values.language })}
          />
        </FormField>
        {CHANGE_USER_PERMISSION_TYPE && (
          <FormField label={t('Permission Type')}>
            <SelectBox
              name="permissionType"
              options={permissionOptions}
              onChange={handleChange}
              value={selectedPermissionValue}
            />
          </FormField>
        )}
        <FormField label={t('Read-Only')}>
          <ToggleInputField name="isReadOnly" />
        </FormField>

        {CHANGE_USER_MFA_TYPE && EMAIL_MFA && (
          <FormField label={t('MFA Type')}>
            <SelectBox
              name="mfaType"
              options={mfaOptions}
              onChange={handleChange}
              value={selectedMFAValue}
            />
          </FormField>
        )}
      </Container>
    </>
  )
}

const USER_QUERY = gql`
  query userById($id: Int!) {
    user(id: $id) {
      id
      email
      firstName
      lastName
      permissionType
      mfaType
      isReadOnly
      language
      assignedGuides {
        id
        name
      }
    }
  }
`

interface IDeleteUserConfirmDialogProps {
  onCancel: () => void
  onDelete: () => void
}
function DeleteUserConfirmDialog({ onCancel, onDelete }: IDeleteUserConfirmDialogProps) {
  return (
    <Dialog>
      <DialogHeader>{t('Delete User?')}</DialogHeader>
      <DialogBody>
        <PermanentActionMessage />
      </DialogBody>
      <DialogActions>
        <Button onClick={onCancel} label={t('Cancel')} />
        <Button type="danger" onClick={onDelete} label={t('Delete User')} />
      </DialogActions>
    </Dialog>
  )
}

export default () => {
  const navigate = useNavigate()
  const userId = useCoercedParam<number>('id')
  const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState(false)
  const { EMAIL_MFA } = useFeatureFlags()

  const { isExistingUser, title, saveButtonLabel, successToastMessage } = _.isNil(userId)
    ? {
        isExistingUser: false,
        title: t('Add User'),
        saveButtonLabel: t('Save & Send Invite'),
        successToastMessage: t('Email invite sent')
      }
    : {
        isExistingUser: true,
        title: t('Edit User'),
        saveButtonLabel: t('Save'),
        successToastMessage: t('Changes saved')
      }

  const {
    object: user,
    createUpdate,
    doDelete,
    isLoading,
    error: serverError
  } = useAdminApi({
    gqlQuery: USER_QUERY,
    url: '/api/users',
    id: userId,
    successToastMessage
  })

  const permissionOptions = useMemo(
    () =>
      _.map(UserPermissionType, (value) => ({
        label: _.startCase(value),
        value
      })),
    []
  )

  const mfaOptions = _.map(MFATypeDisplayName, (label, value) => ({ value, label }))

  const handleCancel = () => navigate('..')

  const handleDelete = () => {
    setShowDeleteConfirmDialog(true)
  }

  const handleSubmit = async (values) => {
    const { assignedGuides: guidesFromData, ...restValues } = values
    await createUpdate({ ...restValues, assignedGuides: _.map(guidesFromData, 'id') })
  }

  // Note, we're using explicit validate function instead of validateSchema because password needs component context that is not
  // captured in the schema context.
  const handleValidate = useCallback((values: FormikValues) => {
    const requiredMessage = t('This field is required.')
    const errors = {} as IFormikFieldErrors

    // Required fields
    const fields = ['email', 'firstName', 'lastName']
    _.each(fields, (field) => {
      if (_.isEmpty(values[field])) {
        errors[field] = `${_.startCase(field)}: ${requiredMessage}`
      }
    })

    if (_.isEmpty(values.assignedGuides)) {
      errors.assignedGuides = t('Guides: At least one guide is required.')
    }

    if (!_.isEmpty(values.password)) {
      const passwordValidInfo = validatePassword(values.password)
      if (!_.isEmpty(passwordValidInfo)) {
        errors.password = (
          <>
            <p>{passwordValidInfo.error}</p>
            <ErrorList>
              {passwordValidInfo.messages.map((message) => (
                <li key={message}>{message}</li>
              ))}
            </ErrorList>
          </>
        )
      }
    }

    return errors
  }, [])

  if (isLoading) {
    return <LoadingOverlay />
  }

  return (
    <Formik
      initialValues={
        user || {
          permissionType: UserPermissionType.STANDARD,
          language: 'en',
          mfaType: EMAIL_MFA ? UserMFAType.EMAIL : UserMFAType.TOTP
        }
      }
      enableReinitialize={true}
      onSubmit={handleSubmit}
      validate={handleValidate}
    >
      {(formikProps) => {
        const { handleSubmit: formikHandleSubmit } = formikProps

        return (
          <StandardForm
            title={title}
            isLoading={isLoading}
            onCancel={handleCancel}
            onSave={formikHandleSubmit}
            onDelete={isExistingUser ? handleDelete : undefined}
            fullWidth={true}
            saveButtonLabel={saveButtonLabel}
          >
            {showDeleteConfirmDialog && (
              <DeleteUserConfirmDialog
                onCancel={() => setShowDeleteConfirmDialog(false)}
                onDelete={() => {
                  setShowDeleteConfirmDialog(false)
                  doDelete!()
                }}
              />
            )}
            <UserForm
              {...formikProps}
              permissionOptions={permissionOptions}
              serverError={serverError}
              mfaOptions={mfaOptions}
            />
          </StandardForm>
        )
      }}
    </Formik>
  )
}
