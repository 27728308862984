import { useEffect, useState } from 'react'
import { t } from 'client/i18n'
import {
  cropBoxTopMargin,
  ErrorBorderContainer,
  ErrorMessage,
  ErrorOverlayContainer,
  ErrorText
} from './styledComponents'

const TIMEOUT_SECONDS = 2

const TOP = cropBoxTopMargin

interface IProps {
  width: number
  height: number
  left: number
  active: boolean
}

const ErrorOverlay = (props: IProps) => {
  const { width, left, height, active } = props
  const [filledIn, setFilledIn] = useState(true)

  useEffect(() => {
    setFilledIn(active)
    if (active) {
      const timeout = setTimeout(() => setFilledIn(false), TIMEOUT_SECONDS * 1000)
      return () => clearTimeout(timeout)
    }
  }, [active])

  return (
    <>
      <ErrorBorderContainer active={active} top={TOP} left={left} width={width} height={height}>
        <ErrorMessage filledIn={filledIn}>
          <ErrorText>{t('The image must cover the entire background')}</ErrorText>
        </ErrorMessage>
      </ErrorBorderContainer>
      <ErrorOverlayContainer
        top={TOP}
        left={left}
        width={width}
        height={height}
        filledIn={filledIn}
      />
    </>
  )
}

export default ErrorOverlay
