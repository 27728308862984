import * as React from 'react'
import { NoteSpan } from 'client/components/ContentRow/styledComponents'

interface INoteProps {
  children: React.ReactNode
}
export const Note = (props: INoteProps) => {
  const { children } = props
  if (!children) {
    return null
  }

  return <NoteSpan>{children}</NoteSpan>
}
