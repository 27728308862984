import * as yup from 'yup'
import { TFunction } from 'i18next'
import { validHtml } from './util'

const TITLE_CHAR_LIMIT = 100

export const VideoSchemaCore = (t: TFunction) =>
  yup.object().shape({
    title: yup.string().nullable().required().max(TITLE_CHAR_LIMIT),
    credits: yup.string().nullable().test(validHtml(t)),
    transcript: yup.string().nullable().test(validHtml(t))
  })
