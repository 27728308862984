import { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import Button from 'client/components/Button/Button'
import { IConfirmationState, IReduxState } from 'client/types'
import { Dialog, DialogHeader, DialogBody, DialogActions } from 'client/dsm/Dialog/Dialog'
import {
  confirmEnd as confirmEndAction,
  confirmYes as confirmYesAction,
  confirmNo as confirmNoAction
} from 'client/redux/actions/confirmation'
import { t } from 'client/i18n'

interface IConfirmationProps extends IConfirmationState {
  onYesClicked?: () => void
  onNoClicked?: () => void
  onConfirmEnd?: () => void
}

const mapStateToProps = (state: IReduxState) => {
  const { show, confirmYes, confirmNo, title, message, isAlert } = state.confirmation
  return {
    show,
    confirmYes,
    confirmNo,
    title,
    message,
    isAlert
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onConfirmEnd: confirmEndAction,
      onYesClicked: confirmYesAction,
      onNoClicked: confirmNoAction
    },
    dispatch
  )

class Confirmation extends Component<IConfirmationProps> {
  private onYes = () => {
    const {
      confirmYes = { action: _.noop },
      onYesClicked = _.noop,
      onConfirmEnd = _.noop
    } = this.props

    const { action: confirmYesCallbackLegacy = _.noop } = confirmYes!

    // Legacy api that uses callback in args. TODO: create ticket to remove this and update consumer to not use this
    confirmYesCallbackLegacy()

    // New API just fires off action for the consumer to listen for
    onYesClicked()
    onConfirmEnd()
  }

  private onNo = () => {
    const {
      confirmNo = { action: _.noop },
      onNoClicked = _.noop,
      onConfirmEnd = _.noop
    } = this.props

    const { action: confirmNoCallbackLegacy = _.noop } = confirmNo!

    // Legacy api that uses callback in args. TODO: create ticket to remove this and update consumer to not use this
    confirmNoCallbackLegacy()

    // New API just fires off action for the consumer to listen for
    onNoClicked()
    onConfirmEnd()
  }

  private renderConfirmButtons = () => {
    const { confirmYes, confirmNo } = this.props
    const labelNo = confirmNo?.label || t('Cancel')
    const labelYes = confirmYes?.label || t('Yes')
    const confirmYesActionIsDestructive = confirmYes?.isDestructive

    return (
      <>
        <Button onClick={this.onNo} label={labelNo} />
        <Button
          onClick={this.onYes}
          type={confirmYesActionIsDestructive ? 'danger' : 'primary'}
          label={labelYes}
        />
      </>
    )
  }

  private renderAlertButtons = () => {
    const { onConfirmEnd } = this.props
    return <Button onClick={onConfirmEnd} type="primary" label={t('OK')} />
  }

  public render() {
    const { show, title, message, isAlert } = this.props
    if (!show) {
      return null
    }

    return (
      <Dialog>
        <DialogHeader>{title}</DialogHeader>
        <DialogBody>{message}</DialogBody>
        <DialogActions>
          {isAlert ? this.renderAlertButtons() : this.renderConfirmButtons()}
        </DialogActions>
      </Dialog>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Confirmation)
