import * as React from 'react'
import { ICropStateImageType, ICropEvent } from 'shared/CropTypes'
import BCropper from 'client/components/ImageCropper/Cropper'
import LoadingOverlay from 'client/components/LoadingOverlay/LoadingOverlay'
import ViewControls from 'client/components/ViewControls/ViewControls'
import styled from 'styled-components'
import { getCanvasToCropboxRatio, getCenter, get2DCoordinates } from './helpers'

const StyledViewControls = styled(ViewControls)`
  z-index: 1;
`
const CropperContainer = styled(BCropper)`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: var(--color-white);
`
interface ICropData {
  width: number
  height: number
  left: number
  top: number
}

interface IProps {
  image: string | undefined
  aspect: number
  onCropError: () => any
  cropState?: ICropStateImageType
  onChange: (event: ICropEvent) => void
  hasStaticCropper?: boolean
  renderFooter?: () => React.ReactElement
  handleReset?: () => void
  showControlForNoCrop?: boolean
  viewControlsBottomPosition?: number
}

interface IState {
  isImageLoaded: boolean
}

const MAX_ZOOM_IN_RATIO = 4.0
const MAX_ZOOM_OUT_RATIO = 1.0
const STEP = 0.01
const ZOOM_IN = 'in'
const ZOOM_OUT = 'out'

export default class ImageCropper extends React.Component<IProps, IState> {
  private cropper

  private zoomValue: number = 1

  private zoomRatio: number = 1

  constructor(props: IProps) {
    super(props)
    // TODO: [DOCNT-1108] - previous crop state should be set back when editing an image
    this.state = {
      isImageLoaded: false
    }

    this.cropper = React.createRef()
  }

  private init = (ignoreState = false): void => {
    const { cropState } = this.props
    if (
      !ignoreState &&
      cropState &&
      (cropState.zoomRatio || cropState.cropBoxData || cropState.canvasData)
    ) {
      this.cropper.current.setCropBoxData(cropState.cropBoxData)
      if (cropState.zoomRatio && cropState.zoomRatio !== 1) {
        this.cropper.current.zoomTo(cropState.zoomRatio)
      }
      this.cropper.current.setCanvasData(cropState.canvasData)

      this.props.onChange({
        cropBoxData: cropState.cropBoxData,
        zoomRatio: cropState.zoomRatio,
        canvasData: cropState.canvasData,
        cropData: this.getCropData()
      })
      return
    }

    const canvas = this.cropper.current.getCanvasData()
    const cropBox = this.cropper.current.getCropBoxData()
    const container = this.cropper.current.getContainerData()
    const containerMidpoint = getCenter(container)
    const { min: minRatio } = getCanvasToCropboxRatio(canvas, cropBox)

    // resize and center cropbox
    const cropboxWidth = cropBox.width * minRatio
    const cropboxHeight = cropBox.height * minRatio
    const left = containerMidpoint.x - cropboxWidth / 2
    const top = containerMidpoint.y - cropboxHeight / 2
    const cropBoxData = {
      top,
      left,
      width: cropboxWidth,
      height: cropboxHeight
    }

    const { hasStaticCropper } = this.props

    this.cropper.current.setCropBoxData(hasStaticCropper ? cropState?.cropBoxData : cropBoxData)

    this.props.onChange({
      ...(hasStaticCropper ? { cropBoxData: null } : { cropBoxData }),
      zoomRatio: this.zoomRatio,
      canvasData: canvas,
      cropData: this.getCropData()
    })
  }

  private reset = (): void => {
    this.cropper.current.reset()
    this.zoomValue = 1
    this.init(true)

    const { handleReset } = this.props
    if (handleReset) {
      handleReset()
    }
  }

  private goToMaxZoomOut = (): void => {
    this.cropper.current.reset()
    this.init(true)
    const canvas = this.cropper.current.getCanvasData()
    const cropBox = this.cropper.current.getCropBoxData()
    const container = this.cropper.current.getContainerData()
    const { x: containerMidX, y: containerMidY } = getCenter(container)
    const { max: maxRatio } = getCanvasToCropboxRatio(canvas, cropBox)

    // resize and center canvas
    const width = canvas.width / maxRatio
    const height = canvas.height / maxRatio
    const canvasLeft = containerMidX - width / 2
    const canvasTop = containerMidY - height / 2
    this.cropper.current.setCanvasData({
      left: canvasLeft,
      top: canvasTop,
      width,
      height
    })

    this.props.onChange({
      cropBoxData: this.cropper.current.getCropBoxData(),
      zoomRatio: this.zoomRatio,
      canvasData: this.cropper.current.getCanvasData(),
      cropData: this.getCropData()
    })
  }

  private zoomChange = (e): void => {
    const {
      detail: { oldRatio, ratio }
    } = e
    const canvas = this.cropper.current.getCanvasData()
    const cropBox = this.cropper.current.getCropBoxData()
    const container = this.cropper.current.getContainerData()
    const { max: maxRatio } = getCanvasToCropboxRatio(canvas, cropBox)
    const { x: containerMidX, y: containerMidY } = getCenter(container)
    const { x: canvasMidX, y: canvasMidY } = getCenter(canvas)
    const zoomingOut = oldRatio > ratio
    const zoomingIn = ratio > oldRatio
    const cropData = this.getCropData()

    if (zoomingOut && maxRatio <= MAX_ZOOM_OUT_RATIO) {
      e.preventDefault()
      const { hasStaticCropper } = this.props
      if (!hasStaticCropper) {
        this.goToMaxZoomOut()
      }
      this.zoomRatio = maxRatio
      if (hasStaticCropper) {
        this.handleCropend()
        return
      }
      this.props.onChange({
        zoomRatio: maxRatio,
        cropBoxData: cropBox,
        canvasData: canvas,
        cropData
      })
      return
    }

    if (zoomingIn && maxRatio > MAX_ZOOM_IN_RATIO) {
      e.preventDefault()
    }

    // center canvas
    const left = containerMidX - canvasMidX
    const top = containerMidY - canvasMidY
    this.cropper.current.setCanvasData({ left, top })
    this.zoomRatio = ratio
    if (this.props.hasStaticCropper) {
      this.handleCropend()
      return
    }
    this.props.onChange({
      cropData,
      zoomRatio: ratio,
      cropBoxData: cropBox,
      canvasData: { ...canvas, left, top }
    })
  }

  public zoom = (type: string): void => {
    this.zoomValue = type === ZOOM_IN ? this.zoomRatio + STEP : this.zoomRatio - STEP
    return type === ZOOM_IN ? this.cropper.current.zoom(STEP) : this.cropper.current.zoom(-STEP)
  }

  private getCropData = (): ICropData => {
    const { width, height } = this.cropper.current.getData(true)
    let { x: left, y: top } = this.cropper.current.getData(true)

    // Cropper can sometimes return -0, so we need to force the top and left to be regular 0 in that case
    // -0 === 0, so we don't need to compare against -0
    top = top === 0 ? 0 : top
    left = left === 0 ? 0 : left

    return {
      width,
      height,
      left,
      top
    }
  }

  private handleCropend = (): void => {
    const canvas = this.cropper.current.getCanvasData()
    const cropbox = this.cropper.current.getCropBoxData()
    const { topLeft, bottomLeft, bottomRight } = get2DCoordinates(canvas)
    const { topLeft: boxTopLeft, bottomRight: boxBottomRight } = get2DCoordinates(cropbox)

    const cropBoxPosition = {
      top: boxTopLeft.y,
      left: boxTopLeft.x
    }

    // reposition cropbox if it's beyond canvas
    if (boxBottomRight.x > bottomRight.x) {
      const canvasWidth = canvas.width
      const { width } = cropbox
      cropBoxPosition.left = canvasWidth - width + bottomLeft.x
    }

    if (boxTopLeft.x < topLeft.x) {
      cropBoxPosition.left = topLeft.x
    }

    if (boxTopLeft.y < topLeft.y) {
      cropBoxPosition.top = topLeft.y
    }

    if (boxBottomRight.y > bottomRight.y) {
      const lengthBelowCanvas = boxBottomRight.y - bottomRight.y
      cropBoxPosition.top = boxTopLeft.y - lengthBelowCanvas
    }

    const { hasStaticCropper } = this.props

    if (!hasStaticCropper) {
      this.cropper.current.setCropBoxData(cropBoxPosition)
    }
    const cropData = this.getCropData()
    this.props.onChange({
      cropBoxData: { ...cropbox, ...cropBoxPosition },
      zoomRatio: this.zoomRatio,
      canvasData: canvas,
      cropData
    })
  }

  private onImageLoad = (): void => {
    this.setState({ isImageLoaded: true })
  }

  private renderLoader = () => {
    const { image, onCropError } = this.props

    return (
      <>
        <LoadingOverlay />
        <img src={image} onLoad={this.onImageLoad} onError={onCropError} hidden={true} />
      </>
    )
  }

  // This is used via refs
  // TODO: check the other ones that were removed
  // eslint-disable-next-line react/no-unused-class-component-methods
  setCropBox = (cropBoxEvent) => {
    if (this.cropper && this.cropper.current) {
      this.cropper.current.setCropBoxData(cropBoxEvent)
    }
  }

  private renderCropper = () => {
    const {
      image,
      aspect,
      renderFooter,
      hasStaticCropper,
      showControlForNoCrop = false,
      viewControlsBottomPosition
    } = this.props

    return (
      <>
        <StyledViewControls
          onZoomIn={() => this.zoom(ZOOM_IN)}
          onZoomOut={() => this.zoom(ZOOM_OUT)}
          onReset={this.reset}
          imageCroppingAvailable={true}
          onNoCrop={showControlForNoCrop ? this.goToMaxZoomOut : undefined}
          bottom={viewControlsBottomPosition}
        />
        <CropperContainer
          aspectRatio={aspect}
          src={image}
          ref={this.cropper}
          zoomTo={this.zoomValue}
          cropend={this.handleCropend}
          zoom={this.zoomChange}
          cropBoxMovable={!hasStaticCropper}
          ready={() => this.init()}
        />
        {renderFooter && renderFooter()}
      </>
    )
  }

  public render() {
    const { isImageLoaded } = this.state
    const { image } = this.props

    if (!image) {
      return null
    }

    return isImageLoaded ? this.renderCropper() : this.renderLoader()
  }
}
