import Button, { IButtonProps } from 'client/components/Button/Button'
import { forwardRef, Ref } from 'react'
import PlusSVG from 'client/assets/svg/icon/plus_20.svg'

export interface IPlusIconAddButtonProps extends IButtonProps {
  label: string
  type?: 'primary' | 'tertiary'
}
const PlusIconAddButtonComponent = (
  props: IPlusIconAddButtonProps,
  ref: Ref<HTMLButtonElement>
) => {
  const { label, type = 'tertiary', ...rest } = props
  return <Button type={type} ref={ref} svgComponent={PlusSVG} label={label} {...rest} />
}

const PlusIconAddButton = forwardRef(PlusIconAddButtonComponent)

export default PlusIconAddButton
