/* eslint-disable react-hooks/exhaustive-deps */
import { t } from 'client/i18n'
import { useCallback } from 'react'
import { useApolloClient } from '@apollo/client'
import { DialogOptions } from 'client/components/TranslationForm/util'
import gql from 'graphql-tag'
import { ExhibitionType } from 'shared/constants/exhibits'

const useValidateFeatured = (
  queryType: 'item' | 'exhibit',
  copyType: 'item' | `${ExhibitionType}`
) => {
  const apolloClient = useApolloClient()
  const isFeaturedQuery = `
  query ${queryType}IsFeatured($id: Int!) {
    ${queryType}(id: $id) {
      id
      isFeatured
    }
  }
  `

  const validateDeleteFeatured = useCallback(
    async ({ contentId }: { contentId?: number }) => {
      const { data } = await apolloClient.query({
        query: gql(isFeaturedQuery),
        variables: { id: contentId }
      })

      const {
        [queryType]: { isFeatured }
      } = data

      if (!isFeatured) {
        return
      }

      // eslint-disable-next-line docent/require-translation-keys-to-be-literals
      const bodyText = t(`This ${copyType} will also be removed from the Home tab.`)
      const options: DialogOptions = {
        body: <p>{bodyText}</p>,
        isBlocking: false
      }

      return options
    },
    [apolloClient]
  )

  return {
    validateDeleteFeatured
  }
}

export default useValidateFeatured
