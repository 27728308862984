import _ from 'lodash'
import MenuPopout, { IMenuOption } from 'client/components/MenuPopout/MenuPopout'
import { t } from 'client/i18n'
import styled from 'styled-components'

const BuildingHeaderSection = styled.div`
  display: flex;
  padding-top: 20px;
  padding-bottom: 28px;
  justify-content: space-between;
`

interface IBuildingHeader {
  name: string
  onEdit?: () => void
  onDelete?: () => void
}
const BuildingHeader = ({ name, onEdit = _.noop, onDelete = _.noop }: IBuildingHeader) => {
  const options: IMenuOption[] = [
    {
      label: t('Edit Building'),
      onClick: onEdit
    },
    {
      label: t('Delete Building'),
      onClick: onDelete
    }
  ]

  return (
    <BuildingHeaderSection>
      {name}
      <MenuPopout options={options} />
    </BuildingHeaderSection>
  )
}

export default BuildingHeader
