import ContextualHelp, {
  ContextualHelpTextSection
} from 'client/components/ContextualHelp/ContextualHelp'
import { t } from 'client/i18n'
import { Trans } from 'react-i18next'

const SubtitlesContextualHelp = () => (
  <ContextualHelp
    helpSize="large"
    header={t('Video Subtitles or Captions')}
    helpContent={
      <>
        <ContextualHelpTextSection>
          <Trans i18nKey="videoSubtitlesCaptionsHelp" />
        </ContextualHelpTextSection>
        <ContextualHelpTextSection subheader={t('Purpose')}>
          {t('Provide a real-time text version of the audio content in a video.')}
        </ContextualHelpTextSection>
        <ContextualHelpTextSection subheader={t('Primary Audience')}>
          <ul>
            <li>
              {t(
                '(Subtitles) People who can hear but are not proficient in the language; people who prefer to watch a video on mute while reading the spoken dialogue.'
              )}
            </li>
            <li>{t('(Captions) People who are deaf or hard of hearing.')}</li>
          </ul>
        </ContextualHelpTextSection>
        <ContextualHelpTextSection subheader={t('Experience in the Guide')}>
          <Trans i18nKey="videoSubtitlesCaptionsExperienceInTheGuide" />
        </ContextualHelpTextSection>
        <ContextualHelpTextSection subheader={t('Tips')}>
          <ul>
            <li>
              {t(
                'Captions should provide a text alternative to the audio of the video. They should convey both the spoken dialogue and essential non-dialogue information (e.g., laughter, music, sound effects).'
              )}
            </li>
          </ul>
        </ContextualHelpTextSection>
      </>
    }
    tooltipContent={t('More on how to use subtitles or captions')}
  />
)

export default SubtitlesContextualHelp
