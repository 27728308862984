import { GraphQLJSONObject } from 'graphql-type-json'
import {
  GraphQLInputObjectType,
  GraphQLString,
  GraphQLList,
  GraphQLBoolean,
  GraphQLNonNull
} from 'graphql'
import { FileInput } from './types'

const ImageTranslationInput = new GraphQLInputObjectType({
  name: 'ImageTranslationInput',
  fields: {
    localeCode: {
      type: GraphQLString
    },
    altText: {
      type: GraphQLString
    },
    caption: {
      type: GraphQLString
    }
  }
})

export const ImageInput = new GraphQLInputObjectType({
  name: 'ImageInput',
  fields: {
    translations: {
      type: new GraphQLList(new GraphQLNonNull(ImageTranslationInput))
    },
    file: {
      type: FileInput
    },
    cropState: {
      type: GraphQLJSONObject
    },
    isMarketingUseAllowed: {
      type: GraphQLBoolean
    }
  }
})
