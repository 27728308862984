import { GQLItem } from 'shared/graphql/types/graphql'
import HTMLText from 'client/components/HTMLText/HTMLText'
import { ItemType } from 'shared/constants/item'
import BaseGridView from 'client/components/BaseGridView/BaseGridView'
import { ITableCellProps } from 'client/dsm/Table/Table'
import gql from 'graphql-tag'
import _ from 'lodash'
import {
  ITEM_OVERFLOW_MENU_COLUMN,
  ITEM_EXHIBIT_IMAGE_COLUMN,
  LANGUAGES_COLUMN,
  LOOKUP_NUMBER_COLUMN,
  UPDATED_AT_COLUMN,
  VISIBILITY_COLUMN,
  ITEM_INCLUDED_IN_COLUMN
} from 'client/screens/Catalog/grids/shared/columns'
import { t } from 'client/i18n'
import { Header3 } from 'client/components/TextStyles'
import GridPlusIconAddButton from './shared/GridPlusIconAddButton'
import ItemForm from '../forms/ItemForm'
import { EmptyStatePlaceholderDescription } from './shared/styledComponents'

const scientificNameValue = ({ rowData }: ITableCellProps<GQLItem>) => (
  <HTMLText html={rowData.scientificName ?? ''} />
)

const BOTANICAL_ITEMS_GRAPHQL_QUERY = gql`
  query botanicalItemsForMuseum($museumId: Int!) {
    museum(id: $museumId) {
      id
      items {
        id
        uuid
        type
        published
        lookupNumber
        commonName
        scientificName
        externalId
        exhibits {
          id
          title
        }
        images {
          id
          hero
        }
        locales {
          code
          englishName
        }
        updatedAt
      }
    }
  }
`

const addBotanicalItemButton = (
  <GridPlusIconAddButton label={t('Add Botanical Item')} to="/catalog/botanicalItems/new" />
)

const BotanicalItemsEmptyPlaceholder = () => (
  <>
    <Header3>{t('Add your first botanical item')}</Header3>
    <EmptyStatePlaceholderDescription centerAlign={true}>
      <p>
        {t(
          'A botanical item has fields specific to living plants, flowers, and other botanical specimens.'
        )}
      </p>
      {addBotanicalItemButton}
    </EmptyStatePlaceholderDescription>
  </>
)

export default function BotanicalItems() {
  return (
    <BaseGridView
      contentName="Botanical Item"
      gqlQuery={BOTANICAL_ITEMS_GRAPHQL_QUERY}
      // Probably want to do this on the server in the future
      gqlFilterFunction={(item: GQLItem) => item.type === ItemType.BOTANICAL}
      columns={_.compact([
        ITEM_EXHIBIT_IMAGE_COLUMN,
        // Note, we're adding custom 150 min width, to not wrap the header column into two lines.
        // These will go away once we implement proper scrolling in our grids so that we're not
        // constraint to the viewport size
        { dataKey: 'commonName', label: t('Common Name'), minWidth: 150 },
        {
          dataKey: 'scientificName',
          label: t('Scientific Name'),
          cellRenderer: scientificNameValue,
          minWidth: 150
        },
        LOOKUP_NUMBER_COLUMN,
        ITEM_INCLUDED_IN_COLUMN,
        VISIBILITY_COLUMN,
        LANGUAGES_COLUMN,
        UPDATED_AT_COLUMN,
        ITEM_OVERFLOW_MENU_COLUMN
      ])}
      filterCriteria={['lookupNumber', 'commonName', 'scientificName', 'exhibits.title']}
      buttons={addBotanicalItemButton}
      formComponent={() => <ItemForm type={ItemType.BOTANICAL} />}
      placeholder={<BotanicalItemsEmptyPlaceholder />}
    />
  )
}
