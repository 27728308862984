import { useState } from 'react'
import _ from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { isLoggedIn } from 'client/redux/selectors/auth'
import { logout as logoutAction } from 'client/redux/actions/auth'
import styled from 'styled-components'
import { Dialog, DialogHeader, DialogBody, DialogActions } from 'client/dsm/Dialog/Dialog'
import MenuPopout, {
  DIVIDER_MENU_OPTION,
  IMenuOption
} from 'client/components/MenuPopout/MenuPopout'
import ArrowDownSVG from 'client/assets/svg/icon/chevron_20_down.svg'
import Button from 'client/components/Button/Button'
import PrivacyNoticeContent from 'client/components/PrivacyNoticeContent/PrivacyNoticeContent'
import { HELP_CENTER_URL } from 'client/constants'
import { t } from 'client/i18n'
import { useFeatureFlags } from 'client/hooks/useFeatureFlags'
import { Body1CSS } from 'client/components/TextStyles'
import LanguageSelector from 'client/screens/App/Header/LanguageSelector'
import UserProfileForm from 'client/screens/UserProfileForm'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'

const Container = styled.div`
  ${Body1CSS};
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
`

const MenuSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  > * + * {
    padding-left: 24px;
  }
  && > * {
    color: var(--color-grey-03);
    svg {
      color: var(--color-grey-03);
    }
    :hover {
      color: var(--color-white);
      svg {
        color: var(--color-white);
      }
    }
  }
`

const UserNameMenuOption = styled.div`
  ${Body1CSS};
  *:nth-child(1) {
    color: var(--color-black);
    font-weight: var(--font-weight-semi-bold);
  }

  *:nth-child(2) {
    color: var(--color-grey-07);
  }
`

const PrivacyContent = styled(PrivacyNoticeContent)`
  overflow: auto;
  height: 342px;
  padding-right: 24px;
`

interface PrivacyNoticeDialogProps {
  onClose: () => void
}
const PrivacyNoticeDialog = ({ onClose }: PrivacyNoticeDialogProps) => (
  <Dialog scrollable={true}>
    <DialogHeader>{t('Privacy Notice')}</DialogHeader>
    <DialogBody>
      <PrivacyContent />
    </DialogBody>
    <DialogActions>
      <Button onClick={onClose} type="primary" label={t('Close')} />
    </DialogActions>
  </Dialog>
)

const USER_QUERY = gql`
  query user {
    user {
      id
      email
      firstName
      lastName
      language
    }
  }
`

const MenuControls = () => {
  const [showPrivacyNotice, setShowPrivacyNotice] = useState(false)
  const [showUserProfileForm, setShowUserProfileForm] = useState(false)
  const { data } = useQuery(USER_QUERY)
  const user = data?.user

  const dispatch = useDispatch()
  const loggedIn = useSelector(isLoggedIn)
  const { LANGUAGE_SELECTION } = useFeatureFlags()

  if (!loggedIn) {
    return null
  }

  const helpOptions: IMenuOption[] = [
    {
      label: t('Help Center'),
      content: (
        <a href={HELP_CENTER_URL} target="_blank" rel="noreferrer">
          {t('CMS Help Center')}
        </a>
      )
    },
    {
      label: t('Privacy Notice'),
      onClick: () => setShowPrivacyNotice(true)
    }
  ]

  const profileOptions: IMenuOption[] = [
    {
      label: t('User Info'),
      content: (
        <UserNameMenuOption>
          <div>{user && [user.firstName, user.lastName].join(' ')}</div>
          <div>{user?.email}</div>
        </UserNameMenuOption>
      ),
      disabled: true
    },
    DIVIDER_MENU_OPTION,
    { label: t('Edit Profile'), onClick: () => setShowUserProfileForm(true) },
    { label: t('Sign Out'), onClick: () => dispatch(logoutAction()) }
  ]

  return (
    <Container>
      <MenuSection>
        {LANGUAGE_SELECTION && <LanguageSelector />}
        <MenuPopout options={helpOptions} placement="bottom-end">
          {t('Help')}
          <ArrowDownSVG />
        </MenuPopout>
        <MenuPopout options={profileOptions} placement="bottom-end">
          {user && _.upperFirst(user.firstName)}
          <ArrowDownSVG />
        </MenuPopout>
      </MenuSection>
      {user && showUserProfileForm && (
        <UserProfileForm
          userId={user.id}
          email={user.email}
          firstName={user.firstName}
          lastName={user.lastName}
          language={user.language}
          onClose={() => setShowUserProfileForm(false)}
        />
      )}
      {showPrivacyNotice && <PrivacyNoticeDialog onClose={() => setShowPrivacyNotice(false)} />}
    </Container>
  )
}

export default MenuControls
