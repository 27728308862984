import { useState } from 'react'
import * as React from 'react'
import styled from 'styled-components'
import Draggable from 'react-draggable'
import _ from 'lodash'
import { ICoordinates } from 'shared/util/maps'
import { MAP_LOCATION_DEFAULT_RADIUS } from 'shared/constants/maps'
import PinIconSVGElement from 'client/assets/svg/icon/ic_map_pin.svg'
import PinIconActiveSVGElement from 'client/assets/svg/icon/ic_map_pin_active.svg'
import Resizable from 'client/screens/AppEditor/MapEditor/MapLocation/Resizable'
import DocentTippy from 'client/dsm/Tooltip/DocentTippy'
import { ThemeType } from 'client/types'

const PIN_ICON_WIDTH = 40
const PIN_ICON_HEIGHT = 40

// NOTE: top and left sides have a negative sign to indicate that origin (0,0) is
// at the top of container
interface IBounds {
  top: number
  bottom: number
  left: number
  right: number
}
const getSidesOffBoundsWithRadius = (areaRadius: number, bounds: IBounds) => {
  const offBounds = _.pickBy(bounds, (side) => {
    return Math.abs(side) <= areaRadius
  })

  return _.isEmpty(offBounds) ? null : offBounds
}

interface IDeltas {
  deltaX: number
  deltaY: number
}
const getDeltasOffBoundsWithRadius = (
  areaRadius: number,
  sidesOffBounds: Partial<IBounds>
): IDeltas => {
  const sideNames = Object.keys(sidesOffBounds)

  let deltaY = 0
  let deltaX = 0

  if (sideNames.includes('top')) {
    deltaY = areaRadius - Math.abs(sidesOffBounds.top!)
  }

  if (sideNames.includes('bottom')) {
    deltaY = Math.abs(sidesOffBounds.bottom!) - areaRadius
  }

  if (sideNames.includes('left')) {
    deltaX = areaRadius - Math.abs(sidesOffBounds.left!)
  }

  if (sideNames.includes('right')) {
    deltaX = Math.abs(sidesOffBounds.right!) - areaRadius
  }

  return { deltaX, deltaY }
}

export interface IDragEndEventProps {
  id: number
  coordinates: ICoordinates
}

const DraggableContainer = styled.div`
  position: absolute;

  /** Necessary to assure react draggable picks up any mouse events before other.
      Without it, we aren't able to preventDefault to stop parent from mistakenly pickup click handler
      on its regular element(ex to dismiss selection on outside <MapLocation> clicked.
  */
  .react-draggable {
    z-index: 1;
  }
`

interface IPinIcon {
  show: boolean
  offset: ICoordinates
}

const PinIcon = styled.div<IPinIcon>`
  position: absolute;
  left: ${({ offset: { x = 0 } }) => x - PIN_ICON_WIDTH / 2}px;
  top: ${({ offset: { y = 0 } }) => y - PIN_ICON_HEIGHT}px;
  width: ${PIN_ICON_WIDTH}px;
  height: ${PIN_ICON_HEIGHT}px;
  visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
  svg {
    width: ${PIN_ICON_WIDTH}px;
    height: ${PIN_ICON_HEIGHT}px;
  }
`

interface IPinArea {
  isSelected?: boolean
  isMouseOver?: boolean
}
const PinArea = styled.div<IPinArea>`
  position: absolute;
  border-radius: 900px;
  background-color: ${({ isSelected, isMouseOver }) => {
    if (isSelected) {
      return 'rgba(36, 117, 212, 0.7)'
    }
    if (isMouseOver) {
      return 'rgba(80, 7, 120, 0.5)'
    }
    return 'rgba(255, 65, 92, .5)'
  }};
  border: ${({ isSelected, isMouseOver }) => {
    if (isSelected) {
      return '1px solid #2275d3'
    }
    if (isMouseOver) {
      return '1px solid var(--color-brand)'
    }
    return '1px solid #ff415c'
  }};

  &:active:hover {
    cursor: grabbing;
  }

  &:hover {
    cursor: pointer;
  }
`

const PinContainer = styled.div`
  &:hover {
    cursor: pointer;
  }

  &:active:hover {
    cursor: grabbing;
  }
`

export interface IMapLocation {
  id: number
  isSelected: boolean
  x: number
  y: number
  diameter?: number
  bounds: {
    top: number
    right: number
    bottom: number
    left: number
  }
  onSelected?: (id: number) => void
  onDragEnd?: ({ id, coordinates }: { id: number; coordinates: ICoordinates }) => void
  onMapAreaResized?: (currentRadius: number, coordinates?: ICoordinates) => void
  onToggleMapArea?: (enabled: boolean, coordinates?: ICoordinates) => void
  mapContainer: React.RefObject<HTMLDivElement>
  tooltipElement: React.ElementType
  pinIcon?: React.ElementType
  pinIconActive?: React.ElementType
  pinPositionOffset?: ICoordinates
}

/**
 * Renders Map pin area plus Pin.
 * Note renders them at the same level(hence <>) due to requirement of having the
 * pin be above all other map pin area. In order for that to be possible, we need
 * the pin to be the same level as the area.
 */
const MapLocation: React.FC<IMapLocation> = React.memo((props: IMapLocation) => {
  const {
    id,
    isSelected = false,
    diameter = 0,
    x,
    y,
    bounds,
    onMapAreaResized = _.noop,
    onToggleMapArea = _.noop,
    onSelected = _.noop,
    onDragEnd = _.noop,
    tooltipElement: TooltipElement,
    pinIcon: PinIconSVG = PinIconSVGElement,
    pinIconActive: PinIconActiveSVG = PinIconActiveSVGElement,
    pinPositionOffset = { x: 0, y: 0 }
  } = props

  const [isDragging, setDragging] = useState<boolean>(false)
  const [draggedDeltaX, setDraggedDeltaX] = useState<number>(0)
  const [draggedDeltaY, setDraggedDeltaY] = useState<number>(0)
  const [draggedDeltaDiameter, setDraggedDeltaDiameter] = useState<number>(0)
  const [isMouseOverLocation, setIsMouseOverLocation] = useState<boolean>(false)

  const currentDiameter = Math.round(diameter + draggedDeltaDiameter)
  const currentRadius = currentDiameter / 2
  const hasPinArea = Math.floor(currentDiameter) > 0

  const draggableBounds = {
    top: bounds.top + currentRadius,
    bottom: bounds.bottom - currentRadius,
    left: bounds.left + currentRadius,
    right: bounds.right - currentRadius
  }

  const handleOnToggleArea = () => {
    if (hasPinArea) {
      return onToggleMapArea(false)
    }

    const sidesOffBounds = getSidesOffBoundsWithRadius(MAP_LOCATION_DEFAULT_RADIUS, draggableBounds)

    if (sidesOffBounds) {
      // when adding area, we need to shift the selected pin if area goes off bounds
      const { deltaX, deltaY } = getDeltasOffBoundsWithRadius(
        MAP_LOCATION_DEFAULT_RADIUS,
        sidesOffBounds
      )
      return onToggleMapArea(true, { x: x + deltaX, y: y + deltaY })
    }
    return onToggleMapArea(true)
  }

  const handleOnDrag = (e, data) => {
    const { x: dx, y: dy }: ICoordinates = data
    setDraggedDeltaX(dx)
    setDraggedDeltaY(dy)
    setDragging(true)
  }

  const handleOnDragEnd = (e, data: ICoordinates) => {
    const changeX = Math.round(data.x)
    const changeY = Math.round(data.y)

    setDragging(false)
    onSelected(id)

    if (changeX || changeY) {
      const coordinates = { x: x + changeX, y: y + changeY }
      setDraggedDeltaX(0)
      setDraggedDeltaY(0)
      onDragEnd({ id, coordinates })
    }
  }

  const handleResize = (event, { size }) => {
    const delta = size.height - diameter
    const sidesOnMapEdge = getSidesOffBoundsWithRadius(currentRadius, bounds)

    if (sidesOnMapEdge && delta > 0) {
      // stop ui updates if area is increasing outside of bounds
      return
    }

    setDraggedDeltaDiameter(delta)
  }

  const handleOnResizeEnd = () => {
    onSelected(id)
    setDraggedDeltaDiameter(0)
    onMapAreaResized(currentRadius)
  }

  // We are manually controlling the position in order
  // to allow mirroring the pin and the pin area position.
  const controlledDraggableProps = {
    bounds: draggableBounds,
    position: {
      x: draggedDeltaX,
      y: draggedDeltaY
    },
    onMouseDown: () => onSelected(id),
    onStop: handleOnDragEnd,
    onDrag: handleOnDrag
  }

  // We need to control the hover states due to
  // Area and Pin being sibling below which means we need
  // to sync the hover states to fit design requirement.
  const controlledHoverableProps = {
    onMouseEnter: () => setIsMouseOverLocation(true),
    onMouseLeave: () => setIsMouseOverLocation(false),
    // Prevent default to avoid click propagation
    // Need this so parent doesn't dismiss selection inadvertently
    onClick: (e: React.MouseEvent) => e.stopPropagation()
  }

  const pinAreaStyle = {
    width: `${currentDiameter}px`,
    height: `${currentDiameter}px`,
    top: `-${currentDiameter / 2}px`,
    left: `-${currentDiameter / 2}px`
  }

  const toolTipContent = (
    <TooltipElement
      onToggleBubble={handleOnToggleArea}
      pinHeight={PIN_ICON_HEIGHT}
      pinBubbleDiameter={hasPinArea ? currentDiameter : 0}
      isShowingBubble={hasPinArea}
      boundary={props.mapContainer}
      locationId={id}
    />
  )

  return (
    <>
      {hasPinArea && (
        <DraggableContainer
          style={{
            left: `${x}px`,
            top: `${y}px`,
            zIndex: isSelected ? 1 : 0
          }}
          key={`location-area-${id}`}
          {...controlledHoverableProps}
        >
          <Draggable {...controlledDraggableProps}>
            <PinArea
              style={pinAreaStyle}
              isSelected={isSelected}
              isMouseOver={isMouseOverLocation}
            />
          </Draggable>
          {isSelected && currentDiameter > 0 && !isDragging && (
            <Resizable
              width={currentDiameter}
              height={currentDiameter}
              onResize={handleResize}
              onResizeEnd={handleOnResizeEnd}
              style={pinAreaStyle}
            />
          )}
        </DraggableContainer>
      )}

      {/* Note, we need to render pin at the same level as pin area in order to
          have its z-index be higher than all of the area. Else it wont work. */}
      <DocentTippy
        content={toolTipContent}
        trigger="click"
        interactive={true}
        size="medium"
        zIndex={9999}
        themeType={ThemeType.LIGHT}
        offset={[0, 40]}
        // add appenTo document.body to avoid console warning
        appendTo={document.body}
      >
        <DraggableContainer
          style={{ left: `${x}px`, top: `${y}px`, zIndex: 2 }}
          key={`location-pin-${id}`}
          {...controlledHoverableProps}
        >
          <Draggable {...controlledDraggableProps}>
            <PinContainer>
              {/* Note: We're explicitly rendering both pin but only showing one. We're doing this due to race condition between
                      react internals updates vs mouse event captures. If we only conditionally render one pin depending on
                      the boolean clause, then there exists a chance where mouseLeave is never called for fast mouse movement.
                      Conditionally rendering one would cause removing the element from dom, which seems to expose the race
                      condition of react updating to reflect the new render vs capturing the current fast mouse movement.
                      Due to mouseLeave never being called for the above case, it leaves pins in a false hover state.
                      That issue happened intermittently. */}
              <PinIcon show={isSelected || isMouseOverLocation} offset={pinPositionOffset}>
                <PinIconActiveSVG />
              </PinIcon>
              <PinIcon show={!(isSelected || isMouseOverLocation)} offset={pinPositionOffset}>
                <PinIconSVG />
              </PinIcon>
            </PinContainer>
          </Draggable>
        </DraggableContainer>
      </DocentTippy>
    </>
  )
})

export default MapLocation
