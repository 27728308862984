import { Body1CSS, Header2CSS } from 'client/components/TextStyles'
import { ReactNode } from 'react'
import styled from 'styled-components'

const Header = styled.header<{ disabled: boolean }>`
  margin-bottom: var(--spacing-large);
  color: ${({ disabled }) => (disabled ? 'var(--color-grey-04)' : 'var(--color-black)')};
`

export const HeaderTitle = styled.div`
  ${Header2CSS}
  margin: 0;
`

const NoteContainer = styled.div`
  margin-top: var(--spacing-small);
`

export const NoteText = styled.div`
  ${Body1CSS};
  margin: 0;
`

interface IProps {
  title?: string
  note?: ReactNode
  disabled?: boolean
}

export default function Section(props: IProps) {
  const { title, note, disabled = false } = props

  return (
    <Header disabled={disabled}>
      <HeaderTitle>{title}</HeaderTitle>
      {note && (
        <NoteContainer>
          <NoteText>{note}</NoteText>
        </NoteContainer>
      )}
    </Header>
  )
}
