import { useState } from 'react'
import Tooltip, { ITooltipProps } from 'client/dsm/Tooltip/Tooltip'
import QuestionSVG from 'client/assets/svg/icon/question_20_circled.svg'
import IconOnlyButton from 'client/components/IconButton/IconOnlyButton'
import { ThemeType } from 'client/types'
import {
  ContextualHelpContentBlock,
  ContextualHelpSubheader,
  ContextualHelpHeader,
  ContextualHelpContentContainer
} from 'client/components/ContextualHelp/styledComponents'
import DocentTippy, { IDocentTippyProps } from 'client/dsm/Tooltip/DocentTippy'

interface IContextualHelpTextSectionProps {
  subheader?: string | null
  children: React.ReactNode
}
export const ContextualHelpTextSection = ({
  subheader,
  children
}: IContextualHelpTextSectionProps) => (
  <ContextualHelpContentBlock>
    {subheader && <ContextualHelpSubheader>{subheader}</ContextualHelpSubheader>}
    {children}
  </ContextualHelpContentBlock>
)

interface IContextualHelpContentWrapper {
  header: string
  children: React.ReactNode
}
const ContextualHelpContentWrapper = ({ header, children }: IContextualHelpContentWrapper) => (
  <ContextualHelpContentContainer>
    <ContextualHelpHeader>{header}</ContextualHelpHeader>
    {children}
  </ContextualHelpContentContainer>
)

interface ContextualHelpProps {
  buttonThemeType?: ThemeType
  tooltipPlacement?: ITooltipProps['placement']
  tooltipContent: ITooltipProps['content']
  tooltipThemeType?: ThemeType
  helpPlacement?: IDocentTippyProps['placement']
  helpContent?: IDocentTippyProps['content']
  helpSize?: IDocentTippyProps['size']
  helpAppendTo?: IDocentTippyProps['appendTo']
  header: string
  // Temporary allow overriding for LeftNavContextualHelp.
  // It is positioned to be removed in favor of HelpScout.
  helpMaxWidthRestrictedProp?: number
}

const ContextualHelp = ({
  buttonThemeType,
  tooltipContent,
  tooltipPlacement,
  tooltipThemeType,
  helpContent,
  // Placement of ContextualHelp should default to right unless specified.
  helpPlacement = 'right',
  helpSize,
  helpAppendTo,
  header,
  helpMaxWidthRestrictedProp = 375
}: ContextualHelpProps) => {
  const [selected, setSelected] = useState(false)

  const content = (
    <ContextualHelpContentWrapper header={header}>{helpContent}</ContextualHelpContentWrapper>
  )

  return (
    <DocentTippy
      content={content}
      placement={helpPlacement}
      trigger="click"
      // MaxWidth of ContextualHelp should default to 375.
      maxWidth={helpMaxWidthRestrictedProp}
      size={helpSize}
      interactive={true}
      onShow={() => setSelected(true)}
      onHide={() => setSelected(false)}
      themeType={ThemeType.LIGHT}
      appendTo={helpAppendTo}
    >
      {/* Wrapper divs to prevent tippy `forwardRef` errors in JS console */}
      <div>
        <Tooltip content={tooltipContent} placement={tooltipPlacement} themeType={tooltipThemeType}>
          <div>
            <IconOnlyButton
              svgComponent={QuestionSVG}
              selected={selected}
              theme={buttonThemeType}
            />
          </div>
        </Tooltip>
      </div>
    </DocentTippy>
  )
}

export default ContextualHelp
