import { Label1CSS } from 'client/components/TextStyles'
import styled from 'styled-components'

export const MessageBody = styled.ul`
  max-height: 300px;
  overflow: auto;
  padding: 2px;
  margin: 0;
  padding-inline-start: 25px;
  white-space: normal;
`

export const PreviewContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: start;
  margin-top: var(--spacing-xsmall);
  margin-bottom: var(--spacing-small);

  > :first-child {
    margin-right: var(--spacing-xsmall);
  }
`

export const PreviewHoverLabel = styled.div`
  ${Label1CSS};
  display: flex;
  justify-content: center;
  position: absolute;
  z-index: 1;
  top: 50%;
  width: 100%;
  margin-top: -10px;
  color: var(--color-white);
  opacity: 0;
  transition: opacity ease 0.2s;
  svg {
    margin-left: 8px;
    margin-right: -8px;
    color: currentColor;
  }
`

export const PreviewImages = styled.div`
  display: flex;
  flex-grow: 0;
`

export const CanvasImage = styled.canvas`
  display: flex;
  transition: opacity ease 0.2s;
  background-color: var(--color-grey-01);
`

export const PreviewImageContainer = styled.div`
  position: relative;
  background: var(--color-black);
  cursor: pointer;
  &:hover {
    ${CanvasImage} {
      opacity: 0.4;
    }
    ${PreviewHoverLabel} {
      opacity: 1;
    }
  }

  :first-of-type {
    margin-right: var(--spacing-small);
  }
`
