export interface IImageDimensions {
  width: number
  height: number
}

export enum ImageType {
  SQUASHED = 'squashed',
  FULL = 'full',
  HERO = 'hero',
  THUMBNAIL = 'thumbnail'
}

export enum ImageFormat {
  PNG = 'png',
  JPEG = 'jpeg'
}

export const ImageInfo = {
  [ImageType.HERO]: {
    type: ImageType.HERO,
    label: 'Hero',
    dimensions: { width: 1280, height: 720 },
    aspectRatio: 16 / 9
  },
  [ImageType.THUMBNAIL]: {
    type: ImageType.THUMBNAIL,
    label: 'Thumbnail',
    dimensions: { width: 315, height: 315 },
    aspectRatio: 1 / 1
  }
}

export enum MuseumImageType {
  // handled by `POST /:type`
  EXPLORE_HERO = 'exploreHero', // Organization Image
  EXPLORE_ICON = 'exploreIcon', // Explore Image / Square Logo
  MUSEUM_LOGO = 'museumLogo', // Home / Rectangular Logo

  // Handled via splash router
  SPLASH_LOGO = 'splashLogo',
  SPLASH_BACKGROUND = 'splashBackground'
}
