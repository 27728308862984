import _ from 'lodash'
import BaseGridView from 'client/components/BaseGridView/BaseGridView'
import gql from 'graphql-tag'
import GridPlusIconAddButton from 'client/screens/Catalog/grids/shared/GridPlusIconAddButton'
import {
  ITEM_EXHIBIT_IMAGE_COLUMN,
  LANGUAGES_COLUMN,
  LOOKUP_NUMBER_COLUMN,
  UPDATED_AT_COLUMN,
  VISIBILITY_COLUMN,
  EXHIBIT_OVERFLOW_MENU_COLUMN
} from 'client/screens/Catalog/grids/shared/columns'
import { GQLExhibit } from 'shared/graphql/types/graphql'
import { ExhibitionType } from 'shared/constants/exhibits'
import { t } from 'client/i18n'
import WarningIcon from 'client/assets/svg/icon/status_warning_20.svg'
import { LeftGutterColumnPropsWrapper } from 'client/screens/Catalog/grids/shared/LeftGutterWrapper'
import { ITableCellProps } from 'client/dsm/Table/Table'
import Tooltip from 'client/dsm/Tooltip/Tooltip'
import { Header3 } from 'client/components/TextStyles'
import EmptyStateGridMapSVG from 'client/assets/svg/emptyStates/empty_state_grid_map.svg'
import TourForm from '../forms/TourForm'
import { EmptyStatePlaceholderDescription } from './shared/styledComponents'

const EXHIBITS_GRAPHQL_QUERY = gql`
  query exhibitsForMuseum($museumId: Int!) {
    museum(id: $museumId) {
      id
      exhibits {
        id
        type
        estimatedDuration
        uuid
        published
        lookupNumber
        title
        externalId
        images {
          hero
        }
        numItemsWithoutMapLocations
        locales {
          code
          englishName
        }
        updatedAt
      }
    }
  }
`

function WarningIconComponent(props: ITableCellProps<GQLExhibit>) {
  const { rowData: exhibit } = props
  const { numItemsWithoutMapLocations } = exhibit
  if (numItemsWithoutMapLocations > 0) {
    return (
      <Tooltip
        content={t('tourItemsMissingMapLocationsTooltipMessage', {
          count: numItemsWithoutMapLocations
        })}
        placement="right"
      >
        <div>
          <WarningIcon />
        </div>
      </Tooltip>
    )
  }
}

const addTourButton = <GridPlusIconAddButton label={t('Add Tour')} to="/catalog/tours/new" />

const ToursEmptyPlaceholder = () => (
  <>
    <Header3>{t('Add your first tour')}</Header3>
    <EmptyStatePlaceholderDescription>
      <p>
        {t(
          'A tour guides visitors on a curated path through a group of items in your physical space, like:'
        )}
      </p>
      <ul>
        <li>{t('Highlights from your permanent collection')}</li>
        <li>{t('A thematic story that unfolds one item at a time')}</li>
        <li>{t('A walk along a nature trail or through a garden')}</li>
        {addTourButton}
      </ul>
    </EmptyStatePlaceholderDescription>
  </>
)

export default function Tours() {
  return (
    <BaseGridView
      contentName="Tour"
      gqlQuery={EXHIBITS_GRAPHQL_QUERY}
      // Probably want to do this on the server in the future
      gqlFilterFunction={(exhibit: GQLExhibit) => exhibit.type === ExhibitionType.TOUR}
      columns={_.compact([
        LeftGutterColumnPropsWrapper(ITEM_EXHIBIT_IMAGE_COLUMN, WarningIconComponent),
        { dataKey: 'title', label: t('Title') },
        LOOKUP_NUMBER_COLUMN,
        VISIBILITY_COLUMN,
        LANGUAGES_COLUMN,
        UPDATED_AT_COLUMN,
        EXHIBIT_OVERFLOW_MENU_COLUMN
      ])}
      filterCriteria={['lookupNumber', 'title', 'information']}
      buttons={addTourButton}
      formComponent={TourForm}
      placeholder={<ToursEmptyPlaceholder />}
      placeholderIcon={<EmptyStateGridMapSVG />}
    />
  )
}
