import _ from 'lodash'
import { useQuery } from '@apollo/client'
import { Routes, Route, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import gql from 'graphql-tag'
import Button from 'client/components/Button/Button'
import PageContent from 'client/components/PageContent/PageContent'
import LoadingOverlay from 'client/components/LoadingOverlay/LoadingOverlay'
import PlusIconAddButton from 'client/components/Button/PlusIconAddButton'
import { GQLFeature } from 'shared/graphql/types/graphql'
import { EmptyView } from 'client/components/PageContent/EmptyView'
import ReorderSectionsForm from 'client/screens/AppEditor/Home/ReorderFeaturesForm'
import FeatureForm from 'client/screens/AppEditor/Home/FeatureForm'
import FeatureSection from 'client/screens/AppEditor/Home/FeatureSection'
import ItemForm from 'client/screens/Catalog/forms/ItemForm'
import ExhibitForm from 'client/screens/Catalog/forms/ExhibitForm'
import EventForm from 'client/screens/Catalog/forms/EventForm'
import TourForm from 'client/screens/Catalog/forms/TourForm'
import { ItemType } from 'shared/constants/item'
import { t } from 'client/i18n'
import { Header3 } from 'client/components/TextStyles'
import { EmptyStatePlaceholderDescription } from 'client/screens/Catalog/grids/shared/styledComponents'

const FEATURES_GRAPHQL_QUERY = gql`
  query features($museumId: Int!) {
    museum(id: $museumId) {
      id
      features {
        id
        title
        locales {
          code
          englishName
        }
        featuredItems {
          id
          item {
            id
            date
            uuid
            type
            title
            creators {
              id
              name
            }
            commonName
            scientificName
            images {
              id
              hero
            }
            locales {
              id
              code
              englishName
            }
          }
          exhibit {
            id
            type
            uuid
            title
            displayPeriod
            from
            to
            timezone
            isAllDay
            images {
              id
              hero
            }
            locales {
              id
              code
              englishName
            }
          }
        }
      }
    }
  }
`

const HomeContentContainer = styled.div`
  padding: var(--container-padding);
  overflow: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
`

const FeaturedSection = styled.div`
  height: 100%;
`

const ButtonContainer = styled.div`
  display: flex;
  > * + * {
    margin-left: 16px;
  }
`
const AddSectionButton = () => {
  const navigate = useNavigate()

  return (
    <PlusIconAddButton
      type="primary"
      label={t('Add Section')}
      onClick={() => navigate('features/new')}
    />
  )
}

interface HomeControlsProps {
  reorderable: boolean
}
const HomeControls = ({ reorderable }: HomeControlsProps) => {
  const navigate = useNavigate()

  return (
    <ButtonContainer>
      <Button
        onClick={() => navigate('reorder')}
        disabled={!reorderable}
        label={t('Reorder Sections')}
      />
      <AddSectionButton />
    </ButtonContainer>
  )
}

export default function Home() {
  const { loading, data } = useQuery(FEATURES_GRAPHQL_QUERY)

  const features: GQLFeature[] = _.get(data, 'museum.features')

  const hasFeatures = _.size(features) > 0
  const allowReordering = _.size(features) > 1

  const content = loading ? (
    <LoadingOverlay />
  ) : (
    features.map((feature) => <FeatureSection feature={feature} key={feature.id} />)
  )

  const message = (
    <>
      <Header3>{t('Organize your guide’s home screen')}</Header3>
      <EmptyStatePlaceholderDescription centerAlign={true}>
        {t(
          'Start by adding a section. For example: Welcome/About Us, Visitor Information, Current Exhibitions, Past Exhibitions, Get Involved.'
        )}
        <AddSectionButton />
      </EmptyStatePlaceholderDescription>
    </>
  )
  return (
    <PageContent
      title={t('Home')}
      controls={hasFeatures && <HomeControls reorderable={allowReordering} />}
    >
      <Routes>
        <Route path="reorder" element={<ReorderSectionsForm />} />
        <Route path="features/new" element={<FeatureForm />} />
        <Route path="features/:featureId/edit" element={<FeatureForm />} />
        <Route path="exhibitions/:id" element={<ExhibitForm />} />
        <Route path="tours/:id" element={<TourForm />} />
        <Route path="events/:id" element={<EventForm />} />
        <Route path="items/:id" element={<ItemForm type={ItemType.ITEM} />} />
        <Route path="botanicalItems/:id" element={<ItemForm type={ItemType.BOTANICAL} />} />
      </Routes>
      <HomeContentContainer>
        {hasFeatures ? (
          <FeaturedSection>{content}</FeaturedSection>
        ) : (
          <EmptyView message={message} />
        )}
      </HomeContentContainer>
    </PageContent>
  )
}
