import styled from 'styled-components'
import {
  SplashPreviewConnectsLogoWidth,
  DefaultBackgroundColor,
  PreviewDimensions
} from 'shared/constants/splash'
import { Header1CSS } from 'client/components/TextStyles'

// The museum logo is 15% down from the top of the screen
// The museum logo is centered, 65% of the total width of the screen
// The museum logo height is 30% of the background image height
// The bottom connects logo container is 15% of the total screen height
export const LogoContainer = styled.div`
  position: absolute;
  top: 15%;
  width: 65%;
  bottom: 55%;
`

export const LogoImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`

export const BackgroundAndLogoContainer = styled.div`
  height: 85%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`

export const ConnectsLogoContainer = styled.div`
  height: 15%;
  width: 100%;
  background-color: var(--color-white);
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: ${SplashPreviewConnectsLogoWidth};
    height: auto;
  }
`

export const BackgroundImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const LogoTitle = styled.p`
  ${Header1CSS};
  text-align: center;
  margin: 0px;
`

export const OuterContainer = styled.div<{ shouldShowDefault: boolean }>`
  height: 100%;
  aspect-ratio: ${PreviewDimensions.width / PreviewDimensions.height};
  box-shadow: var(--elevation-03);
  background-color: ${({ shouldShowDefault }) => shouldShowDefault && DefaultBackgroundColor.HEX};
`
