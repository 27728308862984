import { ChangeEvent, useRef } from 'react'
import styled from 'styled-components'
import Button from 'client/components/Button/Button'
import PlusSVG from 'client/assets/svg/icon/plus_20.svg'
import IconOnlyButton from 'client/components/IconButton/IconOnlyButton'

const Container = styled.div`
  display: flex;
`

const Label = styled.label`
  margin: 0;
`

const PlusIconButton = styled(IconOnlyButton)`
  margin-right: var(--spacing-small);

  && svg {
    color: var(--color-white);
  }

  &:hover:enabled,
  &:active:hover {
    svg {
      color: var(--color-white);
    }
  }
`

export type FileUploadChangeEvent = ChangeEvent<HTMLInputElement>
interface IFileUploaderProps {
  name: string
  onChange: (event: FileUploadChangeEvent) => void
  buttonLabel?: string
  id?: string
  fileFormats?: string[]
  className?: string
  svgComponent?: any
  multiple?: boolean
}
export default function FileUploader(props: IFileUploaderProps) {
  const {
    id,
    fileFormats,
    name,
    className,
    svgComponent: SVGComponent = PlusSVG,
    onChange,
    buttonLabel,
    multiple = false
  } = props
  const fileInput = useRef<HTMLInputElement>(null)

  const handleClick = () => {
    fileInput.current!.click()
  }

  const accept = fileFormats && fileFormats.length ? fileFormats.join(',') : '*'
  const addButton = (
    <Label htmlFor={id}>
      {buttonLabel ? (
        <Button
          type="tertiary"
          onClick={handleClick}
          svgComponent={SVGComponent}
          label={buttonLabel}
        />
      ) : (
        <PlusIconButton svgComponent={PlusSVG} onClick={handleClick} />
      )}
    </Label>
  )

  return (
    <Container className={className}>
      {addButton}
      <input
        name={name}
        id={id}
        type="file"
        accept={accept}
        ref={fileInput}
        onChange={onChange}
        onClick={() => {
          fileInput.current!.value = '' // TODO is this equivalent? Why do we need to set this value at all?
        }}
        hidden={true}
        multiple={multiple}
      />
    </Container>
  )
}
