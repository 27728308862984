import styled from 'styled-components'

export const SubItemContainer = styled.div<{ isSelected: boolean }>`
  height: 100%;
  padding: 14px 24px;
  ${({ isSelected }) => (isSelected ? 'background-color: var(--color-grey-09);' : '')}
`

export const SelectedItemIcon = styled.div`
  position: absolute;
  left: 0;
  top: 0;
`

export const ItemContainer = styled.div<{ isDisabled: boolean }>`
  height: 100%;
  border-bottom: 1px solid var(--color-grey-09);
  cursor: pointer;
  ${({ isDisabled }) => (isDisabled ? 'opacity: 0.5;' : '')}
`

export const ItemInPicker = styled.div`
  position: relative;
  overflow: auto;
  height: 100%;
`

export const PickerWrapper = styled.div`
  margin-top: var(--spacing-small);
  position: relative;
`
