// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".KPMFURYtVMytzeKlJel4 .uSFofT9IMA1F5h8yeeeU {\n  background-color: var(--color-white);\n}\n\n.KPMFURYtVMytzeKlJel4 .EMmQwwxX1ESvnON7umTr {\n  outline: initial;\n  border: var(--border-light);\n  border-bottom: none;\n  box-shadow: var(--elevation-01);\n}\n", "",{"version":3,"sources":["webpack://./screens/AppEditor/BrandAssets/Splash/SplashBackgroundForm/CropperJSOverrides.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;AACtC;;AAEA;EACE,gBAAgB;EAChB,2BAA2B;EAC3B,mBAAmB;EACnB,+BAA+B;AACjC","sourcesContent":[".splash-cropper .cropper-modal {\n  background-color: var(--color-white);\n}\n\n.splash-cropper .cropper-view-box {\n  outline: initial;\n  border: var(--border-light);\n  border-bottom: none;\n  box-shadow: var(--elevation-01);\n}\n"],"sourceRoot":""}]);
// Exports
export var splashCropper = "KPMFURYtVMytzeKlJel4";
export var cropperModal = "uSFofT9IMA1F5h8yeeeU";
export var cropperViewBox = "EMmQwwxX1ESvnON7umTr";
export default ___CSS_LOADER_EXPORT___;
