import BaseGridView from 'client/components/BaseGridView/BaseGridView'
import gql from 'graphql-tag'
import _ from 'lodash'
import {
  FILE_NAME_COLUMN,
  LANGUAGES_COLUMN,
  MEDIA_COLUMN_SPACING,
  UPDATED_AT_COLUMN,
  DOWNLOAD_COLUMN,
  MEDIA_INCLUDED_IN_COLUMN,
  MEDIA_INCLUDED_IN_FILTER_CRITERIA
} from 'client/screens/Catalog/grids/shared/columns'
import { SupportedAudioTypes } from 'client/constants'
import { UploadMediaType } from 'client/hooks/useMediaUpload'
import { t } from 'client/i18n'
import { useFeatureFlags } from 'client/hooks/useFeatureFlags'
import { Header3 } from 'client/components/TextStyles'
import EmptyStateGridAudioSVG from 'client/assets/svg/emptyStates/empty_state_grid_audio.svg'
import GridPlusIconAddButton from './shared/GridPlusIconAddButton'
import AudioForm from '../forms/AudioForm'
import AudioPreview from './renderers/AudioPreview'
import {
  EmptyStatePlaceholderDescription,
  EmptyStatePlaceholderDragDropFooter
} from './shared/styledComponents'

const AUDIOS_GRAPHQL_QUERY = gql`
  query audiosForMuseum($museumId: Int!) {
    museum(id: $museumId) {
      id
      audios {
        id
        url
        sourceUrl
        title
        fileName
        externalId
        isProcessing
        isChildrenProcessing
        processingError
        isChildrenProcessingError
        locales {
          code
          englishName
        }
        updatedAt
        isMarketingUseAllowed
        items {
          title
          commonName
          scientificName
          creators {
            name
          }
        }
        exhibits {
          title
        }
      }
    }
  }
`
const addAudioButton = <GridPlusIconAddButton label={t('Add Audio')} to="/catalog/audios/new" />

const AudiosEmptyPlaceholder = () => (
  <>
    <Header3>{t('Add your first audio')}</Header3>
    <EmptyStatePlaceholderDescription centerAlign={true}>
      <p>
        {t(
          "Upload audio interpretation like an artist's commentary, a curator talk, or an interview so you can use it to support other pieces of content."
        )}
      </p>
      <EmptyStatePlaceholderDragDropFooter addButtonNode={addAudioButton} />
    </EmptyStatePlaceholderDescription>
  </>
)

export default function Audios() {
  const { MARKETING } = useFeatureFlags()
  return (
    <BaseGridView
      contentName="Audio"
      gqlQuery={AUDIOS_GRAPHQL_QUERY}
      columns={_.compact([
        {
          dataKey: 'url',
          label: t('Preview'),
          cellRenderer: AudioPreview,
          minWidth: 300,
          maxWidth: 300,
          ...MEDIA_COLUMN_SPACING,
          sortable: false
        },
        { dataKey: 'title', label: t('Title') },
        FILE_NAME_COLUMN,
        MEDIA_INCLUDED_IN_COLUMN,
        LANGUAGES_COLUMN,
        UPDATED_AT_COLUMN,
        MARKETING && DOWNLOAD_COLUMN
      ])}
      filterCriteria={['title', 'fileName', ...MEDIA_INCLUDED_IN_FILTER_CRITERIA]}
      buttons={addAudioButton}
      formComponent={AudioForm}
      uploadMediaType={UploadMediaType.AUDIO}
      droppableFileFormats={SupportedAudioTypes}
      placeholder={<AudiosEmptyPlaceholder />}
      placeholderIcon={<EmptyStateGridAudioSVG />}
    />
  )
}
