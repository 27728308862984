import AccentListSVG from 'client/assets/svg/accentIcons/accent_list.svg'
import EmptyState from 'client/dsm/EmptyState/EmptyState'
import { ReactNode } from 'react'

interface IEmptyViewProps {
  message: ReactNode
}

export const EmptyView = ({ message }: IEmptyViewProps) => (
  <EmptyState icon={<AccentListSVG />}>{message}</EmptyState>
)
