import { useRef } from 'react'
import * as React from 'react'
import { getSuccessConfirmationMessage } from 'client/screens/AppEditor/MapEditor/DialogMessages/exteriorMap'
import MapDialog from 'client/screens/AppEditor/MapEditor/MapDialog'
import { DialogActions, DialogBody, DialogHeader } from 'client/dsm/Dialog/Dialog'
import Button from 'client/components/Button/Button'
import { t } from 'client/i18n'
import MapImageInput from './MapImageInput'

interface IExteriorMapUploadProps {
  onCancel: () => void
  onFileChange: (file: File) => void
  message: React.ReactNode
  title: React.ReactNode
}

export const ExteriorMapUpload = (props: IExteriorMapUploadProps) => {
  const { onCancel, onFileChange, message, title } = props
  const ref = useRef<HTMLInputElement>(null)

  return (
    <MapDialog>
      <DialogHeader>{title}</DialogHeader>
      <DialogBody>
        <MapImageInput id="create-exterior-map" onFileChange={onFileChange} ref={ref} />
        <span>{message}</span>
      </DialogBody>
      <DialogActions>
        <Button onClick={onCancel} label={t('Cancel')} />
        <Button onClick={() => ref.current?.click()} type="primary" label={t('Upload Map')} />
      </DialogActions>
    </MapDialog>
  )
}

interface IExteriorMapSuccessProps {
  onContinue?: () => void
}
export const ExteriorMapSuccess = ({ onContinue }: IExteriorMapSuccessProps) => {
  const { message, title } = getSuccessConfirmationMessage()

  return (
    <MapDialog>
      <DialogHeader>{title}</DialogHeader>
      <DialogBody>{message}</DialogBody>
      <DialogActions>
        <Button onClick={onContinue} type="primary" label={t('Continue')} />
      </DialogActions>
    </MapDialog>
  )
}
