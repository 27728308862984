import * as React from 'react'
import styled from 'styled-components'
import { ICoordinates } from 'shared/util/maps'
import PinIconSVGElement from 'client/assets/svg/icon/ic_map_pin.svg'
import HTMLText from 'client/components/HTMLText/HTMLText'
import { Body1CSS } from 'client/components/TextStyles'
import { t } from 'client/i18n'

const PinIcon = styled.div`
  position: absolute;
  bottom: 25px;
  left: 6px;
  display: inline-block;
  width: 31px;
  height: 21px;
  svg {
    height: auto;
    width: auto;
  }
`

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 200px;
  max-width: 330px;
`

const LocationName = styled.div`
  ${Body1CSS};
  display: inline-block;
  color: var(--color-grey-06);
  margin-left: 17px;
`

const Description = styled.div`
  display: flex;
  flex-direction: column;
`

const Body = styled.div`
  margin-bottom: 5px;
  line-height: 20px;
`

const BlackText = styled.span`
  color: var(--color-black);
  display: block;
`

const Header = styled(BlackText)`
  padding-right: 5px;
  font-weight: var(--font-weight-semi-bold);
  p {
    margin: 0;
  }
`

const Subheader = styled(BlackText)`
  margin-top: 2px;
  font-size: 14px;
  p {
    margin: 0;
  }
`

const Counter = styled.span`
  color: var(--color-grey-06);
  display: block;
`

interface IBody {
  header?: string
  subheader?: string
  showTotalContent?: boolean
  totalContent?: number
}

export interface IMapTooltip {
  selectedFloorId?: number
  locationId: number
  boundary: React.RefObject<HTMLDivElement>
  pinBubbleDiameter: number
  pinHeight: number
  totalContent: number
  pinOffset?: ICoordinates
  isShowingBubble: boolean
  onToggleBubble: () => void
  locationName: React.ReactNode
  body?: IBody
  menuPopout: React.ElementType
}

const TooltipBody = (props: IBody) => {
  const { header, subheader, showTotalContent = false, totalContent = 0 } = props
  const additionalContentText = showTotalContent
    ? t('+__totalContent__ More', { totalContent })
    : null

  return (
    <Body>
      {header && (
        <Header>
          <HTMLText html={header} />
        </Header>
      )}
      {subheader && (
        <Subheader>
          <HTMLText html={subheader} />
        </Subheader>
      )}
      {showTotalContent && <Counter>{additionalContentText}</Counter>}
    </Body>
  )
}

const MapTooltip = (props: IMapTooltip) => {
  const {
    menuPopout: MenuPopoutElement,
    locationId,
    selectedFloorId,
    isShowingBubble,
    onToggleBubble,
    locationName,
    body
  } = props

  const hasContent = body?.totalContent ? body.totalContent > 0 : false

  return (
    <Content onClick={(e) => e.stopPropagation()}>
      <Description>
        <TooltipBody {...body} />
        {locationName && (
          <>
            <PinIcon>
              <PinIconSVGElement />
            </PinIcon>
            <LocationName>{locationName}</LocationName>
          </>
        )}
      </Description>
      <MenuPopoutElement
        hasContent={hasContent}
        isShowingBubble={isShowingBubble}
        onToggleBubble={onToggleBubble}
        locationId={locationId}
        floorId={selectedFloorId}
      />
    </Content>
  )
}

export default MapTooltip
