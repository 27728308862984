import {
  GraphQLBoolean,
  GraphQLInputObjectType,
  GraphQLList,
  GraphQLNonNull,
  GraphQLString
} from 'graphql'
import { FileInput } from './types'

const AudioTranslationInput = new GraphQLInputObjectType({
  name: 'AudioTranslationInput',
  fields: {
    localeCode: {
      type: GraphQLString
    },
    file: {
      type: FileInput
    },
    title: {
      type: GraphQLString
    },
    description: {
      type: GraphQLString
    },
    transcript: {
      type: GraphQLString
    }
  }
})

export const AudioInput = new GraphQLInputObjectType({
  name: 'AudioInput',
  fields: {
    translations: {
      type: new GraphQLList(new GraphQLNonNull(AudioTranslationInput))
    },
    isMarketingUseAllowed: {
      type: GraphQLBoolean
    }
  }
})
