import { ThemeType } from 'client/types'
import DocentTippy, { IDocentTippyProps } from 'client/dsm/Tooltip/DocentTippy'

// From tippy api: [ SHOW_DELAY, HIDE_DELAY ]
const HOVER_SHOW_HIDE_DELAY = [500, 0] as [number, number]

export interface ITooltipProps extends Omit<IDocentTippyProps, 'theme'> {
  size?: 'large' | 'small' | 'medium'
  themeType?: ThemeType
}

// Note: When children root is svg, tippy doesn't seem to work. We can detect this case and patch for it when needed.
export default function Tooltip({
  size,
  themeType = ThemeType.DARK,
  // Placement of Tooltip should default to top unless specified.
  placement = 'top',
  // MaxWidth of Tooltip should default to 305 unless specified.
  maxWidth = 305,
  arrow = true,
  children,
  content,
  //  `PopupWidget` has a manual z-index of 10000, 10001 is to override the popup forms z-index
  // to ensure tooltips show up above the form
  zIndex = 10001,
  ...tippyProps
}: ITooltipProps) {
  return (
    <DocentTippy
      {...tippyProps}
      // TODO: Follow up on why we need this size differentiation
      size={size}
      content={content}
      zIndex={zIndex}
      themeType={themeType}
      placement={placement}
      arrow={arrow}
      maxWidth={maxWidth}
      animation={false}
      delay={HOVER_SHOW_HIDE_DELAY}
    >
      {children}
    </DocentTippy>
  )
}
