import { FunctionComponent, SVGProps } from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import { ThemeType } from 'client/types'

// This container is relevant only to 2 buttons in the InlineEditField component
const CircularContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  width: 24px;
  height: 24px;
`

interface IIconOnlyButtonProps {
  // Except for 2 InlineEditField buttons, all icon buttons in the CMS are 'default', i.e., do not have a circular outline
  type?: 'default' | 'solid' | 'lined'
  className?: string
  theme?: ThemeType
  disabled?: boolean
  selected?: boolean
  onClick?: (e: any) => void
  svgComponent: FunctionComponent<SVGProps<SVGSVGElement>>
  testId?: string
}

interface IStyledButtonProps {
  buttonType: IIconOnlyButtonProps['type']
  theme: IIconOnlyButtonProps['theme']
  selected: boolean
}

const StyledButton = styled.button<IStyledButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 0px;
  background-color: transparent;

  && svg {
    color: ${({ theme }) =>
      theme === ThemeType.DARK ? 'var(--color-black)' : 'var(--color-grey-03)'};
  }

  ${({ selected, theme }) => {
    if (selected) {
      // The questionmark icon in the left nav bar needs to stay white when selected.
      return `
          && svg {
          color: var(--color-${theme === ThemeType.DARK ? 'blue-07' : 'white'});
          }
       `
    }
  }}

  &:hover:enabled,
  &:active:hover {
    svg {
      color: ${({ theme }) =>
        theme === ThemeType.DARK ? 'var(--color-blue-07)' : 'var(--color-white)'};
    }

    ${CircularContainer} {
      ${({ buttonType }) => {
        return buttonType === 'solid'
          ? `
              background-color: var(--color-blue-07);
              
              svg {
                color: var(--color-white);
              }
            `
          : // buttonType === 'lined'
            `
              border: 1px solid var(--color-grey-07);
              background-color: var(--color-grey-07);
      
              svg {
                color: var(--color-white);
              }
            `
      }}
    }
  }

  &:disabled {
    cursor: default;
    pointer-events: none;

    svg {
      color: var(--color-grey-04);
    }
  }

  ${CircularContainer} {
    ${({ buttonType }) => {
      return buttonType === 'solid'
        ? `
            background-color: var(--color-brand);
            
            svg {
              color: var(--color-white);
            }
          `
        : // buttonType === 'lined'
          `
            border: 1px solid var(--color-black);
            background-color: var(--color-white);

            svg {
              color: var(--color-black);
            }
          `
    }}
  }
`

const IconOnlyButton = ({
  type = 'default',
  className,
  theme = ThemeType.DARK,
  disabled = false,
  selected = false,
  onClick = _.noop,
  svgComponent: SVGComponent,
  testId
}: IIconOnlyButtonProps) => {
  return (
    <StyledButton
      type="button"
      buttonType={type}
      className={className}
      theme={theme}
      onClick={onClick}
      disabled={disabled}
      selected={selected}
    >
      {type === 'default' ? (
        <SVGComponent data-testid={testId} />
      ) : (
        <CircularContainer>
          <SVGComponent data-testid={testId} />
        </CircularContainer>
      )}
    </StyledButton>
  )
}

export default IconOnlyButton
