import styled from 'styled-components'

const DocentField = styled.div`
  margin-top: var(--spacing-large);
  margin-bottom: var(--spacing-large);
  &:first-child {
    margin-top: 0;
  }
`

interface IFieldContainerProps {
  children: any
  className?: string
}

export default function FieldContainer(props: IFieldContainerProps) {
  return <DocentField className={props.className}>{props.children}</DocentField>
}
