import { endOfDay, startOfDay } from 'date-fns'
import { DisplayPeriodType, ExhibitionType } from 'shared/constants/exhibits'
import { fromZonedTime, toZonedTime } from 'date-fns-tz'

export function getDefaultTimezone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone
}

function extractFromToDatesForEvent(values) {
  const { from, to, isAllDay } = values
  if (isAllDay) {
    // TODO: consider putting this into the selector itself???
    return {
      from: startOfDay(from),
      to: endOfDay(to)
    }
  }
  return { from, to }
}

function extractFromToDatesForExhibitAndTour(values) {
  const { from, to } = values

  return {
    from: startOfDay(from),
    to: endOfDay(to)
  }
}

const HANDLER_MAP = {
  [ExhibitionType.EXHIBITION]: extractFromToDatesForExhibitAndTour,
  [ExhibitionType.EVENT]: extractFromToDatesForEvent,
  [ExhibitionType.TOUR]: extractFromToDatesForExhibitAndTour
}

export default function extractFromToDatesAndCleanupExpirationData(values) {
  const { displayPeriodType, type, timezone, expirationEnabled } = values

  if (displayPeriodType !== DisplayPeriodType.DATE_RANGE) {
    // If users change from "Date Range" to "Ongoing" or "None" in an exhibit/tour form
    // we need to explicitly set the from, to and tz values to null before submitting the form
    // lastExpiredDate and hideOnExpiration will also be irrelevant if the exhibit no longer has a display period
    return {
      from: null,
      to: null,
      timezone: null,
      lastExpiredDate: null,
      // Depending on if we've enabled the expirationEnabled toggle, hideOnExpiration should reset to different default values.
      hideOnExpiration: !!expirationEnabled
    }
  }

  const handler = HANDLER_MAP[type]
  const { from, to } = handler(values)

  return {
    from: fromZonedTime(from, timezone),
    to: fromZonedTime(to, timezone)
  }
}

export function convertToLocalDate(date: Date | string | null, timezone: string) {
  if (!date) {
    return null
  }

  return toZonedTime(new Date(date), timezone)
}
