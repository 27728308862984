import { GraphQLInputObjectType, GraphQLList, GraphQLNonNull, GraphQLString } from 'graphql'

const MuseumTranslationInput = new GraphQLInputObjectType({
  name: 'MuseumTranslationInput',
  fields: {
    localeCode: {
      type: GraphQLString
    },
    description: {
      type: GraphQLString
    }
  }
})

const MuseumInput = new GraphQLInputObjectType({
  name: 'MuseumInput',
  fields: {
    title: {
      type: GraphQLString
    },
    friendlyName: {
      type: GraphQLString
    },
    networkName: {
      type: GraphQLString
    },
    marketingUrl: {
      type: GraphQLString
    },
    translations: {
      type: new GraphQLList(new GraphQLNonNull(MuseumTranslationInput))
    }
  }
})

export default MuseumInput
