import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { getSelectedVersionInfo } from 'client/redux/selectors/version'
import _ from 'lodash'
import { useQuery } from '@apollo/client/react/hooks'
import gql from 'graphql-tag'
import MissingLogoThumbnailSVG from 'client/assets/svg/icon/missing_logo_thumbnail_40.svg'
import GuideSelect from './GuideSelect'
import VersionSelect from './VersionSelect'
import MenuControls from './MenuControls'

const BANNER_HEIGHT = 64

const Banner = styled.div`
  width: 100%;
  height: ${BANNER_HEIGHT}px;
  background-color: var(--color-black);
`

const Header = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: ${BANNER_HEIGHT}px;
  z-index: 4;
  color: var(--color-white);
  padding: 12px 16px;
`

const MuseumLogo = styled.img`
  width: 40px;
  height: 40px;
`

const MissingLogoThumbnail = styled(MissingLogoThumbnailSVG)`
  width: 40px;
  height: 40px;
`

const SwitcherContainer = styled.div`
  display: flex;
  align-items: center;
  ${GuideSelect}, ${VersionSelect} {
    width: 184px;
  }

  ${GuideSelect} {
    margin-left: 8px;
  }

  ${VersionSelect} {
    margin-left: 12px;
    margin-right: 8px;
  }
`
const GUIDES_QUERY = gql`
  {
    user {
      id
      guides {
        id
        name
        imageUrl
      }
    }
  }
`
const AppHeader = () => {
  const selectedVersionInfo = useSelector(getSelectedVersionInfo)
  const { data, loading } = useQuery(GUIDES_QUERY)

  if (loading) {
    return null
  }

  const { guides } = data.user
  const selectedGuide = _.find(guides, { id: selectedVersionInfo.guideId })
  const shouldShowGuidePicker = guides.length > 1

  return (
    <>
      <Banner />
      {!loading && selectedVersionInfo && (
        <Header>
          <SwitcherContainer>
            {selectedGuide.imageUrl ? (
              <MuseumLogo src={selectedGuide.imageUrl} />
            ) : (
              <MissingLogoThumbnail />
            )}
            {shouldShowGuidePicker && <GuideSelect selectedGuide={selectedGuide} guides={guides} />}
            <VersionSelect />
          </SwitcherContainer>
          <MenuControls />
        </Header>
      )}
    </>
  )
}

export default AppHeader
