import styled from 'styled-components'
import _ from 'lodash'
import { IMuseumLocationDay } from 'shared/json/IMuseumLocationDay'
import { WEEKDAY_SORT_ORDER } from 'shared/util/hours'
import useField from 'client/hooks/useField'
import { RadioButton, RadioButtonGroup } from 'client/components/RadioButton/RadioButton'
import { t } from 'client/i18n'
import { Header2CSS } from 'client/components/TextStyles'
import { IOrderedLocationDay } from './types'
import LocationDays from './LocationDays'

const SectionTitle = styled.div`
  ${Header2CSS};
  margin-bottom: var(--spacing-small);
`

const Container = styled.div`
  width: 100%;
  margin-bottom: 40px;
`

interface IProps {
  days?: IMuseumLocationDay[]
  displayHours: boolean
  onUpdateDays: (days: IMuseumLocationDay[]) => void
}

export default (props: IProps) => {
  const { days, onUpdateDays } = props

  const locationDays = _.map(days, (day, index) => ({
    ...day,
    position: index
  }))

  const weekdays = _(locationDays)
    .filter((day) => _.hasIn(WEEKDAY_SORT_ORDER, day.name))
    .sortBy((day) => WEEKDAY_SORT_ORDER[day.name])
    .value() as IOrderedLocationDay[]

  const handleUpdateDay = (updatedDay: IOrderedLocationDay) => {
    const updated = [
      ...locationDays.slice(0, updatedDay.position),
      updatedDay,
      ...locationDays.slice(updatedDay.position + 1, locationDays.length)
    ]
    onUpdateDays(updated)
  }

  const { value, setValue } = useField('displayHours')

  return (
    <Container>
      <SectionTitle>{t('Hours of Operation')}</SectionTitle>
      <RadioButtonGroup
        name="displayHours"
        value={_.toString(value)}
        onChange={(newValue) => setValue(newValue === 'true')}
      >
        <RadioButton label={t('None')} value="false" />
        <RadioButton label={t('Add Hours')} value="true" />
      </RadioButtonGroup>
      {props.displayHours && <LocationDays days={weekdays} onUpdateDay={handleUpdateDay} />}
    </Container>
  )
}
