import * as yup from 'yup'
import { RELATIONSHIP_TYPE_MAX_LENGTH } from 'shared/validation/constants'
import { BotanicalItemSchemaCore, ItemSchemaCore } from 'shared/validation/Item'
import { t } from 'client/i18n'

const SharedItemSchemaClient = {
  images: yup
    .array()
    .ensure()
    .min(1, `${t('Images')}: ${t('At least one image is required.')}`),
  relatedContent: yup
    .array()
    .of(
      yup.object().shape({
        relationshipType: yup
          .string()
          .label('Relationship Type')
          .nullable()
          .max(RELATIONSHIP_TYPE_MAX_LENGTH)
      })
    )
    .nullable()
}

export const BotanicalItemSchemaClient = BotanicalItemSchemaCore(t).shape(SharedItemSchemaClient)
export const ItemSchemaClient = ItemSchemaCore(t).shape(SharedItemSchemaClient)
