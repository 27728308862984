import * as yup from 'yup'
import _ from 'lodash'
import { ExhibitItemsHeading } from 'shared/constants/exhibits'
import ExhibitDisplayType from 'shared/ExhibitDisplayType'
import { TFunction } from 'i18next'
import { isSameDay } from 'date-fns'
import { validHtml, validLookupNumber } from './util'
import { EXHIBITION_TITLE_MAX_LENGTH } from './constants'

// Currently we use the out-of-the-box type errors from yup (which are thorough) to avoid having to add typeErrors everywhere.
const SharedSchemaCore = (t: TFunction) =>
  yup.object({
    title: yup.string().nullable().required().max(EXHIBITION_TITLE_MAX_LENGTH),
    description: yup.string().nullable().test(validHtml(t)),
    information: yup.string().nullable().test(validHtml(t)),
    // TODO: In the future, set the published value in the DB to default to 'true'
    published: yup.boolean(),
    from: yup.date().nullable(),
    to: yup.date().nullable(),
    lookupNumber: validLookupNumber(t)
  })

export const ExhibitionSchemaCore = (t: TFunction) =>
  SharedSchemaCore(t).shape({
    displayType: yup.string().oneOf(_.values(ExhibitDisplayType)),
    itemsHeading: yup.string().oneOf(_.values(ExhibitItemsHeading))
  })

export const EventSchemaCore = (t: TFunction) =>
  SharedSchemaCore(t).shape({
    timezone: yup.string().label('Time Zone').nullable().required(),
    timeCheck: yup.boolean().test({
      name: 'validate-time-client',
      test: (value, context) => {
        const { from, to, isAllDay } = context.parent

        if (value && isSameDay(from, to) && !isAllDay && from >= to) {
          return context.createError({
            message: `${t('Date/Time')}: ${t('End Time must be later than Start Time.')}`
          })
        }

        return true
      }
    })
  })

export const TourSchemaCore = (t: TFunction) =>
  SharedSchemaCore(t).shape({
    estimatedDuration: yup
      .number()
      .positive()
      // eslint-disable-next-line no-template-curly-in-string
      .integer()
      .nullable()
      .label('Estimated Duration (Minutes)')
  })
