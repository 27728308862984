import { GraphQLInputObjectType, GraphQLString, GraphQLInt, GraphQLNonNull } from 'graphql'

export const IdInput = new GraphQLInputObjectType({
  name: 'IdInput',
  fields: {
    id: {
      type: new GraphQLNonNull(GraphQLInt)
    }
  }
})

export const FileInput = new GraphQLInputObjectType({
  name: 'FileInput',
  fields: {
    url: {
      type: new GraphQLNonNull(GraphQLString)
    },
    name: {
      type: new GraphQLNonNull(GraphQLString)
    }
  }
})
