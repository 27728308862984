import { IBulkUploadState } from 'client/types'
import { AnyAction } from 'redux'
import {
  FETCH_BULK_UPLOAD_FAILURE,
  FETCH_BULK_UPLOAD_START,
  FETCH_BULK_UPLOAD_SUCCESS
} from 'client/redux/actions/types'

const initialState: IBulkUploadState = {
  isLoading: false,
  error: null,
  data: null
}

export const bulkUpload = (
  state: IBulkUploadState = initialState,
  action: AnyAction = { type: null }
) => {
  switch (action.type) {
    case FETCH_BULK_UPLOAD_START:
      return {
        ...state,
        isLoading: true,
        error: null
      }
    case FETCH_BULK_UPLOAD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        data: action.latestBulkUpload
      }
    case FETCH_BULK_UPLOAD_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
        data: null
      }

    default:
      return state
  }
}
