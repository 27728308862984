import {
  FETCH_BULK_UPLOAD_FAILURE,
  FETCH_BULK_UPLOAD_START,
  FETCH_BULK_UPLOAD_SUCCESS
} from 'client/redux/actions/types'

export const fetchBulkUpload = () => ({ type: FETCH_BULK_UPLOAD_START })
export const fetchBulkUploadSuccess = (latestBulkUpload: any) => ({
  type: FETCH_BULK_UPLOAD_SUCCESS,
  latestBulkUpload
})
export const fetchBulkUploadError = () => ({ type: FETCH_BULK_UPLOAD_FAILURE })
