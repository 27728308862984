import { Dialog, DialogHeader, DialogBody, DialogActions } from 'client/dsm/Dialog/Dialog'
import Button from 'client/components/Button/Button'
import { t } from 'client/i18n'

interface IDiscardChangesDialogProps {
  onSave: () => void
  onCancel: () => void
}

const DiscardChangesDialog = ({ onSave, onCancel }: IDiscardChangesDialogProps) => (
  <Dialog>
    <DialogHeader>{t('Discard Changes?')}</DialogHeader>
    <DialogBody>{t('Your unsaved changes will be lost.')}</DialogBody>
    <DialogActions>
      <Button onClick={onCancel} label={t('Continue Editing')} />
      <Button type="danger" onClick={onSave} label={t('Discard Changes')} />
    </DialogActions>
  </Dialog>
)

export default DiscardChangesDialog
