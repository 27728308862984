import { GQLExhibit } from 'shared/graphql/types/graphql'
import { ExhibitionType } from 'shared/constants/exhibits'
import { useDispatch } from 'react-redux'
import { useMutation } from '@apollo/client'
import createMutationQueries from 'shared/util/gqlMutation'
import { flash } from 'client/redux/actions/flash'
import { Dialog, DialogActions, DialogBody, DialogHeader } from 'client/dsm/Dialog/Dialog'
import { useErrorDialog } from 'client/components/ErrorDialog'
import Button from 'client/components/Button/Button'
import styled from 'styled-components'
import ContextualHelp, {
  ContextualHelpTextSection
} from 'client/components/ContextualHelp/ContextualHelp'
import { t } from 'client/i18n'
import { Trans } from 'react-i18next'

const StyledDialogHeader = styled(DialogHeader)`
  display: flex;
  justify-content: space-between;
`

interface IExhibitionTourConversionConfirmationProps {
  exhibit: GQLExhibit
  onClose: () => void
}

export default function ExhibitionTourConversionConfirmation({
  exhibit,
  onClose
}: IExhibitionTourConversionConfirmationProps) {
  const dispatch = useDispatch()
  const [errorDialog, setError] = useErrorDialog()

  const isTour = exhibit.type === ExhibitionType.TOUR
  const title = isTour
    ? t('Convert this tour to an exhibition?')
    : t('Convert this exhibition to a tour?')
  const convertTo = isTour ? ExhibitionType.EXHIBITION : ExhibitionType.TOUR
  const buttonLabel = isTour ? t('Convert to Exhibition') : t('Convert to Tour')
  const successFlashMessage = isTour
    ? t('Tour converted to exhibition')
    : t('Exhibition converted to tour')

  const [updateExhibition] = useMutation(createMutationQueries('exhibit').update, {
    onCompleted: () => {
      onClose()
      dispatch(flash(successFlashMessage))
    },
    onError: (error) => {
      setError({
        error,
        title: isTour
          ? t('Unable to Convert Tour to Exhibition')
          : t('Unable to Convert Exhibition to Tour')
      })
    }
  })

  const message = isTour ? (
    <>
      <p>
        <Trans
          i18nKey="You are about to convert __exhibitTitle__ to an exhibition."
          values={{ exhibitTitle: exhibit.title }}
        />
      </p>

      <p>
        {t(
          'Converting this tour to an exhibition changes how visitors navigate through the items.'
        )}
      </p>

      <p>
        {t('This tour will turn into an exhibition that you can edit from the Exhibitions page.')}
      </p>
    </>
  ) : (
    <>
      <p>
        <Trans
          i18nKey="You are about to convert __exhibitTitle__ to a tour."
          values={{ exhibitTitle: exhibit.title }}
        />
      </p>

      <p>
        {t('Converting this exhibition to a tour changes how visitors navigate through the items.')}
      </p>

      <p>{t('This exhibition will turn into a tour that you can edit from the Tours page.')}</p>
    </>
  )

  const help = isTour ? (
    <>
      <ContextualHelpTextSection subheader={t('Purpose')}>
        <p>
          {t(
            'Arrange the items as a non-linear grouping of content, so visitors can experience the items in any order.'
          )}
        </p>
      </ContextualHelpTextSection>
      <ContextualHelpTextSection subheader={t('Impact')}>
        <p>
          {t(
            'The tour turns into an exhibition. Exhibition-specific fields and formatting appear.'
          )}
        </p>
        <p>
          {t(
            'The exhibition takes over the tour’s position on the Home screen, lookup number, and related content. The tour’s location is removed.'
          )}
        </p>
      </ContextualHelpTextSection>
      <ContextualHelpTextSection subheader={t('Undo')}>
        <p>{t('You can convert an exhibition to a tour if you need to change the experience.')}</p>
      </ContextualHelpTextSection>
    </>
  ) : (
    <>
      <ContextualHelpTextSection subheader={t('Purpose')}>
        <p>
          {t(
            'Arrange the items as a set of sequential stops, so visitors experience a story that unfolds one step at a time. Tours are best experienced in your physical space.'
          )}
        </p>
      </ContextualHelpTextSection>
      <ContextualHelpTextSection subheader={t('Impact')}>
        <p>{t('The exhibition turns into a tour. Tour-specific fields and formatting appear.')}</p>
        <p>
          {t(
            'The tour takes over the exhibition’s position on the Home screen, lookup number, and related content. The exhibition’s location is removed.'
          )}
        </p>
      </ContextualHelpTextSection>
      <ContextualHelpTextSection subheader={t('Undo')}>
        <p>{t('You can convert a tour to an exhibition if you need to change the experience.')}</p>
      </ContextualHelpTextSection>
    </>
  )

  return (
    <>
      <Dialog>
        <StyledDialogHeader>
          {title}
          <ContextualHelp
            tooltipContent={t('More on the impact of this change')}
            tooltipPlacement="top"
            helpSize="large"
            header={
              isTour
                ? t('Converting a Tour to an Exhibition')
                : t('Converting an Exhibition to a Tour')
            }
            helpContent={help}
          />
        </StyledDialogHeader>
        <DialogBody>{message}</DialogBody>
        <DialogActions>
          <Button onClick={onClose} label={t('Cancel')} />
          <Button
            type="primary"
            onClick={() => {
              updateExhibition({
                // Remove map locations upon exhibit/tour conversion
                variables: { id: exhibit.id, input: { type: convertTo, exhibitMapLocations: [] } }
              })
            }}
            label={buttonLabel}
          />
        </DialogActions>
      </Dialog>
      {errorDialog}
    </>
  )
}
