import { Header3CSS, Body1CSS } from 'client/components/TextStyles'
import styled from 'styled-components'

export const ContextualHelpContentBlock = styled.div`
  color: var(--color-grey-07);

  &:not(:last-of-type) {
    margin-bottom: 20px;
  }

  ul {
    padding-left: 20px;
    margin-bottom: 0;
  }
`

export const ContextualHelpSubheader = styled.div`
  font-weight: var(--font-weight-bold);
`

export const ContextualHelpHeader = styled.div`
  ${Header3CSS};
  color: var(--color-black);
  margin-bottom: var(--spacing-xsmall);
`

export const ContextualHelpImage = styled.img`
  max-width: 327px;
  display: block;
  margin-left: auto;
  margin-right: auto;
`

export const ContextualHelpContentContainer = styled.div`
  ${Body1CSS};
`
