import { getItemDescription } from 'shared/util/items'
import { IReorderableRowProps } from 'client/components/ContentRow/ReorderableRow'
import GlobeIconTooltip from 'client/components/Languages/GlobeIconTooltip'
import { GQLFeaturedItem } from 'shared/graphql/types/graphql'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { ItemType } from 'shared/constants/item'
import { ExhibitionType } from 'shared/constants/exhibits'
import MenuPopout, { IMenuOption } from 'client/components/MenuPopout/MenuPopout'
import ImagePlaceholderSVG from 'client/assets/svg/emptyStates/no_image_rectangle.svg'
import { t } from 'client/i18n'
import { getExhibitDescription } from 'client/util/exhibits'
import { getItemOrExhibitTags } from 'client/util/contentTags'
import { FullContentRow } from 'client/components/ContentRow/FullContentRow'

const MenuPopoutContainer = styled.div`
  margin-left: 24px;
`

const getHeaderSubheader = (feature: GQLFeaturedItem) => {
  if (feature.item) {
    return getItemDescription(feature.item)
  }

  return getExhibitDescription(feature.exhibit!)
}

// TODO: We already have a `ReorderableRow` component, could this use this instead?
export default function FeaturedItemRow(props: IReorderableRowProps<GQLFeaturedItem>) {
  const navigate = useNavigate()
  const { value: featuredItem, onRemove } = props
  if (!featuredItem) {
    return null
  }

  const featuredContent = (featuredItem.exhibit || featuredItem.item)!
  const heroImage = featuredContent!.images[0]?.hero
  const image = heroImage || ImagePlaceholderSVG
  const { header, subheaders } = getHeaderSubheader(featuredItem)
  const tags = getItemOrExhibitTags(featuredContent)

  const handleEdit = () => {
    if (featuredItem.exhibit) {
      switch (featuredItem.exhibit.type) {
        case ExhibitionType.EXHIBITION:
          navigate(`exhibitions/${featuredItem.exhibit.id}`)
          break
        case ExhibitionType.TOUR:
          navigate(`tours/${featuredItem.exhibit.id}`)
          break
        case ExhibitionType.EVENT:
          navigate(`events/${featuredItem.exhibit.id}`)
          break
        default:
          throw new Error('Invalid exhibit content')
      }
    }
    if (featuredItem.item) {
      switch (featuredItem.item.type) {
        case ItemType.ITEM:
          navigate(`items/${featuredItem.item.id}`)
          break
        case ItemType.BOTANICAL:
          navigate(`botanicalItems/${featuredItem.item.id}`)
          break
        default:
          throw new Error('Invalid item content')
      }
    }
  }

  const options: IMenuOption[] = [
    { label: t('Edit'), onClick: handleEdit },
    { label: t('Remove'), onClick: onRemove }
  ]

  return (
    <FullContentRow
      {...props}
      header={header}
      subheaders={subheaders}
      image={image}
      tags={tags}
      note={<GlobeIconTooltip locales={featuredContent.locales} />}
      iconNodes={
        <MenuPopoutContainer>
          <MenuPopout options={options} placement="bottom-end" />
        </MenuPopoutContainer>
      }
      showRemoveIcon={false}
    />
  )
}
