import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getAuthData } from 'client/redux/selectors/auth'
import { useLocation } from 'react-router-dom'

const BEACON_ID = 'f815859d-bb9e-4959-878d-f9e10bef6a2a'

export function useHelpScoutBeacon() {
  const { name, email } = useSelector(getAuthData)
  const location = useLocation()

  useEffect(() => {
    if (name && email) {
      window.Beacon('init', BEACON_ID)
      const hasSeenBeaconBefore = localStorage.getItem('hasSeenBeaconBefore') === 'true'

      window.Beacon('config', {
        // show the pulsing wave of beacon icon only the very first time
        enableFabAnimation: !hasSeenBeaconBefore,
        // ensure Beacon always stays above all dialogs and forms etc.
        display: {
          zIndex: 9999999
        }
      })

      if (!hasSeenBeaconBefore) {
        localStorage.setItem('hasSeenBeaconBefore', 'true')
      }

      window.Beacon('identify', { name, email })
      return () => window.Beacon('destroy')
    }
  }, [email, name])

  useEffect(() => {
    // add an event listener here that depends on url path change to ensure
    // the suggested articles are refreshed every time users change a web page url
    window.Beacon('event', {
      type: 'page-viewed',
      url: document.location.href,
      title: document.title
    })
    window.Beacon('suggest')
  }, [location])
}
