import { ImageFormat } from 'shared/constants/images'

export enum LanguageType {
  SUPPORTED = 'SUPPORTED', // in the Locales table e.g. de-DE (German)
  UNSUPPORTED = 'UNSUPPORTED', // not in the Locales table e.g. ro-RO (Romanian)
  UNRECOGNIZED = 'UNRECOGNIZED' // can't determine what the language is based on CP's language tag e.g. jkls
}
export interface ITrackAndLanguageInfo {
  name: string
  code: string
  languageType: LanguageType
  hasAudio: boolean
  hasAudioDescription: boolean
}

export interface IAudioLanguageInfo {
  trackAndLanguageInfoList: ITrackAndLanguageInfo[]
  numUntaggedTracks: number
}

export const SUPPORTED_VIDEO_MIME_TYPES = ['video/mp4']

export const VIDEO_POSTER_IMAGE_GENERATION_SETTINGS = {
  format: ImageFormat.JPEG,
  // 720 is the max height dimension we re-encode videos to.
  dimensions: { width: 1280, height: 720 }
}
