import { AnyAction } from 'redux'

export default function createReducer(initialState, handlers) {
  return function reducer(state = initialState, action: AnyAction = { type: null }) {
    if (handlers[action.type]) {
      return handlers[action.type](state, action)
    }
    return state
  }
}
