import { GraphQLInputObjectType, GraphQLString } from 'graphql'
import { IdInput } from 'shared/graphql/mutations/types'

export const RelatedContentInput = new GraphQLInputObjectType({
  name: 'RelatedContentInput',
  fields: {
    item: {
      type: IdInput
    },
    exhibit: {
      type: IdInput
    },
    relationshipType: {
      type: GraphQLString
    }
  }
})
