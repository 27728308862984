import styled from 'styled-components'
import _ from 'lodash'
import { Body1CSS, Label1CSS } from 'client/components/TextStyles'
import { t } from 'client/i18n'
import { ICommonDayProps } from './types'
import HoursInput from './HoursInput/HoursInput'

interface IContainerProps {
  hasHours: boolean
}

const Container = styled.div<IContainerProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ hasHours }) => (hasHours ? 11 : 19)}px;
`

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-start;
  margin-top: 10px;
`

const NameLabel = styled.div`
  ${Label1CSS};
  display: inline-block;
  vertical-align: middle;
  width: 90px;
  padding: 0;
  margin-bottom: 0;
  margin-right: 40px;
  color: var(--color-black);
`

interface IOpenCloseProps {
  isOpen: boolean
}

const OpenCloseLabel = styled.div<IOpenCloseProps>`
  ${Body1CSS};
  display: inline-block;
  vertical-align: middle;
  font-style: ${({ isOpen }) => (isOpen ? 'normal' : 'italic')};
  color: var(--color-black);
  padding: 0;
  margin: 0;
`

export default (props: ICommonDayProps) => {
  const { name, open24Hours, hours } = props
  const hasHours = !_.isEmpty(hours)
  const isOpen = open24Hours || hasHours

  const label = t(`day.${name}`)

  return (
    <Container hasHours={hasHours}>
      <DescriptionContainer>
        <NameLabel>{label}</NameLabel>
        <OpenCloseLabel isOpen={isOpen}>{isOpen ? t('Open') : t('Closed')}</OpenCloseLabel>
      </DescriptionContainer>
      <HoursInput {...props} />
    </Container>
  )
}
