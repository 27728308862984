import { useQuery } from '@apollo/client'
import GuideVisibilityType from 'shared/GuideVisibilityType'
import gql from 'graphql-tag'

const GUIDE_VISIBILITY_GRAPHQL_QUERY = gql`
  query guideDefaultLocale($guideId: Int!) {
    guide(id: $guideId) {
      id
      visibilityType
      publicDate
    }
  }
`
export default function useGuideVisibility() {
  const { data, loading, error } = useQuery(GUIDE_VISIBILITY_GRAPHQL_QUERY)
  const visibilityType = data?.guide.visibilityType
  const publicDate = data?.guide.publicDate ? new Date(data?.guide.publicDate) : undefined
  const isPrivate = visibilityType !== GuideVisibilityType.PUBLIC

  return { loading, error, isPrivate, publicDate }
}
