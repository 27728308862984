import { IConfirmationState } from 'client/types'
import createReducer from './createReducer'
import * as types from '../actions/types'

const initialState: IConfirmationState = {
  id: null,
  show: false,
  title: null,
  message: null,
  confirmNo: null,
  confirmYes: null,
  isAlert: false,
  help: null
}
export const confirmation = createReducer(initialState, {
  [types.CONFIRM_START](state, action) {
    const { id, title, message, confirmYes, confirmNo, isAlert, help } = action
    return {
      ...state,
      id,
      title,
      message,
      confirmYes,
      confirmNo,
      isAlert,
      help,
      show: true
    }
  },
  [types.CONFIRM_END]() {
    return initialState // reset to initial state
  }
})
