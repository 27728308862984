import { GQLUser, GQLExhibit, GQLItem } from 'shared/graphql/types/graphql'
import { MapsActionTypes } from 'client/redux/actions/types'
import UserPermissionType from 'shared/UserPermissionType'
import IVersionInfo from 'shared/VersionInfo'
import { IMapJson } from 'shared/json/IMapJson'
import { IBuildingJson } from 'shared/json/IBuildingJson'
import { IMapLocationContentJson } from 'shared/json/IMapLocationContentJson'
import { ReactNode } from 'react'
import { IBulkUploadError } from 'shared/bulkUpload/BulkUploadError'
import UserMFAType from 'shared/UserMFAType'

interface IHttpStatus<T> {
  type?: T | null
  loading: boolean
}

export enum AuthenticationStatus {
  UNKNOWN = 'AuthenticationStatus.UNKNOWN', // We do not know if a jwt cookie exists
  UNAUTHORIZED = 'AuthenticationStatus.UNAUTHORIZED', // We know the jwt cookie is invalid or revoked
  ACCESS_TIME_RESTRICTED = 'AuthenticationStatus.ACCESS_TIME_RESTRICTED', // We know jwt cookie is invalid and too many failed attempts made to login.
  AUTHORIZED = 'AuthenticationStatus.AUTHORIZED', // We have successfully been issued a jwt with UN/PW, but might need MFA
  VERIFIED = 'AuthenticationStatus.VERIFIED' // We have been issued a jwt following a successful MFA verify
}

export interface IAuthError {
  statusCode?: number
  message?: string
}

export interface IAuthState {
  isLoading: boolean
  authStatus: AuthenticationStatus
  mfaPassed: boolean
  mfaEnabled: boolean
  mfaEnrolled: boolean
  mfaType: UserMFAType
  qrDataURL: string | null
  error: IAuthError | null
  user: GQLUser | null
  name: string | null
  permissionType: UserPermissionType | null
  isPasswordExpired: boolean
  privacyPolicyAcceptedAt: Date
  isUserLoggingInForFirstTime: boolean
  firstName: string | null
  lastName: string | null
  email: string | null
}

export interface IVersionData {
  versionInfo: IVersionInfo | null
}

export interface IFlashMessage {
  text: string
  error: boolean
}
export interface IFlashState {
  message: IFlashMessage | null
}

export interface IConfirmationState {
  show?: boolean
  id?: string | null
  title?: ReactNode
  message?: ReactNode
  confirmNo?: {
    action: () => void
    label: string
  } | null
  confirmYes?: {
    action: () => void
    label: string
    isDestructive?: boolean
  } | null
  isAlert?: boolean
  help?: ReactNode
}

export default interface BulkUploadData {
  id: number
  fileName: string
  createdAt: Date
  processingError?: string | IBulkUploadError | null
  isProcessing: boolean
  // 0-100
  percentageComplete: number | null
  contentHasMediaErrors: boolean
}

export interface IBulkUploadState {
  isLoading: boolean
  error: boolean | null
  data: BulkUploadData | null
}

export interface IReduxState {
  authData: IAuthState
  flash: IFlashState
  confirmation: IConfirmationState
  maps: IMapsState
  versionData: IVersionData
  bulkUpload: IBulkUploadState
}

export interface IBuildingById {
  [key: number]: IBuildingJson
}

export interface IMapsState {
  exterior: IMapJson
  buildings: IBuildingById
  mapLocationContent: { [pinId: number]: IMapLocationContentJson[] }
  dialog: {
    buildingId: number | null
    showEditBuildingTitle: boolean
  }
  selected: {
    pinId: number | null
  }
  httpStatus: IHttpStatus<MapsActionTypes>
}

export enum ContentType {
  ITEM = 'item',
  EXHIBITION = 'exhibition',
  TOUR = 'tour',
  EVENT = 'event'
}

export type IContent = GQLItem | GQLExhibit

interface BuildingsUrlParams {
  buildingId: string
  floorId: string
  locationId: string
  editBuildingId: string
}

/* A mapping of form fields to error strings or React components:
{
  email: 'Email: This field is required.'
  firstName: 'First Name: This field is required.'
  assignedGuides: 'Guide: At least one guide is required.'
  lastName: 'Last Name: This field is required.'
  password:
    <div>
      <span>Password does not meet the required password policy.</span>
      <ul>
        <li>At least 8 characters in length</li>
        <li>Contain at least 3 of the following 4 types of characters: upper case letters (A-Z), lower case letters (a-z), numbers (i.e. 0-9), special characters (e.g. !@#$%^&*)</li>
      </ul>
    </div>
}
*/
export interface IErrorMap {
  [key: string]: ReactNode
}

export type IBuildingsURLParams = Record<keyof BuildingsUrlParams, string>

export enum ThemeType {
  DARK = 'dark',
  LIGHT = 'light'
}

export interface IThemeObject {
  type?: ThemeType
}
