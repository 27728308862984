import { ReactNode } from 'react'
import Banner, { BannerItem } from 'client/dsm/Banner/Banner'
import _ from 'lodash'
import { IErrorMap } from 'client/types'
import { t } from 'client/i18n'

interface IFormErrorBannerProps {
  children?: ReactNode
  errorMap: IErrorMap
}
const FormErrorBanner = ({ errorMap, children }: IFormErrorBannerProps) => {
  if (_.isEmpty(errorMap) && _.isEmpty(children)) {
    return null
  }

  return (
    <Banner type="error" headingText={t('Please correct the following before saving:')}>
      {_.map(errorMap, (errorMessage, field) => (
        <BannerItem key={field}>{errorMessage}</BannerItem>
      ))}
      {children}
    </Banner>
  )
}

export default FormErrorBanner
