import { navigateWithVersionInfo } from 'client/redux/actions/navigation'
import store from 'client/redux/store'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import MuseumVersionType from 'shared/MuseumVersionType'

export const GuideExportRedirectRoute = () => {
  // Extract the content info from the current path
  // path: /guide/1/version/live/exhibits/110
  const { guideId, versionType, contentType, contentTypeId } = useParams()
  const guideIdAsNumber = Number(guideId)

  if (!guideIdAsNumber || !versionType || !contentType || !contentTypeId) {
    throw new Error('Invalid url')
  }

  const newRoute = `/catalog/${contentType}/${contentTypeId}`

  // Use useEffect here to re-render only one time once we wait store.dispatch happen to set local storage
  useEffect(() => {
    // set local storage and redirect to newRoute
    store.dispatch(
      navigateWithVersionInfo(
        {
          guideId: guideIdAsNumber,
          versionType: versionType as MuseumVersionType
        },
        newRoute
      )
    )
  }, [guideIdAsNumber, newRoute, versionType])

  // Need to put a dummy div here because the React Router needs a returned JSX component
  return <div />
}
