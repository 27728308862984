import styled from 'styled-components'
import {
  IReorderableRowProps,
  ReorderableGuideRow
} from 'client/components/ContentRow/ReorderableRow'
import { PickerGuideRow } from 'client/components/ContentRow/PickerRow'
import ReorderableListWithPicker from 'client/components/Formik/ReorderableList/ReorderableListWithPicker'
import IGuidePickerJson from 'shared/json/IGuidePickerJson'
import _ from 'lodash'
import { useMemo } from 'react'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import GuideVisibilityType from 'shared/GuideVisibilityType'
import { GQLGuide } from 'shared/graphql/types/graphql'

const StyledGuideListWithPicker = styled(ReorderableListWithPicker)`
  margin-top: var(--spacing-small);
  margin-bottom: var(--spacing-medium);
`

const GUIDES_QUERY = gql`
  query allGuides {
    guides {
      id
      name
      visibilityType
      imageUrl
    }
  }
`

const usePickerGuides = (showOnlyPublicGuides: boolean) => {
  const { data } = useQuery<{ guides: GQLGuide[] }>(GUIDES_QUERY)
  const guides = data?.guides

  return useMemo(() => {
    const filteredGuides = showOnlyPublicGuides
      ? _.filter(guides, { visibilityType: GuideVisibilityType.PUBLIC })
      : guides

    const pickerGuides = _(filteredGuides)
      .map(({ id, imageUrl, name }) => {
        return {
          id,
          name,
          imageUrl
        }
      })
      .sortBy('name')
      .value()

    return pickerGuides
  }, [guides, showOnlyPublicGuides])
}

interface IGuideListWithPicker {
  name: string
  maxValues?: number
  isDragDisabled?: boolean
  showOnlyPublicGuides?: boolean
}

const GuideListWithPicker = ({
  name,
  maxValues,
  isDragDisabled = false,
  showOnlyPublicGuides = true
}: IGuideListWithPicker) => {
  const guides = usePickerGuides(showOnlyPublicGuides)

  return (
    <StyledGuideListWithPicker
      name={name}
      isDragDisabled={!isDragDisabled}
      rowComponent={(rowComponentProps: IReorderableRowProps<IGuidePickerJson>) => (
        <ReorderableGuideRow {...rowComponentProps} showGrabber={isDragDisabled} />
      )}
      source={guides}
      maxValues={maxValues}
      pickerProps={{
        contentName: 'Guide',
        rowComponent: PickerGuideRow,
        filterCriteria: ['name']
      }}
    />
  )
}

export default GuideListWithPicker
