import authSaga from './auth'
import mapsSaga from './maps'
import versionSaga from './version'
import navigationSaga from './navigation'
import gqlIntegrationSaga from './gqlIntegration'
import toastSaga from './toast'
import bulkUploadSaga from './bulkUpload'

export default [
  authSaga,
  mapsSaga,
  versionSaga,
  navigationSaga,
  gqlIntegrationSaga,
  toastSaga,
  bulkUploadSaga
]
