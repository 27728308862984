import gql from 'graphql-tag'
import DataAwareReorderableList from 'client/components/Formik/ReorderableList/DataAwareReorderableList'
import styled from 'styled-components'
import { ReorderableCreatorRow } from 'client/components/ContentRow/ReorderableRow'
import { PickerCreatorRow } from 'client/components/ContentRow/PickerRow'

const StyledList = styled(DataAwareReorderableList)`
  margin-top: 8px;
`

const CREATORS_GRAPHQL_QUERY = gql`
  query creatorsForMuseum($museumId: Int!) {
    museum(id: $museumId) {
      id
      creators {
        id
        prefix
        name
        lifedates
        locales {
          code
          englishName
        }
      }
    }
  }
`

interface ICreatorsProps {
  name: string
}
export default function Creators(props: ICreatorsProps) {
  const { name } = props

  return (
    <StyledList
      name={name}
      gqlQuery={CREATORS_GRAPHQL_QUERY}
      rowComponent={ReorderableCreatorRow}
      pickerRowComponent={PickerCreatorRow}
      filterCriteria={['name']}
      contentName="Creator"
    />
  )
}
