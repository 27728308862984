import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { Body2CSS } from 'client/components/TextStyles'

export const StyledNavLink = styled(NavLink)`
  color: var(--color-grey-03);
  text-decoration: none;
  padding: var(--spacing-xsmall) var(--spacing-medium);
  font-size: 14px;
  align-items: center;
  display: flex;
  align-items: center;
  &:hover {
    text-decoration: none;
    background-color: var(--color-grey-10);
    color: var(--color-grey-03);
  }
  &.active,
  &.active:hover {
    background-color: var(--color-grey-09);
    border-left: 4px solid var(--color-red-06);
    padding: 8px 20px;
    color: var(--color-white);
  }
`

export const NavSubHeading = styled.div`
  font-size: 12px;
  font-weight: 600;
  color: var(--color-grey-03);
  letter-spacing: 0.5px;
  padding: 6px 24px 0px 24px;
  align-items: center;
  margin: 0 0 8px 0;
  padding-right: var(--spacing-medium);
`

export const ContentSectionLabel = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const DividerWrapper = styled.div`
  width: 100%;
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 16px;
  margin-bottom: 16px;
`

export const DividerLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: var(--color-white);
  opacity: 0.15;
`

export const LeftNavContainer = styled.div`
  display: flex;
  align-self: flex-start;
  overflow: hidden;
  width: 100%;
  padding-left: 248px;
`

export const LeftNavBody = styled.nav`
  width: 248px;
  height: calc(100vh - 64px);
  border-top: 1px solid var(--color-grey-09);
  background-color: var(--color-black);
  padding-top: 16px;
  overflow: auto;
  position: fixed;
  left: 0px;
  display: flex;
  flex-direction: column;
`

export const LeftNavFooter = styled.div`
  width: 100%;
  background-color: var(--color-black);
  justify-content: center;
  display: flex;
  margin-top: 16px;
  display: flex;
  align-items: flex-end;
  flex: 1;
`

export const LogosContainer = styled.div`
  border-top: 1px solid var(--color-grey-09);
  width: 100%;
  padding: 20px 0px;
  > * {
    justify-content: center;
    align-items: center;
    display: flex;
  }

  > :nth-child(2) {
    ${Body2CSS};
    color: var(--color-white);
    margin-top: 10px;
    margin-bottom: 5px;
  }
`

export const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
