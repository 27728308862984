import DownloadSVG from 'client/assets/svg/icon/download_20.svg'
import DownloadWithCautionSVG from 'client/assets/svg/icon/download_with_caution_20.svg'
import Tooltip from 'client/dsm/Tooltip/Tooltip'
import IconOnlyButton from 'client/components/IconButton/IconOnlyButton'
import { AssetType } from 'shared/constants/asset'
import { t } from 'client/i18n'

interface IDownloadAssetIconButton {
  assetType: AssetType
  assetId: number
  hasSourceUrl: boolean
  isMarketingUseAllowed: boolean
}

export default function DownloadAssetIconButton({
  assetType,
  assetId,
  hasSourceUrl,
  isMarketingUseAllowed
}: IDownloadAssetIconButton) {
  // This download button is visible only to marketing to allow them to download media assets for social media campaigns and does not need to be translated.
  /* eslint-disable docent/require-translation-keys-to-be-literals */
  const tooltipContent = (
    <>
      <p>{t(`Download ${hasSourceUrl ? 'original' : 'processed'} ${assetType}.`)}</p>
      {!isMarketingUseAllowed && (
        <>{t(`This ${assetType} is not allowed for Bloomberg Connects social media.`)}</>
      )}
    </>
  )

  return (
    <Tooltip content={tooltipContent}>
      <div>
        <IconOnlyButton
          svgComponent={isMarketingUseAllowed ? DownloadSVG : DownloadWithCautionSVG}
          onClick={(e) => {
            e.stopPropagation()
            window.open(`/api/download/${assetType}/${assetId}`)
          }}
        />
      </div>
    </Tooltip>
  )
}
