import { IVersionData, IReduxState } from 'client/types'
import { AnyAction } from 'redux'
import { SET_SELECTED_VERSION_INFO } from 'client/redux/actions/types'
import { ISetSelectedVersionAction } from 'client/redux/actions/version'

const initialState: IVersionData = {
  versionInfo: null
}

export const versionData = (
  state: IReduxState['versionData'] = initialState,
  action: AnyAction = { type: null }
) => {
  switch (action.type) {
    case SET_SELECTED_VERSION_INFO:
      return {
        ...state,
        versionInfo: (action as ISetSelectedVersionAction).selectedVersionInfo
      }

    default:
      return state
  }
}
