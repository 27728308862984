import api from 'client/util/api'
import resolveUnknownError from 'shared/util/resolveUnknownError'
import { useCallback, useEffect, useState } from 'react'

interface IApiHookOptions {
  onSuccess?: (result?: any) => void
  onError?: (e: Error) => void
}

type UsePostReturnType<T> = [(postBody?: any) => Promise<T | undefined>, boolean]
export function usePost<T>(url: string, options: IApiHookOptions = {}): UsePostReturnType<T> {
  const [loading, setLoading] = useState(false)
  const { onSuccess, onError } = options

  const doPost = useCallback(
    async (postBody?) => {
      try {
        setLoading(true)
        const { data } = await api.post<T>(url, postBody)
        setLoading(false)

        if (onSuccess) {
          await onSuccess(data)
        }
        return data
      } catch (e) {
        setLoading(false)
        if (onError) {
          await onError(resolveUnknownError(e))
        }
      }
    },
    [url, onSuccess, onError]
  )

  return [doPost, loading]
}

type UsePutReturnType<T> = [(putBody?: any) => Promise<T | undefined>, boolean]
export function usePut<T>(url: string, options: IApiHookOptions = {}): UsePutReturnType<T> {
  const [loading, setLoading] = useState(false)
  const { onSuccess, onError } = options

  const doPut = useCallback(
    async (putBody?) => {
      try {
        setLoading(true)
        const { data } = await api.put<T>(url, putBody)
        setLoading(false)

        if (onSuccess) {
          await onSuccess(data)
        }
        return data
      } catch (e) {
        setLoading(false)
        if (onError) {
          await onError(resolveUnknownError(e))
        }
      }
    },
    [url, onSuccess, onError]
  )

  return [doPut, loading]
}

type UseDeleteReturnType<T> = [() => Promise<T | undefined>, boolean]
export function useDelete<T>(url: string, options: IApiHookOptions = {}): UseDeleteReturnType<T> {
  const [loading, setLoading] = useState(false)
  const { onSuccess, onError } = options

  const doDelete = useCallback(async () => {
    try {
      setLoading(true)
      const { data } = await api.delete<T>(url)
      setLoading(false)

      if (onSuccess) {
        await onSuccess(data)
      }
      return data
    } catch (e) {
      setLoading(false)
      if (onError) {
        await onError(resolveUnknownError(e))
      }
    }
  }, [url, onSuccess, onError])

  return [doDelete, loading]
}

type UseGetReturnType<T> = [T | null, boolean, () => Promise<T | undefined>, number]
export function useGet<T>(url: string, options: IApiHookOptions = {}): UseGetReturnType<T> {
  const [loading, setLoading] = useState(true)
  const [value, setValue] = useState<T | null>(null)
  const [fetchCount, setFetchCount] = useState(0)
  const { onSuccess, onError } = options

  const doFetch = useCallback(async () => {
    try {
      setLoading(true)
      const { data } = await api.get<T>(url)
      setValue(data)
      setLoading(false)

      if (onSuccess) {
        await onSuccess(data)
      }
      return data
    } catch (e) {
      setLoading(false)
      if (onError) {
        await onError(resolveUnknownError(e))
      }
    } finally {
      setFetchCount((currentFetchCount) => currentFetchCount + 1)
    }
  }, [onError, onSuccess, url])

  useEffect(() => {
    doFetch().catch((e) => {
      // eslint-disable-next-line no-console
      console.log(e)
    })
  }, [url, onSuccess, onError, doFetch])

  return [value, loading, doFetch, fetchCount]
}
