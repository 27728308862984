import {
  Dialog,
  DIALOG_MAX_WIDTH,
  DialogActions,
  DialogBody,
  DialogHeader
} from 'client/dsm/Dialog/Dialog'
import Button from 'client/components/Button/Button'
import { t } from 'client/i18n'
import { useState } from 'react'
import { RadioButton, RadioButtonGroup } from 'client/components/RadioButton/RadioButton'
import styled from 'styled-components'
import WarningSVG from 'client/assets/svg/icon/status_warning_20.svg'
import { Trans } from 'react-i18next'

const StyledDialog = styled(Dialog)`
  min-width: ${DIALOG_MAX_WIDTH};
  max-width: min-content;
`

const StyledButton = styled(Button)`
  white-space: nowrap;
`

const IconWrapper = styled.i`
  & svg {
    display: inline;
  }
`

const Warning = styled.p`
  display: flex;
  gap: var(--spacing-xsmall);
  color: var(--color-orange-08);
`

interface IUpdateItemVisibilityDialogProps {
  contentName: string
  onDownload: () => void
  onClose: () => void
}

export default function GenerateQRCodesDialog({
  contentName,
  onDownload,
  onClose
}: IUpdateItemVisibilityDialogProps) {
  const [isOnsite, setIsOnsite] = useState(true)

  return (
    <StyledDialog>
      <DialogHeader>
        <Trans i18nKey="Get QR codes for __contentName__" values={{ contentName }} />
      </DialogHeader>
      <DialogBody>
        <p>{t('Where will you use the codes?')}</p>
        <RadioButtonGroup
          value={isOnsite.toString()}
          name="whereQRCodeIsUsed"
          onChange={(value) => {
            setIsOnsite(value === 'true')
          }}
        >
          <RadioButton label={t('On site (e.g., lookup)')} value="true" />
          <RadioButton label={t('Off site (e.g., print and digital marketing)')} value="false" />
        </RadioButtonGroup>

        <hr />
        {isOnsite ? (
          <Warning>
            <IconWrapper>
              <WarningSVG />
            </IconWrapper>
            {t(
              'Using on-site codes for other purposes may impact the accuracy of your usage data.'
            )}
          </Warning>
        ) : (
          <p>{t('The Bloomberg Connects marketing team creates off-site QR codes for you.')}</p>
        )}
      </DialogBody>
      <DialogActions>
        <Button onClick={onClose} label={t('Cancel')} />
        {isOnsite ? (
          <StyledButton
            type="primary"
            onClick={onDownload}
            label={t('Download QR Code for Lookup')}
          />
        ) : (
          <Button
            type="primary"
            onClick={() => {
              window.open('mailto:marketing@bloombergconnects.org')
              onClose()
            }}
            label={t('Contact Marketing')}
          />
        )}
      </DialogActions>
    </StyledDialog>
  )
}
