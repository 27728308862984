import PlusIconAddButton from 'client/components/Button/PlusIconAddButton'
import { useNavigate } from 'react-router-dom'

interface IGridPlusIconAddButton {
  label: string
  to: string
}

const GridPlusIconAddButton = ({ label, to }: IGridPlusIconAddButton) => {
  const navigate = useNavigate()
  return <PlusIconAddButton type="primary" label={label} onClick={() => navigate(to)} />
}

export default GridPlusIconAddButton
