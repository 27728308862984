import { extractGQLError } from 'client/util/extractDisplayErrorMessage'
import { t } from 'client/i18n'

interface IGQLErrorRendererProps {
  error: any
}

export default function GQLErrorRenderer(props: IGQLErrorRendererProps) {
  // NOTE: Need to work with IX on error states
  const { error } = props
  return (
    <div>
      <h1>{t('Error!')}</h1>
      <pre>{extractGQLError(error)}</pre>
    </div>
  )
}
