import _ from 'lodash'
import { useDispatch } from 'react-redux'
import { useCallback, useMemo, useState } from 'react'
import tusdUpload from 'client/util/tusdUpload'
import { showChangesSavedToast } from 'client/redux/actions/toast'
import { extractGQLData } from 'client/util/graphql'
import gql from 'graphql-tag'
import { useApolloClient } from '@apollo/client'
import { GQLFileInput } from 'shared/graphql/types/graphql'
import { useErrorDialog } from 'client/components/ErrorDialog'
import { refetchActiveQueries } from 'client/apollo'
import { t } from 'client/i18n'
import resolveUnknownError from 'shared/util/resolveUnknownError'

export type IUploadMediaFunction = (files: FileList) => Promise<number[]>
type IUseMediaUploadResult = {
  uploadMedia: IUploadMediaFunction
  isUploading: boolean
  loadingText: string | undefined
  errorDialog?: React.ReactNode
}

export enum UploadMediaType {
  IMAGE = 'Image',
  AUDIO = 'Audio',
  VIDEO = 'Video'
}

type FilesUploadProgress = { [fileName: string]: { bytesUploaded: number; bytesTotal: number } }

export default function useMediaUpload(mediaType: UploadMediaType): IUseMediaUploadResult {
  const dispatch = useDispatch()
  const apolloClient = useApolloClient()
  const [errorDialog, setError] = useErrorDialog()

  const createMedia = useCallback(
    async (file: GQLFileInput) => {
      const mutation = gql`
        mutation create${mediaType}FromFile($museumId: Int!, $file: FileInput!) {
          create${mediaType}FromFile(museumId: $museumId, file: $file) {
            id
          }
        }
      `
      const { data } = await apolloClient.mutate({
        mutation,
        variables: { file },
        // note we want to disable refetching all active queries for this call for each mutate call.
        // we refetchActiveQueries at the end of resolving all createMedia calls.
        refetchQueries: null as any // TODO not sure how to handle this (without the type assertion) in a nonbreaking way
      })

      return extractGQLData(data).id as number
    },
    [apolloClient, mediaType]
  )

  const [isUploading, setUploading] = useState(false)
  const [uploadPercentage, setUploadPercentage] = useState<number | undefined>()

  const loadingText = useMemo(
    () =>
      _.isFinite(uploadPercentage)
        ? // eslint-disable-next-line docent/require-translation-keys-to-be-literals
          t(`Uploading ${mediaType}(s) __uploadPercentage__%`, { uploadPercentage })
        : undefined,
    [mediaType, uploadPercentage]
  )

  const uploadMedia = useCallback(
    async (files: FileList) => {
      try {
        setUploading(true)
        setUploadPercentage(0)

        const totalBytes = _.sumBy(files, 'size')
        const fileUploadProgressInfo: FilesUploadProgress = {}
        const uploadedMediaIds: number[] = []

        /* eslint-disable no-await-in-loop */
        for (const file of files) {
          const url = await tusdUpload(file, (bytesUploaded, bytesTotal) => {
            // can't simply just accumulate bytesUploaded. It will contain the total bytes uploaded
            // and in addition, at 100% it is called multiple times.
            fileUploadProgressInfo[file.name] = { bytesUploaded, bytesTotal }
            const totalBytesUploaded = _(fileUploadProgressInfo).values().sumBy('bytesUploaded')
            setUploadPercentage(Math.round((totalBytesUploaded / totalBytes) * 100))
          })

          uploadedMediaIds.push(await createMedia({ name: file.name, url }))
        }
        /* eslint-enable no-await-in-loop */
        dispatch(showChangesSavedToast())
        return uploadedMediaIds
      } catch (e) {
        const error = resolveUnknownError(e)
        setError({ title: t('Unable to Upload Files'), error })
        throw error
      } finally {
        refetchActiveQueries()
        setUploading(false)
      }
    },
    [createMedia, dispatch, setError]
  )

  return { uploadMedia, isUploading, loadingText, errorDialog }
}
