import { useSelector } from 'react-redux'
import { getAuthData } from 'client/redux/selectors/auth'
import UserMFAType from 'shared/UserMFAType'
import { IMFAProps } from './types'
import GoogleAuthMFA from './GoogleAuthMFA'
import EmailMFA from './EmailMFA'

const MFA = (props: IMFAProps) => {
  const auth = useSelector(getAuthData)
  return auth.mfaType === UserMFAType.EMAIL ? <EmailMFA /> : <GoogleAuthMFA {...props} />
}

export default MFA
