import { ChangeEvent } from 'react'
import * as React from 'react'
import styled from 'styled-components'
import CheckmarkSVG from 'client/assets/svg/icon/check_20.svg'
import { Body1CSS } from 'client/components/TextStyles'

const Container = styled.div`
  ${Body1CSS};
  display: flex;
  * + label {
    margin-left: 11px;
  }
`
interface CheckboxContainerProps {
  checked?: boolean
  disabled?: boolean
}

const CheckboxContainer = styled.div<CheckboxContainerProps>`
  width: 18px;
  height: 18px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ checked, disabled }) => {
    if (disabled) {
      return 'var(--color-grey-06)'
    }
    return checked ? 'var(--color-accent-01)' : 'var(--color-white)'
  }};

  border: ${({ checked }) => (checked ? 'none' : '1px solid var(--input-border-normal)')};
  border-radius: 2px;
  position: relative;
`

const CheckedSVG = styled(CheckmarkSVG)`
  color: white;
  width: 16px;
  height: 16px;
`

const HiddenInput = styled.input`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
`

const Label = styled.label`
  margin: 0;
  display: inline;
  font-weight: var(--font-weight-regular);
`

interface CheckboxProps {
  checked: boolean
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  name: string
  disabled?: boolean
  label?: React.ReactNode
  className?: string
}

const Checkbox = styled((props: CheckboxProps) => {
  const { checked = false, disabled, name, onChange, label, className, ...rest } = props

  return (
    <Container className={className} {...rest}>
      <CheckboxContainer checked={checked} disabled={disabled}>
        <HiddenInput
          type="checkbox"
          checked={checked}
          onChange={onChange}
          disabled={disabled}
          name={name}
          id={name}
        />
        {checked && <CheckedSVG />}
      </CheckboxContainer>
      {label && <Label htmlFor={name}>{label}</Label>}
    </Container>
  )
})``

export const CheckboxGroup = styled.div`
  padding-top: var(--spacing-large);
  ${Checkbox} {
    height: 36px;
    align-items: center;
  }
`

export default Checkbox
