import styled, { keyframes } from 'styled-components'
import { SplashScreenPreviewFooter } from 'shared/constants/splash'

export const footerBottomMargin = 25
export const cropBoxTopMargin = 20

export const TipsContainer = styled.div`
  position: absolute;
  top: 14%;
  left: 3%;
  width: 23%;
  @media (max-width: 1000px) {
    display: none;
  }
`

export const FooterContainer = styled.footer`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: ${SplashScreenPreviewFooter.height + footerBottomMargin}px;
  background-color: var(--color-white);
`

interface IFooterProps {
  width: number
  left: number
}

export const Footer = styled.div<IFooterProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - ${footerBottomMargin}px);
  width: ${({ width }) => width}px;
  background-color: var(--color-white);
  left: ${({ left }) => left}px;
  position: relative;
  border: var(--border-light);
  border-top: none;
  box-shadow: var(--elevation-01);
`

export const ErrorText = styled.span`
  color: var(--color-white);
  text-align: center;
`

const overlayFadeIn = keyframes`
  from {
    opacity: 0
  }

  to {
    opacity: 0.5
  }
`

const overlayFadeOut = keyframes`
  from {
    opacity: 0.5
  }

  to {
    opacity: 0
  }
`

interface IErrorOverlayContainerProps {
  top: number
  left: number
  height: number
  width: number
  filledIn: boolean
}

export const ErrorOverlayContainer = styled.div<IErrorOverlayContainerProps>`
  pointer-events: none;
  position: absolute;
  z-index: 1;
  top: ${({ top }) => top}px;
  left: ${({ left }) => left}px;
  height: ${({ height }) => height}px;
  width: ${({ width }) => width}px;
  animation: ${({ filledIn }) => (filledIn ? overlayFadeIn : overlayFadeOut)} 0.5s;
  opacity: ${({ filledIn }) => (filledIn ? 0.5 : 0)};
  background-color: var(--color-status-error);
`

const messageFadeIn = keyframes`
  from {
    opacity: 0
  }

  to {
    opacity: 1
  }
`

const messageFadeOut = keyframes`
  from {
    opacity: 1
  }

  to {
    opacity: 0
  }
`

interface IErrorMessageProps {
  filledIn: boolean
}

export const ErrorMessage = styled.div<IErrorMessageProps>`
  animation: ${({ filledIn }) => (filledIn ? messageFadeIn : messageFadeOut)} 0.5s;
  opacity: ${({ filledIn }) => (filledIn ? 1 : 0)};
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin: 30px;
  background-color: var(--color-black);
  padding: 10px;
  z-index: 10;
  box-shadow: var(--elevation-01);
  border-radius: 3px;
`

interface IErrorBorderContainer {
  active: boolean
  top: number
  left: number
  height: number
  width: number
}

export const ErrorBorderContainer = styled.div<IErrorBorderContainer>`
  pointer-events: none;
  position: absolute;
  animation: ${({ active }) => (active ? messageFadeIn : messageFadeOut)} 0.5s;
  opacity: ${({ active }) => (active ? 1 : 0)};
  z-index: 10;
  top: ${({ top }) => top}px;
  left: ${({ left }) => left}px;
  height: ${({ height }) => height}px;
  width: ${({ width }) => width}px;
  border: 2px solid var(--color-status-error);
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`
