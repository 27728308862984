import { useMemo } from 'react'
import _ from 'lodash'
import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { getAvailableLookupNumbers } from 'client/util/lookupNumbers'

const LOOKUP_NUMBER_QUERY = gql`
  query lookupNumbers($museumId: Int!) {
    museum(id: $museumId) {
      id
      unavailableLookupNumbers
    }
  }
`

export interface IUsedLookupMapping {
  // Mapping from oldUsedLookupNumber to the newUsedLookupNumber
  // Note: a mapping from number-to-null means the number is no longer used
  [oldUsedLookupNumber: number]: number
}

const useAvailableLookupNumbers = (usedLookupMapping: IUsedLookupMapping) => {
  const { data } = useQuery(LOOKUP_NUMBER_QUERY)
  const allAvailableLookupNumbers = useMemo(
    () => getAvailableLookupNumbers(data?.museum?.unavailableLookupNumbers),
    [data?.museum?.unavailableLookupNumbers]
  )

  const allAvailableLocalNumbers = useMemo(() => {
    const numbersToAdd = _(usedLookupMapping).keys().map(Number).value()
    const numbersToRemove = _.values(usedLookupMapping) as number[]
    return _(allAvailableLookupNumbers)
      .concat(numbersToAdd)
      .without(...numbersToRemove)
      .compact()
      .sortBy()
      .sortedUniq()
      .value()
  }, [allAvailableLookupNumbers, usedLookupMapping])

  return allAvailableLocalNumbers
}

export default useAvailableLookupNumbers
