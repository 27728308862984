import { ITableCellProps } from 'client/dsm/Table/Table'
import { t } from 'client/i18n'
import Spinner, { SpinnerSizeType } from 'client/dsm/Spinner/Spinner'
import ErrorSVG from 'client/assets/svg/icon/status_error_20.svg'
import styled from 'styled-components'
import { ReactNode } from 'react'
import Tooltip from 'client/dsm/Tooltip/Tooltip'

interface IMedia {
  isProcessing?: boolean
  isChildrenProcessing?: boolean
  processingError?: string | null
  isChildrenProcessingError?: boolean | null
}

const TooltipContainer = styled.div`
  position: absolute;
  left: 0;
`

interface IStatusTooltipProps {
  content: string
  icon: ReactNode
}
function StatusTooltip({ content, icon }: IStatusTooltipProps) {
  return (
    <TooltipContainer>
      <Tooltip content={content} placement="right">
        <div>{icon}</div>
      </Tooltip>
    </TooltipContainer>
  )
}
export function MediaStatusIcon(props: ITableCellProps<IMedia>) {
  const { rowData } = props
  const { isProcessing, isChildrenProcessing, processingError, isChildrenProcessingError } = rowData
  const isAnyProcessing = isProcessing || isChildrenProcessing
  const isAnyProcessingError = processingError || isChildrenProcessingError

  if (isAnyProcessing) {
    return (
      <StatusTooltip
        content={t('Media processing')}
        icon={<Spinner size={SpinnerSizeType.SMALL} />}
      />
    )
  }
  if (isAnyProcessingError) {
    return <StatusTooltip content={t('Unable to process')} icon={<ErrorSVG />} />
  }
}
