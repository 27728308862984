import styled from 'styled-components'
import MuseumVersionType from 'shared/MuseumVersionType'
import SelectBox from 'client/components/Form/SelectBox/SelectBox'
import { GQLMuseum } from 'shared/graphql/types/graphql'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { getSelectedVersionInfo } from 'client/redux/selectors/version'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import VersionsMenu from 'client/screens/App/Header/VersionsMenu'
import { setSelectedVersionInfo } from 'client/redux/actions/version'
import { ThemeType } from 'client/types'
import { t } from 'client/i18n'
import { format } from 'date-fns'
import { UNIVERSAL_DATE_FORMAT } from 'shared/constants/dates'

interface IVersionSelectOption {
  value: number
  label: string
  isLiveVersion: boolean
}

function versionToOption(version: GQLMuseum): IVersionSelectOption {
  let label
  if (version.versionType === MuseumVersionType.LIVE) {
    label = t('Live')
  } else if (version.versionType === MuseumVersionType.PREVIEW) {
    label = t('Draft')
  } else if (version.versionType === MuseumVersionType.ARCHIVE) {
    label = t('Archived __date__', { date: format(version.archiveDate!, UNIVERSAL_DATE_FORMAT) })
  }
  return {
    value: version.id,
    label,
    isLiveVersion: version.versionType === MuseumVersionType.LIVE
  }
}

const LiveVersionValueContainer = styled.span`
  padding: 2px 4px;
  color: var(--color-white);
  font-weight: 600;
  border-radius: 2px;
  background-color: var(--color-red-06);
`

const StyledSelectBox = styled(SelectBox)`
  .docent-select__control {
    padding-left: 12px;
  }
`

const GUIDE_QUERY = gql`
  query guide($id: Int!) {
    guide(id: $id) {
      id
      code
      visibilityType
      versions {
        id
        versionType
        archiveDate
      }
    }
  }
`

interface IProps {
  className?: string
}
const VersionSelectBox = ({ className }: IProps) => {
  const dispatch = useDispatch()
  const selectedVersionInfo = useSelector(getSelectedVersionInfo)

  const { data, loading } = useQuery(GUIDE_QUERY, {
    variables: { id: selectedVersionInfo.guideId }
  })

  if (loading || !data) {
    return null
  }

  const { guide } = data
  const { versions } = guide

  const selectedVersion = _.find(versions, { id: selectedVersionInfo.id })
  if (!selectedVersion) {
    return null
  }

  // A guide's versions are ordered based on versionType (live, draft, archive)
  // and for archived versions based on archived date (newest to oldest)
  const options = _(versions as GQLMuseum[])
    .orderBy(
      [
        (version) =>
          [MuseumVersionType.LIVE, MuseumVersionType.PREVIEW, MuseumVersionType.ARCHIVE].indexOf(
            version.versionType as MuseumVersionType
          ),
        (version) => version.archiveDate
      ],
      ['asc', 'desc']
    )
    .map(versionToOption)
    .value()

  const handleChange = (e) => {
    const { id, versionType } = _.find(versions, { id: e.target.value })
    dispatch(setSelectedVersionInfo({ guideId: guide.id, id, versionType }))
  }

  return (
    <>
      <StyledSelectBox
        theme={ThemeType.DARK}
        className={className}
        options={options}
        value={versionToOption(selectedVersion)}
        onChange={handleChange}
        isSearchable={false}
        name="version-select-box"
        formatOptionLabel={(option, meta) => {
          // TODO StyledSelectBox could probably be made to properly thread-through the proper
          //      generic type params. But we assert the type here instead, for now
          if ((option as IVersionSelectOption).isLiveVersion && meta.context === 'value') {
            return <LiveVersionValueContainer>{option.label}</LiveVersionValueContainer>
          }
          return option.label
        }}
      />
      <VersionsMenu guide={guide} selectedVersion={selectedVersion} />
    </>
  )
}

export default styled(VersionSelectBox)``
