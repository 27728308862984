import IVersionInfo from 'shared/VersionInfo'

let versionInfo: IVersionInfo

export function setVersionInfo(newVersionInfo: IVersionInfo) {
  versionInfo = newVersionInfo
}

export function getVersionInfo() {
  return versionInfo
}
