import * as React from 'react'
import { t } from 'client/i18n'

interface IConfirmation {
  title: React.ReactNode
  message: React.ReactNode
}

export const getBuildingCreateConfirmationMessage = (): IConfirmation => {
  const message = (
    <>
      <p>{t('To support multiple buildings, an Exterior Map is required.')}</p>
      <p>
        {t(
          'An exterior map should include all buildings and will be shown in the Map Tab of the app by default.'
        )}
      </p>
    </>
  )
  const title = t('Add Exterior Map First')
  return { message, title }
}

export const getExteriorMapCreateConfirmationMessage = (): IConfirmation => {
  const message = (
    <p>
      {t(
        'Add the Exterior Map to help visitors orient themselves in your outdoor space (if any). The Exterior Map will be shown in the Map Tab of the app by default.'
      )}
    </p>
  )
  const title = t('Add Exterior Map')
  return { message, title }
}

export const getSuccessConfirmationMessage = (): IConfirmation => {
  const message = (
    <>
      <p>{t('The Exterior Map has been added.')}</p>
      <p>
        {t(
          'A pin has been added to the Exterior Map to represent your first building. You can adjust the position of the building pin.'
        )}
      </p>
    </>
  )
  const title = t('Success')
  return { message, title }
}
