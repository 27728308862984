import styled from 'styled-components'
import { t } from 'client/i18n'
import { Header3CSS } from 'client/components/TextStyles'

const PlaceHolderContainer = styled.div`
  width: 320px;
`
const PlaceHolderHeader = styled.div`
  ${Header3CSS};
  color: var(--color-grey-07);
`

export default function PlaceholderText() {
  return (
    <PlaceHolderContainer>
      <PlaceHolderHeader>{t('Great work! No suggestions.')}</PlaceHolderHeader>
      <p>
        {t(
          'Accessible content helps more people enjoy your guide. As you make updates, check back here for new suggestions.'
        )}
      </p>
    </PlaceHolderContainer>
  )
}
