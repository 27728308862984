import * as yup from 'yup'
import _ from 'lodash'
import { DisplayPeriodType } from 'shared/constants/exhibits'
import { RELATIONSHIP_TYPE_MAX_LENGTH } from 'shared/validation/constants'
import { ExhibitionSchemaCore, TourSchemaCore, EventSchemaCore } from 'shared/validation/Exhibition'
import { t } from 'client/i18n'
import { isSameDay } from 'date-fns'

const SharedSchemaClient = {
  images: yup
    .array()
    .ensure()
    .min(1, `${t('Images')}: ${t('At least one image is required.')}`),
  relatedContent: yup
    .array()
    .of(
      yup.object().shape({
        relationshipType: yup
          .string()
          .label('Relationship Type')
          .nullable()
          .max(RELATIONSHIP_TYPE_MAX_LENGTH)
      })
    )
    .nullable(),
  displayPeriod: yup.boolean().test({
    // this test has to be associated with displayPeriod, in order for the inline error to work properly
    name: 'validate-display-period-client',
    test: (value, context) => {
      const { displayPeriodType, from, to, isAllDay } = context.parent

      const hasDateRangeFormatError =
        value && displayPeriodType === DisplayPeriodType.DATE_RANGE && _.some([to, from], _.isNil)

      if (hasDateRangeFormatError) {
        return context.createError({
          message: `${t('Date Range')}: ${t(
            'Both Start Date and End Date are required in the YYYY-MM-DD format.'
          )}`
        })
      }

      const hasFromComesAfterToError =
        value &&
        displayPeriodType === DisplayPeriodType.DATE_RANGE &&
        !_.some([to, from], _.isNil) &&
        from > to

      if (hasFromComesAfterToError) {
        return context.createError({
          message: `${t('Date Range')}: ${t('The Start Date cannot be later than the End Date.')}`
        })
      }
      // check for event date when All Day is toggled on
      if (isAllDay && !isSameDay(from, to) && from > to) {
        return context.createError({
          message: `${t('Date/Time')}: ${t('The Start Date cannot be later than the End Date.')}`
        })
      }

      return true
    }
  })
}

export const ExhibitionSchemaClient = ExhibitionSchemaCore(t).shape(SharedSchemaClient)

export const TourSchemaClient = TourSchemaCore(t).shape(SharedSchemaClient)

export const EventSchemaClient = EventSchemaCore(t).shape(SharedSchemaClient)
