import _ from 'lodash'

export function createHasMediaProcessingErrorTest(name: string, message: string) {
  return {
    name: `${name}-media-processing-error`,
    test: (value, context) => {
      const { processingError, file } = context.parent
      return !_.isEmpty(processingError) && !file
        ? context.createError({
            message
          })
        : true
    }
  }
}

export function createRequiredIfNoProcessingErrorTest(name: string, message: string) {
  return {
    name: `${name}-required-if-no-processing-error`,
    test: (value, context) => {
      const { isProcessing, processingError } = context.parent
      return !isProcessing && _.isEmpty(processingError) && !value
        ? context.createError({
            message
          })
        : true
    }
  }
}
