import ContextualHelp, {
  ContextualHelpTextSection
} from 'client/components/ContextualHelp/ContextualHelp'
import SupportLink from 'client/components/SupportLink'
import { t } from 'client/i18n'
import { Trans } from 'react-i18next'

const DefaultLanguageContextualHelp = () => (
  <ContextualHelp
    helpSize="large"
    header={t('Default Language')}
    helpContent={
      <ContextualHelpTextSection>
        <p>{t('The default language cannot be removed.')}</p>
        <p>
          <Trans
            i18nKey="To change your guide’s default language, email support"
            components={{ SupportLink: <SupportLink /> }}
          />
        </p>
      </ContextualHelpTextSection>
    }
    tooltipContent={t('More on your default language')}
  />
)

export default DefaultLanguageContextualHelp
