import IconOnlyButton from 'client/components/IconButton/IconOnlyButton'
import XSVG from 'client/assets/svg/icon/x_20.svg'
import Tooltip from 'client/dsm/Tooltip/Tooltip'
import { t } from 'client/i18n'

interface XIconButtonTooltipProps {
  className?: string
  onRemove?: () => void
}
const XIconButtonTooltip = ({ className, onRemove }: XIconButtonTooltipProps) => {
  return (
    <Tooltip content={t('Remove')}>
      <div className={className}>
        <IconOnlyButton svgComponent={XSVG} onClick={onRemove} />
      </div>
    </Tooltip>
  )
}

export default XIconButtonTooltip
