import { AnyAction } from 'redux'
import _ from 'lodash'
import UserMFAType from 'shared/UserMFAType'
import * as types from '../actions/types'
import { AuthenticationStatus, IAuthState } from '../../types'

const initialState: IAuthState = {
  authStatus: AuthenticationStatus.UNKNOWN,
  isLoading: false,
  mfaPassed: false,
  mfaEnabled: false,
  mfaEnrolled: false,
  mfaType: UserMFAType.TOTP,
  qrDataURL: null,
  error: null,
  user: null,
  name: null,
  permissionType: null,
  isPasswordExpired: false,
  // By default we don't want to show the privacy policy unless explicitly triggered by
  // the server which in turn affects this state, hence setting to a default beginning epoch date.
  privacyPolicyAcceptedAt: new Date(0),
  isUserLoggingInForFirstTime: false,
  firstName: null,
  lastName: null,
  email: null
}

export const authData = (state: IAuthState = initialState, action: AnyAction = { type: null }) => {
  switch (action.type) {
    case types.LOGIN_START: {
      return {
        ...state,
        ...initialState,
        authStatus: AuthenticationStatus.UNAUTHORIZED,
        isLoading: true
      }
    }
    case types.LOGIN_SUCCESS: {
      return {
        ...state,
        ..._.pick(action, _.keys(initialState)),
        authStatus: AuthenticationStatus.AUTHORIZED,
        isLoading: false,
        mfaPassed: false,
        error: null
      }
    }
    case types.LOGIN_ERROR: {
      return {
        ...state,
        ...initialState,
        authStatus:
          action?.error.statusCode !== 429
            ? AuthenticationStatus.UNAUTHORIZED
            : AuthenticationStatus.ACCESS_TIME_RESTRICTED,
        error: action.error
      }
    }

    case types.CLEAR_AUTH_ERROR: {
      return { ...state, error: null }
    }

    case types.AUTH_FAILED: {
      return {
        ...state,
        ...initialState,
        authStatus:
          action?.error.statusCode !== 429
            ? AuthenticationStatus.UNAUTHORIZED
            : AuthenticationStatus.ACCESS_TIME_RESTRICTED,
        error: action.error
      }
    }

    case types.LOGOUT_SUCCESS: {
      return {
        ...state,
        ...initialState,
        authStatus: AuthenticationStatus.UNAUTHORIZED
      }
    }
    case types.FETCH_TOKEN_SUCCESS: {
      return {
        ...state,
        ..._.pick(action, _.keys(initialState)),
        authStatus: action.mfaPassed
          ? AuthenticationStatus.VERIFIED
          : AuthenticationStatus.AUTHORIZED,
        isLoading: false
      }
    }
    case types.MFA_VERIFICATION_START: {
      return {
        ...state,
        error: null
      }
    }
    case types.MFA_VERIFICATION_SUCCESS: {
      return {
        ...state,
        authStatus: AuthenticationStatus.VERIFIED,
        mfaEnrolled: true,
        mfaPassed: true,
        error: null
      }
    }
    case types.MFA_VERIFICATION_FAILURE: {
      return {
        ...state,
        mfaPassed: false,
        error: action.error
      }
    }
    case types.MFA_FETCH_QR_URL_SUCCESS: {
      return {
        ...state,
        qrDataURL: action.qrDataURL
      }
    }
    case types.RESET_APP: {
      return {
        ...state,
        authStatus: AuthenticationStatus.UNAUTHORIZED
      }
    }
    case types.USER_RESET_PASSWORD: {
      return {
        ...state,
        isLoading: true,
        error: null
      }
    }

    case types.USER_RESET_PASSWORD_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    }
    case types.AUTH_ACCESS_TIME_RESTRICTED: {
      return {
        ...state,
        isLoading: false,
        authStatus: AuthenticationStatus.ACCESS_TIME_RESTRICTED,
        error: action.error
      }
    }

    default: {
      return state
    }
  }
}
