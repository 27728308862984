import styled from 'styled-components'
import Button from 'client/components/Button/Button'
import { t } from 'client/i18n'
import { Header2CSS } from 'client/components/TextStyles'

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--color-grey-01);
  padding: var(--spacing-small) var(--spacing-medium);
`

const Buttons = styled.div`
  display: flex;
  align-items: center;

  > * {
    margin-right: var(--spacing-xsmall);
  }

  > :last-child {
    margin-right: 0;
  }
`

const Title = styled.div`
  ${Header2CSS};
  color: var(--color-black);
  margin-bottom: 0;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`

interface IHeaderProps {
  title: string
  onDelete?: () => void
  allowDelete?: boolean
  onCancel: () => void
  onSave: () => void
  enableSave?: boolean
  saveButtonLabel?: string
}

const FormHeader = (props: IHeaderProps) => {
  const {
    title,
    onDelete,
    allowDelete = true,
    onCancel,
    onSave,
    enableSave = true,
    saveButtonLabel = t('Save')
  } = props
  return (
    <Container>
      <Buttons>
        {allowDelete && onDelete && <Button onClick={onDelete} label={t('Delete')} />}
      </Buttons>
      <Title>{title}</Title>
      <Buttons>
        <Button onClick={onCancel} label={t('Cancel')} />
        <Button onClick={onSave} type="primary" disabled={!enableSave} label={saveButtonLabel} />
      </Buttons>
    </Container>
  )
}

export default FormHeader
