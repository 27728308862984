import * as React from 'react'
import styled from 'styled-components'
import FormField from 'client/components/Form/FormField/FormField'
import { HeaderTitle, NoteText } from 'client/components/Form/Section/Section'
import { SectionDivider } from 'client/screens/Catalog/forms/shared/styledComponents'

const Label = styled(HeaderTitle)``

const Description = styled(NoteText)`
  padding-top: 12px;
`

export interface IFormFieldSectionProps {
  label?: React.ReactNode
  description?: React.ReactNode
  className?: string
  headerContainer?: React.ElementType
  children?: React.ReactNode
  hideDivider?: boolean
}
export const BaseFormFieldSection = (props: IFormFieldSectionProps) => {
  const { label: rawLabel, description: rawDescription, hideDivider, ...rest } = props
  const label = rawLabel && <Label>{rawLabel}</Label>
  const description = rawDescription && <Description>{rawDescription}</Description>

  return (
    <>
      {!hideDivider && <SectionDivider />}
      <FormField label={label} description={description} {...rest} />
    </>
  )
}
// Note: exported as styled in order to allow selecting it inside styled components
export default styled(BaseFormFieldSection)``
