import { PickerItemRow } from 'client/components/ContentRow/PickerRow'
import { useCallback } from 'react'
import gql from 'graphql-tag'
import DataAwareReorderableList from 'client/components/Formik/ReorderableList/DataAwareReorderableList'
import { COMMON_CONTENT_FILTER_CRITERIA } from 'client/util/filters'
import { ExhibitionType } from 'shared/constants/exhibits'
import ExhibitItemRow from '../ItemRow/ExhibitItemRow'

const ITEMS_MINIPICKER_QUERY = gql`
  query itemsForMinipicker($museumId: Int!) {
    museum(id: $museumId) {
      id
      items {
        id
        date
        type
        published
        lookupNumber
        commonName
        scientificName
        title
        creators {
          id
          name
        }
        images {
          id
          full
          thumbnail
          hero
        }
        locales {
          code
          englishName
        }
        itemMapLocation {
          mapLocation {
            id
          }
        }
      }
    }
  }
`

const VALUE_FIELDS_WITH_HIGHER_PRIORITY = ['lookupNumber', 'itemMapLocation']

interface IItemsListProps {
  name: string
  lookupNumbers: number[]
  type: ExhibitionType
}
export default function ItemsList(props: IItemsListProps) {
  const { name, lookupNumbers, type } = props

  const RowComponent = useCallback(
    (itemProps) => <ExhibitItemRow {...itemProps} lookupNumbers={lookupNumbers} type={type} />,
    [lookupNumbers, type]
  )

  return (
    <DataAwareReorderableList
      name={name}
      rowComponent={RowComponent}
      pickerRowComponent={PickerItemRow}
      filterCriteria={COMMON_CONTENT_FILTER_CRITERIA}
      contentName="Item"
      gqlQuery={ITEMS_MINIPICKER_QUERY}
      valueFieldsWithHigherPriority={VALUE_FIELDS_WITH_HIGHER_PRIORITY}
    />
  )
}
