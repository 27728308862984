import { IReorderableRowWrapperProps } from 'client/components/ContentRow/types'
import {
  Container,
  Grabber,
  GrabberContainer,
  ReorderableRow,
  RightContentContainer
} from 'client/components/ContentRow/styledComponents'

export const ReorderableRowWrapper = <T extends object>(props: IReorderableRowWrapperProps<T>) => {
  const { className, isDragging, children, showGrabber = true } = props
  return (
    <ReorderableRow className={className} isDragging={isDragging}>
      <Container>
        {showGrabber && (
          <GrabberContainer>
            <Grabber />
          </GrabberContainer>
        )}
        <RightContentContainer>{children}</RightContentContainer>
      </Container>
    </ReorderableRow>
  )
}
