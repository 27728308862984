import Section from 'client/components/Form/Section/Section'
import ContentLookupNumberSelect from 'client/screens/Catalog/forms/shared/LookupNumber/ContentLookupNumberSelect'
import useExhibitLookupMapping from 'client/screens/Catalog/forms/shared/LookupNumber/useExhibitLookupMapping'
import {
  LookupSectionContent,
  SelectContainer
} from 'client/screens/Catalog/forms/shared/styledComponents'
import { t } from 'client/i18n'

interface IExhibitLookupNumberSelect {
  name: string
}
const ExhibitLookupNumberSelect = (props: IExhibitLookupNumberSelect) => {
  const { name } = props
  const usedExhibitLookupMapping = useExhibitLookupMapping()
  return (
    <LookupSectionContent>
      <Section title={t('Lookup Number')} />
      <SelectContainer>
        <ContentLookupNumberSelect name={name} usedLookupMapping={usedExhibitLookupMapping} />
      </SelectContainer>
    </LookupSectionContent>
  )
}

export default ExhibitLookupNumberSelect
