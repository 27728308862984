import { PropsWithChildren, useMemo } from 'react'
import Tooltip from 'client/dsm/Tooltip/Tooltip'
import styled from 'styled-components'

const TooltipIncludedInContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`

interface MultilineTooltipProps {
  lines: string[]
}

const MultilineTooltip = (props: PropsWithChildren<MultilineTooltipProps>) => {
  const { lines, children } = props
  const content = useMemo(() => lines.map((line) => <div key={line}>{line}</div>), [lines])
  return (
    <Tooltip
      content={<TooltipIncludedInContent>{content}</TooltipIncludedInContent>}
      placement="bottom"
    >
      <div>{children}</div>
    </Tooltip>
  )
}

export default MultilineTooltip
