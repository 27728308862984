import { ITime } from '../json/IMuseumLocationDay'

export const WEEKDAY_SORT_ORDER = {
  Monday: 0,
  Tuesday: 1,
  Wednesday: 2,
  Thursday: 3,
  Friday: 4,
  Saturday: 5,
  Sunday: 6
}

export function timeToString(time: ITime) {
  return `${time.hour.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false
  })}:${time.minute.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })}:00`
}

export function timeFromString(timeString: string): ITime {
  const splitAry = timeString.split(':')

  const hourNum = Number(splitAry[0])
  const minuteNum = Number(splitAry[1])

  const hour = hourNum < 24 && hourNum >= 0 ? hourNum : null
  const minute = minuteNum < 60 && minuteNum >= 0 ? minuteNum : null

  return {
    hour,
    minute
  } as ITime
}
