import { Route, Routes } from 'react-router-dom'
import Login from 'client/screens/Login'
import ManyFailedLoginAttempts from 'client/screens/Login/ManyFailedLoginAttempts'
import ForgotPassword from 'client/screens/Login/ForgotPassword'
import MFA from 'client/screens/MFA'
import PasswordReset from 'client/screens/Password/PasswordReset'
import PrivacyNotice from 'client/screens/PrivacyNotice/PrivacyNotice'
import styled, { keyframes } from 'styled-components'

const gradientAnimation = keyframes`
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
`

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 100%;
  width: 100%;
  background: linear-gradient(
    60deg,
    #d33454 20%,
    #f1792e,
    #f3bd30,
    #45c1c0,
    #003bc2,
    #ac4399,
    #d33454,
    #f1792e,
    #f3bd30,
    #45c1c0,
    #003bc2,
    #ac4399,
    #d33454
  );
  background-size: 700% 700%;
  animation: ${gradientAnimation} 210s linear infinite;
`

const Unauthenticated = () => (
  <Container>
    <Routes>
      <Route path="/auth/login/restricted" element={<ManyFailedLoginAttempts />} />
      <Route path="/auth/login/forgot-password" element={<ForgotPassword />} />
      <Route path="/auth/login" element={<Login />} />
      <Route path="/auth/mfa-enrollment" element={<MFA enrolling={true} />} />
      <Route path="/auth/mfa-verification" element={<MFA />} />
      <Route path="/auth/reset-password" element={<PasswordReset />} />
      <Route path="/auth/acknowledge-privacy-notice" element={<PrivacyNotice />} />
    </Routes>
  </Container>
)

export default Unauthenticated
