import { ThemeType } from 'client/types'
import _ from 'lodash'

function getBorderColor(state, theme?: ThemeType) {
  if (state.selectProps.hasError) {
    return 'var(--color-status-error)'
  }

  if (theme === ThemeType.DARK) {
    return 'var(--color-accent-01)'
  }

  if (state.isFocused) {
    return 'var(--input-border-focus)'
  }
  if (state.hasValue) {
    return 'var(--input-border-normal)'
  }
  return 'var(--input-border-placeholder)'
}

function getTextColor(state) {
  if (state.selectProps.value) {
    return 'var(--input-text-normal)'
  }
  return 'var(--color-grey-07)'
}

const DefaultThemeStyles = {
  container: (base, state) => {
    return {
      ...base,
      fontSize: 14,
      backgroundColor: 'var(--color-white)',
      opacity: state.isDisabled ? 0.3 : 1
    }
  },
  control: (base) => ({
    ...base,
    paddingLeft: 16,
    paddingRight: 8,
    borderRadius: '2px',
    border: '1px solid var(--input-border-normal)',
    height: 40
  }),
  group: (base) => ({
    ...base,
    padding: '8px 0px',
    // Styles for sibling group
    '+ *': {
      paddingTop: 0
    }
  }),
  groupHeading: (base) => ({
    ...base,
    padding: '8px 12px 4px 12px',
    textTransform: 'none',
    fontSize: '12px',
    lineHeight: '18px',
    color: 'var(--color-grey-06)',
    fontWeight: 'var(--font-weight-semi-bold)',
    margin: 0
  }),
  option: (base, state) => {
    const { isSelected, options, data } = state
    const isGroupOption = _.has(options[0], 'options')

    return {
      ...base,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: state.isFocused ? 'var(--color-grey-01)' : 'var(--color-white)',
      '&:hover': {
        backgroundColor: state.isFocused ? 'var(--color-grey-01)' : 'var(--color-white)'
      },
      '&:active': {
        backgroundColor: 'var(--color-grey-02)'
      },
      color: data?.isError ? 'var(--color-status-error)' : 'var(--color-black)',
      cursor: 'pointer',
      minHeight: 40,
      padding: !isGroupOption ? '8px 16px' : '0px 12px',
      svg: {
        color: !isSelected && data?.isError ? 'var(--color-status-error)' : 'var(--color-black)',
        flexShrink: 0,
        marginLeft: -4
      }
    }
  },
  singleValue: (base) => {
    return {
      ...base,
      // show ellipses on text overflow
      '> *': {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden'
      }
    }
  },
  menu: (base) => ({
    ...base,
    border: '1px solid var(--color-grey-02)',
    borderTop: '0px',
    borderRadius: '2px',
    backgroundColor: 'var(--color-white)'
  }),
  noOptionsMessage: (base) => ({
    ...base,
    color: 'var(--color-grey-03)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 40
  }),
  loadingMessage: (base) => ({
    ...base,
    minHeight: 40,
    padding: '8px 16px'
  })
}

export const LightThemeStyles = {
  ...DefaultThemeStyles,
  container: (base, state) => ({
    ...DefaultThemeStyles.container(base, state)
  }),
  control: (base, state) => {
    const backgroundColor = state.selectProps.selectedHighlightColor || 'var(--color-white)'

    const hoverBorderColor = state.isFocused
      ? 'var(--input-border-focus)'
      : 'var(--input-border-hover)'

    return {
      ...DefaultThemeStyles.control(base),
      backgroundColor,
      color: getTextColor(state),
      '&:hover': {
        borderColor: state.selectProps.hasError ? 'var(--color-status-error)' : hoverBorderColor,
        color: getTextColor(state)
      },
      borderColor: getBorderColor(state)
    }
  }
}

export const DarkThemeStyles = {
  ...DefaultThemeStyles,
  container: (base, state) => ({
    ...DefaultThemeStyles.container(base, state),
    backgroundColor: 'var(--color-accent-01)'
  }),
  control: (base, state) => {
    const backgroundColor = state.selectProps.selectedHighlightColor || 'var(--color-black)'
    return {
      ...DefaultThemeStyles.control(base),
      backgroundColor,
      color: 'var(--color-white)',
      '&:hover': {
        borderColor: state.selectProps.hasError ? 'var(--color-status-error)' : 'var(--color-white)'
      }
    }
  },
  dropdownIndicator: () => ({
    '& svg': { color: 'var(--color-white)' }
  })
}
