import styled from 'styled-components'
import Section from 'client/components/Form/Section/Section'
import Button from 'client/components/Button/Button'
import FormFieldComponent from 'client/components/Form/FormField/FormField'
import RefreshSVG from 'client/assets/svg/icon/refresh_20.svg'
import DownloadSVG from 'client/assets/svg/icon/download_20.svg'
import { UsageReportServiceJobType } from 'shared/constants/usageReport'
import { t } from 'client/i18n'
import { Body1CSS } from 'client/components/TextStyles'

const ReportRange = styled.div`
  ${Body1CSS};
  padding-bottom: var(--spacing-medium);
  label {
    font-weight: var(--font-weight-semi-bold);
    display: inline;
  }
  & > * + * {
    padding-left: var(--spacing-xsmall);
  }
`

const FormField = styled(FormFieldComponent)`
  label {
    margin-bottom: var(--spacing-xsmall);
  }
`

const InlineGroup = styled.div<{ paddingLeft: number }>`
  display: flex;
  & > * + * {
    padding-left: ${({ paddingLeft }) => paddingLeft}px;
  }
`

const StyledButton = styled(Button)`
  padding: 10px 20px;
`

interface ICompletedReportView {
  job: UsageReportServiceJobType
  isLoaner: boolean
  onDownload: () => any
  onNewReport: () => any
}

const CompletedReportView = ({ job, onDownload, onNewReport, isLoaner }: ICompletedReportView) => (
  <>
    <Section
      title={t('Download Report')}
      note={
        isLoaner
          ? t(
              'Your loaner device usage report is ready. Click the button below to download the report.'
            )
          : t('Your usage report is ready. Click the button below to download the report.')
      }
    />

    <ReportRange>
      <label>{t('Report Range:')}</label>
      <span>
        {t('ReportsCompletedReportViewReportRangeLabel', {
          startDate: job.startDate,
          endDate: job.endDate
        })}
      </span>
    </ReportRange>

    <InlineGroup paddingLeft={8}>
      <FormField>
        <Button
          type="primary"
          onClick={onDownload}
          svgComponent={DownloadSVG}
          label={t('Download Report')}
        />
      </FormField>
      <FormField>
        <StyledButton
          type="tertiary"
          onClick={onNewReport}
          svgComponent={RefreshSVG}
          label={t('New Report')}
        />
      </FormField>
    </InlineGroup>
  </>
)

export default CompletedReportView
