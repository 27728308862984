import { useEffect, useState } from 'react'
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom'
import _ from 'lodash'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import {
  fetchBuildings,
  fetchExterior,
  updateSelectedFloorPinId,
  fetchPinContent
} from 'client/redux/actions/maps'
import {
  didAttemptToLoad,
  getBuildings,
  didPositionUpdateFail,
  getSelectedFloorPinId,
  getMapById
} from 'client/redux/selectors/maps'
import { IReduxState } from 'client/types'
import PageContent, { MAX_PAGE_CONTENT_WIDTH } from 'client/components/PageContent/PageContent'
import useCoercedParam from 'client/hooks/useCoercedParam'
import { t } from 'client/i18n'
import MapSidebar from './MapSideBar'
import FloorPreview from './FloorPreview'
import ExteriorMapPreview from './ExteriorMapPreview'
import EmptyMapContentView from './MapEmptyContentView'

const MapEditor = styled.article`
  display: flex;
  height: 100%;
  overflow: hidden;
  width: 100%;
  max-width: ${MAX_PAGE_CONTENT_WIDTH}px;
`

const LeftColumn = styled.div`
  display: flex;
`

const RightColumn = styled.div`
  display: flex;
  flex-grow: 1;
`

const Editor = () => {
  const navigate = useNavigate()
  const buildingId = useCoercedParam<string>('buildingId')
  const floorId = useCoercedParam<number>('floorId')
  const isExteriorMapSelected = _.isNil(buildingId) && !_.isNil(floorId)

  const dispatch = useDispatch()
  const floor = useSelector((state: IReduxState) =>
    getMapById(state, { buildingId, floorId: Number(floorId) })
  )
  const didPositionChangeFail = useSelector(didPositionUpdateFail)
  const selectedPinId = useSelector(getSelectedFloorPinId)
  const attemptedToLoad = useSelector(didAttemptToLoad)

  useEffect(() => {
    if (floorId && selectedPinId) {
      dispatch(fetchPinContent(floorId, selectedPinId))
    }
  }, [floorId, selectedPinId, dispatch])

  useEffect(() => {
    // TODO There's an upcoming ticket to add a UI when update fails
    if (didPositionChangeFail) {
      dispatch(fetchBuildings())
    }
  }, [didPositionChangeFail, dispatch])

  const handleSelectedPinChanged = (id: number) => dispatch(updateSelectedFloorPinId(id))

  const handleOnFloorChanged = (changedBuildingId, changedFloorId) =>
    navigate(`/app-editor/maps/building/${changedBuildingId}/floor/${changedFloorId}`)

  const handleExteriorMapSelected = (id) => {
    navigate(`/app-editor/maps/exterior/${id}`)
  }

  const showEmptyState = _.isEmpty(floor) && attemptedToLoad

  const rightColumnContent = (
    <>
      {isExteriorMapSelected && (
        <ExteriorMapPreview
          selectedPinId={selectedPinId}
          onSelectedPinChanged={handleSelectedPinChanged}
        />
      )}
      {!isExteriorMapSelected && floor && (
        <FloorPreview
          floor={floor}
          selectedPinId={selectedPinId}
          onSelectedPinChanged={handleSelectedPinChanged}
        />
      )}
    </>
  )

  return (
    <>
      <LeftColumn>
        <MapSidebar
          selectedId={floorId}
          onSelect={handleOnFloorChanged}
          isExteriorMapSelected={isExteriorMapSelected}
          onExteriorMapSelected={handleExteriorMapSelected}
        />
      </LeftColumn>
      <RightColumn>{showEmptyState ? <EmptyMapContentView /> : rightColumnContent}</RightColumn>
    </>
  )
}

export default () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const buildings = useSelector(getBuildings)
  const attemptedToLoad = useSelector(didAttemptToLoad)
  const location = useLocation()
  // We only want to redirect if we're arriving here for the first time
  const [redirected, setRedirected] = useState(location.pathname.includes('building/'))

  useEffect(() => {
    dispatch(fetchBuildings())
    dispatch(fetchExterior())
  }, [dispatch])

  useEffect(() => {
    if (!redirected && attemptedToLoad && !_.isEmpty(buildings)) {
      const building = _.values(buildings)[0]
      const floor = _.find(building?.floors, { isDefault: true }) || building?.floors[0]
      const buildingUrl = building ? `building/${building.id}` : 'building'
      const redirectUrl = floor ? `${buildingUrl}/floor/${floor.id}` : `${buildingUrl}/floor`
      navigate(redirectUrl)
      setRedirected(true)
    }
  }, [buildings, attemptedToLoad, navigate, setRedirected, redirected, location])

  return (
    <PageContent title={t('Map')}>
      <MapEditor>
        <Routes>
          {/* They are all pointing to the same component but the component is making the decision to render specific component based on url. */}
          {/* We should split up <Editor /> into specific components to be used here. Doing so will remove the need for our redirected state. */}
          <Route path="building/:buildingId/floor/:floorId/*" element={<Editor />} />
          <Route path="exterior" element={<Editor />} />
          <Route path="exterior/:floorId/*" element={<Editor />} />
          <Route path="/*" element={<Editor />} />
        </Routes>
      </MapEditor>
    </PageContent>
  )
}
