import _ from 'lodash'
import SelectBox from 'client/components/Form/SelectBox/SelectBox'
import useField from 'client/hooks/useField'
import { t } from 'client/i18n'
import ErrorMessage from 'client/components/Formik/ErrorMessage/ErrorMessage'
import styled from 'styled-components'

const SelectBoxWrapper = styled.div`
  margin-top: var(--spacing-xsmall);
`

interface IEventTimeZoneSelectField {
  name: string
  onChange: (newTimezone: string) => void
}

function getTimeZoneName(timeZone: string) {
  const timeZoneName = Intl.DateTimeFormat('en', {
    timeZoneName: 'short',
    timeZone
  })
    .formatToParts()
    .find((p) => p.type === 'timeZoneName')?.value

  if (!timeZoneName) {
    return undefined
  }

  if (timeZoneName.startsWith('GMT+') || timeZoneName.startsWith('GMT-')) {
    return undefined
  }

  return timeZoneName
}

function getFormattedTimezoneOffset(timeZone: string) {
  return Intl.DateTimeFormat('en', {
    timeZoneName: 'longOffset',
    timeZone
  })
    .formatToParts()
    .find((p) => p.type === 'timeZoneName')?.value
}

function formatTimezone(timezone: string) {
  const timezoneName = getTimeZoneName(timezone)
  const formattedOffset = getFormattedTimezoneOffset(timezone)
  return _.compact([formattedOffset, timezone, timezoneName]).join(' ')
}

const TIMEZONE_OPTIONS = Intl.supportedValuesOf('timeZone').map((name) => {
  return {
    value: name,
    label: formatTimezone(name)
  }
})

// Manually add a UTC label to dropdown options as Intl.supportedValuesOf('timeZone') do not cover UTC
// Will be removed once done with UTC migration for all exhibits/tours
const TIMEZONE_OPTIONS_WITH_UTC = [{ value: 'UTC', label: 'GMT +00:00 UTC' }, ...TIMEZONE_OPTIONS]

const TimeZoneSelectField = (props: IEventTimeZoneSelectField) => {
  const { name, onChange } = props
  const { value: timezoneOption, error, touched } = useField(name)
  const hasError = error ? touched : false

  return (
    <SelectBoxWrapper>
      <SelectBox
        name={name}
        options={TIMEZONE_OPTIONS_WITH_UTC}
        onChange={({ target }) => {
          onChange(target.value)
        }}
        value={_.find(TIMEZONE_OPTIONS_WITH_UTC, { value: timezoneOption })}
        isSearchable={true}
        placeholder={t('Choose a time zone')}
        hasError={hasError}
      />
      {hasError && <ErrorMessage name={name} />}
    </SelectBoxWrapper>
  )
}

export default TimeZoneSelectField
