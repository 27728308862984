import { MutableRefObject, useEffect, useState } from 'react'

function useHover<T extends HTMLElement | null = HTMLElement | null>(ref: MutableRefObject<T>) {
  const [value, setValue] = useState(false)

  const handleMouseOver = () => setValue(true)
  const handleMouseOut = () => setValue(false)

  useEffect(() => {
    const node = ref.current
    if (node) {
      node.addEventListener('mouseover', handleMouseOver)
      node.addEventListener('mouseout', handleMouseOut)
      return () => {
        node.removeEventListener('mouseover', handleMouseOver)
        node.removeEventListener('mouseout', handleMouseOut)
      }
    }
  }, [ref])

  return value
}

export default useHover
