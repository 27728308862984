import { ITableCellProps } from 'client/dsm/Table/Table'
import { GQLAudio } from 'shared/graphql/types/graphql'
import styled from 'styled-components'
import { LeftGutterRendererWrapper } from 'client/screens/Catalog/grids/shared/LeftGutterWrapper'
import { MediaStatusIcon } from 'client/screens/Catalog/grids/shared/MediaStatusIcon'

const AudioElementWrapper = styled.div`
  display: flex;
  align-items: center;
  border-radius: 27px;
  border: 1px solid var(--color-grey-03);
`

const InnerAudioPreview = (props: ITableCellProps<GQLAudio>) => {
  const { cellData } = props
  return (
    <AudioElementWrapper>
      <audio
        src={cellData}
        onClick={(event) => event.stopPropagation()}
        controls={true}
        preload="metadata"
        controlsList="nodownload noplaybackrate"
      />
    </AudioElementWrapper>
  )
}

const AudioPreview = LeftGutterRendererWrapper(InnerAudioPreview, MediaStatusIcon)

export default AudioPreview
