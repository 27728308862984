import gql from 'graphql-tag'
import styled from 'styled-components'
import DataAwareReorderableList from 'client/components/Formik/ReorderableList/DataAwareReorderableList'
import { ReorderableImageRow } from 'client/components/ContentRow/ReorderableRow'
import { PickerImageRow } from 'client/components/ContentRow/PickerRow'
import { DefaultImageFormats } from 'client/constants'
import { UploadMediaType } from 'client/hooks/useMediaUpload'

export const MAX_IMAGES: number = 25

const IMAGES_QUERY = gql`
  query imagesForImageContainer($museumId: Int!) {
    museum(id: $museumId) {
      id
      contentImages {
        id
        fileName
        caption
        thumbnail
        hero
        sourceUrl
        isMarketingUseAllowed
        locales {
          code
          englishName
        }
      }
    }
  }
`

const StyledList = styled(DataAwareReorderableList)`
  margin-top: var(--spacing-small);
`

interface IImagesListProps {
  name: string
}

const ImagesList = ({ name }: IImagesListProps) => {
  return (
    <StyledList
      name={name}
      contentName="Image"
      gqlQuery={IMAGES_QUERY}
      rowComponent={ReorderableImageRow}
      pickerRowComponent={PickerImageRow}
      filterCriteria={['fileName', 'caption']}
      maxValues={MAX_IMAGES}
      uploadMedia={{
        uploadFileFormats: DefaultImageFormats,
        type: UploadMediaType.IMAGE
      }}
    />
  )
}

export default ImagesList
