import * as yup from 'yup'
import { AudioSchemaCore } from 'shared/validation/Audio'
import { t } from 'client/i18n'
import { createHasMediaProcessingErrorTest, createRequiredIfNoProcessingErrorTest } from './util'

export const AudioSchemaClient = AudioSchemaCore(t).shape({
  url: yup
    .string()
    .nullable()
    .test(
      createRequiredIfNoProcessingErrorTest(
        'audio',
        `${t('Audio')}: ${t('An audio file is required.')}`
      )
    ),
  processingError: yup
    .string()
    .nullable()
    .test(
      createHasMediaProcessingErrorTest('audio', `${t('Audio')}: ${t('Unable to process audio.')}`)
    )
})
