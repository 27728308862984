import styled from 'styled-components'
import ItemsList from 'client/components/PickerLists/ItemsList'
import useAvailableLookupNumbers from 'client/screens/Catalog/forms/shared/LookupNumber/useAvailableLookupNumbers'
import useExhibitLookupMapping from 'client/screens/Catalog/forms/shared/LookupNumber/useExhibitLookupMapping'
import { ExhibitionType } from 'shared/constants/exhibits'

const Container = styled.div`
  margin-top: var(--spacing-small);
`

interface IExhibitItemList {
  name: string
  type: ExhibitionType
}
const ExhibitItemList = (props: IExhibitItemList) => {
  const { name, type } = props
  const usedExhibitLookupMapping = useExhibitLookupMapping()
  const availableLookupNumbers = useAvailableLookupNumbers(usedExhibitLookupMapping)
  return (
    <Container>
      <ItemsList name={name} lookupNumbers={availableLookupNumbers} type={type} />
    </Container>
  )
}

export default ExhibitItemList
