import { ReactNode } from 'react'
import { ThemeProvider } from 'styled-components'
import {
  GQLItem,
  GQLExhibit,
  GQLAudio,
  GQLVideo,
  GQLImage,
  GQLCreator
} from 'shared/graphql/types/graphql'
import { IContent, ThemeType, IThemeObject } from 'client/types'
import { isItemContent } from 'shared/util/items'
import { IRowComponentProps } from 'client/components/MiniPicker/FilterableMiniPicker/FilterableMiniPicker'
import IGuidePickerJson from 'shared/json/IGuidePickerJson'
import { ContentContainer } from './styledComponents'
import {
  ReorderableAudioRow,
  ReorderableCreatorRow,
  ReorderableExhibitRow,
  ReorderableGuideRow,
  ReorderableImageRow,
  ReorderableItemRow,
  ReorderableVideoRow
} from './ReorderableRow'

interface IPickerRowProps {
  children: ReactNode
}
const PickerRow = ({ children }: IPickerRowProps) => {
  const theme: IThemeObject = { type: ThemeType.LIGHT }

  return (
    <ThemeProvider theme={theme}>
      <ContentContainer>{children}</ContentContainer>
    </ThemeProvider>
  )
}

export const PickerItemRow = ({ value: item, isSelected }: IRowComponentProps<GQLItem>) => {
  return (
    <PickerRow>
      <ReorderableItemRow value={item} isSelected={isSelected} isCompact={true} />
    </PickerRow>
  )
}

export const PickerExhibitRow = ({
  value: exhibit,
  isSelected
}: IRowComponentProps<GQLExhibit>) => {
  return (
    <PickerRow>
      <ReorderableExhibitRow value={exhibit} isSelected={isSelected} isCompact={true} />
    </PickerRow>
  )
}

export const PickerGuideRow = ({ value: guide }: IRowComponentProps<IGuidePickerJson>) => {
  return (
    <PickerRow>
      <ReorderableGuideRow value={guide} isCompact={true} />
    </PickerRow>
  )
}

export const PickerCreatorRow = ({ value: creator }: IRowComponentProps<GQLCreator>) => (
  <PickerRow>
    <ReorderableCreatorRow value={creator} isCompact={true} />
  </PickerRow>
)

export const PickerAudioRow = ({ value: audio }: IRowComponentProps<GQLAudio>) => (
  <PickerRow>
    <ReorderableAudioRow value={audio} isCompact={true} />
  </PickerRow>
)

export const PickerVideoRow = ({ value: video }: IRowComponentProps<GQLVideo>) => (
  <PickerRow>
    <ReorderableVideoRow value={video} isCompact={true} />
  </PickerRow>
)

export const PickerImageRow = ({ value: image }: IRowComponentProps<GQLImage>) => (
  <PickerRow>
    <ReorderableImageRow value={image} isCompact={true} />
  </PickerRow>
)

export const PickerItemOrExhibitRow = ({ value, isSelected }: IRowComponentProps<IContent>) => {
  return isItemContent(value) ? (
    <PickerItemRow value={value} isSelected={isSelected} />
  ) : (
    <PickerExhibitRow value={value} isSelected={isSelected} />
  )
}
