import styled from 'styled-components'
import { Grabber as GrabberIcon } from 'client/components/Grabber/Grabber'
import HTMLTextComponent from 'client/components/HTMLText/HTMLText'
import { Body1CSS, Body2CSS, Label2CSS } from 'client/components/TextStyles'
import XIconButtonTooltip from 'client/components/Tooltip/XIconButtonTooltip'

export const ThumbnailContainer = styled.div<{
  isError: boolean
  isSVG: boolean
  isPickerRow?: boolean
  isGridRow?: boolean
}>`
  height: ${({ isGridRow }) => (isGridRow ? 80 : 64)}px;
  width: ${({ isPickerRow, isGridRow }) => {
    if (isPickerRow) {
      return 64
    }
    if (isGridRow) {
      return 140
    }
    return 112
  }}px;
  background-color: var(--color-grey-01);

  ${({ isError }) => (isError ? 'border: 2px solid var(--color-red-06);' : '')}

  ${({ isSVG }) =>
    isSVG
      ? `
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          fill: var(--color-red-06);
        }`
      : ''}
`

export const ThumbnailImage = styled.img<{
  isError?: boolean
  isPickerRow?: boolean
  isGridRow?: boolean
}>`
  object-fit: ${({ isPickerRow }) => (isPickerRow ? 'cover' : 'contain')};
  height: ${({ isError }) => 64 - (isError ? 4 : 0)}px;
  width: ${({ isError, isPickerRow }) => (isPickerRow ? 64 : 112) - (isError ? 4 : 0)}px;
  ${({ isGridRow }) =>
    isGridRow
      ? `
    width: 140px;
    height: 80px;
  `
      : ''}
`

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 15px;
  margin-right: 8px;
  flex: 1;
  min-width: 0;
`

export const HeaderSubheaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 0;
  margin-left: 16px;
  height: 100%;
  justify-content: center;
  align-items: flex-start;
`

export const HeaderContainer = styled.div`
  width: 100%;
`
export const SubHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const LowerContent = styled.div`
  ${Body1CSS};
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 16px;
`

export const HTMLText = styled(HTMLTextComponent)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  & > * {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  /* Override default browser margin style */
  & > p {
    margin-bottom: 0px;
  }
`

export const Header = styled.div<{ isPickerRow?: boolean }>`
  font-size: 14px;
  line-height: ${({ isPickerRow }) => (isPickerRow ? 1.2 : 1.5)};
  font-weight: var(--font-weight-bold);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const Subheader = styled.div<{ isPickerRow?: boolean }>`
  ${Body1CSS};
  line-height: ${({ isPickerRow }) => (isPickerRow ? 1.2 : 1.5)};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const ContentStateCaption = styled.div<{ isError?: boolean }>`
  ${Body2CSS};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${({ isError }) => (isError ? 'var(--color-red-06)' : null)};
`

export const Label = styled.div<{ isSelected?: boolean }>`
  ${Label2CSS};
  display: inline-block;
  width: fit-content;
  background-color: ${({ isSelected }) =>
    isSelected ? 'var(--color-grey-02)' : 'var(--color-grey-08)'};
  color: ${({ isSelected }) => (isSelected ? 'var(--color-grey-08)' : 'var(--color-grey-03)')};
  padding: 1px 3px;
  border: 1px solid
    ${({ isSelected }) => (isSelected ? 'var(--color-grey-02)' : 'var(--color-grey-08)')};
  border-radius: 2px;
  margin-right: 8px;
`

export const NoteSpan = styled.div`
  ${Label2CSS};
  display: flex;
  color: var(--color-grey-03);
`
export const GrabberContainer = styled.div`
  display: flex;
  align-items: center;
  border-right: 1px solid var(--color-grey-02);
  margin-left: 4px;
  align-self: stretch;
`

export const Tag = styled.div<{ isSelected?: boolean }>`
  ${Label2CSS};
  display: inline-block;
  width: fit-content;
  height: var(--spacing-small);
  color: var(--color-black);
  background-color: var(--color-grey-02);
  padding: 2px 5px;
  border-radius: 2px;
  height: 20px;
  text-align: center;
  margin-right: 8px;
`

export const ContentContainer = styled.div`
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 100%;
`

export const ReorderableRow = styled.div<IReorderableRowProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  background-color: var(--color-white);

  border: 1px solid
    ${({ isDragging }) => (isDragging ? 'var(--color-accent-01)' : 'var(--color-grey-06)')};
  border-radius: 2px;
  ${({ isDragging }) => isDragging && 'box-shadow: var(--elevation-02)'};
`

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1;
  min-width: 0;
  width: 100%;
  height: 100%;
`

export const UpperContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: 100%;
`

export const IconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 6px;
`

export const RightContentContainer = styled.div`
  display: flex;
  min-width: 0px;
  flex: 1;
  padding: 12px 16px;
  justify-content: flex-start;
  align-items: flex-start;
`

interface IReorderableRowProps {
  isDragging?: boolean
}

export const Grabber = styled(GrabberIcon)`
  margin-left: -12px;
`

export const IconGroup = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  :last-child {
    margin-left: 32px;
  }
`

export const NoteIconsContainer = styled.div`
  display: flex;
  column-gap: var(--spacing-xsmall);
`
export const RemoveButton = styled(XIconButtonTooltip)`
  margin-left: 24px;
`
