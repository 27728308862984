import ContextualHelp, {
  ContextualHelpTextSection
} from 'client/components/ContextualHelp/ContextualHelp'
import styled from 'styled-components'
import { t } from 'client/i18n'
import { Trans } from 'react-i18next'

// TODO maybe share these styles with LeftNavContextualHelp?
const StyledUL = styled.ul`
  margin-bottom: 0;

  &,
  li {
    margin-left: 10px;
    padding: 0;
  }
`

const HelpWrapper = styled.span`
  display: inline-block;
  margin-left: var(--spacing-xxsmall);
  vertical-align: middle;
`

export default function AccessibilityContextualHelp() {
  return (
    <HelpWrapper>
      <ContextualHelp
        helpPlacement="bottom"
        helpSize="large"
        header={t('Accessibility Suggestions')}
        helpContent={
          <ContextualHelpTextSection>
            <p>
              {t(
                'The suggestions in this dashboard can help you build a strategy around accessible content and create a guide that can be enjoyed by all.'
              )}
            </p>
            <p>{t('This dashboard:')}</p>
            <StyledUL>
              <li>{t('Only considers content in your guide’s default language.')}</li>
              <li>
                {t(
                  'Only checks for the presence of: image alt text, video captions/subtitles, audio transcripts, and video transcripts.'
                )}
              </li>
              <li>
                <Trans i18nKey="a11ySuggestionsDoesNotConsiderContext" />
              </li>
              <li>{t('Does not evaluate the quality of any provided content.')}</li>
            </StyledUL>
          </ContextualHelpTextSection>
        }
        tooltipContent={t('More on the suggestions in this dashboard')}
        tooltipPlacement="bottom"
        helpAppendTo={document.body}
      />
    </HelpWrapper>
  )
}
