import ContextualHelp, {
  ContextualHelpTextSection
} from 'client/components/ContextualHelp/ContextualHelp'
import { ThemeType } from 'client/types'
import { t } from 'client/i18n'

const LeftNavContextualHelp = () => {
  return (
    <ContextualHelp
      helpPlacement="right"
      helpSize="large"
      buttonThemeType={ThemeType.LIGHT}
      helpMaxWidthRestrictedProp={500}
      header={t('Content Types')}
      helpContent={
        <>
          <ContextualHelpTextSection subheader={t('Exhibition')}>
            <p>
              {t(
                'A group of items that can be experienced in any order. For example, you could use an exhibition to group:'
              )}
            </p>
            <ul>
              <li>{t('Artworks that share a creator or theme')}</li>
              <li>{t('Artifacts shown together in a historical house')}</li>
              <li>{t('A cluster of plants in a botanic garden')}</li>
              <li>{t('A collection of informative or instructional items about your guide')}</li>
            </ul>
          </ContextualHelpTextSection>
          <ContextualHelpTextSection subheader={t('Tour')}>
            <p>
              {t(
                'A group of items that should be experienced on site and in a specific order, like:'
              )}
            </p>
            <ul>
              <li>{t('Highlights from your collection')}</li>
              <li>{t('A self-guided nature walk')}</li>
              <li>{t('A linear story that unfolds one stop at a time')}</li>
            </ul>
          </ContextualHelpTextSection>
          <ContextualHelpTextSection subheader={t('Item')}>
            <p>
              {t(
                'A single object that can be presented by itself or grouped into an exhibition or tour. For example:'
              )}
            </p>

            <ul>
              <li>{t('A work of art')}</li>
              <li>{t('A flower or tree (botanical item)')}</li>
              <li>{t('A performance, show, or play')}</li>
              <li>{t('A director’s welcome or other informational content')}</li>
            </ul>
          </ContextualHelpTextSection>

          <ContextualHelpTextSection subheader={t('Event')}>
            <p>
              {t(
                'An interactive visitor experience that takes place at a specific date and time, like:'
              )}
            </p>
            <ul>
              <li>{t('A performance, show, or play')}</li>
              <li>{t('An in-person or virtual artist talk or reading')}</li>
              <li>{t('An arts and crafts fair or other community gathering')}</li>
            </ul>
          </ContextualHelpTextSection>
        </>
      }
      tooltipContent={t('Learn more about content types')}
      tooltipPlacement="bottom"
      tooltipThemeType={ThemeType.LIGHT}
      helpAppendTo={document.body}
    />
  )
}

export default LeftNavContextualHelp
