import ContextualHelp, {
  ContextualHelpTextSection
} from 'client/components/ContextualHelp/ContextualHelp'
import { t } from 'client/i18n'

const TourExpirationContextualHelp = () => (
  <ContextualHelp
    tooltipContent={t(
      'More on why you need to convert the tour to an exhibition to keep the content in the guide'
    )}
    helpSize="large"
    helpPlacement="bottom"
    header={t('Keep Expired Tours Visible as Exhibitions')}
    helpContent={
      <>
        <ContextualHelpTextSection>
          {t('Tour stops don’t appear in the guide without map locations.')}
        </ContextualHelpTextSection>
        <ContextualHelpTextSection>
          {t(
            'When an expired tour’s map locations are removed, converting it to an exhibition lets you keep its stops as items without locations.'
          )}
        </ContextualHelpTextSection>
      </>
    }
  />
)

export default TourExpirationContextualHelp
