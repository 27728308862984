import { useContext } from 'react'
import styled from 'styled-components'
import FormFieldSection, {
  IFormFieldSectionProps
} from 'client/components/Form/FormField/FormFieldSection'
import { TranslationFormContext } from 'client/components/TranslationForm/TranslationFormContext'
import FormField, {
  EnableOrDisableWrapper
} from 'client/components/TranslationForm/TranslatableFormField'
import { SectionDivider } from 'client/screens/Catalog/forms/shared/styledComponents'

const HeaderContainer = styled.div`
  padding-right: var(--spacing-small);
  margin-bottom: var(--spacing-small);
`

const Container = styled(EnableOrDisableWrapper)`
  padding-left: var(--spacing-small);
  ${FormField} {
    margin-inline-start: calc(-1 * var(--spacing-small));
    padding-inline-start: var(--spacing-small);
  }
  ${SectionDivider} {
    margin-right: var(--spacing-small);
  }
  /* When the formfield is next to the header, adjust the spacing to meet specs depending on background state*/
  ${HeaderContainer} + ${FormField}[data-showbackground='false'] {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  ${HeaderContainer} + ${FormField}[data-showbackground='true'] {
    padding-top: var(--spacing-small);
  }

  ${FormFieldSection}:last-child {
    margin-bottom: 0px;
  }
`

interface ITranslatableFormFieldSectionProps extends IFormFieldSectionProps {
  translatable?: boolean
}

const TranslatableFormFieldSection = (props: ITranslatableFormFieldSectionProps) => {
  const { translatable = true, className } = props
  const { isDefaultLocaleSelected } = useContext(TranslationFormContext)

  return (
    <Container disabled={!translatable && !isDefaultLocaleSelected} className={className}>
      <FormFieldSection {...props} headerContainer={HeaderContainer} />
    </Container>
  )
}

export default styled(TranslatableFormFieldSection)``
