import IconOnlyButton from 'client/components/IconButton/IconOnlyButton'
import PencilSVG from 'client/assets/svg/icon/edit_20.svg'
import Tooltip from 'client/dsm/Tooltip/Tooltip'
import { t } from 'client/i18n'

interface IPencilIconButtonTooltipProps {
  className?: string
  onClick: () => void
}
const PencilIconButtonTooltip = ({ className, onClick }: IPencilIconButtonTooltipProps) => {
  return (
    <Tooltip content={t('Edit')}>
      <div className={className}>
        <IconOnlyButton svgComponent={PencilSVG} onClick={onClick} />
      </div>
    </Tooltip>
  )
}

export default PencilIconButtonTooltip
