/* eslint-disable react-hooks/rules-of-hooks */
import _ from 'lodash'
import { useState, useEffect, useMemo } from 'react'
import traverse from 'traverse'
import { useQuery, DocumentNode, QueryHookOptions } from '@apollo/client'
import { GQLLocale } from 'shared/graphql/types/graphql'
import { extractGQLData } from 'client/util/graphql'
import { ApolloError } from '@apollo/client/errors'
import useGuideLocalesQuery from 'client/hooks/useGuideLocalesQuery'

interface ContentData {
  translations: { locale: GQLLocale }[]
}

interface TranslationsQueryResponse {
  loading: boolean
  defaultLocale?: GQLLocale
  contentData?: ContentData
  availableLocales?: GQLLocale[]
  error?: ApolloError | null
}

export const useTranslationFormQuery = (
  query: DocumentNode,
  options?: QueryHookOptions
): TranslationsQueryResponse => {
  const [isLoading, setIsLoading] = useState(false)

  const {
    defaultLocale,
    availableLocales,
    loading: isLoadingLocales,
    error: localesError
  } = useGuideLocalesQuery()

  const {
    data: contentData,
    loading: isLoadingContentData,
    error: contentError
  } = _.isNil(options?.variables?.id)
    ? { data: null, loading: false, error: null }
    : useQuery(query, { fetchPolicy: 'network-only', ...options })

  useEffect(() => {
    setIsLoading(isLoadingContentData || isLoadingLocales)
  }, [isLoadingContentData, isLoadingLocales])

  // We must deeply omit the __typename, as this may block mutations when present;
  // Not doing it here, requires it be done in each form via submitValuesParser function;
  // Wihtout useMemo, 'maximum depth exceeded' occurs -- it loops
  const updatedContentData = useMemo(() => {
    // eslint-disable-next-line array-callback-return
    return traverse(extractGQLData(contentData, false)).map(function cleanData() {
      if (this.key === '__typename') {
        this.remove()
      }
    })
  }, [contentData])

  return {
    loading: isLoading,
    contentData: updatedContentData,
    defaultLocale,
    availableLocales,
    error: localesError || contentError
  }
}
