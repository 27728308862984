import SelectBox from 'client/components/Form/SelectBox/SelectBox'
import styled from 'styled-components'
import _ from 'lodash'
import Tooltip from 'client/dsm/Tooltip/Tooltip'
import { useState } from 'react'
import { GQLGuide } from 'shared/graphql/types/graphql'
import { setSelectedVersionInfo } from 'client/redux/actions/version'
import { useDispatch } from 'react-redux'
import { ThemeType } from 'client/types'

interface IProps {
  selectedGuide: GQLGuide
  guides: GQLGuide[]
  className?: string
}

const GuideSelectBox = ({ selectedGuide, guides, className }: IProps) => {
  const dispatch = useDispatch()
  const guideToOption = (guide: GQLGuide) => ({ value: guide.id, label: guide.name })

  const guidesToDisplay = _.sortBy(guides, (guide) => _.toUpper(guide.name.replace(/^The\s/, '')))

  const options = _.map(guidesToDisplay, guideToOption)

  const handleChange = (e) => {
    const newGuide: GQLGuide = _.find(guides, { id: e.target.value })!

    dispatch(
      setSelectedVersionInfo({
        guideId: newGuide.id
      })
    )
  }

  const [isTooltipDisabled, setIsTooltipDisabled] = useState(false)
  const handleMenuOpen = () => {
    setIsTooltipDisabled(true)
  }
  const handleMenuClose = () => {
    setIsTooltipDisabled(false)
  }

  return (
    <Tooltip themeType={ThemeType.LIGHT} content={selectedGuide.name} disabled={isTooltipDisabled}>
      <div>
        <SelectBox
          className={className}
          theme={ThemeType.DARK}
          options={options}
          value={guideToOption(selectedGuide)}
          onChange={handleChange}
          isSearchable={true}
          name="guide-select-box"
          onMenuOpen={handleMenuOpen}
          onMenuClose={handleMenuClose}
        />
      </div>
    </Tooltip>
  )
}

export default styled(GuideSelectBox)``
