import ContainerWithConnectsLogo from 'client/components/ContainerWithConnectsLogo'
import styled from 'styled-components'
import SupportFooter from 'client/components/SupportFooter'
import { t } from 'client/i18n'
import { Body1CSS } from 'client/components/TextStyles'

const RestrictedMessageContainer = styled.div`
  ${Body1CSS};
  color: var(--color-black);
  strong {
    font-weight: var(--font-weight-semi-bold);
  }
  p {
    margin: 8px 0px;
  }
`

const ManyFailedLoginAttempts = () => {
  return (
    <ContainerWithConnectsLogo>
      <RestrictedMessageContainer>
        <strong>{t('Too many login attempts.')}</strong>
        <p>{t('Try again later.')}</p>
      </RestrictedMessageContainer>
      <SupportFooter />
    </ContainerWithConnectsLogo>
  )
}

export default ManyFailedLoginAttempts
