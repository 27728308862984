import { GraphQLInputObjectType, GraphQLList, GraphQLNonNull, GraphQLString } from 'graphql'

const CreatorTranslationInput = new GraphQLInputObjectType({
  name: 'CreatorTranslationInput',
  fields: {
    localeCode: {
      type: GraphQLString
    },
    name: {
      type: GraphQLString
    },
    prefix: {
      type: GraphQLString
    },
    lifedates: {
      type: GraphQLString
    }
  }
})

const CreatorInput = new GraphQLInputObjectType({
  name: 'CreatorInput',
  fields: {
    translations: {
      type: new GraphQLList(new GraphQLNonNull(CreatorTranslationInput))
    }
  }
})

export default CreatorInput
