import { t } from 'client/i18n'
import _ from 'lodash'
import { GQLExhibit, GQLItem } from 'shared/graphql/types/graphql'
import { ItemType } from 'shared/constants/item'
import { ContentType } from 'client/types'

function getTag(contentType: string) {
  if (contentType === ContentType.ITEM) {
    return t('Item')
  }
  if (contentType === ContentType.EXHIBITION) {
    return t('Exhibition')
  }
  if (contentType === ContentType.TOUR) {
    return t('Tour')
  }
  if (contentType === ContentType.EVENT) {
    return t('Event')
  }
}

export function getItemOrExhibitTags(itemOrExhibit: GQLExhibit | GQLItem) {
  const { type, published = true } = itemOrExhibit
  const actualType = type === ItemType.BOTANICAL ? ContentType.ITEM : type

  return _.compact([getTag(actualType), !published && t('Hidden')])
}
