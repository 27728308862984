import * as React from 'react'
import PopupWidget from 'client/components/PopupWidget/PopupWidget'
import FormHeader from 'client/components/Form/FormHeader'
import styled from 'styled-components'
import LoadingOverlay from '../LoadingOverlay/LoadingOverlay'

const SectionGroup = styled.div`
  padding: 0 var(--spacing-large) var(--spacing-xlarge);
`

const StyledSection = styled.div`
  margin-top: var(--spacing-xlarge);
  padding-top: var(--spacing-xlarge);
  border-top: var(--border-light);
  &:first-child {
    margin-top: 0;
    border-top: none;
  }
`

const ContentContainer = styled.div<{ fullWidth?: boolean }>`
  position: relative;
  overflow: auto;
  height: 100%;
  padding: ${({ fullWidth }) => (fullWidth ? '0' : 'var(--spacing-xlarge) var(--spacing-large)')};
`
interface IStandardFormProps {
  title: string
  onCancel: () => void
  onSave: () => void
  onDelete?: () => any

  enableSave?: boolean
  saveButtonLabel?: string

  // Reconsider this, probably better ways to adjust margins/padding in children
  fullWidth?: boolean
  isLarge?: boolean

  isLoading?: boolean
  loadingText?: string

  transparentBackgroundContainer?: boolean

  children: React.ReactNode
}

// Note: This is an old form implementation. Going forward, use the TranslationForm.
export default function StandardForm(props: IStandardFormProps) {
  const {
    fullWidth = false,
    title,
    isLoading,
    loadingText,
    transparentBackgroundContainer = false,
    isLarge = false,
    onDelete,
    onCancel,
    onSave,
    enableSave = true,
    saveButtonLabel,
    children
  } = props

  return (
    <PopupWidget
      zIndex={10000}
      isLarge={isLarge}
      transparentBackgroundContainer={transparentBackgroundContainer}
    >
      {isLoading && <LoadingOverlay loadingText={loadingText} />}
      <FormHeader
        title={title}
        onDelete={onDelete}
        onCancel={onCancel}
        onSave={onSave}
        enableSave={enableSave}
        saveButtonLabel={saveButtonLabel}
      />
      <ContentContainer fullWidth={fullWidth}>{children}</ContentContainer>
    </PopupWidget>
  )
}

export { SectionGroup, StyledSection }
