// eslint-disable-next-line no-console
import * as tus from 'tus-js-client'
import { UploadOptions } from 'tus-js-client'

const UPLOAD_CHUNK_SIZE = 50000000 // 50 MB

function tusdUpload(file: File, onProgress?: UploadOptions['onProgress']): Promise<string> {
  return new Promise((resolve, reject) => {
    const upload = new tus.Upload(file, {
      chunkSize: UPLOAD_CHUNK_SIZE,
      endpoint: '/api/files/',
      retryDelays: [0, 3000, 5000, 10000, 20000],
      metadata: {
        // These are written into the `.info` file that tusd creates in S3
        // We don't actually use these at this point, but it may be useful in the future
        filename: file.name,
        filetype: file.type
      },
      onError: reject,
      onProgress,
      onSuccess: () => resolve(upload.url!)
    })
    upload.start()
  })
}

export default tusdUpload
