import styled from 'styled-components'
import { Grabber } from 'client/components/Grabber/Grabber'
import { Body2CSS } from 'client/components/TextStyles'

export const EditorContainer = styled.div`
  width: 420px;
  overflow-y: auto;
  border-right: var(--border-light);
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
`

interface IFloorCount {
  count: number
}

export const EditorListContainer = styled.div<IFloorCount>`
  border-left: ${({ count }) => (count > 1 ? '1px dotted var(--color-brand)' : 'none')};
  padding-left: ${({ count }) => (count > 1 ? 16 : 0)}px;

  /* spacing between floor row item*/
  & > * + * {
    margin-top: ${({ count }) => (count >= 1 ? 8 : 0)}px;
  }
`
export interface IEditorHeaderFooter {
  isHighestLevel?: boolean
  isLowestLevel?: boolean
}

export const EditorHeaderFooter = styled.div<IEditorHeaderFooter>`
  ${Body2CSS};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  padding-bottom: ${({ isHighestLevel }) => (isHighestLevel ? 8 : 0)}px;
  padding-top: ${({ isLowestLevel }) => (isLowestLevel ? 8 : 0)}px;
  color: var(--color-grey-06);
`

export const EditorBullet = styled.div`
  border-radius: 50%;
  width: 9px;
  height: 9px;
  margin-right: 11px;
  background-color: var(--color-brand);
  margin-left: -4px;
`

export const EditorFloorContainer = styled.div<IFloorCount>`
  & > * + * {
    margin-top: ${({ count }) => (count >= 1 ? 8 : 0)}px;
  }
`

interface IFloorRowContainerProps {
  dragging: boolean
  selected: boolean
}

export const FloorRowContainer = styled.div<IFloorRowContainerProps>`
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 64px;
  width: 100%;
  background-color: var(--color-white);
  border-width: 1px;
  border-style: solid;
  border-radius: 2px;
  border-color: var(--color-grey-06);
  ${({ dragging }) =>
    dragging &&
    `
    border-color: var(--color-accent-01);
    box-shadow: var(--elevation-02);
  `};
  ${({ selected }) => selected && 'border-color: var(--color-brand)'};

  ::before {
    content: '';
    width: 4px;
    margin-right: 10px;
    height: 100%;
    background: ${({ selected }) => (selected ? 'var(--color-brand)' : '#fff0')};
    display: block;
  }
  &:hover {
    background: var(--color-grey-01);
  }
`

export const FloorRowLeftContent = styled.div({
  alignItems: 'center',
  display: 'flex',
  height: '100%',
  flex: '1'
})

export const FloorRowRightContent = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  marginRight: 5,
  marginLeft: '0',
  height: '100%'
})

export const FloorRowGrabber = styled(Grabber)({
  marginLeft: -8
})

export const FloorRowFloorLabel = styled.div`
  display: block;
  margin: 8px;
  margin-left: 0;
  color: var(--color-grey-08);
  cursor: grab;
`

export const FloorRowUpdateInput = styled.input`
  display: none;
`

export const ExteriorMapSection = styled.div<{ selected?: boolean }>`
  height: 60px;
  flex-shrink: 0;
  box-shadow: ${({ selected }) => (selected ? 'inset 4px 0 0 var(--color-brand)' : 'none')};
  border-bottom: var(--border-light);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px var(--spacing-small);

  &:hover {
    cursor: pointer;
  }

  label {
    margin: 0;
  }
`
export const ExteriorMapLeftSection = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
`
