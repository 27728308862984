import { Navigate, Route, Routes } from 'react-router-dom'
import Home from './Home'
import About from './About'
import BrandAssets from './BrandAssets'
import MapEditor from './MapEditor/MapEditor'

const AppEditor = () => {
  return (
    <Routes>
      <Route path="about/*" element={<About />} />
      <Route path="brand-assets/*" element={<BrandAssets />} />
      <Route path="home/*" element={<Home />} />
      <Route path="maps/*" element={<MapEditor />} />
      <Route path="/*" element={<Navigate replace={true} to="home" />} />
    </Routes>
  )
}

export default AppEditor
