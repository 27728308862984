import { useContext } from 'react'
import gql from 'graphql-tag'
import TranslationForm from 'client/components/TranslationForm/TranslationForm'
import { TranslationFormContext } from 'client/components/TranslationForm/TranslationFormContext'
import TextInputFormField from 'client/components/TranslationForm/TranslatableTextInputField'
import { createApiConfig } from 'client/components/TranslationForm/util'
import { getSelectedVersionInfo } from 'client/redux/selectors/version'
import { useSelector } from 'react-redux'
import MuseumInput from 'shared/graphql/mutations/MuseumInput'
import { t } from 'client/i18n'

const apiConfig = createApiConfig(
  'museum',
  gql`
    query getSingleMuseum($id: Int!) {
      museum(id: $id) {
        id
        networkName
      }
    }
  `
)

const NetworkFormView = () => {
  const { getFieldName } = useContext(TranslationFormContext)
  return (
    <TextInputFormField
      translatable={false}
      name={getFieldName('networkName')}
      label={t('Guest Wi-Fi Network')}
      description={t(
        'Enter the Wi-Fi network to which visitors can connect their personal mobile devices.'
      )}
    />
  )
}

const WiFiNetworkForm = () => {
  const contentId = useSelector(getSelectedVersionInfo).id

  return (
    <TranslationForm
      contentName="Guest Wi-Fi Network"
      contentId={contentId}
      apiConfig={apiConfig}
      inputType={MuseumInput}
      allowDelete={false}
      translationsEnabled={false}
    >
      <NetworkFormView />
    </TranslationForm>
  )
}

export default WiFiNetworkForm
