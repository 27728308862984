import { GQLExhibit, GQLItem } from 'shared/graphql/types/graphql'
import { t } from 'client/i18n'
import _ from 'lodash'
import { DisplayPeriodType, ExhibitionType } from 'shared/constants/exhibits'
import { convertToLocalDate } from 'client/screens/Catalog/forms/shared/fromToDates'
import { formatDateRange } from 'client/util/dates'

export function getExhibitDate(exhibit: Partial<GQLExhibit>) {
  const { displayPeriod, from, to, timezone, type, isAllDay } = exhibit

  if (type === ExhibitionType.EVENT) {
    return formatDateRange(new Date(from!), new Date(to!), timezone!, !isAllDay)
  }

  if (displayPeriod && from && to) {
    return formatDateRange(new Date(from), new Date(to), timezone!, false)
  }

  if (displayPeriod) {
    return t('Ongoing')
  }
  return null
}

interface IExhibitDescription {
  header: string
  subheaders?: string[]
}

interface IExhibitWithDescription
  extends Pick<GQLExhibit, 'title' | 'displayPeriod' | 'from' | 'to'> {}

export function getExhibitDescription(exhibit: IExhibitWithDescription): IExhibitDescription {
  const { title } = exhibit

  const subheaders = _.compact([getExhibitDate(exhibit)])

  return {
    header: title,
    subheaders
  }
}

function getDisplayPeriodType(
  startDate: Date | string | null,
  endDate: Date | string | null,
  displayPeriod: boolean
) {
  let displayPeriodType: DisplayPeriodType

  const hasDates = _.every([startDate, endDate])

  if (hasDates) {
    displayPeriodType = DisplayPeriodType.DATE_RANGE
  } else {
    displayPeriodType = displayPeriod ? DisplayPeriodType.ON_GOING : DisplayPeriodType.NONE
  }

  return displayPeriodType
}

export function extractItemsForExhibitAndTour(items: GQLItem[]) {
  return _.map(items, ({ id, lookupNumber, itemMapLocation }) => ({
    id,
    // TODO: Get <ItemsList> to avoid using -1 for empty state so we can remove this
    lookupNumber: lookupNumber === -1 ? null : lookupNumber,
    itemMapLocation
  }))
}

export function parseInitialHideOnExpirationValue({
  expirationEnabled,
  hideOnExpiration
}: {
  expirationEnabled: boolean
  hideOnExpiration: boolean | undefined
}): boolean {
  if (!expirationEnabled) {
    return false
  }
  return hideOnExpiration ?? true
}

export function parseInitialValuesForExhibitAndTour(values) {
  return {
    ...values,
    displayPeriodType: getDisplayPeriodType(values.from, values.to, values.displayPeriod),
    hideOnExpiration: parseInitialHideOnExpirationValue({
      expirationEnabled: values.expirationEnabled,
      hideOnExpiration: values.hideOnExpiration
    }),
    // NOTE: We convert the date into a value that will format the same way in the browser timezone
    // For example, if the event is at Jan 10 at 7pm in Paris, and the user has their system timezone set to NYC
    // then we want the equivalent of Jan 10 at 7pm _in New York_.  We use date-fns-tz `toZonedTime` for this
    // We then convert back to the correct French (or whatever selected) timezone when submitting in the submitValuesParser
    // This keeps means we only have to do with this timezone stuff in one place, and underlying form components don't have to.
    // They all just deal with system-local times and the form code will convert these back to the proper timezone on submit
    // The downside of this is that the value in the form state is _technically_ not the actual Date/time of the event
    from: convertToLocalDate(values.from, values.timezone),
    to: convertToLocalDate(values.to, values.timezone)
  }
}
