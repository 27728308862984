// With the exception of the styles in the export statement, this entire file was taken from
// https://bbgithub.dev.bloomberg.com/bbnpm/bb-ui-framework/blob/v1/src/components/VirtualTable/BaseHeaderRowRenderer/BaseHeaderRowRenderer.tsx
import { ReactNode, CSSProperties } from 'react'
import _ from 'lodash'
import styled from 'styled-components'

interface BaseHeaderRowRendererProps {
  // react-virtualized headerRow
  children: ReactNode

  // styled-component classes
  className?: string

  // react-virtualized styles
  style?: Omit<CSSProperties, 'overflow'>
}

const BaseHeaderRowRenderer = ({ className, style, children }: BaseHeaderRowRendererProps) => (
  <div className={className} style={_.omit(style, 'overflow')}>
    {children}
  </div>
)

export default styled(BaseHeaderRowRenderer)`
  margin: 0;
  padding: 0;
  border-bottom: var(--border-light);
`
