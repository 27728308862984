import styled from 'styled-components'
import IconOnlyButton from 'client/components/IconButton/IconOnlyButton'
import PlusSVG from 'client/assets/svg/icon/plus_20.svg'
import MinusSVG from 'client/assets/svg/icon/minus_20.svg'
import ResetSVG from 'client/assets/svg/icon/refresh_20.svg'
import NoCropSVG from 'client/assets/svg/icon/no_crop_20.svg'
import Tooltip from 'client/dsm/Tooltip/Tooltip'
import { t } from 'client/i18n'

const Container = styled.div<{ bottom: number }>`
  position: absolute;
  right: 20px;
  bottom: ${({ bottom }) => bottom}px;
  display: flex;
  flex-direction: column;

  > :not(:first-child) {
    margin-top: var(--spacing-xsmall);
  }
`

const ViewControlButton = styled(IconOnlyButton)`
  flex-direction: column;
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid var(--color-grey-02);
  background-color: var(--color-white);
  box-shadow: var(--elevation-01);
`

const ZoomControls = styled.div`
  border-radius: 4px;
  background-color: var(--color-white);
  box-shadow: var(--elevation-01);
`
const ZoomControlButton = styled(ViewControlButton)`
  box-shadow: none;
  border-bottom: 0;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  :last-child {
    border: 1px solid var(--color-grey-02);
    border-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
  }
`

interface IViewControlProps {
  onZoomIn: () => void
  onZoomOut: () => void
  onReset: () => void
  onNoCrop?: () => void
  imageCroppingAvailable?: boolean
  bottom?: number
  className?: string
}
const ViewControls = ({
  onZoomIn,
  onZoomOut,
  onReset,
  onNoCrop,
  imageCroppingAvailable = false,
  bottom = 20,
  className
}: IViewControlProps) => {
  const placement = 'left'

  return (
    <Container className={className} bottom={bottom}>
      <ZoomControls>
        <Tooltip content={t('Zoom in')} placement={placement}>
          <div>
            <ZoomControlButton svgComponent={PlusSVG} onClick={onZoomIn} />
          </div>
        </Tooltip>
        <Tooltip content={t('Zoom out')} placement={placement}>
          <div>
            <ZoomControlButton svgComponent={MinusSVG} onClick={onZoomOut} />
          </div>
        </Tooltip>
      </ZoomControls>
      <Tooltip
        content={imageCroppingAvailable ? t('Reset crop') : t('Reset to original zoom')}
        placement={placement}
      >
        <div>
          <ViewControlButton svgComponent={ResetSVG} onClick={onReset} />
        </div>
      </Tooltip>
      {onNoCrop && (
        <Tooltip content={t('No crop')} placement={placement}>
          <div>
            <ViewControlButton svgComponent={NoCropSVG} onClick={onNoCrop} />
          </div>
        </Tooltip>
      )}
    </Container>
  )
}

export default ViewControls
