import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import styled from 'styled-components'
import { Route, Routes, useNavigate } from 'react-router-dom'
import GlobeIconTooltip from 'client/components/Languages/GlobeIconTooltip'
import PageContent from 'client/components/PageContent/PageContent'
import ContextualHelp, {
  ContextualHelpTextSection
} from 'client/components/ContextualHelp/ContextualHelp'
import FormField from 'client/components/Form/FormField/FormField'
import PencilIconEditButton from 'client/components/Button/PencilIconEditButton'
import DescriptionForm from 'client/screens/AppEditor/About/DescriptionForm'
import FormalFriendlyNamesForm from 'client/screens/AppEditor/About/FormalFriendlyNamesForm'
import LocationForm from 'client/screens/AppEditor/About/LocationForm'
import Locations from 'client/screens/AppEditor/About/Locations'
import EditSupportedLanguagesForm from 'client/screens/AppEditor/About/EditSupportedLanguagesForm'
import WiFiNetworkForm from 'client/screens/AppEditor/About/WiFiNetworkForm'
import MarketingWebPageForm from 'client/screens/AppEditor/About/MarketingWebPageLink'
import { t } from 'client/i18n'
import { Trans } from 'react-i18next'

const StyledGlobeIconTooltip = styled(GlobeIconTooltip)`
  margin-left: 10px;
`

const Panel = styled.article`
  padding: var(--container-padding);
  display: flex;
  flex-direction: column;

  > section + section {
    padding-top: var(--spacing-large);
    border-top: var(--border-light);
  }
`

function useSupportedLanguagesDetails() {
  const { data } = useQuery(gql`
    query getSupportedLanguagesInfo($museumId: Int!) {
      museum(id: $museumId) {
        id
        languages {
          id
          code
          englishName
        }
        guide {
          id
          isGoogleTranslateEnabled
        }
      }
    }
  `)

  const museumData = data?.museum
  const supportedLanguages = museumData?.languages

  const isGoogleTranslateEnabled = data?.museum?.guide?.isGoogleTranslateEnabled

  // TODO: DRY THIS UP
  const supportedLanguagesDescription = isGoogleTranslateEnabled
    ? t('supportedLanguagesWithGoogleTranslateLabel')
    : t('supportedLanguagesWithoutGoogleTranslateLabel')

  return {
    supportedLanguagesDescription,
    supportedLanguages
  }
}

function About() {
  const navigate = useNavigate()
  const { data } = useQuery(gql`
    query getMuseumDescriptionLanguages($museumId: Int!) {
      museum(id: $museumId) {
        id
        locales {
          id
          englishName
          code
        }
      }
    }
  `)

  const museumData = data?.museum
  const museumDescriptionLanguages = museumData?.locales
  const { supportedLanguagesDescription, supportedLanguages } = useSupportedLanguagesDetails()

  return (
    <PageContent title={t('About')}>
      <Panel>
        <section>
          <FormField label={t('Names')}>
            <PencilIconEditButton onClick={() => navigate('names')} />
          </FormField>

          <FormField
            label={t('Description')}
            additionalLabelNode={() => (
              <StyledGlobeIconTooltip locales={museumDescriptionLanguages} />
            )}
          >
            <PencilIconEditButton onClick={() => navigate('description')} />
          </FormField>

          <FormField
            label={t('Locations & Hours')}
            additionalLabelNode={() => (
              <ContextualHelp
                tooltipContent={t('More on where locations & hours appear')}
                tooltipPlacement="right"
                helpSize="large"
                header={t('Locations & Hours')}
                helpContent={
                  <>
                    <ContextualHelpTextSection>
                      <Trans i18nKey="Your location is used to pin your organization to the Explore screen map and to calculate which organizations are nearby for a visitor." />
                    </ContextualHelpTextSection>
                    <ContextualHelpTextSection>
                      {t('If you have more than one location:')}
                      <ul>
                        <li>
                          {t(
                            'Your primary location controls the map pin and distance calculation.'
                          )}
                        </li>
                        <li>{t("The location names appear on your guide's detail screen.")}</li>
                      </ul>
                    </ContextualHelpTextSection>
                  </>
                }
              />
            )}
          >
            <Locations />
          </FormField>
        </section>

        <section>
          <FormField
            label={t('Languages')}
            additionalLabelNode={() => <StyledGlobeIconTooltip locales={supportedLanguages} />}
            description={supportedLanguagesDescription}
          >
            <PencilIconEditButton onClick={() => navigate('languages')} />
          </FormField>
        </section>

        <section>
          <FormField
            label={t('Guest Wi-Fi Network')}
            additionalLabelNode={() => (
              <ContextualHelp
                helpSize="large"
                header={t('Guest Wi-Fi Network')}
                helpContent={
                  <ContextualHelpTextSection>
                    {t(
                      'When a visitor connects to this Wi-Fi network, your usage data records them as an on-site visitor.'
                    )}
                  </ContextualHelpTextSection>
                }
                tooltipContent={t('More on how we use your guest Wi-Fi network')}
              />
            )}
          >
            <PencilIconEditButton onClick={() => navigate('network')} />
          </FormField>

          <FormField
            label={t('Marketing Web Page Link')}
            additionalLabelNode={() => (
              <ContextualHelp
                helpSize="large"
                header={t('Marketing Web Page Link')}
                helpContent={
                  <ContextualHelpTextSection>
                    <p>
                      {t(
                        "If your organization's website has a page that highlights your digital guide, add the link to that page."
                      )}
                    </p>
                    <p>
                      <Trans
                        i18nKey="howMarketingLinkIsUsed"
                        components={{
                          // eslint-disable-next-line jsx-a11y/control-has-associated-label, jsx-a11y/anchor-has-content
                          ConnectsGuidesLink: <a href="https://www.bloombergconnects.org/guides/" />
                        }}
                      />
                    </p>
                  </ContextualHelpTextSection>
                }
                tooltipContent={t('More on how we use this link')}
              />
            )}
          >
            <PencilIconEditButton onClick={() => navigate('marketing-page')} />
          </FormField>
        </section>
      </Panel>
      <Routes>
        <Route path="names" element={<FormalFriendlyNamesForm />} />
        <Route path="description" element={<DescriptionForm />} />
        <Route path="location" element={<LocationForm />} />
        <Route path="location/:id" element={<LocationForm />} />
        <Route path="languages" element={<EditSupportedLanguagesForm />} />
        <Route path="network" element={<WiFiNetworkForm />} />
        <Route path="marketing-page" element={<MarketingWebPageForm />} />
      </Routes>
    </PageContent>
  )
}

export default About
