import * as React from 'react'
import { Dialog, DialogHeader, DialogBody, DialogActions } from 'client/dsm/Dialog/Dialog'

interface TranslationDialogProps {
  title: string
  body: React.ReactNode
  actions: React.ReactNode
}

const TranslationFormDialog = (props: TranslationDialogProps) => {
  return (
    <Dialog>
      <DialogHeader>{props.title}</DialogHeader>
      <DialogBody>{props.body}</DialogBody>
      <DialogActions>{props.actions}</DialogActions>
    </Dialog>
  )
}

export default TranslationFormDialog
