import _ from 'lodash'
import { GQLLocale } from 'shared/graphql/types/graphql'
import { ISelectBoxOptions } from 'client/components/Form/SelectBox/SelectBox'
import { t } from 'client/i18n'
import { TKey } from 'shared/i18n/types/translationResources'

const mapLocaleToOption = (
  locale: GQLLocale,
  isDefault: boolean = false,
  localesWithErrors: string[] = []
): ISelectBoxOptions => ({
  label: `${t(`language.${locale.code}` as TKey)}${isDefault ? ` - ${t('Default')}` : ''}`,
  value: locale.code,
  isError: _.includes(localesWithErrors, locale.code)
})

export default mapLocaleToOption
