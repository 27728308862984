import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { getSelectedVersionInfo } from 'client/redux/selectors/version'
import { useSelector } from 'react-redux'

interface IDefaultLocaleQuery {
  guide: {
    id: number
    defaultLocale: {
      code: string
    }
  }
}

const GUIDE_DEFAULT_LOCALE_QUERY = gql`
  query guideDefaultLocale($guideId: Int!) {
    guide(id: $guideId) {
      id
      defaultLocale {
        code
      }
    }
  }
`

// TODO these types aren't totally correct, because they ignore the error state. Specifically where
//      loading is false but error (not currently being read) is present
type UseGuideDefaultLocaleReturn =
  | {
      loading: false
      defaultLocale: IDefaultLocaleQuery['guide']['defaultLocale']
    }
  | {
      loading: true
      defaultLocale: undefined
    }

export default function useGuideDefaultLocale(): UseGuideDefaultLocaleReturn {
  const { guideId } = useSelector(getSelectedVersionInfo)
  const { loading, data } = useQuery<IDefaultLocaleQuery>(GUIDE_DEFAULT_LOCALE_QUERY, {
    fetchPolicy: 'cache-first',
    variables: {
      guideId
    }
  })

  const defaultLocale = data?.guide?.defaultLocale
  return { loading, defaultLocale } as UseGuideDefaultLocaleReturn
}
