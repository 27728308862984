import LookupNumberSelect from 'client/components/Formik/LookupNumberSelect/LookupNumberSelect'
import useAvailableLookupNumbers, {
  IUsedLookupMapping
} from 'client/screens/Catalog/forms/shared/LookupNumber/useAvailableLookupNumbers'

interface IContentLookupNumberSelectProps {
  name: string
  usedLookupMapping?: IUsedLookupMapping
}

export default function ContentLookupNumberSelect(props: IContentLookupNumberSelectProps) {
  const { name, usedLookupMapping = [] } = props
  const availableLookupNumbers = useAvailableLookupNumbers(usedLookupMapping)

  return <LookupNumberSelect source={availableLookupNumbers} name={name} />
}
