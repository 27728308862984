import { t } from 'client/i18n'
import { Body1CSS, Body2 } from 'client/components/TextStyles'
import styled from 'styled-components'

export const HeroImage = styled.img`
  width: 140px;
  height: 80px;
  background-color: var(--color-grey-01);
  object-fit: contain;
`

export const SearchHeaderContainer = styled.div`
  display: flex;
  flex: 1 1 0%;
  flex-direction: row;
  justify-content: flex-end;
`

export const EmptyStatePlaceholderDescription = styled.section<{ centerAlign?: boolean }>`
  width: 400px;
  text-align: ${({ centerAlign }) => (centerAlign ? 'center' : 'left')};
  ul {
    padding-left: var(--spacing-medium);
  }
  ${Body1CSS};
  button {
    margin: 0px auto;
    margin-top: var(--spacing-medium);
  }
`

const PlaceholderFooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  button {
    margin: 0px;
  }
  row-gap: var(--spacing-small);
  margin-top: var(--spacing-small);
`
const ORDivider = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  column-gap: var(--spacing-xsmall); ;
`
const HorizontalDash = styled.div`
  height: 1px;
  width: 100%;
  background-color: var(--color-grey-02);
`
export const EmptyStatePlaceholderDragDropFooter = ({
  addButtonNode
}: {
  addButtonNode: React.ReactNode
}) => {
  return (
    <PlaceholderFooterContainer>
      {addButtonNode}
      <ORDivider>
        <HorizontalDash />
        <Body2>
          <strong>OR</strong>
        </Body2>
        <HorizontalDash />
      </ORDivider>
      <span>{t('Drag and drop')}</span>
    </PlaceholderFooterContainer>
  )
}
