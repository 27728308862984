import styled from 'styled-components'

interface IPill {
  label?: string
}

const Pill = styled.div<IPill>`
  padding: 0 4px;
  border-radius: 2px;
  background: var(--color-grey-02);
  color: var(--color-grey-10);
  ::before {
    content: ${({ label }) => `"${label}"`};
    font-size: 12px;
    font-weight: var(--font-weight-bold);
    line-height: 18px;
  }
`

export default Pill
