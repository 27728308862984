import { GraphQLInputObjectType, GraphQLList, GraphQLNonNull, GraphQLString } from 'graphql'

const FeatureTranslationInput = new GraphQLInputObjectType({
  name: 'FeatureTranslationInput',
  fields: {
    localeCode: {
      type: GraphQLString
    },
    title: {
      type: GraphQLString
    }
  }
})

const FeatureInput = new GraphQLInputObjectType({
  name: 'FeatureInput',
  fields: {
    translations: {
      type: new GraphQLList(new GraphQLNonNull(FeatureTranslationInput))
    }
  }
})

export default FeatureInput
