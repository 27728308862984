import { ReactNode } from 'react'
import * as types from './types'

export interface IConfirmArgs {
  id?: string
  title: ReactNode
  message: ReactNode
  isAlert?: boolean
  help?: ReactNode
  confirmYes?: {
    action?: () => void
    label?: string
    isDestructive?: boolean
  }
  confirmNo?: {
    action?: () => void
    label?: string
  }
}

export function confirm(data: IConfirmArgs) {
  return {
    type: types.CONFIRM_START,
    ...data
  }
}

export function alert(title: String, message: String) {
  const confirmArgs = {
    title,
    message,
    isAlert: true
  }
  return {
    type: types.CONFIRM_START,
    ...confirmArgs
  }
}

// Explicit yes action to avoid callback and have clients listen to the event
export function confirmYes() {
  return { type: types.CONFIRM_YES }
}

// Explicit no action to avoid callback and have clients listen to the event
export function confirmNo() {
  return { type: types.CONFIRM_NO }
}

export function confirmEnd() {
  return { type: types.CONFIRM_END }
}
