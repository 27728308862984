import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogHeader,
  PermanentActionMessage
} from 'client/dsm/Dialog/Dialog'
import Button from 'client/components/Button/Button'
import styled from 'styled-components'
import { t } from 'client/i18n'

const StyledDialogBody = styled(DialogBody)`
  ul {
    padding-left: 14px;
  }
`

interface ConfirmBulkUploadDialogProps {
  onConfirmed: () => void
  onCancel: () => void
}
const ConfirmBulkUploadDialog = (props: ConfirmBulkUploadDialogProps) => (
  <Dialog>
    <DialogHeader>{t('Bulk Upload')}</DialogHeader>
    <StyledDialogBody>
      <p>
        {t(
          'A bulk upload replaces all previously bulk uploaded content in the following sections:'
        )}
      </p>
      <ul>
        <li>{t('Exhibitions')}</li>
        <li>{t('Items')}</li>
        <li>{t('Images')}</li>
        <li>{t('Audios')}</li>
        <li>{t('Videos')}</li>
        <li>{t('Creators')}</li>
      </ul>
      <p>
        {t(
          'All content included in your upload file will be added or updated. All previously bulk uploaded content not included in your upload file will be deleted. Manually added content will not be impacted.'
        )}
      </p>
      <PermanentActionMessage />
    </StyledDialogBody>
    <DialogActions>
      <Button onClick={props.onCancel} label={t('Cancel')} />
      <Button type="primary" onClick={props.onConfirmed} label={t('Upload')} />
    </DialogActions>
  </Dialog>
)

export default ConfirmBulkUploadDialog
