import { useMemo } from 'react'
import sanitizeHtml from 'sanitize-html'

interface IProps {
  html: string
  className?: string
}

const HTMLText = ({ html, className }: IProps) => {
  const sanitizedHTML = useMemo(() => sanitizeHtml(html), [html])
  return (
    /* eslint-disable react/no-danger */
    <div
      dangerouslySetInnerHTML={{
        __html: sanitizedHTML
      }}
      className={className}
    />
    /* eslint-enable react/no-danger */
  )
}

export default HTMLText
