import { useDispatch } from 'react-redux'
import _ from 'lodash'
import styled from 'styled-components'
import {
  createBuildingFloor,
  deleteBuildingFloor,
  updateBuildingFloor,
  updateBuildingFloorOrder,
  deleteBuilding
} from 'client/redux/actions/maps'
import { IBuildingJson } from 'shared/json/IBuildingJson'
import { Route, Routes, useNavigate, useParams } from 'react-router-dom'
import { confirm } from 'client/redux/actions/confirmation'
import { getDeleteMessage } from 'client/screens/AppEditor/MapEditor/DialogMessages/buildings'
import { IBuildingsURLParams } from 'client/types'
import { t } from 'client/i18n'
import BuildingForm from 'client/screens/AppEditor/MapEditor/Buildings/BuildingForm'
import BuildingHeader from './BuildingHeader'
import FloorList from '../FloorEditor/FloorList'

const BuildingItem = styled.div`
  border-bottom: var(--border-light);
  padding: 0px 16px 24px 16px;
`
type IDType = number | string

interface IProps {
  buildings: IBuildingJson[]
  selectedFloorId: IDType
  onFloorSelected: (buildingId: IDType, floorId: IDType) => void
}

export default (props: IProps) => {
  const { buildings, selectedFloorId, onFloorSelected } = props
  const { buildingId: selectedBuildingId } = useParams<IBuildingsURLParams>()

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleFloorAdd = (buildingId: number, image: File) =>
    dispatch(createBuildingFloor(buildingId, image))

  const handleFloorTitleChanged = (building: IBuildingJson, id: number, title: string) => {
    const floor = _.find(building.floors, { id })!
    dispatch(updateBuildingFloor({ ...floor, title }))
  }

  const handleFloorDelete = (buildingId: number, floorId: number) =>
    dispatch(deleteBuildingFloor(buildingId, floorId, selectedFloorId === floorId))

  const handleFloorReplaceImage = (building: IBuildingJson, id: number, image: File) => {
    const floor = _.find(building.floors, { id })!
    dispatch(updateBuildingFloor(floor, image))
  }

  const handleFloorSetDefault = (building: IBuildingJson, id: number) => {
    const floor = _.find(building.floors, { id })!
    dispatch(updateBuildingFloor({ ...floor, isDefault: true }))
  }

  const handleFloorsRepositioned = (building: IBuildingJson, orderedFloorIds) => {
    dispatch(updateBuildingFloorOrder(building.id, orderedFloorIds))
  }

  const handleDeleteBuilding = (id) => {
    const currentBuildingId = parseInt(selectedBuildingId!, 10)
    const building = _.find(buildings, { id })!
    const { title, message } = getDeleteMessage(building.floors.length > 0)

    dispatch(
      confirm({
        title,
        message,
        confirmYes: {
          action: () => dispatch(deleteBuilding(id, id === currentBuildingId)),
          label: t('Delete Building'),
          isDestructive: true
        }
      })
    )
  }

  return (
    <>
      {buildings.map((building) => (
        <BuildingItem key={building.id}>
          <BuildingHeader
            name={building.name}
            onEdit={() => navigate(`buildings/${building.id}/edit`)}
            onDelete={() => handleDeleteBuilding(building.id)}
          />
          <FloorList
            id={building.id}
            floors={building.floors}
            selectedFloorId={selectedFloorId}
            onFloorSelected={(id) => onFloorSelected(building.id, id)}
            onFloorAdd={(file) => handleFloorAdd(building.id, file)}
            onFloorTitleChanged={(floorId, title) =>
              handleFloorTitleChanged(building, floorId, title)
            }
            onFloorDelete={(floorId) => handleFloorDelete(building.id, floorId)}
            onFloorReplaceImage={(floorId, file) =>
              handleFloorReplaceImage(building, floorId, file)
            }
            onFloorSetDefault={(floorId) => handleFloorSetDefault(building, floorId)}
            onFloorsRepositioned={(ids) => handleFloorsRepositioned(building, ids)}
          />
        </BuildingItem>
      ))}
      <Routes>
        <Route path="buildings/new" element={<BuildingForm />} />
        <Route path="buildings/:editBuildingId/edit" element={<BuildingForm />} />
      </Routes>
    </>
  )
}
