import { GQLExhibit } from 'shared/graphql/types/graphql'
import { useDispatch } from 'react-redux'
import { useMutation, useQuery } from '@apollo/client'
import { flash } from 'client/redux/actions/flash'
import Checkbox from 'client/components/Checkbox/Checkbox'
import styled from 'styled-components'
import DividerComponent from 'client/components/Divider/Divider'
import gql from 'graphql-tag'
import { ReactNode, useMemo, useState } from 'react'
import _ from 'lodash'
import { Dialog, DialogActions, DialogBody, DialogHeader } from 'client/dsm/Dialog/Dialog'
import LoadingOverlay from 'client/components/LoadingOverlay/LoadingOverlay'
import Button from 'client/components/Button/Button'
import { useErrorDialog } from 'client/components/ErrorDialog'
import { ExhibitionType } from 'shared/constants/exhibits'
import { UpdateItemOrExhibitVisibilityDialogBodyContent } from 'client/screens/Catalog/grids/shared/UpdateItemOrExhibitVisibilityDialogContent'
import GQLErrorRenderer from 'client/components/GQLErrorRenderer'
import { t } from 'client/i18n'
import { Trans } from 'react-i18next'

const StyledCheckbox = styled.div`
  padding-top: var(--spacing-medium);
`
const Divider = styled(DividerComponent)`
  margin-top: 16px;
  margin-bottom: 16px;
`

const EXHIBIT_ITEMS_QUERY = gql`
  query getSingleExhibit($id: Int!) {
    exhibit(id: $id) {
      id
      published
      isFeatured
      title
      type
      items {
        exhibits {
          id
        }
      }
    }
  }
`

const SHOW_EXHIBIT_MUTATION = gql`
  mutation showExhibit($id: Int!, $applyToExhibitItems: Boolean!) {
    showExhibit(id: $id, applyToExhibitItems: $applyToExhibitItems) {
      id
    }
  }
`

const HIDE_EXHIBIT_MUTATION = gql`
  mutation hideExhibit($id: Int!, $applyToExhibitItems: Boolean!) {
    hideExhibit(id: $id, applyToExhibitItems: $applyToExhibitItems) {
      id
    }
  }
`

interface IDialogContentProps {
  exhibit: GQLExhibit
  onClose: () => void
}

function DialogContent({ exhibit, onClose }: IDialogContentProps) {
  const { published, isFeatured, title: exhibitTitle, type, items } = exhibit
  const numItemsBelongingToMultipleExhibits = _.filter(
    items,
    (item) => item.exhibits.length > 1
  ).length
  const numItems = items.length

  /* eslint-disable docent/require-translation-keys-to-be-literals */
  const labels = useMemo(() => {
    const capitalizedContentType = _.capitalize(type) as Capitalize<`${ExhibitionType}`>
    const buttonLabelText = published
      ? (`Hide ${capitalizedContentType}` as const)
      : (`Make ${capitalizedContentType} Visible` as const)
    const buttonLabel = t(buttonLabelText)
    const title = t(`${buttonLabelText}?`)
    const successFlashMessage = published ? t('Content hidden') : t('Content made visible')
    const checkboxLabel = published
      ? t('Also hide all included items')
      : t('Also make all included items visible')
    const errorTitle = published
      ? t(`Unable to Hide ${capitalizedContentType}`)
      : t(`Unable to Make ${capitalizedContentType} Visible`)
    /* eslint-enable docent/require-translation-keys-to-be-literals */

    return {
      title,
      buttonLabel,
      successFlashMessage,
      checkboxLabel,
      errorTitle
    }
  }, [published, type])

  const isEvent = type === ExhibitionType.EVENT

  const [errorDialog, setError] = useErrorDialog()
  const dispatch = useDispatch()
  const onCompleted = () => {
    onClose()
    dispatch(flash(labels.successFlashMessage))
  }
  const onError = (error) => {
    setError({
      error,
      title: labels.errorTitle
    })
  }
  const [showExhibit] = useMutation(SHOW_EXHIBIT_MUTATION, {
    onCompleted,
    onError
  })
  const [hideExhibit] = useMutation(HIDE_EXHIBIT_MUTATION, {
    onCompleted,
    onError
  })
  const [checked, setChecked] = useState(true)
  const hideOrShowExhibit = published ? hideExhibit : showExhibit

  return (
    <>
      <DialogHeader>{labels.title}</DialogHeader>
      <DialogBody>
        <>
          <UpdateItemOrExhibitVisibilityDialogBodyContent
            contentName={exhibitTitle}
            contentType={type as ExhibitionType}
            published={published}
            isFeatured={isFeatured}
          />
          {numItems > 0 && !isEvent && (
            <StyledCheckbox>
              <Checkbox
                name="update-items-too"
                checked={checked}
                onChange={({ target }) => {
                  setChecked(target.checked)
                }}
                label={labels.checkboxLabel}
              />
            </StyledCheckbox>
          )}

          {numItemsBelongingToMultipleExhibits > 0 && checked && !isEvent && (
            <>
              <Divider />
              <p>
                <Trans i18nKey="updateExhibitVisibilityItemsWarning" />
              </p>
            </>
          )}
        </>
      </DialogBody>
      <DialogActions>
        <Button onClick={onClose} label={t('Cancel')} />
        <Button
          type="primary"
          onClick={() =>
            hideOrShowExhibit({
              variables: {
                id: exhibit.id,
                applyToExhibitItems: checked
              }
            })
          }
          label={labels.buttonLabel}
        />
      </DialogActions>
      {errorDialog}
    </>
  )
}

interface IUpdateExhibitVisibilityDialogProps {
  id: number
  onClose: () => void
}

export default function UpdateExhibitVisibilityDialog({
  id,
  onClose
}: IUpdateExhibitVisibilityDialogProps) {
  const { data, loading, error } = useQuery<{ exhibit: GQLExhibit }>(EXHIBIT_ITEMS_QUERY, {
    variables: { id }
  })

  let contents: ReactNode

  if (loading) {
    return <LoadingOverlay fullScreen={true} />
  }

  if (error) {
    contents = (
      <>
        <GQLErrorRenderer error={error} />
        <DialogActions>
          <Button onClick={onClose} label={t('Cancel')} />
        </DialogActions>
      </>
    )
  } else {
    contents = <DialogContent exhibit={data!.exhibit} onClose={onClose} />
  }

  return <Dialog>{contents}</Dialog>
}
