import { combineReducers } from 'redux'
import { RESET_APP } from '../actions/types'
import * as mapsReducer from './maps'
import * as authReducer from './auth'
import * as flashReducer from './flash'
import * as confirmationReducer from './confirmation'
import * as versionReducer from './version'
import * as bulkUploadReducer from './bulkUpload'

export default function createReducer() {
  const appReducers = combineReducers({
    ...authReducer,
    ...mapsReducer,
    ...flashReducer,
    ...confirmationReducer,
    ...versionReducer,
    ...bulkUploadReducer
  })

  return (state, action) => {
    const actualState = action.type === RESET_APP ? undefined : state
    // @ts-ignore
    return appReducers(actualState, action)
  }
}
