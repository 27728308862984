import classnames from 'classnames'
import SearchInput, { ISearchInputProps } from 'client/components/Form/SearchInput/SearchInput'
import styled from 'styled-components'
import Header from './Header/Header'

const css = require('./MiniPicker.css')

const StyledSearchInput = styled(SearchInput)`
  flex: none;
  margin: 0 24px;
  background: transparent;
  &:focus-within {
    border-color: var(--color-white);
  }
  && input {
    color: var(--color-white);
    :focus,
    :hover {
      color: var(--color-white);
    }
  }
  div:hover {
    color: var(--color-white);
  }
  svg {
    color: currentColor;
  }
`

interface IProps {
  children: any
  filterText: string
  onFilterChange: ISearchInputProps['onChange']
  onFilterCancel: ISearchInputProps['onCancel']
  className?: string
  headerOptions: any[]
}

export function MiniPicker(props: IProps) {
  const { headerOptions, children, filterText, onFilterCancel, onFilterChange, className } = props

  const containerClassNames = classnames(css.container, className)

  return (
    <div className={containerClassNames}>
      <Header options={headerOptions}>
        <StyledSearchInput value={filterText} onChange={onFilterChange} onCancel={onFilterCancel} />
      </Header>
      <div className={css.contentContainer}>{children}</div>
    </div>
  )
}
