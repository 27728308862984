import { Route, Routes } from 'react-router-dom'
import UsageReports from 'client/screens/Reports/UsageReports'
import { useFeatureFlags } from 'client/hooks/useFeatureFlags'
import DataDashboard from 'client/screens/Reports/DataDashboard'

// Passing the same component into both routes below was not causing the component
// to unmount and thus leaving the internal state as is. As a result, for example
// startDate when changed in usage-report would persists in loaner-device-usage-reports.
// We need to have a separate component for react-router to properly unmount.
const LoanerUsageReport = () => <UsageReports isLoaner={true} />

export default function Reports() {
  const { LOANER_REPORT, USAGE_REPORTS } = useFeatureFlags()
  return (
    <Routes>
      <Route path="data-dashboard" element={<DataDashboard />} />
      {USAGE_REPORTS && <Route path="usage-reports" element={<UsageReports />} />}
      {LOANER_REPORT && (
        <Route path="loaner-device-usage-reports" element={<LoanerUsageReport />} />
      )}
    </Routes>
  )
}
