import styled from 'styled-components'
import { ReorderableRowWrapper } from 'client/components/ContentRow/ReorderableRowWrapper'
import { HeaderSubheaderContainer } from 'client/components/ContentRow/HeaderSubheaderContainer'

const Wrapper = styled.div`
  min-width: 0;
`

export interface IMinimalFeature {
  id: number
  title: string | null | undefined
  label: string
}

interface IProps {
  value: IMinimalFeature
}

export default function FeatureRow(props: IProps) {
  const { value: feature } = props
  const subheaders = [feature.label]

  return (
    <ReorderableRowWrapper>
      <Wrapper>
        <HeaderSubheaderContainer subheaders={subheaders} />
      </Wrapper>
    </ReorderableRowWrapper>
  )
}
