import _ from 'lodash'

function getValueForFirstKey(data: any) {
  const withoutTypename = _.omit(data, '__typename', 'id')
  const firstKey = _.keys(withoutTypename)[0]
  return data[firstKey]
}

export function extractGQLData<ReturnType = any>(
  data: any,
  checkForMuseumRoot: boolean = true
): ReturnType | null {
  if (!data) {
    return null
  }

  return data.museum && checkForMuseumRoot
    ? getValueForFirstKey(data.museum)
    : getValueForFirstKey(data)
}
