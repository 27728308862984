import { MapLocationRenderer } from 'client/screens/Catalog/forms/shared/MapLocations/MapLocationRenderer'
import useAvailableShowOnMapOptions from 'client/screens/Catalog/forms/shared/MapLocations/useAvailableShowOnMapOptions'
import useField from 'client/hooks/useField'
import PlusIconAddButton from 'client/components/Button/PlusIconAddButton'
import { t } from 'client/i18n'

interface IMapLocationsProps {
  name: string
}

export default function MapLocation(props: IMapLocationsProps) {
  const { name } = props
  const { value, setValue } = useField(name)

  const mapLocationOptions = useAvailableShowOnMapOptions()

  if (value) {
    return (
      <MapLocationRenderer
        buildings={mapLocationOptions}
        onChange={setValue}
        onRemove={() => setValue(null)}
        value={value}
      />
    )
  }
  return <PlusIconAddButton label={t('Add Location')} onClick={() => setValue({})} />
}
