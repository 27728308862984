import _ from 'lodash'
import { useParams } from 'react-router-dom'

type RouterUseParamsType = string | Record<string, string>
type UseCoercedParamsType = number | RouterUseParamsType

const useCoercedParam = <T extends UseCoercedParamsType>(paramKey: string): T => {
  const params = useParams<RouterUseParamsType>()
  const n = Number(params[paramKey])
  return _.isFinite(n) ? n : params[paramKey]
}

export default useCoercedParam
