import {
  GraphQLInputObjectType,
  GraphQLList,
  GraphQLString,
  GraphQLNonNull,
  GraphQLInt,
  GraphQLBoolean
} from 'graphql'
import { RelatedContentInput } from 'shared/graphql/mutations/RelatedContentInput'
import { IdInput } from './types'

const ExhibitTranslationInput = new GraphQLInputObjectType({
  name: 'ExhibitTranslationInput',
  fields: {
    localeCode: {
      type: GraphQLString
    },
    title: {
      type: GraphQLString
    },
    information: {
      type: GraphQLString
    }
  }
})
const ExhibitItemMapLocationInput = new GraphQLInputObjectType({
  name: 'ExhibitItemMapLocationInput',
  fields: {
    mapLocation: {
      type: new GraphQLNonNull(IdInput)
    }
  }
})

const ExhibitItemInput = new GraphQLInputObjectType({
  name: 'ExhibitItemInput',
  fields: {
    id: {
      type: new GraphQLNonNull(GraphQLInt)
    },
    lookupNumber: {
      type: GraphQLInt
    },
    itemMapLocation: {
      type: ExhibitItemMapLocationInput
    }
  }
})

const ExhibitMapLocationInput = new GraphQLInputObjectType({
  name: 'ExhibitMapLocationInput',
  fields: {
    mapLocation: {
      type: new GraphQLNonNull(IdInput)
    },
    featured: {
      type: GraphQLBoolean
    }
  }
})

export const ExhibitInput = new GraphQLInputObjectType({
  name: 'ExhibitInput',
  fields: {
    type: {
      type: GraphQLString
    },
    estimatedDuration: {
      type: GraphQLInt
    },
    displayPeriod: {
      type: GraphQLBoolean
    },
    from: {
      type: GraphQLString
    },
    to: {
      type: GraphQLString
    },
    timezone: {
      type: GraphQLString
    },
    isAllDay: {
      type: GraphQLBoolean
    },
    displayType: {
      type: GraphQLString
    },
    published: {
      type: GraphQLBoolean
    },
    lookupNumber: {
      type: GraphQLInt
    },
    itemsHeading: {
      type: GraphQLString
    },
    images: {
      type: new GraphQLList(new GraphQLNonNull(IdInput))
    },
    audios: {
      type: new GraphQLList(new GraphQLNonNull(IdInput))
    },
    videos: {
      type: new GraphQLList(new GraphQLNonNull(IdInput))
    },
    items: {
      type: new GraphQLList(new GraphQLNonNull(ExhibitItemInput))
    },
    relatedContent: {
      type: new GraphQLList(new GraphQLNonNull(RelatedContentInput))
    },
    exhibitMapLocations: {
      type: new GraphQLList(new GraphQLNonNull(ExhibitMapLocationInput))
    },
    translations: {
      type: new GraphQLList(new GraphQLNonNull(ExhibitTranslationInput))
    },
    linkedGuideId: {
      type: GraphQLInt
    },
    lastExpiredDate: {
      type: GraphQLString
    },
    expirationEnabled: {
      type: GraphQLBoolean
    },
    hideOnExpiration: {
      type: GraphQLBoolean
    }
  }
})
