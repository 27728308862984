import styled from 'styled-components'
import { Dialog } from 'client/dsm/Dialog/Dialog'
import Spinner from 'client/dsm/Spinner/Spinner'
import { Label1CSS } from 'client/components/TextStyles'

const StyledDialog = styled(Dialog)`
  min-width: 224px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const LoadingText = styled.div`
  ${Label1CSS};
  margin-top: 16px;
  text-align: center;
`

interface ILoadingOverlayWithDialogProps {
  loadingText?: string
}

const LoadingOverlayWithDialog = ({ loadingText }: ILoadingOverlayWithDialogProps) => {
  return (
    <StyledDialog>
      <Spinner />
      <LoadingText>{loadingText}</LoadingText>
    </StyledDialog>
  )
}

export default LoadingOverlayWithDialog
