import ContextualHelp, {
  ContextualHelpTextSection
} from 'client/components/ContextualHelp/ContextualHelp'
import { ContextualHelpImage } from 'client/components/ContextualHelp/styledComponents'
import { Trans } from 'react-i18next'
import { t } from 'client/i18n'

const organizationImage = require('client/assets/images/contextualHelp/organization_image.png')
const squareLogo = require('client/assets/images/contextualHelp/square_logo.png')
const rectangularLogo = require('client/assets/images/contextualHelp/rectangular_logo.png')

export function OrganizationImageContextualHelp() {
  return (
    <ContextualHelp
      helpSize="large"
      header={t('Organization Image')}
      helpContent={
        <>
          <ContextualHelpTextSection>
            <Trans i18nKey="The organization image is your first opportunity to make an impression on your guide’s users. It appears in several places, including in the list of guides on the Explore screen." />
          </ContextualHelpTextSection>
          <ContextualHelpTextSection>
            {t('Recommendations:')}
            <ul>
              <li>{t('Highlight a unique, compelling, or recognizable feature of your space;')}</li>
              <li>{t('Showcase marquee content; OR')}</li>
              <li>{t('Convey your vivid on-site experience, events, or programming')}</li>
            </ul>
            <ContextualHelpImage src={organizationImage} />
          </ContextualHelpTextSection>
        </>
      }
      tooltipContent={t('More on the organization image')}
    />
  )
}

export function SquareLogoContextualHelp() {
  return (
    <ContextualHelp
      helpSize="large"
      header={t('Square Logo')}
      helpContent={
        <ContextualHelpTextSection>
          <Trans i18nKey="The square logo appears next to your organization’s name on the Explore screen." />
          <ContextualHelpImage src={squareLogo} />
        </ContextualHelpTextSection>
      }
      tooltipContent={t('More on the square logo')}
    />
  )
}

export function RectangularLogoContextualHelp() {
  return (
    <ContextualHelp
      helpSize="large"
      header={t('Rectangular Logo')}
      helpContent={
        <>
          <ContextualHelpTextSection>
            <Trans i18nKey="The rectangular logo appears at the top of your guide’s Home screen." />
          </ContextualHelpTextSection>

          <ContextualHelpTextSection>
            {t('Recommendations:')}
            <ul>
              <li>{t('Use landscape orientation')}</li>
              <li>{t('Use a transparent background')}</li>
              <li>{t('Upload the highest resolution possible')}</li>
            </ul>
            <ContextualHelpImage src={rectangularLogo} />
          </ContextualHelpTextSection>
        </>
      }
      tooltipContent={t('More on the rectangular logo')}
    />
  )
}

export function SplashLogoContextualHelp() {
  return (
    <ContextualHelp
      tooltipContent={t('More on the splash logo')}
      header={t('Splash Logo')}
      helpContent={
        <>
          <ContextualHelpTextSection>
            {t('The optional splash logo is overlaid on your full-screen image.')}
          </ContextualHelpTextSection>
          <ContextualHelpTextSection>
            {t('Recommendations:')}
            <ul>
              <li>{t('Use landscape orientation')}</li>
              <li>{t('Choose a color that contrasts with the image to ensure readability')}</li>
              <li>{t('Use a transparent background')}</li>
              <li>{t('Upload the highest resolution possible')}</li>
            </ul>
          </ContextualHelpTextSection>
        </>
      }
      helpSize="large"
    />
  )
}
