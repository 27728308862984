import * as React from 'react'
import { IconGroup, RemoveButton, Tag } from 'client/components/ContentRow/styledComponents'
import { Note } from 'client/components/ContentRow/Note'

interface IIconGroupProps {
  onRemove?: () => void
  tags?: string[] | null
  note?: React.ReactNode
  isSelected?: boolean
  iconNodes?: React.ReactNode
  showRemoveIcon?: boolean
}
export const IconGroupContainer = ({
  onRemove,
  tags,
  note,
  isSelected,
  iconNodes,
  showRemoveIcon
}: IIconGroupProps) => {
  return (
    <IconGroup>
      {iconNodes}
      {showRemoveIcon && <RemoveButton onRemove={onRemove} />}
      <Note>{note}</Note>
      <div>
        {tags?.map((tag) => (
          <Tag key={tag} isSelected={isSelected}>
            {tag}
          </Tag>
        ))}
      </div>
    </IconGroup>
  )
}
