import { memo } from 'react'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getMapById } from 'client/redux/selectors/maps'
import { IBuildingsURLParams, IReduxState } from 'client/types'
import { GQLExhibit, GQLItem } from 'shared/graphql/types/graphql'
import { isItemContent, getItemDescription } from 'shared/util/items'
import useMapLocationContent from 'client/screens/AppEditor/MapEditor/useMapLocationContent'
import LoadingOverlay from 'client/components/LoadingOverlay/LoadingOverlay'
import MapTooltip, { IMapTooltip } from './MapLocationTooltip/MapLocationTooltip'
import MapLocation, { IMapLocation } from './MapLocation'
import FloorTooltipMenu from './MapLocationTooltip/FloorTooltipMenu'

interface ITooltipBody {
  header?: string
  subheader?: string
}

const getBody = (content: GQLExhibit | GQLItem): ITooltipBody => {
  return isItemContent(content) ? getItemDescription(content) : { header: content.title }
}

const FloorMapLocationTooltip = memo<IMapTooltip>((incomingProps) => {
  const { locationId } = incomingProps
  const { buildingId, floorId: floorIdParam } = useParams<IBuildingsURLParams>()
  const floorId = parseInt(floorIdParam!, 10)
  const floor = useSelector((state: IReduxState) => getMapById(state, { buildingId, floorId }))
  const { mapLocationContents: contents, loading } = useMapLocationContent(locationId)

  const mapLocation = _.find(floor?.mapLocations, { id: locationId })!
  const content = contents.length ? contents[0] : null
  const showTotalContent = contents.length > 1
  const totalContent = showTotalContent ? contents.length - 1 : 0
  const body = content ? getBody(content) : null

  return (
    <>
      <MapTooltip
        {...incomingProps}
        locationName={mapLocation.name}
        menuPopout={FloorTooltipMenu}
        body={{ ...body, showTotalContent, totalContent }}
      />
      {loading && <LoadingOverlay />}
    </>
  )
})

export default (props: IMapLocation) => (
  <MapLocation {...props} tooltipElement={FloorMapLocationTooltip} />
)
