import { useQuery } from '@apollo/client'
import _ from 'lodash'
import GQLErrorRenderer from 'client/components/GQLErrorRenderer'
import FilterableMiniPicker from 'client/components/MiniPicker/FilterableMiniPicker/FilterableMiniPicker'
import { PickerItemOrExhibitRow } from 'client/components/ContentRow/PickerRow'
import { COMMON_CONTENT_FILTER_CRITERIA } from 'client/util/filters'
import { IContent } from 'client/types'
import gql from 'graphql-tag'
import { GQLMuseum } from 'shared/graphql/types/graphql'

const FEATURABLE_CONTENT_QUERY = gql`
  query featurableContentQuery($museumId: Int!) {
    museum(id: $museumId) {
      id
      items {
        id
        uuid
        title
        date
        type
        scientificName
        commonName
        published
        uuid
        creators {
          id
          name
        }
        images {
          id
          thumbnail
          hero
          full
        }
        locales {
          code
          englishName
        }
      }
      exhibits {
        id
        type
        uuid
        title
        published
        displayPeriod
        from
        to
        timezone
        isAllDay
        uuid
        images {
          id
          thumbnail
          hero
          full
        }
        locales {
          code
          englishName
        }
      }
    }
  }
`

interface IFeaturesMiniPickerProps {
  selectedUuids: string[]
  onSubmit: (ids: string[], pickerData: any) => void
}

const FeaturesMiniPicker = ({ onSubmit, selectedUuids }: IFeaturesMiniPickerProps) => {
  const { data, loading, error } = useQuery<{ museum: GQLMuseum }>(FEATURABLE_CONTENT_QUERY)

  if (error) {
    return <GQLErrorRenderer error={error} />
  }

  if (loading) {
    return null
  }

  const { items, exhibits } = data!.museum

  const pickerData: IContent[] = _([...Object.values(items), ...Object.values(exhibits)])
    .filter('published')
    .reject((value: IContent) => _.includes(selectedUuids, value.uuid))
    .value()

  return (
    <FilterableMiniPicker
      items={pickerData}
      contentName="Content"
      rowComponent={PickerItemOrExhibitRow}
      filterCriteria={COMMON_CONTENT_FILTER_CRITERIA}
      onSubmit={(ids: string[]) => onSubmit(ids, pickerData)}
      idField="uuid"
    />
  )
}

export default FeaturesMiniPicker
