import { useContext } from 'react'
import gql from 'graphql-tag'
import TranslationForm from 'client/components/TranslationForm/TranslationForm'
import { TranslationFormContext } from 'client/components/TranslationForm/TranslationFormContext'
import useCoercedParam from 'client/hooks/useCoercedParam'
import FormField from 'client/components/TranslationForm/TranslatableFormField'
import FormFieldSection from 'client/components/TranslationForm/TranslatableFormFieldSection'
import TextInputFormField from 'client/components/TranslationForm/TranslatableTextInputField'
import RichTextEditor from 'client/components/Formik/RichTextEditor/RichTextEditor'
import AudiosList from 'client/components/PickerLists/AudiosList'
import VideosList from 'client/components/PickerLists/VideosList'
import RelatedContent from 'client/screens/Catalog/forms/shared/RelatedContent'
import MapLocations from 'client/screens/Catalog/forms/shared/MapLocations'
import { createApiConfig } from 'client/components/TranslationForm/util'
import useValidateFeatured from 'client/components/TranslationForm/useValidateFeatured'
import ImagesListField from 'client/screens/Catalog/forms/shared/ImagesListField'
import { ExhibitInput } from 'shared/graphql/mutations/ExhibitInput'
import { DisplayPeriodType, ExhibitionType } from 'shared/constants/exhibits'
import { EventSchemaClient } from 'client/validation/Exhibition'
import { EXHIBITION_TITLE_MAX_LENGTH } from 'shared/validation/constants'
import { t } from 'client/i18n'
import extractFromToDatesAndCleanupExpirationData, {
  convertToLocalDate,
  getDefaultTimezone
} from 'client/screens/Catalog/forms/shared/fromToDates'
import LinkedGuideSelectBoxField from 'client/screens/Catalog/forms/shared/LinkedGuideSelectBoxField'
import { parseInitialHideOnExpirationValue } from 'client/util/exhibits'
import DisplayDateTime from './DisplayDateTime'

const apiConfig = createApiConfig(
  'exhibit',
  gql`
    query getSingleEvent($id: Int!) {
      exhibit(id: $id) {
        id
        type
        externalId
        uuid
        from
        to
        timezone
        isAllDay
        linkedGuideId
        lastExpiredDate
        expirationEnabled
        hideOnExpiration
        images {
          id
        }
        audios {
          id
        }
        videos {
          id
        }
        relatedContent {
          item {
            uuid
            id
          }
          exhibit {
            uuid
            id
          }
          relationshipType
        }
        exhibitMapLocations {
          mapLocation {
            id
          }
          featured
        }
        translations {
          locale {
            englishName
            code
          }
          title
          information
        }
      }
    }
  `
)

const EventFormView = () => {
  const { getFieldName } = useContext(TranslationFormContext)

  return (
    <>
      <ImagesListField name={getFieldName('images')} />

      <TextInputFormField
        label={t('* Title')}
        name={getFieldName('title')}
        maxLength={EXHIBITION_TITLE_MAX_LENGTH}
      />

      <DisplayDateTime />

      <FormFieldSection
        label={t('Show on Map')}
        description={t(
          'Choose a location pin to help visitors find the content on your map. Manage your pins on the Map page.'
        )}
        translatable={false}
      >
        <FormField translatable={false}>
          <MapLocations name={getFieldName('exhibitMapLocations')} />
        </FormField>
      </FormFieldSection>

      <FormFieldSection>
        <RichTextEditor
          name={getFieldName('information')}
          label={t('Description')}
          fieldContainer={FormField}
        />
      </FormFieldSection>

      <LinkedGuideSelectBoxField />

      <FormFieldSection label={t('Audio')} translatable={false}>
        <FormField translatable={false}>
          <AudiosList name={getFieldName('audios')} />
        </FormField>
      </FormFieldSection>

      <FormFieldSection label={t('Video')} translatable={false}>
        <FormField translatable={false}>
          <VideosList name={getFieldName('videos')} />
        </FormField>
      </FormFieldSection>

      <FormFieldSection label={t('Related')} translatable={false}>
        <FormField translatable={false}>
          <RelatedContent name={getFieldName('relatedContent')} />
        </FormField>
      </FormFieldSection>
    </>
  )
}

const EventForm = () => {
  const type = ExhibitionType.EVENT
  const { validateDeleteFeatured } = useValidateFeatured('exhibit', type)
  const contentId = useCoercedParam<number>('id')

  return (
    <TranslationForm
      contentName="Event"
      contentId={contentId}
      apiConfig={apiConfig}
      validationSchema={EventSchemaClient}
      defaultValues={{
        type,
        // TODO: keep displayPeriod in event for yup validation, may refactor yup and no longer need it
        displayPeriod: true,
        // TODO: keep displayPeriodType in event for yup validation, may refactor yup and no longer need it
        displayPeriodType: DisplayPeriodType.DATE_RANGE,
        isAllDay: false,
        timezone: getDefaultTimezone(),
        // only new events created after Expiration feature rolledout can set expirationEnabled to true
        expirationEnabled: true
      }}
      inputType={ExhibitInput}
      initialValuesParser={(values) => {
        return {
          ...values,
          displayPeriodType: DisplayPeriodType.DATE_RANGE,
          hideOnExpiration: parseInitialHideOnExpirationValue({
            expirationEnabled: values.expirationEnabled,
            hideOnExpiration: values.hideOnExpiration
          }),
          // NOTE: We convert the date into a value that will format the same way in the browser timezone
          // For example, if the event is at Jan 10 at 7pm in Paris, and the user has their system timezone set to NYC
          // then we want the equivalent of Jan 10 at 7pm _in New York_.  We use date-fns-tz `toZonedTime` for this
          // We then convert back to the correct French (or whatever selected) timezone when submitting in the submitValuesParser
          // This keeps means we only have to do with this timezone stuff in one place, and underlying form components don't have to.
          // They all just deal with system-local times and the form code will convert these back to the proper timezone on submit
          // The downside of this is that the value in the form state is _technically_ not the actual Date/time of the event
          from: convertToLocalDate(values.from, values.timezone),
          to: convertToLocalDate(values.to, values.timezone)
        }
      }}
      submitValuesParser={(values) => {
        return {
          ...values,
          ...extractFromToDatesAndCleanupExpirationData(values)
        }
      }}
      validateDelete={validateDeleteFeatured}
      additionalFields={{ nontranslatable: ['displayPeriodType'] }}
    >
      <EventFormView />
    </TranslationForm>
  )
}
export default EventForm
