import type {} from 'react-select/base'
import { useMemo, useRef } from 'react'
import Select, {
  components as SelectComponents,
  Props as SelectProps,
  OptionProps,
  GroupBase,
  SingleValue
} from 'react-select'
import _ from 'lodash'
import CheckmarkSVG from 'client/assets/svg/icon/check_20.svg'
import ErrorSVG from 'client/assets/svg/icon/exclamation_20_filled.svg'
import { ThemeType } from 'client/types'
import { LightThemeStyles, DarkThemeStyles } from './themes'

// Extend base props to add in our additional props to be used by styles
declare module 'react-select/base' {
  // Based on the recommended approach by react-select - https://react-select.com/typescript#custom-select-props
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  export interface Props<Option, IsMulti extends boolean, Group extends GroupBase<Option>> {
    selectedHighlightColor?: string
    hasError?: boolean
  }
}

export interface ISelectBoxOptions {
  value: any
  label: string
  isError?: boolean
  options?: ISelectBoxOptions[]
}

export interface ISelectBoxProps<OptionType = ISelectBoxOptions>
  extends Omit<SelectProps<OptionType, false>, 'theme'> {
  onChange: (event) => void
  theme?: ThemeType
}

export const Option = (props: OptionProps<any, any>) => {
  const { isSelected, data } = props
  const selectedIcon = isSelected ? <CheckmarkSVG /> : null
  const errorIcon = data?.isError ? <ErrorSVG /> : null

  return (
    <SelectComponents.Option {...props}>
      {props.children}
      {selectedIcon || errorIcon}
    </SelectComponents.Option>
  )
}

const SelectBox = (props: ISelectBoxProps) => {
  const { name, onChange, theme = ThemeType.LIGHT, components = {}, styles, ...restProps } = props

  const selectRef = useRef<any>()

  const adjustedStyles = useMemo(() => {
    const themeStyles = theme === ThemeType.LIGHT ? LightThemeStyles : DarkThemeStyles
    return !_.isEmpty(styles) ? styles : themeStyles
  }, [styles, theme])

  const handleInputChange = (changedValue: string) => {
    // reset scroll to top when input changes else react-select will keep the scroll position
    if (selectRef?.current?.select?.menuListRef && changedValue !== '') {
      selectRef.current.select.menuListRef.scrollTop = 0
    }
  }

  const handleSelectChange = (selected: SingleValue<ISelectBoxOptions>) => {
    onChange({ target: { name, value: selected?.value } })
  }

  return (
    <Select
      classNamePrefix="docent-select"
      unstyled={true}
      styles={adjustedStyles}
      onChange={handleSelectChange}
      components={{ Option, ...components }}
      menuPlacement="bottom"
      ref={selectRef}
      onInputChange={handleInputChange}
      menuShouldScrollIntoView={false}
      {...restProps}
    />
  )
}

export default SelectBox
