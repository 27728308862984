import { DocumentNode } from '@apollo/client/core'
import { useQuery } from '@apollo/client'
import _ from 'lodash'
import { extractGQLData } from 'client/util/graphql'
import { useDelete, usePost } from 'client/hooks/api'
import { refetchActiveQueries } from 'client/apollo'
import { useState } from 'react'
import { AxiosError } from 'axios'
import { useNavigate } from 'react-router-dom'
import { flash } from 'client/redux/actions/flash'
import { useDispatch } from 'react-redux'
import { t } from 'client/i18n'

interface IUseAdminApiArgs {
  gqlQuery: DocumentNode
  url: string
  id?: number
  successToastMessage?: string
}
export default function useAdminApi({
  url,
  gqlQuery,
  id,
  successToastMessage = t('Changes saved')
}: IUseAdminApiArgs) {
  const [error, setError] = useState<AxiosError | null>(null)
  const dispatch = useDispatch()

  const navigate = useNavigate()
  const { data, loading: isFetching } = useQuery(gqlQuery, {
    variables: { id },
    skip: !_.isFinite(id)
  })

  const onSuccess = () => {
    navigate('..')
    dispatch(flash(successToastMessage))
    refetchActiveQueries()
  }

  const onError = (e) => {
    setError(e)
  }

  const [createUpdate, isCreatingUpdating] = usePost(id ? `${url}/${id}` : url, {
    onSuccess,
    onError
  })

  const [doDelete, isDeleting] = useDelete(`${url}/${id}`, {
    onSuccess,
    onError
  })

  const isLoading = isFetching || isCreatingUpdating || isDeleting

  return {
    object: data ? extractGQLData(data) : null,
    createUpdate,
    doDelete: id ? doDelete : null,
    isLoading,
    error
  }
}
