import { ITableCellProps } from 'client/dsm/Table/Table'
import { GQLVideo } from 'shared/graphql/types/graphql'
import styled from 'styled-components'
import { Body2CSS } from 'client/components/TextStyles'
import { t } from 'client/i18n'
import { LeftGutterRendererWrapper } from 'client/screens/Catalog/grids/shared/LeftGutterWrapper'
import { MediaStatusIcon } from 'client/screens/Catalog/grids/shared/MediaStatusIcon'

const THUMBNAIL_WIDTH = 140
const THUMBNAIL_HEIGHT = 80

const StyledVideo = styled.video`
  display: block;
  width: ${THUMBNAIL_WIDTH}px;
  height: ${THUMBNAIL_HEIGHT}px;
`

interface IProcessingOverlayProps {
  error?: boolean | null
}
const ProcessingOverlay = styled.div<IProcessingOverlayProps>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 4px;
  background-color: rgba(0, 0, 0, 0.75);
  box-shadow: ${({ error }) =>
    error
      ? 'inset 0 0 0 2px var(--color-status-error), inset 0 0 0 3px var(--color-white)'
      : 'none'};
`

const ProcessingText = styled.div`
  ${Body2CSS};
  margin-left: 4px;
  color: var(--color-white);
`

function InnerVideoPreview(props: ITableCellProps<GQLVideo>) {
  const { rowData: video } = props
  const { isProcessing, isChildrenProcessingError, url, sourceUrl, posterImageUrl } = video

  let message: string | null = null
  if (isProcessing) {
    message = t('Processing')
  } else if (isChildrenProcessingError) {
    message = t('Unable to process')
  }

  return (
    <>
      <StyledVideo
        src={(url || sourceUrl)!}
        controls={true}
        disablePictureInPicture={true}
        poster={posterImageUrl!}
        onClick={(event) => event.stopPropagation()}
        preload="metadata"
        controlsList="nodownload noplaybackrate nofullscreen noremoteplayback"
      />

      {message && (
        <ProcessingOverlay error={isChildrenProcessingError}>
          <ProcessingText>{message}</ProcessingText>
        </ProcessingOverlay>
      )}
    </>
  )
}
const VideoPreview = LeftGutterRendererWrapper(InnerVideoPreview, MediaStatusIcon)

export default VideoPreview
