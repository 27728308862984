import IVersionInfo from 'shared/VersionInfo'
import { AnyAction } from 'redux'
import { SET_SELECTED_VERSION_INFO } from './types'

export interface ISetSelectedVersionAction extends AnyAction {
  selectedVersionInfo: Partial<IVersionInfo>
  reload: boolean
}
export const setSelectedVersionInfo = (
  selectedVersionInfo: Partial<IVersionInfo>,
  reload: boolean = true
): ISetSelectedVersionAction => ({
  type: SET_SELECTED_VERSION_INFO,
  selectedVersionInfo,
  reload
})
