import { memo, useMemo, useCallback } from 'react'
import _ from 'lodash'
import gql from 'graphql-tag'
import GQLErrorRenderer from 'client/components/GQLErrorRenderer'
import { COMMON_CONTENT_FILTER_CRITERIA } from 'client/util/filters'
import { PickerItemOrExhibitRow } from 'client/components/ContentRow/PickerRow'
import FormField from 'client/components/Form/FormField/FormField'
import {
  IReorderableRowProps,
  ReorderableExhibitRow,
  ReorderableItemRow
} from 'client/components/ContentRow/ReorderableRow'
import styled from 'styled-components'
import StringInput from 'client/components/Form/StringInput/StringInput'
import useField from 'client/hooks/useField'
import useTranslationContextAwareQuery from 'client/hooks/useTranslationContextAwareQuery'
import { GQLRelatedContent } from 'shared/graphql/types/graphql'
import { RELATIONSHIP_TYPE_MAX_LENGTH } from 'shared/validation/constants'
import { t } from 'client/i18n'
import ListWithPicker from './ListWithPicker'

// TODO: Avoid having to pass in a container to only override margin
const ListWithPickerFieldContainer = styled.div`
  margin-top: 0;
`

const RelationshipTypeInput = styled(StringInput)`
  margin-top: 0 !important;
`

const TextContainer = styled.span`
  white-space: nowrap;
  margin-right: 8px;
`

const ITEMS_AND_EXHIBITS_GRAPHQL_QUERY = gql`
  query itemsAndExhibits($museumId: Int!) {
    museum(id: $museumId) {
      id
      items {
        id
        date
        uuid
        title
        type
        scientificName
        commonName
        published
        updatedAt
        creators {
          id
          name
        }
        images {
          id
          hero
          thumbnail
        }
        locales {
          code
          englishName
        }
      }
      exhibits {
        id
        type
        uuid
        title
        published
        displayPeriod
        from
        to
        timezone
        isAllDay
        updatedAt
        images {
          id
          hero
          thumbnail
        }
        locales {
          code
          englishName
        }
      }
    }
  }
`

function RelatedContentRow(props: IReorderableRowProps<GQLRelatedContent>) {
  const { isDragging, value, onRemove, onEdit, showGrabber } = props

  const { item, exhibit, relationshipType } = value

  const handleOnChange = useCallback(
    (e) => {
      onEdit!({ ...value, relationshipType: e.target.value })
    },
    [onEdit, value]
  )

  const flexComponent = () => (
    <>
      <TextContainer>{t('Relationship Type:')}</TextContainer>
      <FormField inline={true}>
        <RelationshipTypeInput
          name="relationshipType"
          value={relationshipType || ''}
          placeholder={t('e.g., More by this artist')}
          onChange={handleOnChange}
          maxLength={RELATIONSHIP_TYPE_MAX_LENGTH}
          hasBorder={false}
        />
      </FormField>
    </>
  )

  if (item) {
    return (
      <ReorderableItemRow
        value={item}
        flexComponent={flexComponent}
        onRemove={onRemove}
        isDragging={isDragging}
        showGrabber={showGrabber}
      />
    )
  }
  return (
    <ReorderableExhibitRow
      value={exhibit!}
      flexComponent={flexComponent}
      onRemove={onRemove}
      isDragging={isDragging}
      showGrabber={showGrabber}
    />
  )
}

interface IRelatedContentProps {
  name: string
}

function RelatedContent(props: IRelatedContentProps) {
  const { name } = props
  const { value, setValue } = useField(name)
  const { value: uuid } = useField('uuid')

  const { data, error, loading } = useTranslationContextAwareQuery(ITEMS_AND_EXHIBITS_GRAPHQL_QUERY)

  const allItems = useMemo(() => data?.museum?.items || [], [data])
  const allExhibits = useMemo(() => data?.museum?.exhibits || [], [data])

  const pickerValues = useMemo(() => {
    const currentUuids = _.map(
      value,
      (itemOrExhibit) => itemOrExhibit.item?.uuid || itemOrExhibit.exhibit?.uuid
    )
    return _([...allItems, ...allExhibits])
      .reject({ uuid })
      .reject((itemOrExhibit) => _.includes(currentUuids, itemOrExhibit.uuid))
      .orderBy(['updatedAt'], ['desc'])
      .value()
  }, [value, allItems, allExhibits, uuid])

  const handleCreateRelatedItem = useCallback((itemOrExhibit): any => {
    // eslint-disable-next-line no-underscore-dangle
    const typename = itemOrExhibit.__typename

    if (typename === 'Item') {
      return {
        uuid: itemOrExhibit.uuid,
        item: itemOrExhibit,
        relationshipType: null
      }
    }
    return {
      uuid: itemOrExhibit.uuid,
      exhibit: itemOrExhibit,
      relationshipType: null
    }
  }, [])

  const actualRelatedContent = useMemo(
    () =>
      _.map(value, (relatedContentObj) => {
        const { item, exhibit } = relatedContentObj

        const actualItem = item && _.find(allItems, { id: item.id })
        const actualExhibit = exhibit && _.find(allExhibits, { id: exhibit.id })

        return {
          ...relatedContentObj,
          uuid: actualItem?.uuid || actualExhibit?.uuid,
          item: item
            ? {
                ...item,
                ...actualItem
              }
            : undefined,
          exhibit: exhibit
            ? {
                ...exhibit,
                ...actualExhibit
              }
            : undefined
        }
      }),
    [value, allItems, allExhibits]
  )

  const handleRelatedContentChange = useCallback(
    (changedRelatedContent) => {
      setValue(changedRelatedContent)
    },
    [setValue]
  )

  if (error) {
    return <GQLErrorRenderer error={error} />
  }

  if (loading || !data) {
    return null
  }

  return (
    <ListWithPicker
      idFieldName="uuid"
      values={actualRelatedContent}
      pickerValues={pickerValues}
      onUpdate={handleRelatedContentChange}
      pickerRowComponent={PickerItemOrExhibitRow}
      pickerFields={COMMON_CONTENT_FILTER_CRITERIA}
      listRowComponent={RelatedContentRow}
      createItem={handleCreateRelatedItem}
      fieldContainer={ListWithPickerFieldContainer}
    />
  )
}

export default memo(RelatedContent)
