import BaseGridView from 'client/components/BaseGridView/BaseGridView'
import gql from 'graphql-tag'
import { t } from 'client/i18n'

const FIRST_OPENS_QUERY = gql`
  query firstOpens {
    firstOpensReport {
      date
      platform
      firstRuns
    }
  }
`

// TODO: do these reports even work any more?
export default function FirstOpens() {
  return (
    <BaseGridView
      contentName="First Open"
      gqlQuery={FIRST_OPENS_QUERY}
      columns={[
        { dataKey: 'date', label: t('Date') },
        { dataKey: 'platform', label: t('Platform') },
        { dataKey: 'firstRuns', label: t('First Opens') }
      ]}
    />
  )
}
