import { SupportedLogoFormats } from 'client/constants'
import isSvg from 'is-svg'
import _ from 'lodash'

export default async function isValidLogo(file: File) {
  const { type } = file

  if (!_.includes(SupportedLogoFormats, type)) {
    return false
  }

  if (type === 'image/svg+xml') {
    const text = await file.text()
    return isSvg(text)
  }

  return true
}
