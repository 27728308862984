import { Navigate, Route, Routes } from 'react-router-dom'
import MuseumVersionType from 'shared/MuseumVersionType'
import BulkUpload from 'client/screens/Tools/BulkUpload/BulkUpload'
import IVersionInfo from 'shared/VersionInfo'
import { useFeatureFlags } from 'client/hooks/useFeatureFlags'
import GuideExport from 'client/screens/Tools/GuideExport'
import AccessibilitySuggestions from 'client/screens/Tools/AccessibilitySuggestions'

interface IToolsProps {
  versionInfo: IVersionInfo
}
export default function Tools({ versionInfo }: IToolsProps) {
  const { BULK_UPLOAD } = useFeatureFlags()
  return (
    <Routes>
      {BULK_UPLOAD && versionInfo.versionType === MuseumVersionType.PREVIEW && (
        <Route path="bulk-upload" element={<BulkUpload />} />
      )}
      <Route path="guide-export" element={<GuideExport />} />
      <Route path="accessibility-suggestions/*" element={<AccessibilitySuggestions />} />
      <Route path="/*" element={<Navigate replace={true} to="/" />} />
    </Routes>
  )
}
