import _ from 'lodash'
import { AxiosError } from 'axios'
import { ApolloError } from '@apollo/client'

function defaultIfEmpty(obj, defaultValue: any = null) {
  return _.isEmpty(obj) ? defaultValue : obj
}

export function extractGQLError(error) {
  // There's a bug that prevents the error.graphQLErrors property from being populated
  // https://github.com/apollographql/apollo-client/issues/6222
  const graphQLErrors = defaultIfEmpty(error.graphQLErrors)
  const networkErrors = defaultIfEmpty(error.networkError?.result?.errors)
  const errorMessage = [{ message: error.message }]
  const errorDetails = graphQLErrors || networkErrors || errorMessage
  return _.map(
    errorDetails,
    (e) =>
      `${e.message}\n${
        e.extensions?.stacktrace ? JSON.stringify(e.extensions.stacktrace, null, 2) : ''
      }`
  )
}

function extractAxiosError(error) {
  const isAxiosError = _.get(error, 'name') === 'AxiosError'
  const axiosErrorMessage = isAxiosError && ((error as AxiosError)?.response?.data as any)?.message
  return axiosErrorMessage
}

export type IExtractDisplayErrorMessageProps = string | Error | ApolloError | undefined

export default function extractDisplayErrorMessage(error: IExtractDisplayErrorMessageProps) {
  if (_.isString(error)) {
    return error
  }
  return extractAxiosError(error) || defaultIfEmpty(extractGQLError(error), error)
}
