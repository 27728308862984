import _ from 'lodash'
import styled, { keyframes, css } from 'styled-components'

export enum SpinnerSizeType {
  SMALL = 'small',
  LARGE = 'large'
}

const SpinnerSize = {
  [SpinnerSizeType.SMALL]: { diameter: 16, strokeWidth: 2 },
  [SpinnerSizeType.LARGE]: { diameter: 48, strokeWidth: 4 }
}

export enum SpinnerColorType {
  LIGHT = 'light',
  DARK = 'dark'
}

const SpinnerColor = {
  [SpinnerColorType.DARK]: {
    backgroundColor: 'var(--color-grey-07)',
    fillColor: 'var(--color-white)'
  },
  [SpinnerColorType.LIGHT]: {
    backgroundColor: 'var(--color-grey-02)',
    fillColor: 'var(--color-blue-06)'
  }
}

const CirclesContainer = styled.div<{ diameter: number }>`
  position: relative;
  width: ${_.property('diameter')}px;
  height: ${_.property('diameter')}px;

  svg {
    width: ${_.property('diameter')}px;
    height: ${_.property('diameter')}px;
  }
`

const CircleContainer = styled.svg`
  position: absolute;
`

const Circle = styled.circle`
  fill: transparent;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
`

const rotationAnimation = keyframes`
  from { transform: rotate(0deg) }
  to { transform: rotate(360deg) }
`

const IndeterminateAnimation = css`
  animation-name: ${rotationAnimation};
  animation-duration: 0.8s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
`

const DeterminateAnimation = css`
  transition: stroke-dasharray 0.3s;
`

const ProgressCircle = styled(Circle)<{ animation: any }>`
  ${_.property('animation')}
`

export interface ISpinnerProps {
  className?: string
  progress?: number | null
  color?: SpinnerColorType
  size?: SpinnerSizeType
}

const Spinner = ({
  className,
  progress,
  color = SpinnerColorType.LIGHT,
  size = SpinnerSizeType.LARGE
}: ISpinnerProps) => {
  const { diameter, strokeWidth } = SpinnerSize[size]
  const { backgroundColor, fillColor } = SpinnerColor[color]

  const radius = diameter / 2
  const normalizedRadius = (diameter - strokeWidth) / 2
  const circumference = normalizedRadius * Math.PI * 2

  const commonProps = {
    strokeWidth,
    r: normalizedRadius,
    cx: radius,
    cy: radius
  }

  const isDeterminate = !_.isNil(progress)
  const animation = isDeterminate ? DeterminateAnimation : IndeterminateAnimation
  const strokeDasharray = isDeterminate
    ? `${(progress / 100) * circumference} ${circumference}`
    : `${circumference * 0.25} ${circumference * 0.75}`

  return (
    <CirclesContainer className={className} diameter={diameter}>
      <CircleContainer>
        <Circle {...commonProps} stroke={backgroundColor} />
      </CircleContainer>
      <CircleContainer>
        <ProgressCircle
          animation={animation}
          {...commonProps}
          stroke={fillColor}
          strokeDasharray={strokeDasharray}
        />
      </CircleContainer>
    </CirclesContainer>
  )
}

export default Spinner
