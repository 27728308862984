import styled from 'styled-components'
import { ITimeRange } from 'shared/json/IMuseumLocationDay'
import { Body1CSS } from 'client/components/TextStyles'
import {
  HoursInputSelect,
  getOptionByValue,
  TWENTY_FOUR_HOURS
} from 'client/components/HoursInputSelect'

const EnDashContainer = styled.div`
  ${Body1CSS};
  margin: 5px var(--spacing-small);
  align-self: center;
`

const HoursRowContainer = styled.div`
  display: flex;
  flex-direction: row;
`
interface IHour {
  startTime: string
  endTime: string
}

export interface IHoursInputRowChangePayload {
  range: ITimeRange | null
}

const mapTimeRangeToHour = (timeRange: ITimeRange): IHour => {
  const { start, end } = timeRange
  return {
    startTime: `${start.hour < 10 ? 0 : ''}${start.hour}:${start.minute < 10 ? 0 : ''}${
      start.minute
    }`,
    endTime: `${end.hour < 10 ? 0 : ''}${end.hour}:${end.minute < 10 ? 0 : ''}${end.minute}`
  }
}

const mapHourToTimeRange = (hour: IHour): ITimeRange => {
  const [startHour, startMinute] = hour.startTime.split(':').map(Number)
  const [endHour, endMinute] = hour.endTime.split(':').map(Number)
  return {
    start: {
      hour: startHour,
      minute: startMinute
    },
    end: {
      hour: endHour,
      minute: endMinute
    }
  } as ITimeRange
}

interface IProps {
  range: ITimeRange
  onChange: (payload: IHoursInputRowChangePayload) => void
  open24Hours?: boolean
}

const HoursRow = (props: IProps) => {
  const { range, onChange, open24Hours } = props
  const { startTime, endTime } = mapTimeRangeToHour(range)
  const optionStart = getOptionByValue(open24Hours ? TWENTY_FOUR_HOURS : startTime)
  const optionEnd = getOptionByValue(endTime)

  const onTypeaheadChange =
    (timeOption: 'startTime' | 'endTime') => (option: { target: { value: any } }) => {
      if (option) {
        const value = option?.target?.value
        const is24Hours = value === TWENTY_FOUR_HOURS
        const updatedHour =
          timeOption === 'startTime' ? { startTime: value, endTime } : { startTime, endTime: value }
        onChange({
          range: !is24Hours ? { ...range, ...mapHourToTimeRange(updatedHour) } : null
        })
      }
    }

  return (
    <HoursRowContainer>
      <HoursInputSelect value={optionStart} onChange={onTypeaheadChange('startTime')} />
      {!open24Hours ? (
        <>
          <EnDashContainer>–</EnDashContainer>
          <HoursInputSelect value={optionEnd} onChange={onTypeaheadChange('endTime')} />
        </>
      ) : null}
    </HoursRowContainer>
  )
}

export default HoursRow
