import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogHeader,
  PermanentActionMessage
} from 'client/dsm/Dialog/Dialog'
import Button from 'client/components/Button/Button'
import { t } from 'client/i18n'

interface IDeleteGuideConfirmDialogProps {
  onCancel: () => void
  onDelete: () => void
}

export function DeleteGuideConfirmDialog({ onCancel, onDelete }: IDeleteGuideConfirmDialogProps) {
  return (
    <Dialog>
      <DialogHeader>{t('Delete Guide?')}</DialogHeader>
      <DialogBody>
        <PermanentActionMessage />
      </DialogBody>
      <DialogActions>
        <Button onClick={onCancel} label={t('Cancel')} />
        <Button type="danger" onClick={onDelete} label={t('Delete Guide')} />
      </DialogActions>
    </Dialog>
  )
}

interface IMakeGuidePublicConfirmDialogProps {
  onCancel: () => void
  onMakeGuidePublic: () => void
}

export function MakeGuidePublicConfirmDialog({
  onCancel,
  onMakeGuidePublic
}: IMakeGuidePublicConfirmDialogProps) {
  return (
    <Dialog>
      <DialogHeader>{t('Confirm Legal Agreement & Make Public')}</DialogHeader>
      <DialogBody>
        <p>
          {t(
            'To make this guide available to the public, confirm that this partner has executed the Content Submission Agreement.'
          )}
        </p>
        <p>{t('This action cannot be undone.')}</p>
      </DialogBody>
      <DialogActions>
        <Button onClick={onCancel} label={t('Cancel')} />
        <Button type="primary" onClick={onMakeGuidePublic} label={t('Confirm & Make Public')} />
      </DialogActions>
    </Dialog>
  )
}
