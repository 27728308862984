import styled from 'styled-components'

import Label from '../Label/Label'

interface ICharLimitProps {
  currentLength: number
  maxLength: number
}

const CharLimit = styled.p<ICharLimitProps>`
  font-size: 11px;
  display: inline;
  color: ${(props) =>
    props.currentLength > props.maxLength ? 'var(--color-status-error)' : 'var(--color-grey-09)'};
`

const CharLimitLabel = ({ currentLength, maxLength }: ICharLimitProps) => (
  <Label>
    <CharLimit maxLength={maxLength} currentLength={currentLength}>
      {currentLength > maxLength && '* '}
      {`${currentLength}/${maxLength}`}
    </CharLimit>
  </Label>
)

export default CharLimitLabel
