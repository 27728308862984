import ContextualHelp, {
  ContextualHelpTextSection
} from 'client/components/ContextualHelp/ContextualHelp'
import { t } from 'client/i18n'

const TimeZoneContextualHelp = () => (
  <ContextualHelp
    tooltipContent={t('More on how the time zone is used')}
    helpSize="large"
    header={t('Time Zone')}
    helpContent={
      <>
        <ContextualHelpTextSection>
          {t(
            'Ensures that visitors see the correct date and time and that the content expires at the right time.'
          )}
        </ContextualHelpTextSection>
        <ContextualHelpTextSection>
          {t('Usually, the time zone matches your organization’s location.')}
        </ContextualHelpTextSection>
      </>
    }
  />
)

export default TimeZoneContextualHelp
