import _ from 'lodash'
import { AuthenticationStatus, IAuthState, IReduxState } from 'client/types'
import UserPermissionType from 'shared/UserPermissionType'

export const isUserEnrolledInMFA = (state: IReduxState) => state.authData.mfaEnrolled
export const getMFA = (state: IReduxState) => ({
  mfaPassed: state.authData.mfaPassed,
  mfaEnrolled: state.authData.mfaEnrolled,
  mfaEnabled: state.authData.mfaEnabled,
  mfaType: state.authData.mfaType
})

export const getAuthData = (state: IReduxState) => state.authData
export const isUserAdmin = (state: IReduxState) =>
  state.authData.permissionType === UserPermissionType.ADMIN

interface IAuthDataState {
  authData: IAuthState
}
export const isLoggedIn = (state: IAuthDataState) =>
  _.includes(
    [AuthenticationStatus.AUTHORIZED, AuthenticationStatus.VERIFIED],
    state.authData.authStatus
  )

export const getAuthError = (state: IReduxState) => getAuthData(state).error
