import ms from 'ms'
import _ from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import api from 'client/util/api'
import { UsageReportServiceJobType } from 'shared/constants/usageReport'
import useInterval from 'client/hooks/useInterval'

const LATEST_JOB_POLL_INTERVAL_MS = ms('5s')

const getLatestJob = async (isLoaner: boolean) =>
  (await api.get(`/reports/usage-report/latest?isLoaner=${isLoaner ? 'true' : 'false'}`))
    .data as UsageReportServiceJobType

const useLatestJob = (isLoaner: boolean, fetchIntervalMs: number = LATEST_JOB_POLL_INTERVAL_MS) => {
  const [job, setJob] = useState<UsageReportServiceJobType | null>(null)
  const [isRefreshed, setIsRefreshed] = useState(false)
  const [isLoadingInitialFetch, setIsLoadingInitialFetch] = useState(true)

  const refreshJob = useCallback(
    () =>
      getLatestJob(isLoaner).then((latest) => {
        setJob((curJob) => {
          if (!_.isEqual(curJob, latest)) {
            setIsRefreshed(true)
            return latest
          }
          setIsRefreshed(false)
          return curJob
        })
      }),
    [isLoaner]
  )

  // Leading Fetch
  useEffect(() => {
    setIsLoadingInitialFetch(true)
    refreshJob().finally(() => setIsLoadingInitialFetch(false))
  }, [fetchIntervalMs, refreshJob])

  // Refresh periodically
  useInterval(refreshJob, fetchIntervalMs)

  return { job, isRefreshed, isLoadingInitialFetch }
}

export default useLatestJob
