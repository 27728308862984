/* eslint-disable i18next/no-literal-string */
import styled from 'styled-components'
import Scrollable, { IScrollable } from './Scrollable'

const Container = styled(Scrollable)`
  strong {
    font-weight: var(--font-weight-semi-bold);
  }
  > strong + p {
    margin-top: var(--spacing-xsmall);
  }
`

type IPrivacyNoticeContentProps = Omit<IScrollable, 'children'>

export default (props: IPrivacyNoticeContentProps) => {
  return (
    <Container maskBottom={true} {...props}>
      <p>LAST UPDATED: July 18, 2024</p>
      <p>
        Bloomberg Philanthropies Support LLC and its affiliates (“BP” or “we” or “us”) respect the
        privacy rights of its end users and are committed to protecting your privacy on the
        Bloomberg Connects Content Management System (“CMS”). This Privacy Notice applies to
        personal information collected through the CMS and does not apply to any other product or
        service, unless otherwise expressly indicated. Your use of the CMS constitutes your
        agreement and acknowledgment of the terms of this Privacy Notice.
      </p>
      <strong>PERSONAL INFORMATION WE COLLECT</strong>
      <p>
        We, or where relevant a designated service provider, collects and processes personal
        information, such as during registration for the CMS or whilst using the CMS, which may
        include personal information your employer provides, your name, email address, information
        regarding your use and interaction with the CMS, contact information and password
        information. If you do not provide certain personal information, not all functionality will
        be available.
      </p>
      <p>
        We, and authorized third parties (e.g., service providers/vendors), may place, view, and/or
        use “cookies,” web server logs, web beacons, and other electronic mechanisms to collect
        information about your use of the CMS. This information may include, but is not limited to,
        information about the IP address of your computer, browser type, language, device
        information, location data, operating system, the state or country from which you accessed
        the CMS, the content visited, the date and the time of a visit, the number of links you
        click and function you use within the CMS. Most browsers are set to accept cookies by
        default. If you prefer, you can usually set your browser to disable cookies, or to alert you
        when cookies are being sent. However, note that portions of this Site may not function
        properly if you disable cookies. To opt-out of Google Analytics, please click{' '}
        <a href="https://tools.google.com/dlpage/gaoptout" target="_blank" rel="noreferrer">
          here
        </a>
        .
      </p>
      <strong>USE AND DISCLOSURE OF PERSONAL INFORMATION</strong>
      <p>
        We, and authorized service providers/vendors, use this information for carefully considered
        business purposes which also benefit our users and which include some or all of the
        following:
      </p>
      <ul>
        <li>to provide and facilitate our services and products;</li>
        <li>to communicate with you, including to provide you with newsletters and updates;</li>
        <li>to help improve our services and products and develop new services and products;</li>
        <li>
          to provide you with information regarding BP or other parties that may be of interest to
          you;
        </li>
        <li>to enhance your experience with the CMS, including to save your preferences;</li>
        <li>
          to analyze CMS usage, including to compile or use aggregate and anonymous information
          about usage of the CMS;
        </li>
        <li>
          to protect the interests, rights, safety, or property of us or others and to monitor
          compliance with and enforce any terms of any agreement with BP;
        </li>
        <li>to operate our services and systems properly.</li>
      </ul>
      <p>
        You may instruct BP at any time not to use your personal information collected in connection
        with your request to provide you with offers and promotions by contacting us at{' '}
        <a href="mailto:privacy@bloomberg.org">privacy@bloomberg.org</a> or by following the
        unsubscribe mechanism in communications sent to you. Please also note that these processes
        may take some time to complete, consistent with applicable law. Please note that if you
        exercise choice in this manner, you will continue to receive communications concerning your
        account and products and services you requested, and in response to any requests for
        information by you.
      </p>
      <p>
        BP may share your personal information with our affiliates, authorized service providers,
        including for the purposes set out in this Privacy Notice, such as service providers who we
        rely on for data storage, disaster recovery and to perform our obligations to you.
        Information related to your use of the CMS may be aggregated, de-identified and analyzed
        with other information for the uses described in this Privacy Notice and for purposes of
        creating and distributing reports and analyses with other CMS users, but only on an
        unattributed and aggregate basis.
      </p>
      <p>
        We may also disclose your personal information if we believe in good faith that such
        disclosure is necessary: (a) to comply with the law or in response to a subpoena, court
        order, government request, or other legal process; (b) to produce relevant documents or
        information in connection with litigation, arbitration, mediation, adjudication, government
        or internal investigations, or other legal or administrative proceedings; (c) to protect the
        interests, rights, safety, or property of us, any member of us, or others; (d) to monitor
        compliance with and enforce any terms of service for the CMS; (e) to provide our services
        and products (including in connection with the transfer of any assets or allowing a
        purchaser or successor of us or any member of us, any organization in Bloomberg
        Philanthropies, or this CMS to continue to serve you), and to perform other activities
        related to such services and products; (f) to provide you with information about our
        products and services and those of third parties that may be of interest to you; or (g) to
        operate the systems that provide our services and products properly.
      </p>
      <p>
        If you participate in certain features of the CMS, please note that any information you or
        others voluntarily disclose through use of these features becomes available to other end
        users whom you have designated and will be visible to such individuals for their use. We are
        not responsible for the information that you or others choose to disclose on the CMS, and
        neither our use nor others’ use of such information is subject to this Privacy Notice.
      </p>

      <strong>ACCURACY, SECURITY, AND RETENTION OF YOUR PERSONAL INFORMATION</strong>
      <p>
        Any of your personal information collected by us through the CMS is kept on secure servers.
        We use security measures appropriate to the provision of the relevant product or services,
        such as reasonable administrative, technical, personnel, and physical measures to safeguard
        personal information on such servers. To help us protect your privacy, you should maintain
        the secrecy of any username and password you set up in connection with your use of the CMS.
        Personal information is retained for as long as is necessary for the purpose for which it is
        collected, including legitimate business reasons, and/or legal and regulatory requirements.
      </p>
      <strong>RIGHTS WITH RESPECT TO YOUR PERSONAL INFORMATION</strong>
      <p>
        Depending on the jurisdiction, you may have the right to access, correct, delete, or
        restrict use of certain personal information covered by this Privacy Notice. Depending on
        the jurisdiction, you may also have the right to request that we refrain from processing
        personal information. Please bear in mind that if you make such a request this may affect
        our ability to provide our products and services. For inquiries about your personal
        information, please contact us at{' '}
        <a href="mailto:privacy@bloomberg.org">privacy@bloomberg.org</a>. Please include the
        following information in all requests: first name, last name, and e-mail address and
        “Privacy” in the Subject line. While we will make reasonable efforts to accommodate your
        request, we also reserve the right to impose certain restrictions and requirements on such
        requests, if allowed or required by applicable laws. Please note that it may take some time
        to process your request, consistent with applicable law.
      </p>
      <strong>TRANSFER OF PERSONAL INFORMATION TO OTHER COUNTRIES</strong>
      <p>
        Some of the uses and disclosures mentioned in this Privacy Notice may involve the transfer
        and processing of your personal information to and in various countries that may have
        different levels of privacy protection than your country. By using our products and
        services, you acknowledge and agree to such transfers and processing, including to and in
        the United States. We implement appropriate safeguards, which may include but is not limited
        to European Commission standard data protection clauses, to protect your personal
        information that is transferred.
      </p>
      <strong>QUESTIONS REGARDING THIS PRIVACY NOTICE</strong>
      <p>
        If you have questions regarding this Privacy Notice, please contact us at{' '}
        <a href="mailto:privacy@bloomberg.org">privacy@bloomberg.org</a>.
      </p>
    </Container>
  )
}
